import { useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsPatch, AppsPost } from "../apiService";

export function usePostParticipant(participantBody, onSuccessFunction) {
  const queryClient = useQueryClient();

  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPost(
        token,
        "participants/PostParticipant",
        participantBody
      );
    },
    {
      onSuccess: async (data) => {
        snackbar.enqueueSnackbar(`Successfully registered`, {
          variant: "success",
        });
        await queryClient.invalidateQueries((key) =>
          key.startsWith("Programs")
        );

        onSuccessFunction();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error registering for this program. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function useWithdrawParticipant(participantDetails, onSuccessFunction) {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPatch(
        token,
        `participants/WithdrawCompassParticipant/`,
        participantDetails
      );
    },
    {
      onSuccess: async (data) => {
        snackbar.enqueueSnackbar(`Successfully withdrawn`, {
          variant: "success",
        });
        //invalidate all queries that start with 'programs'
        await queryClient.invalidateQueries((key) =>
          key.startsWith("Programs")
        );
        onSuccessFunction();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error withdrawing from this program. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}