import { useTheme } from "@mui/material/styles";

export default function CompassLogoSmall(props) {
  const theme = useTheme();

  return (
    <svg
      // Illustrator svg attributes unsupported in React
      //   version="1.1"
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      // xml:space="preserve"
      id="Layer_1"
      x="0px"
      y="0px"
      width="138px"
      height="50px"
      viewBox="69 25 138 50"
      enableBackground="new 69 25 138 50"
    >
      <g>
        <g id="hashes">
          <polygon
            fill={theme?.palette.nav?.hashes}
            points="138.147,32.158 135.126,26.873 138.237,26.118 141.167,26.963 		"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M156.027,35.058l2.023-3.474l-1.57-0.905l-1.993,3.473C155.001,34.424,155.515,34.726,156.027,35.058z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M147.933,31.403l1.027-3.866l-1.751-0.483l-1.027,3.835C146.755,31.071,147.359,31.222,147.933,31.403z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M162.914,40.675l2.899-2.899l-1.269-1.269l-2.869,2.869C162.068,39.8,162.491,40.253,162.914,40.675z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M114.83,39.407l-2.869-2.87l-1.269,1.269l2.9,2.9C113.984,40.253,114.407,39.8,114.83,39.407z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M121.988,34.122l-1.993-3.473l-1.571,0.906l2.023,3.474C120.961,34.726,121.475,34.424,121.988,34.122z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M130.294,30.92l-1.027-3.836l-1.752,0.483l1.028,3.866C129.117,31.222,129.721,31.071,130.294,30.92z"
          />
        </g>
        <g id="circle_1_">
          <path
            fill={theme?.palette.nav?.arc}
            d="M138.237,27.718c12.807,0,24.103,6.735,30.476,16.824c0.694-0.574,1.449-1.088,2.265-1.51
           C164.092,32.218,152.011,25,138.237,25c-13.773,0-25.854,7.218-32.74,18.031c0.815,0.423,1.571,0.937,2.266,1.51
           C114.135,34.453,125.432,27.718,138.237,27.718z"
          />
        </g>
        <g id="text">
          <g>
            <path
              fill={theme?.palette.nav?.logoText}
              d="M153.701,49.284c-0.362-0.906-0.845-1.692-1.51-2.386c-0.634-0.695-1.389-1.299-2.265-1.752
               c-0.876-0.423-1.843-0.695-2.899-0.695h-9.393v21.443h6.041v-6.342h3.504c1.057,0,1.993-0.272,2.868-0.695
               c0.876-0.423,1.632-0.997,2.235-1.691c0.604-0.694,1.087-1.51,1.45-2.416c0.332-0.906,0.514-1.813,0.514-2.779
               C154.245,51.065,154.064,50.159,153.701,49.284z M147.782,53.845c-0.303,0.332-0.665,0.573-0.997,0.573h-3.111v-4.833h2.961
               c0.361,0,0.725,0.211,1.087,0.544c0.362,0.332,0.543,0.997,0.543,1.872C148.265,52.848,148.114,53.513,147.782,53.845z"
            />
            <polygon
              fill={theme?.palette.nav?.logoText}
              points="128.875,65.895 134.915,65.895 134.915,44.451 128.452,44.451 123.8,54.539 119.179,44.451 
               112.867,44.451 112.867,65.895 118.605,65.895 118.605,54.689 122.229,62.572 125.372,62.572 128.875,54.689 			"
            />
            <path
              fill={theme?.palette.nav?.logoText}
              d="M69.435,55.082c0-1.299,0.242-2.598,0.725-3.866c0.483-1.268,1.208-2.416,2.145-3.412
               c0.936-0.997,2.083-1.813,3.443-2.446c1.359-0.635,2.899-0.937,4.621-0.937c2.084,0,3.866,0.423,5.376,1.299
               c1.51,0.846,2.658,1.994,3.413,3.413l-4.47,3.172c-0.211-0.514-0.453-0.967-0.816-1.299c-0.332-0.333-0.694-0.604-1.087-0.815
               c-0.393-0.211-0.815-0.333-1.268-0.423c-0.423-0.091-0.846-0.121-1.269-0.121c-0.846,0-1.57,0.181-2.205,0.514
               c-0.604,0.332-1.118,0.754-1.51,1.298c-0.393,0.543-0.695,1.147-0.876,1.813c-0.181,0.664-0.302,1.359-0.302,2.023
               c0,0.755,0.121,1.449,0.332,2.144c0.211,0.696,0.543,1.3,0.967,1.813c0.423,0.513,0.936,0.936,1.571,1.238
               c0.604,0.303,1.299,0.453,2.054,0.453c0.393,0,0.815-0.061,1.239-0.121c0.422-0.091,0.845-0.241,1.208-0.453
               c0.393-0.211,0.755-0.483,1.057-0.815s0.543-0.725,0.755-1.208l4.802,2.869c-0.332,0.786-0.814,1.48-1.449,2.114
               c-0.665,0.635-1.389,1.148-2.235,1.571c-0.846,0.423-1.721,0.755-2.688,0.966c-0.967,0.211-1.903,0.332-2.81,0.332
               c-1.601,0-3.05-0.332-4.379-0.966c-1.329-0.635-2.446-1.48-3.413-2.507c-0.967-1.058-1.691-2.236-2.235-3.565
               C69.707,57.801,69.435,56.441,69.435,55.082z"
            />
            <path
              fill={theme?.palette.nav?.logoText}
              d="M100.061,66.167c-1.631,0-3.111-0.302-4.44-0.936c-1.329-0.636-2.477-1.45-3.414-2.447
               c-0.936-1.026-1.691-2.175-2.205-3.503c-0.513-1.299-0.785-2.658-0.785-4.048c0-1.419,0.272-2.778,0.815-4.077
               c0.543-1.299,1.299-2.446,2.265-3.443c0.967-0.997,2.145-1.782,3.473-2.386c1.329-0.604,2.81-0.906,4.38-0.906
               c1.631,0,3.111,0.302,4.44,0.937c1.329,0.634,2.477,1.45,3.413,2.477c0.937,1.027,1.662,2.206,2.205,3.503
               c0.514,1.299,0.755,2.658,0.755,4.018c0,1.42-0.271,2.779-0.815,4.077c-0.543,1.3-1.298,2.447-2.265,3.443
               c-0.967,0.997-2.115,1.812-3.444,2.417C103.111,65.865,101.662,66.167,100.061,66.167z M95.198,55.294
               c0,0.725,0.091,1.419,0.302,2.113c0.21,0.665,0.513,1.27,0.906,1.813c0.393,0.513,0.906,0.937,1.51,1.269
               s1.359,0.483,2.205,0.483c0.845,0,1.571-0.151,2.205-0.514c0.635-0.332,1.148-0.755,1.541-1.299s0.695-1.147,0.875-1.843
               c0.181-0.694,0.302-1.389,0.302-2.084c0-0.725-0.091-1.419-0.302-2.084c-0.211-0.664-0.513-1.269-0.906-1.782
               c-0.423-0.513-0.937-0.936-1.54-1.237s-1.329-0.483-2.145-0.483c-0.846,0-1.571,0.15-2.205,0.483
               c-0.604,0.332-1.117,0.755-1.511,1.298c-0.392,0.544-0.694,1.148-0.905,1.813C95.289,53.904,95.198,54.6,95.198,55.294z"
            />
            <path
              fill={theme?.palette.nav?.logoText}
              d="M158.927,44.542h5.377l7.43,21.443h-5.98l-1.299-4.197h-5.708l-1.269,4.197h-6.011L158.927,44.542z
                M163.518,57.861l-1.902-6.646l-1.994,6.646H163.518z"
            />
            <path
              fill={theme?.palette.nav?.logoText}
              d="M186.11,51.549c-0.604-0.363-1.238-0.695-1.873-0.967c-0.544-0.242-1.148-0.453-1.843-0.694
               c-0.664-0.211-1.329-0.302-1.964-0.302c-0.513,0-0.936,0.091-1.238,0.242c-0.301,0.151-0.483,0.423-0.483,0.815
               c0,0.271,0.091,0.514,0.272,0.694c0.181,0.182,0.452,0.333,0.785,0.483c0.332,0.151,0.755,0.302,1.269,0.453
               c0.482,0.15,1.057,0.302,1.661,0.514c0.996,0.301,1.872,0.634,2.688,0.996c0.784,0.363,1.479,0.786,2.053,1.299
               c0.574,0.483,0.997,1.088,1.299,1.813c0.303,0.694,0.453,1.571,0.453,2.567c0,1.298-0.241,2.355-0.724,3.262
               c-0.484,0.906-1.088,1.57-1.874,2.113c-0.784,0.514-1.66,0.906-2.627,1.147c-0.967,0.242-1.963,0.333-2.96,0.333
               c-0.786,0-1.602-0.061-2.417-0.181c-0.815-0.121-1.66-0.302-2.477-0.514c-0.815-0.212-1.601-0.483-2.355-0.785
               c-0.756-0.302-1.45-0.635-2.114-1.027l2.537-5.165c0.694,0.454,1.449,0.847,2.204,1.179c0.635,0.302,1.359,0.574,2.205,0.815
               c0.815,0.242,1.661,0.362,2.508,0.362c0.634,0,1.087-0.091,1.359-0.241c0.241-0.181,0.392-0.393,0.392-0.694
               c0-0.303-0.12-0.544-0.392-0.785c-0.242-0.212-0.604-0.393-1.058-0.544s-0.967-0.332-1.51-0.483
               c-0.573-0.151-1.178-0.363-1.813-0.573c-0.937-0.333-1.752-0.665-2.446-1.058c-0.694-0.362-1.238-0.785-1.69-1.269
               c-0.454-0.483-0.756-0.997-0.997-1.601c-0.241-0.604-0.302-1.299-0.302-2.084c0-1.178,0.212-2.235,0.634-3.142
               c0.424-0.906,1.027-1.661,1.782-2.265c0.755-0.604,1.601-1.057,2.537-1.359c0.967-0.301,1.963-0.483,3.051-0.483
               c0.783,0,1.54,0.091,2.295,0.242s1.449,0.333,2.175,0.574c0.695,0.241,1.329,0.483,1.933,0.755
               c0.604,0.272,1.148,0.544,1.632,0.755L186.11,51.549z"
            />
            <path
              fill={theme?.palette.nav?.logoText}
              d="M203.99,51.549c-0.604-0.363-1.238-0.695-1.872-0.967c-0.544-0.242-1.148-0.453-1.843-0.694
               c-0.664-0.211-1.328-0.302-1.963-0.302c-0.514,0-0.938,0.091-1.239,0.242s-0.482,0.423-0.482,0.815
               c0,0.271,0.09,0.514,0.271,0.694c0.181,0.182,0.452,0.333,0.785,0.483c0.332,0.151,0.755,0.302,1.269,0.453
               c0.483,0.15,1.057,0.302,1.661,0.514c0.996,0.301,1.872,0.634,2.688,0.996c0.784,0.363,1.479,0.786,2.054,1.299
               c0.572,0.483,0.996,1.088,1.299,1.813c0.302,0.694,0.452,1.571,0.452,2.567c0,1.298-0.241,2.355-0.725,3.262
               s-1.088,1.57-1.872,2.113c-0.785,0.514-1.662,0.906-2.628,1.147c-0.967,0.242-1.964,0.333-2.96,0.333
               c-0.786,0-1.602-0.061-2.417-0.181c-0.815-0.121-1.661-0.302-2.477-0.514s-1.602-0.483-2.355-0.785
               c-0.755-0.302-1.45-0.635-2.114-1.027l2.537-5.165c0.694,0.454,1.45,0.847,2.204,1.179c0.635,0.302,1.36,0.574,2.205,0.815
               c0.815,0.242,1.661,0.362,2.507,0.362c0.635,0,1.088-0.091,1.36-0.241c0.241-0.181,0.393-0.393,0.393-0.694
               c0-0.303-0.121-0.544-0.393-0.785c-0.242-0.212-0.604-0.393-1.058-0.544s-0.967-0.332-1.51-0.483
               c-0.573-0.151-1.178-0.363-1.812-0.573c-0.938-0.333-1.753-0.665-2.446-1.058c-0.695-0.362-1.239-0.785-1.692-1.269
               s-0.755-0.997-0.996-1.601c-0.242-0.604-0.302-1.299-0.302-2.084c0-1.178,0.211-2.235,0.634-3.142
               c0.424-0.906,1.027-1.661,1.782-2.265c0.755-0.604,1.601-1.057,2.537-1.359c0.966-0.301,1.963-0.483,3.05-0.483
               c0.785,0,1.54,0.091,2.296,0.242c0.755,0.151,1.449,0.333,2.175,0.574c0.695,0.241,1.328,0.483,1.933,0.755
               s1.148,0.544,1.632,0.755L203.99,51.549z"
            />
          </g>
        </g>
        <g id="arrow">
          <g>
            <polygon
              fill={theme?.palette.nav?.arrowLight}
              points="143.674,65.895 143.733,62.966 133.224,29.289 132.741,64.567 133.164,65.895 			"
            />
            <polygon
              fill={theme?.palette.nav?.arrowDark}
              points="138.54,65.895 133.224,29.289 132.741,64.567 133.164,65.895 			"
            />
          </g>
        </g>
        <g enableBackground="new    ">
          <path
            fill={theme?.palette.nav?.logoText}
            d="M72.299,74.835v-5.011h-1.971V68.89h5.045v0.935h-1.97v5.011H72.299z"
          />
          <polygon
            fill={theme?.palette.nav?.logoText}
            points="82.933,68.89 82.933,71.344 79.858,71.344 79.858,68.89 78.754,68.89 78.754,74.835 
           79.858,74.835 79.858,72.287 82.933,72.287 82.933,74.835 84.037,74.835 84.037,68.89 		"
          />
          <polygon
            fill={theme?.palette.nav?.logoText}
            points="89.286,73.909 89.286,72.271 92.156,72.271 92.156,71.361 89.286,71.361 89.286,69.815 
           92.521,69.815 92.521,68.89 88.181,68.89 88.181,74.835 92.64,74.835 92.64,73.909 		"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M102.518,74.835v-5.011h-1.97V68.89h5.045v0.935h-1.97v5.011H102.518z"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M113.644,69.875c-0.204-0.317-0.495-0.561-0.871-0.73c-0.377-0.17-0.829-0.255-1.355-0.255h-2.446v5.945
           h1.104V73.12h1.342c0.526,0,0.978-0.085,1.355-0.256c0.376-0.17,0.667-0.413,0.871-0.73c0.204-0.316,0.306-0.693,0.306-1.129
           C113.95,70.568,113.848,70.192,113.644,69.875z M112.467,71.875c-0.246,0.207-0.613,0.311-1.1,0.311h-1.291v-2.361h1.291
           c0.487,0,0.854,0.102,1.1,0.306c0.247,0.204,0.37,0.496,0.37,0.875C112.837,71.378,112.714,71.669,112.467,71.875z"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M119.529,74.92c-0.464,0-0.909-0.066-1.333-0.199c-0.425-0.134-0.762-0.305-1.011-0.514l0.382-0.858
           c0.238,0.188,0.532,0.343,0.883,0.467c0.351,0.125,0.711,0.188,1.079,0.188c0.312,0,0.563-0.034,0.756-0.103
           c0.192-0.067,0.334-0.16,0.425-0.275c0.09-0.116,0.136-0.248,0.136-0.396c0-0.181-0.065-0.326-0.195-0.438
           c-0.13-0.11-0.298-0.198-0.505-0.263c-0.207-0.065-0.436-0.126-0.688-0.183c-0.252-0.057-0.504-0.123-0.756-0.2
           c-0.252-0.076-0.481-0.175-0.688-0.297c-0.207-0.121-0.375-0.284-0.505-0.488c-0.131-0.204-0.195-0.464-0.195-0.781
           c0-0.323,0.086-0.618,0.259-0.888c0.172-0.269,0.436-0.484,0.79-0.646c0.354-0.161,0.803-0.242,1.346-0.242
           c0.356,0,0.71,0.046,1.062,0.136c0.351,0.091,0.657,0.221,0.917,0.392l-0.348,0.857c-0.266-0.158-0.541-0.276-0.824-0.353
           s-0.555-0.115-0.815-0.115c-0.306,0-0.553,0.037-0.743,0.111c-0.189,0.073-0.329,0.17-0.416,0.288
           c-0.088,0.119-0.132,0.255-0.132,0.408c0,0.181,0.064,0.326,0.191,0.438c0.128,0.11,0.294,0.196,0.501,0.259
           c0.207,0.063,0.438,0.123,0.692,0.183s0.508,0.126,0.76,0.199c0.252,0.074,0.481,0.17,0.688,0.289s0.374,0.28,0.501,0.484
           c0.128,0.203,0.191,0.461,0.191,0.772c0,0.317-0.086,0.61-0.259,0.879c-0.172,0.27-0.437,0.484-0.794,0.646
           C120.523,74.84,120.073,74.92,119.529,74.92z"
          />
          <polygon
            fill={theme?.palette.nav?.logoText}
            points="134.809,69.815 134.809,68.89 130.469,68.89 130.469,74.835 131.573,74.835 131.573,72.559 
           134.443,72.559 134.443,71.625 131.573,71.625 131.573,69.815 		"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M142.622,74.835h1.155l-2.684-5.945h-1.087l-2.676,5.945h1.138l0.589-1.376h2.974L142.622,74.835z
            M139.429,72.593l1.113-2.602l1.117,2.602H139.429z"
          />
          <polygon
            fill={theme?.palette.nav?.logoText}
            points="153.587,74.835 153.578,68.89 152.67,68.89 150.349,72.829 147.989,68.89 147.081,68.89 
           147.081,74.835 148.134,74.835 148.134,70.947 150.088,74.155 150.58,74.155 152.535,70.896 152.542,74.835 		"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M157.74,74.835V68.89h1.104v5.945H157.74z"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M162.997,74.835V68.89h1.104V73.9h3.108v0.935H162.997z"
          />
          <polygon
            fill={theme?.palette.nav?.logoText}
            points="174.904,68.89 173.817,68.89 172.062,71.782 170.318,68.89 169.138,68.89 171.474,72.755 
           171.474,74.835 172.577,74.835 172.577,72.74 		"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M187.517,74.835h1.155l-2.684-5.945h-1.087l-2.676,5.945h1.138l0.589-1.376h2.974L187.517,74.835z
            M184.323,72.593l1.113-2.602l1.117,2.602H184.323z"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M196.646,69.875c-0.203-0.317-0.493-0.561-0.87-0.73c-0.376-0.17-0.828-0.255-1.354-0.255h-2.446v5.945
           h1.104V73.12h1.342c0.526,0,0.979-0.085,1.354-0.256c0.377-0.17,0.667-0.413,0.87-0.73c0.204-0.316,0.307-0.693,0.307-1.129
           C196.953,70.568,196.851,70.192,196.646,69.875z M195.471,71.875c-0.246,0.207-0.613,0.311-1.1,0.311h-1.291v-2.361h1.291
           c0.486,0,0.854,0.102,1.1,0.306s0.369,0.496,0.369,0.875C195.84,71.378,195.717,71.669,195.471,71.875z"
          />
          <path
            fill={theme?.palette.nav?.logoText}
            d="M205.276,69.875c-0.204-0.317-0.494-0.561-0.871-0.73s-0.828-0.255-1.354-0.255h-2.446v5.945h1.104V73.12
           h1.342c0.526,0,0.978-0.085,1.354-0.256c0.377-0.17,0.667-0.413,0.871-0.73c0.203-0.316,0.306-0.693,0.306-1.129
           C205.582,70.568,205.479,70.192,205.276,69.875z M204.1,71.875c-0.246,0.207-0.612,0.311-1.1,0.311h-1.291v-2.361H203
           c0.487,0,0.854,0.102,1.1,0.306s0.37,0.496,0.37,0.875C204.47,71.378,204.346,71.669,204.1,71.875z"
          />
        </g>
      </g>
    </svg>
  );
}
