import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Typography,
  Menu,
  MenuItem,
  DialogContent,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { faEdge, faChrome } from "@fortawesome/free-brands-svg-icons";
import { blob } from "../../services/utilities";

const edgeLanguage = [
  {
    code: "en",
    name: "English",
    introText:
      "The best way to view Compass in another language is to use your browser's built-in translation features.",
    linkText:
      "See Microsoft's instructions to set up Edge to translate automatically.",
    smallText:
      "(For iPhones, iPads, Android devices, computers, tablets, etc.)",
    primaryButtonText: "How to translate in Edge",
    secondaryButtonText: "Get Edge",
    primaryUrl:
      "https://support.microsoft.com/en-us/microsoft-edge/use-microsoft-edge-in-another-language-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl: "https://Microsoft.com/Edge?hl=en",
    flagUrl: `${blob}Flags/en.svg`,
    flagCountry: "United States",
  },
  {
    code: "es",
    name: "Español",
    introText:
      "La mejor manera de ver la Compass App en otro idioma es utilizar las funciones de traducción integradas de su navegador.",
    linkText:
      "Consulte las instrucciones de Microsoft para configurar Edge para que traduzca automáticamente.",
    smallText:
      "(Para iPhones, iPads, dispositivos Android, computadoras, tabletas, etc.)",
    primaryButtonText: "Cómo traducir en Edge",
    secondaryButtonText: "Descargar Edge",
    primaryUrl:
      "https://support.microsoft.com/es-es/microsoft-edge/usar-microsoft-edge-en-otro-idioma-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl: "https://Microsoft.com/Edge?hl=es",
    flagUrl: `${blob}Flags/es.svg`,
    flagCountry: "Spain",
  },
  {
    code: "km",
    name: "ភាសាខ្មែរ",
    introText:
      "វិធីល្អបំផុតដើម្បីមើល Compass App ជាភាសាផ្សេងទៀតគឺប្រើលក្ខណៈបកប្រែដែលភ្ជាប់មកជាមួយនៃកម្មវិធីរុករករបស់អ្នក។",
    linkText:
      "សូមមើលការណែនាំរបស់ Microsoft ដើម្បីតំឡើង Edge ដើម្បីបកប្រែដោយស្វ័យប្រវត្តិ។",
    smallText:
      "(សម្រាប់ទូរស័ព្ទ iPhone, iPad, ឧបករណ៍ Android, កុំព្យូទ័រថេប្លេត។ ល។ )",
    primaryButtonText: "វិធីបកប្រែក្នុង Edge",
    secondaryButtonText: "ទាញយក Edge",
    primaryUrl:
      "http://translate.google.com/translate?js=n&sl=auto&tl=km&u=https://support.microsoft.com/en-us/microsoft-edge/use-microsoft-edge-in-another-language-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl:
    //   "https://translate.Microsoft.com/translate?hl=&sl=auto&tl=km&u=https%3A%2F%2FMicrosoft.com%2FEdge",
    flagUrl: `${blob}Flags/km.svg`,
    flagCountry: "Cambodia",
  },
  {
    code: "ru",
    name: "Pусский",
    introText:
      "Лучший способ просматривать Compass App на другом языке - использовать встроенные функции перевода вашего браузера.",
    linkText:
      "См. Инструкции Microsoft по настройке автоматического перевода Edge.",
    smallText:
      "(Для iPhone, iPad, устройств Android, компьютеров, планшетов и т. Д.)",
    primaryButtonText: "Как перевести в Edge",
    secondaryButtonText: "Скачать Edge",
    primaryUrl:
      "https://support.microsoft.com/ru-ru/microsoft-edge/%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-microsoft-edge-%D0%BD%D0%B0-%D0%B4%D1%80%D1%83%D0%B3%D0%BE%D0%BC-%D1%8F%D0%B7%D1%8B%D0%BA%D0%B5-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl: "https://Microsoft.com/Edge?hl=ru",
    flagUrl: `${blob}Flags/ru.svg`,
    flagCountry: "Russia",
  },
  {
    code: "vi",
    name: "Tiếng Việt",
    introText:
      "Cách tốt nhất để xem Compass App bằng ngôn ngữ khác là sử dụng các tính năng dịch tích hợp của trình duyệt của bạn.",
    linkText: "Xem hướng dẫn của Microsoft để thiết lập Edge dịch tự động.",
    smallText:
      "(Đối với iPhone, iPad, thiết bị Android, máy tính, máy tính bảng, v.v.)",
    primaryButtonText: "Cách dịch trong Edge",
    secondaryButtonText: "Tải xuống Edge",
    primaryUrl:
      "http://translate.google.com/translate?js=n&sl=auto&tl=vi&u=https://support.microsoft.com/en-us/microsoft-edge/use-microsoft-edge-in-another-language-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl: "https://Microsoft.com/Edge?hl=vi",
    flagUrl: `${blob}Flags/vi.svg`,
    flagCountry: "Vietnam",
  },
  {
    code: "ko",
    name: "한국어",
    introText:
      "다른 언어로 Compass App를 보는 가장 좋은 방법은 브라우저에 내장 된 번역 기능을 사용하는 것입니다.",
    linkText:
      "Edge이 자동으로 번역되도록 설정하려면 Microsoft의 안내를 참조하세요.",
    smallText: "(iPhone, iPad, Android 기기, 컴퓨터, 태블릿 등)",
    primaryButtonText: "Edge에서 번역하는 방법",
    secondaryButtonText: "Edge 다운로드",
    primaryUrl:
      "http://translate.google.com/translate?js=n&sl=auto&tl=ko&u=https://support.microsoft.com/en-us/microsoft-edge/use-microsoft-edge-in-another-language-4da8b5e0-11ce-7ea4-81d7-4e332eec551f",
    // secondaryUrl: "https://Microsoft.com/Edge?hl=ko",
    flagUrl: `${blob}Flags/ko.svg`,
    flagCountry: "South Korea",
  },
];
const chromeLanguage = [
  {
    code: "en",
    name: "English",
    introText:
      "The best way to view Compass in another language is to use your browser's built-in translation features.",
    linkText:
      "See Google's instructions to set up Chrome to translate automatically.",
    smallText:
      "(For iPhones, iPads, Android devices, computers, tablets, etc.)",
    primaryButtonText: "How to translate in Chrome",
    secondaryButtonText: "Get Chrome",
    primaryUrl: "https://support.google.com/chrome/answer/173424?hl=en",
    secondaryUrl: "https://google.com/chrome?hl=en",
    flagUrl: `${blob}Flags/en.svg`,
    flagCountry: "United States",
  },
  {
    code: "es",
    name: "Español",
    introText:
      "La mejor manera de ver la Compass App en otro idioma es utilizar las funciones de traducción integradas de su navegador.",
    linkText:
      "Consulte las instrucciones de Google para configurar Chrome para que traduzca automáticamente.",
    smallText:
      "(Para iPhones, iPads, dispositivos Android, computadoras, tabletas, etc.)",
    primaryButtonText: "Cómo traducir en Chrome",
    secondaryButtonText: "Descargar Chrome",
    primaryUrl: "https://support.google.com/chrome/answer/173424?hl=es",
    secondaryUrl: "https://google.com/chrome?hl=es",
    flagUrl: `${blob}Flags/es.svg`,
    flagCountry: "Spain",
  },
  {
    code: "km",
    name: "ភាសាខ្មែរ",
    introText:
      "វិធីល្អបំផុតដើម្បីមើល Compass App ជាភាសាផ្សេងទៀតគឺប្រើលក្ខណៈបកប្រែដែលភ្ជាប់មកជាមួយនៃកម្មវិធីរុករករបស់អ្នក។",
    linkText:
      "សូមមើលការណែនាំរបស់ Google ដើម្បីតំឡើង Chrome ដើម្បីបកប្រែដោយស្វ័យប្រវត្តិ។",
    smallText:
      "(សម្រាប់ទូរស័ព្ទ iPhone, iPad, ឧបករណ៍ Android, កុំព្យូទ័រថេប្លេត។ ល។ )",
    primaryButtonText: "វិធីបកប្រែក្នុង Chrome",
    secondaryButtonText: "ទាញយក Chrome",
    primaryUrl:
      "https://translate.google.com/translate?hl=&sl=auto&tl=km&u=https%3A%2F%2Fsupport.google.com%2Fchrome%2Fanswer%2F173424",
    secondaryUrl:
      "https://translate.google.com/translate?hl=&sl=auto&tl=km&u=https%3A%2F%2Fgoogle.com%2Fchrome",
    flagUrl: `${blob}Flags/km.svg`,
    flagCountry: "Cambodia",
  },
  {
    code: "ru",
    name: "Pусский",
    introText:
      "Лучший способ просматривать Compass App на другом языке - использовать встроенные функции перевода вашего браузера.",
    linkText:
      "См. Инструкции Google по настройке автоматического перевода Chrome.",
    smallText:
      "(Для iPhone, iPad, устройств Android, компьютеров, планшетов и т. Д.)",
    primaryButtonText: "Как перевести в Chrome",
    secondaryButtonText: "Скачать Chrome",
    primaryUrl: "https://support.google.com/chrome/answer/173424?hl=ru",
    secondaryUrl: "https://google.com/chrome?hl=ru",
    flagUrl: `${blob}Flags/ru.svg`,
    flagCountry: "Russia",
  },
  {
    code: "vi",
    name: "Tiếng Việt",
    introText:
      "Cách tốt nhất để xem Compass App bằng ngôn ngữ khác là sử dụng các tính năng dịch tích hợp của trình duyệt của bạn.",
    linkText: "Xem hướng dẫn của Google để thiết lập Chrome dịch tự động.",
    smallText:
      "(Đối với iPhone, iPad, thiết bị Android, máy tính, máy tính bảng, v.v.)",
    primaryButtonText: "Cách dịch trong Chrome",
    secondaryButtonText: "Tải xuống Chrome",
    primaryUrl: "https://support.google.com/chrome/answer/173424?hl=vi",
    secondaryUrl: "https://google.com/chrome?hl=vi",
    flagUrl: `${blob}Flags/vi.svg`,
    flagCountry: "Vietnam",
  },
  {
    code: "ko",
    name: "한국어",
    introText:
      "다른 언어로 Compass App를 보는 가장 좋은 방법은 브라우저에 내장 된 번역 기능을 사용하는 것입니다.",
    linkText:
      "Chrome이 자동으로 번역되도록 설정하려면 Google의 안내를 참조하세요.",
    smallText: "(iPhone, iPad, Android 기기, 컴퓨터, 태블릿 등)",
    primaryButtonText: "Chrome에서 번역하는 방법",
    secondaryButtonText: "Chrome 다운로드",
    primaryUrl: "https://support.google.com/chrome/answer/173424?hl=ko",
    secondaryUrl: "https://google.com/chrome?hl=ko",
    flagUrl: `${blob}Flags/ko.svg`,
    flagCountry: "South Korea",
  },
];

function TranslationDialog(props) {
  const theme = useTheme();
  const { onClose, selectedLang, open, icon } = props;

  const handleCloseDialog = () => {
    onClose(selectedLang);
  };

  // Styles
  const dialogFlagStyles = {
    maxWidth: 100,
    float: "right",
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `2px solid ${alpha(theme?.palette?.neutral?.main, 0.2)}`,
  };
  const paragraphStyles = {
    marginBottom: "1em",
  };
  const actionsStyles = {
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  };

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="translation-info"
      open={open}
    >
      {/* {getLanguageContent(selectedLang)} */}
      <DialogTitle id="translation-info">
        {selectedLang.code === "en"
          ? "Translate..."
          : "English → " + selectedLang.name}
      </DialogTitle>
      <DialogContent>
        <img
          src={selectedLang.flagUrl}
          alt={`flag of ${selectedLang.flagCountry}`}
          style={dialogFlagStyles}
        />
        <Typography variant="body1" sx={paragraphStyles}>
          {selectedLang.introText}
        </Typography>
        <Typography variant="body1" sx={paragraphStyles}>
          <Link
            href={selectedLang.primaryUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {selectedLang.linkText}
          </Link>{" "}
          <small>{selectedLang.smallText}</small>
        </Typography>
      </DialogContent>
      <DialogActions sx={actionsStyles}>
        <Button
          variant="contained"
          color="primary"
          target="_blank"
          href={selectedLang.primaryUrl}
          endIcon={
            <FontAwesomeIcon icon={icon === "edge" ? faEdge : faChrome} />
          }
        >
          {selectedLang.primaryButtonText}
        </Button>
        {selectedLang.secondaryUrl && (
          <Button
            href={selectedLang.secondaryUrl}
            variant="outlined"
            color="primary"
            target="_blank"
            size="small"
            startIcon={<FontAwesomeIcon icon={faDownload} />}
          >
            {selectedLang.secondaryButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default function TranslationInfo(props) {
  const theme = useTheme();

  const { expanded, browser } = props;
  const [icon, setIcon] = React.useState("chrome");
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [languages, setLanguages] = React.useState(chromeLanguage);
  const [selectedLang, setSelectedLang] = React.useState(chromeLanguage[0]);

  // Styles
  const expandedBtnStyles = {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  };
  const collapsedBtnStyles = {
    "&.MuiButton-text": {
      display: "flex",
      flexDirection: "column",
    },
  };
  const menuButtonFlagsStyles = {
    width: 102,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  };
  const menuButtonFlagStyles = {
    maxHeight: 12,
    marginRight: 2,
    border: `1px solid ${alpha(theme?.palette?.neutral?.main, 0.5)}`,
  };
  const menuItemFlagStyles = {
    maxHeight: 24,
    border: `1px solid ${alpha(theme?.palette?.neutral?.main, 0.5)}`,
  };

  useEffect(() => {
    if (browser) {
      switch (browser.name) {
        case "chrome":
          setLanguages(chromeLanguage);
          setIcon("chrome");
          break;
        case "edge-chromium":
          setLanguages(edgeLanguage);
          setIcon("edge");
          break;
        default:
          setLanguages(chromeLanguage);
          setIcon("chrome");
          break;
      }
    }
  }, [browser]);
  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <span>
      {Boolean(expanded) ? (
        <>
          {languages.map((language) => (
            <Button
              sx={expandedBtnStyles}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                handleClickOpenDialog();
                setSelectedLang(language);
              }}
              key={language.code}
            >
              {language.name}
            </Button>
          ))}
          <TranslationDialog
            selectedLang={selectedLang}
            open={open}
            onClose={handleCloseDialog}
            icon={icon}
          />
        </>
      ) : (
        <>
          <Button
            sx={collapsedBtnStyles}
            size="small"
            aria-controls="translate-menu"
            aria-haspopup="true"
            onClick={handleClickOpenMenu}
          >
            <span>Translate...</span>
            <span style={menuButtonFlagsStyles}>
              {languages.map((language) => (
                <img
                  src={language.flagUrl}
                  alt={`flag of ${language.flagCountry}`}
                  key={language.code}
                  style={menuButtonFlagStyles}
                />
              ))}
            </span>
          </Button>
          <Menu
            id="translate-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {languages.map((language) => (
              <MenuItem
                onClick={() => {
                  handleClickOpenDialog();
                  handleCloseMenu();
                  setSelectedLang(language);
                }}
                key={language.code}
              >
                <ListItemIcon>
                  <img
                    src={language.flagUrl}
                    alt={`flag of ${language.flagCountry}`}
                    style={menuItemFlagStyles}
                  />
                </ListItemIcon>
                <ListItemText>{language.name}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
          <TranslationDialog
            selectedLang={selectedLang}
            open={open}
            onClose={handleCloseDialog}
            icon={icon}
          />
        </>
      )}
    </span>
  );
}
