import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsGetPdf } from "../apiService";

export function useGetStudentISRFileNames(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `students/GetStudentISRFileNames/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting ISR  file names. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetStudentISRFile(studentId, fileName) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `students/GetStudentISRFile/${studentId}?filename=${fileName}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGetPdf(token, url);
    },
    {
      enabled: false,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting ISR file. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
