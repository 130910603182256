import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Grid,
  Link,
  Typography,
  Paper,
  Skeleton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faBan,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import {
  useGetSexualHealthOptOuts,
  usePostSexualEdOptOutController,
} from "../../services/hooks/sexualHealthOptOutsHook";
import {
  formatDate,
  getCurrentSchoolYear,
  getGradeName,
} from "../../services/utilities";
import dayjs from "dayjs";
import { useUser } from "../../services/contexts/userContext";

export default function FormSexEdOptOut() {
  const theme = useTheme();
  const { user } = useUser();
  const commonStyles = useCommonStyles();
  const { activeStudent } = useActiveStudent();
  const studentId = activeStudent?.studentId;
  const schoolYear = getCurrentSchoolYear();
  const mediumPlusScreen = useMediaQuery(theme.breakpoints.up("md"));

  const sexEdOptOutsQuery = useGetSexualHealthOptOuts(studentId, schoolYear);
  const sexEdOptOuts = sexEdOptOutsQuery.data?.data;
  const [confirmOptOutOpen, setConfirmOptOutOpen] = useState(false);
  const closeDialog = () => {
    setConfirmOptOutOpen(false);
  };
  const requestBody = {
    studentId: studentId,
    schoolYear: schoolYear,
    createdDate: dayjs(),
    modifiedDate: dayjs(),
    createBy: user?.email,
    modifiedBy: user?.email,
    deleted: 0,
  };
  const submitOptOut = usePostSexualEdOptOutController(
    requestBody,
    closeDialog
  );

  const clickConfirmOptOut = () => {
    submitOptOut.mutate();
  };

  // Styles
  const wrapperStyles = {
    ...commonStyles.paperPadding,
    maxWidth: 700,
  };
  const accordionStyles = {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    backgroundColor: theme?.palette?.background?.neutral,
    "& p": {
      marginBottom: theme.spacing(2),
    },
    "&.Mui-expanded": {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(2),
      "&:last-of-type": {
        marginBottom: theme.spacing(4),
      },
      "&:first-of-type": {
        marginTop: theme.spacing(2),
      },
    },
  };

  return (
    <>
      <Paper sx={wrapperStyles}>
        <Typography variant="h5" component="h2">
          Sex Ed Opt-Out
        </Typography>
        <Typography>
          Each school year, parents and guardians have the option to opt their
          student(s) out of Sexual Education lessons. Learn about and preview
          the curriculum to make your decision.
        </Typography>
        <Accordion sx={accordionStyles}>
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography component="h3" variant="h6">
              Preview the FLASH Curriculum
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="h4" variant="h5" color="secondary">
              About
            </Typography>
            <Typography>
              FLASH is a medically accurate, age-appropriate comprehensive
              sexual health curriculum, as identified by the Office of the
              Superintendent of Public Instruction (OSPI) and adopted in
              alignment with TPS{" "}
              <Link
                target="_blank"
                href="https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-2000/post/2125"
              >
                {" "}
                Policy 2125
              </Link>
              ,{" "}
              <Link
                target="_blank"
                href="https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-2000/post/2126"
              >
                {" "}
                Policy 2126
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                href="https://www.tacomaschools.org/about/school-board/policy-manual/policy-details-page/~board/policy-2000/post/2126r"
              >
                {" "}
                Regulation 2126
              </Link>
              . The goals of the curriculum are to prevent teen pregnancy,
              sexually transmitted diseases (STDs) and sexual violence, and to
              increase family communication about sexual health-related topics
              such as dating, sex and&nbsp;abstinence.
            </Typography>
            <Typography>
              FLASH builds skills to support young people in remaining
              abstinent, as well as teaching about other methods of disease and
              pregnancy prevention. It promotes respectful communication, and
              builds skills for healthy relationships. FLASH also contains
              family homework assignments that are recommended for students to
              talk with a family member about the topics covered in class via a
              prescribed set of&nbsp;questions.
            </Typography>
            <Typography>
              FLASH provides a protocol for teachers when answering students’
              questions about values that includes referring students back to
              families to learn their specific values. As a result, students may
              come home with questions about the values and expectations you
              have for them regarding these topics. We hope this will provide a
              meaningful opportunity for you to talk about these important
              topics&nbsp;together.
            </Typography>
            <Typography>
              Students will be presented with information concerning human
              growth and development. Surveys show that young people would like
              to talk more about these issues with a parent or other trusted
              adult, and research shows that having these conversations now
              plays a very important role in helping students prevent pregnancy,
              STDs and HIV when they are older. Please consider talking with
              your&nbsp;student.
            </Typography>
            <Typography>
              Washington State Law also now requires that students (grades 5 –
              12) receive information annually concerning HIV/AIDS. Students
              will learn about how the disease attacks the body’s immune system
              and what can be done to prevent the spread of it. Students will be
              given the opportunity to ask questions during
              the&nbsp;presentation.
            </Typography>
            <br />
            <Typography component="h4" variant="h5" color="secondary">
              FLASH Curriculum Documents
            </Typography>
            <Typography>
              Preview the curriculum files maintained by the publisher on their
              website and Google&nbsp;Drive:
            </Typography>
            <Grid
              container
              spacing={2}
              flexDirection={mediumPlusScreen ? "row" : "column"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  href="https://www.kingcounty.gov/depts/health/locations/family-planning/education/FLASH/elementary-school.aspx"
                >
                  Elementary&nbsp; <small>(Grade 5)</small>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  href="https://drive.google.com/drive/folders/17scfjUBb2rBrzbo13M_Ae3ttLKMcZUeu"
                >
                  Middle&nbsp; <small>(Grades 6-8)</small>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                  href="https://drive.google.com/drive/folders/1QWJcAypodtQLNwuTzw-LkBZ3MbX52dWo"
                >
                  High&nbsp; <small>(Grades 9-12)*</small>
                </Button>
              </Grid>
            </Grid>
            <Typography variant="body2">
              <br />
              *In high school, the FLASH curriculum is taught in Health class,
              typically to 9th graders. Other students only receive HIV/AIDS
              Prevention instruction (Lessons 11 and&nbsp;12).
            </Typography>
          </AccordionDetails>
        </Accordion>

        {activeStudent?.isSecondary === false &&
        activeStudent?.grade !== "05" ? (
          <Typography>
            The Sexual Education opt-out does not apply to{" "}
            {activeStudent?.firstName} because the FLASH curriculum is not
            taught in {getGradeName(activeStudent?.grade)}. But you can preview
            the curriculum for other grades&nbsp;above.
          </Typography>
        ) : (
          <>
            {/* Loading */}
            {sexEdOptOutsQuery.isLoading && (
              <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                <Skeleton width={150} height={60} />
              </Box>
            )}

            {/* Already opted-out */}
            {sexEdOptOutsQuery.isSuccess && sexEdOptOuts?.createdDate != null && (
              <>
                <Typography>
                  Opted out on {formatDate(sexEdOptOuts.createdDate)}
                </Typography>
              </>
            )}

            {/* Not yet opted-out */}
            {sexEdOptOutsQuery.isSuccess && sexEdOptOuts?.createdDate == null && (
              <>
                <Typography>
                  Click the Opt-Out button below if you have previewed the
                  Sexual Health Education Curriculum (FLASH) and HIV/AIDS
                  prevention lessons for {activeStudent?.firstName} and are
                  requesting that this student be excused from participation in
                  the&nbsp;lesson(s).
                </Typography>
                <br />

                <Box sx={{ textAlign: "right" }}>
                  <Button
                    onClick={() => {
                      setConfirmOptOutOpen(true);
                    }}
                    variant="contained"
                    startIcon={<FontAwesomeIcon icon={faBan} />}
                  >
                    Opt {activeStudent?.firstName} Out
                  </Button>
                </Box>
                <Dialog open={confirmOptOutOpen} onClose={closeDialog}>
                  <DialogTitle>Confirm Sex Ed Opt-out?</DialogTitle>
                  <DialogContent>
                    <Typography>
                      I have previewed the Sexual Health Education Curriculum
                      (FLASH) and HIV/AIDS prevention lessons for&nbsp;
                      {activeStudent?.firstName}.
                    </Typography>
                    <br />
                    <Typography>
                      I do not wish {activeStudent?.firstName} to receive
                      instruction using the Sexual Health Education Curriculum
                      (FLASH) and HIV/AIDS prevention lessons, and I request{" "}
                      {activeStudent?.firstName} be excused from participation
                      in the&nbsp;lesson(s).
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={closeDialog}
                      disabled={submitOptOut.isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="warning"
                      variant="contained"
                      disabled={submitOptOut.isLoading}
                      endIcon={
                        submitOptOut.isLoading && <CircularProgress size={20} />
                      }
                      onClick={() => {
                        clickConfirmOptOut();
                      }}
                    >
                      Confirm Opt-out for {activeStudent?.firstName}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
}
