import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetSexualHealthOptOuts(studentId, schoolYear) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `SexualHealthOptOuts/${studentId}/${schoolYear}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting opt outs. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function usePostSexualEdOptOutController(requestBody, onSuccess) {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPost(token, `SexualHealthOptOuts`, requestBody);
    },
    {
      onSuccess: async (data) => {
        snackbar.enqueueSnackbar(`Opt-out Submitted`, {
          variant: "success",
        });
        //invalidate all queries that start with 'programs'
        await queryClient.invalidateQueries((key) =>
          key.startsWith("SexualHealthOptOuts")
        );
        onSuccess();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(`There was an error opting-out. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return mutation;
}
