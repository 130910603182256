import "./BouncingO.css";
import { useTheme } from "@mui/material/styles";

export default function BouncingO(props) {
  const theme = useTheme();

  return (
    <svg width="100" height="100" viewBox="0 -30 50 100" fill="none">
      <path
        d="M21.3679 7.3C27.5159 7.3 33.0839 9.50159 37.1439 13.2095L44.4519 10.6603L22.0639 0L0.0239258 10.5444L6.05592 12.9778C10.1159 9.38571 15.4519 7.3 21.3679 7.3Z"
        fill={theme.palette.text.primary}
        className="hat"
      />
      <path
        d="M21.4841 9.2699C15.1041 9.2699 9.42013 11.935 5.47613 16.1064C3.85213 17.8445 2.57614 19.9302 1.64814 22.2477C0.72014 24.5651 0.140137 27.2302 0.140137 29.8953C0.140137 32.6762 0.720138 35.4572 1.76414 37.7747C2.69214 39.9762 3.96813 41.9461 5.47613 43.6842C9.30413 47.8556 14.9881 50.5207 21.3681 50.5207C33.2001 50.5207 42.5961 41.4826 42.5961 29.8953C42.5961 18.308 33.3161 9.2699 21.4841 9.2699ZM21.4841 43.5683C14.4081 43.5683 7.91213 37.5429 7.91213 29.8953C7.91213 22.2477 14.2921 16.2223 21.4841 16.2223C28.5601 16.2223 35.0561 22.2477 35.0561 29.8953C35.0561 37.5429 28.5601 43.5683 21.4841 43.5683Z"
        fill={theme.palette.primary.main}
        className="letter-O"
      />
    </svg>
  );
}
