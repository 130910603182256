import {
  faHome,
  faSmile,
  faShieldAlt,
  faFlag,
  faThumbsUp,
  faCalendarCheck,
  faCalendarDays,
  faChartLine,
  faLifeRing,
  faUserPen,
  faBook,
  faBus,
  faFileCheck,
  faClipboardList,
  faVirus,
  faFileChartColumn,
} from "@fortawesome/pro-solid-svg-icons";
import { useUser } from "../../services/contexts/userContext";
import { showFeatures } from "./../../services/utilities";

// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function useNavItems() {
  const { user } = useUser();

  return user?.validUser === false
    ? []
    : [
        {
          name: "Home",
          icon: faHome,
          to: "/",
          enabled: true,
          studentsOnly: false,
        },
        {
          name: "STUDENTS ONLY",
          icon: "",
          to: "",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Badges",
          icon: faShieldAlt,
          to: "badges",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Goals",
          icon: faFlag,
          to: "goals",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Interests",
          icon: faThumbsUp,
          to: "interests",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Moods",
          icon: faSmile,
          to: "moods",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "My Journey",
          icon: faChartLine,
          to: "journey",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Help",
          icon: faLifeRing,
          to: "help",
          enabled: user.role === "student",
          studentsOnly: true,
        },
        {
          name: "Activities",
          icon: fasBeyondTheBell,
          to: "activities",
          enabled: true,
          studentsOnly: false,
        },
        {
          name: "Attendance",
          icon: faCalendarCheck,
          to: "attendance",
          enabled: showFeatures.attendance,
          studentsOnly: false,
        },
        {
          name: "Buses",
          icon: faBus,
          to: "buses",
          enabled: showFeatures.buses,
          studentsOnly: false,
        },
        {
          name: "Courses",
          icon: faBook,
          to: "courses",
          enabled: showFeatures.courses,
          studentsOnly: false,
        },
        {
          name: "Events",
          icon: faCalendarDays,
          to: "events",
          enabled: true,
          studentsOnly: false,
        },
        {
          name: "Surveys",
          icon: faClipboardList,
          to: "surveys",
          enabled: showFeatures.survey,
          studentsOnly: false,
        },
        {
          name: "Forms",
          icon: faFileCheck,
          to: "forms",
          enabled: true,
          studentsOnly: false,
        },
        {
          name: "COVID",
          icon: faVirus,
          to: "covid",
          enabled: showFeatures.covid,
          studentsOnly: false,
        },
        {
          name: "Reports",
          icon: faFileChartColumn,
          to: "reports",
          enabled: true,
          studentsOnly: false,
        },
        {
          name: "Personalization",
          icon: faUserPen,
          to: "personalization",
          enabled: true,
          studentsOnly: false,
        },
      ];
}
