export default function MicrosoftLoginBtn() {
  return (
    <svg
      width="108"
      height="41"
      viewBox="0 0 108 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M108 0H0V41H108V0Z" fill="white" />
      <path
        d="M107.498 1V40H0.502326V1H107.498ZM108 0H0V41H108V0Z"
        fill="#8C8C8C"
      />
      <path
        d="M47.812 25.082V23.288C47.9868 23.4445 48.1803 23.5789 48.388 23.688C48.6147 23.808 48.8512 23.9083 49.095 23.988C49.3393 24.0677 49.589 24.1301 49.842 24.175C50.069 24.2166 50.2992 24.2384 50.53 24.24C51.0996 24.28 51.6684 24.1532 52.167 23.875C52.3413 23.7601 52.4826 23.6017 52.577 23.4154C52.6713 23.2292 52.7155 23.0215 52.705 22.813C52.7108 22.5837 52.6485 22.3579 52.526 22.164C52.3916 21.9679 52.2221 21.7984 52.026 21.664C51.7859 21.4986 51.5328 21.3531 51.269 21.229C50.9777 21.0897 50.666 20.9443 50.334 20.793C49.978 20.603 49.647 20.41 49.334 20.215C49.0427 20.0314 48.7741 19.8138 48.534 19.567C48.31 19.3341 48.1292 19.0632 48 18.767C47.8638 18.4351 47.7977 18.0786 47.806 17.72C47.7948 17.269 47.9102 16.8238 48.139 16.435C48.3603 16.0724 48.6608 15.7647 49.018 15.535C49.3994 15.2904 49.8192 15.1119 50.26 15.007C50.7247 14.8925 51.2015 14.8347 51.68 14.835C52.4976 14.7862 53.316 14.9138 54.08 15.209V16.93C53.4016 16.4754 52.5958 16.2494 51.78 16.285C51.5206 16.2852 51.2618 16.31 51.007 16.359C50.7662 16.4029 50.5337 16.4842 50.318 16.6C50.1234 16.7061 49.9547 16.8539 49.824 17.033C49.6936 17.2193 49.627 17.4427 49.634 17.67C49.6286 17.8819 49.679 18.0916 49.78 18.278C49.8892 18.4619 50.0353 18.6212 50.209 18.746C50.4248 18.9053 50.6552 19.0439 50.897 19.16C51.168 19.294 51.481 19.44 51.839 19.596C52.2037 19.786 52.549 19.986 52.875 20.196C53.1859 20.3961 53.4731 20.631 53.731 20.896C53.9734 21.1423 54.1716 21.4285 54.317 21.742C54.4667 22.0743 54.5408 22.4356 54.534 22.8C54.5489 23.27 54.4377 23.7354 54.212 24.148C53.9989 24.511 53.7011 24.8171 53.344 25.04C52.9581 25.2801 52.5324 25.4494 52.087 25.54C51.5941 25.6456 51.0911 25.6976 50.587 25.695C50.411 25.695 50.195 25.681 49.938 25.655C49.681 25.629 49.42 25.588 49.151 25.538C48.8944 25.4885 48.6404 25.4261 48.39 25.351C48.1862 25.2874 47.9919 25.197 47.812 25.082V25.082Z"
        fill="#5E5E5E"
      />
      <path
        d="M57.129 16.426C56.8648 16.4313 56.6088 16.3338 56.415 16.154C56.3179 16.0681 56.2408 15.962 56.1889 15.8432C56.1371 15.7243 56.1119 15.5956 56.115 15.466C56.1119 15.3346 56.137 15.2041 56.1888 15.0834C56.2406 14.9626 56.3177 14.8543 56.415 14.766C56.6071 14.583 56.8637 14.4831 57.129 14.488C57.3999 14.4817 57.6626 14.5814 57.861 14.766C57.9587 14.8541 58.0361 14.9622 58.0878 15.0831C58.1396 15.2039 58.1646 15.3346 58.161 15.466C58.162 15.5939 58.1358 15.7204 58.0841 15.8373C58.0323 15.9542 57.9563 16.0588 57.861 16.144C57.6635 16.3305 57.4006 16.4318 57.129 16.426V16.426ZM57.971 25.5H56.271V18H57.971V25.5Z"
        fill="#5E5E5E"
      />
      <path
        d="M67.017 24.9C67.017 27.654 65.6327 29.031 62.864 29.031C61.9868 29.0504 61.1155 28.8831 60.308 28.54V26.986C61.0123 27.4198 61.8182 27.6612 62.645 27.686C63.0046 27.7405 63.3719 27.7105 63.7178 27.5984C64.0638 27.4863 64.3789 27.2952 64.6382 27.0402C64.8975 26.7852 65.0938 26.4733 65.2116 26.1292C65.3294 25.7852 65.3655 25.4184 65.317 25.058V24.24H65.288C65.05 24.6385 64.7212 24.9751 64.3284 25.2224C63.9357 25.4698 63.49 25.6209 63.0278 25.6634C62.5656 25.7059 62.0999 25.6386 61.6686 25.467C61.2373 25.2954 60.8527 25.0244 60.546 24.676C59.9544 23.9105 59.657 22.9581 59.708 21.992C59.65 20.9054 59.9678 19.8319 60.608 18.952C60.9026 18.5822 61.2805 18.2872 61.7108 18.0912C62.1411 17.8951 62.6116 17.8036 63.084 17.824C63.5276 17.8016 63.9686 17.9036 64.3573 18.1185C64.746 18.3333 65.067 18.6525 65.284 19.04H65.313V18H67.013L67.017 24.9ZM65.333 22.065V21.092C65.3428 20.59 65.1546 20.1043 64.809 19.74C64.6435 19.5585 64.441 19.4146 64.2152 19.318C63.9894 19.2214 63.7455 19.1744 63.5 19.18C63.2089 19.1672 62.9191 19.2256 62.6557 19.35C62.3922 19.4744 62.163 19.6611 61.988 19.894C61.5903 20.4822 61.3983 21.1854 61.442 21.894C61.4097 22.5262 61.5946 23.1504 61.966 23.663C62.1277 23.8759 62.338 24.047 62.5793 24.1621C62.8205 24.2773 63.0858 24.3331 63.353 24.325C63.6233 24.3335 63.8921 24.281 64.1394 24.1716C64.3868 24.0623 64.6064 23.8987 64.782 23.693C65.1613 23.2371 65.3577 22.6566 65.333 22.064V22.065Z"
        fill="#5E5E5E"
      />
      <path
        d="M75.908 25.5H74.208V21.273C74.208 19.873 73.7127 19.173 72.722 19.173C72.4783 19.1675 72.2365 19.2169 72.0146 19.3177C71.7926 19.4184 71.5962 19.5679 71.44 19.755C71.0973 20.1659 70.9191 20.6893 70.94 21.224V25.5H69.229V18H70.936V19.245H70.965C71.1981 18.8048 71.5497 18.4385 71.9799 18.1874C72.4102 17.9363 72.902 17.8104 73.4 17.824C73.7518 17.8021 74.1038 17.8625 74.4281 18.0005C74.7523 18.1385 75.0399 18.3503 75.268 18.619C75.7404 19.289 75.9678 20.1011 75.912 20.919L75.908 25.5Z"
        fill="#5E5E5E"
      />
      <path
        d="M82.962 16.426C82.6977 16.4313 82.4418 16.3338 82.248 16.154C82.1509 16.0681 82.0737 15.962 82.0219 15.8432C81.9701 15.7243 81.9449 15.5956 81.948 15.466C81.9449 15.3346 81.97 15.2041 82.0218 15.0834C82.0736 14.9626 82.1507 14.8543 82.248 14.766C82.4401 14.583 82.6967 14.4831 82.962 14.488C83.2329 14.4817 83.4956 14.5814 83.694 14.766C83.7917 14.8541 83.8691 14.9622 83.9208 15.0831C83.9726 15.2039 83.9976 15.3346 83.994 15.466C83.995 15.5939 83.9688 15.7204 83.9171 15.8373C83.8653 15.9542 83.7893 16.0588 83.694 16.144C83.4965 16.3305 83.2336 16.4318 82.962 16.426V16.426ZM83.8 25.5H82.1V18H83.8V25.5Z"
        fill="#5E5E5E"
      />
      <path
        d="M92.7 25.5H91V21.273C91 19.873 90.5047 19.173 89.514 19.173C89.2703 19.1675 89.0285 19.2169 88.8066 19.3177C88.5846 19.4184 88.3882 19.5679 88.232 19.755C87.8867 20.1648 87.7063 20.6885 87.726 21.224V25.5H86.023V18H87.73V19.245H87.76C87.9928 18.8054 88.3437 18.4395 88.7732 18.1884C89.2027 17.9374 89.6937 17.8112 90.191 17.824C90.5428 17.8021 90.8948 17.8625 91.2191 18.0005C91.5433 18.1385 91.831 18.3503 92.059 18.619C92.5314 19.289 92.7588 20.1011 92.703 20.919L92.7 25.5Z"
        fill="#5E5E5E"
      />
      <path d="M24 11H15V20H24V11Z" fill="#F25022" />
      <path d="M24 21H15V30H24V21Z" fill="#00A4EF" />
      <path d="M34 11H25V20H34V11Z" fill="#7FBA00" />
      <path d="M34 21H25V30H34V21Z" fill="#FFB900" />
    </svg>
  );
}
