import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompassSlash, faHome } from "@fortawesome/pro-solid-svg-icons";

export default function NotFound404() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(9);

  useEffect(() => {
    let interval;
    if (timeLeft) {
      interval = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      if (timeLeft === 1) {
        clearInterval(interval);
        setTimeout(navigate("/"), 1000);
      }
      return () => clearInterval(interval);
    }
  }, [timeLeft]);

  // Styles
  const contentStyles = {
    alignItems: "center",
    display: "flex",
    minHeight: "63vh",
    justifyContent: "center",
    padding: theme?.spacing(2),
  };

  return (
    <Grid container sx={contentStyles}>
      <Grid item>
        <Typography component="h1" variant="h1">
          <FontAwesomeIcon icon={faCompassSlash} /> Lost your way?
        </Typography>
        <Typography gutterBottom>
          <Link
            onClick={() => {
              navigate("/");
            }}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            Search &amp; Rescue will get you back...
          </Link>
        </Typography>
        <Button
          variant="outlined"
          size="large"
          onClick={() => {
            navigate("/");
          }}
          startIcon={<FontAwesomeIcon icon={faHome} />}
        >
          Home in {timeLeft}
        </Button>
      </Grid>
    </Grid>
  );
}
