import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetTransportations(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `transportations/GetTransportations`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting transportations. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
