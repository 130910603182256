import { useQuery, useQueries } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useCompassEnrolledPrograms(studentId) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `Programs/CompassEnrolledPrograms/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting Enrolled programs. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useCompassEnrolledProgramsAllStudents(studentIds) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const error = {
    onError: (error) => {
      snackbar.enqueueSnackbar(`Error getting activity events. ${error}`, {
        variant: "error",
      });
    },
  };
  const students = !!studentIds ? studentIds : [];
  const queryArray = students?.map((studentId) => {
    return {
      queryKey: `Programs/CompassEnrolledPrograms/${studentId}`,
      queryFn: async () => {
        return await AppsGet(
          token,
          `Programs/CompassEnrolledPrograms/${studentId}`
        );
      },
      error,
    };
  });

  const queries = useQueries(queryArray);
  return queries;
}

export function useCompassEligiblePrograms(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Programs/CompassEligiblePrograms/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting eligible programs. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useCompassProgramHistory(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Programs/CompassProgramHistory/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting program history. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useCompassProgramDetails(studentId, programId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Programs/CompassProgramDetails/${studentId}/${programId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId && !!programId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting program details. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
