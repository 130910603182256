import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { darken } from "@mui/material/styles";
import {
  lightGreen,
  deepOrange,
  lightBlue,
  yellow,
} from "@mui/material/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserLarge,
  faUserGraduate,
  faEyeSlash,
} from "@fortawesome/pro-solid-svg-icons";
import useCommonStyles from "./../../services/hooks/useCommonStyles";

export default function AboutCompassGuardians() {
  const commonStyles = useCommonStyles();
  const bgColor = deepOrange[700];

  const slideStyles = {
    ...commonStyles.slideStyles,
    color: "#fff",
    backgroundColor: darken(bgColor, 0.2),
    backgroundImage: `linear-gradient(135deg, 
    ${darken(bgColor, 0.2)} 30%, 
    ${darken(bgColor, 0.3)} 30%, 
    ${darken(bgColor, 0.3)} 70%, 
    ${darken(bgColor, 0.4)} 70%, 
    ${darken(bgColor, 0.4)} 90%, 
    ${darken(bgColor, 0.5)} 90%, 
    ${darken(bgColor, 0.5)} 100%)`,
    backgroundSize: "cover",
  };

  return (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1" sx={{ fontWeight: 300 }}>
        Different strokes
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 32px)",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item>
            <FontAwesomeIcon icon={faUserLarge} size="4x" color={yellow[200]} />
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faEyeSlash} />
          </Grid>
          <Grid item>
            <FontAwesomeIcon
              icon={faUserGraduate}
              size="4x"
              color={lightGreen[300]}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography variant="body2">
            <em
              style={{ color: lightBlue[200], textShadow: "#000 1px 1px 1px" }}
            >
              FYI: There are some differences between what you and your child
              see in&nbsp;Compass.
            </em>{" "}
            Only middle and high schoolers can login, and they get features like
            badges for school achievements, goal-setting help, etc. And anything
            requiring guardian permission (like activity registration) is just
            for&nbsp;you.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
