import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  useFormControl,
  useTheme,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltLeft,
  faCirclePlus,
  faCircleCheck,
  faTrashCan,
  faStar as faSolidStar,
  faSave,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faStar as faHollowStar,
  faTrashCan as faTrashCanRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {
  useGetStudentGoalByGoalId,
  useUpsertStudentGoal,
  useDeleteStudentGoal,
} from "../../services/hooks/studentGoalsHook";

import {
  useGetBadgeDetailsForStudent,
  usePostEarnedBadge,
} from "../../services/hooks/badgesHook";
import { useGetInterest } from "../../services/hooks/interestsHook";

import dayjs from "dayjs";
import GoalMilestonesTimeline from "../common/GoalMilestonesTimeline";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import {
  getCurrentSchoolYear,
  ScrollToTopOnMount,
  formatDateOnly,
} from "../../services/utilities";

export default function GoalForm() {
  const { goalId, badgeId, interestId } = useParams();
  const { activeStudent } = useActiveStudent();
  const query = useGetStudentGoalByGoalId(goalId);
  const goalData = query.data?.data;
  const firstGoalBadgeId = 63;
  const deleteGoal = useDeleteStudentGoal(goalId, () => {
    navigate("/goals");
  });

  const currentSchoolYear = getCurrentSchoolYear();

  const badgeResponse = useGetBadgeDetailsForStudent(badgeId);
  const badgeData = badgeResponse?.data?.data;

  const firstGoalBadgeResponse = useGetBadgeDetailsForStudent(firstGoalBadgeId);
  const firstGoalBadgeData = firstGoalBadgeResponse?.data?.data;

  const interestsResponse = useGetInterest(interestId);
  const interestData = interestsResponse?.data?.data;

  useEffect(() => {
    if (goalData) {
      setTitle(goalData.title);
      setReason(goalData.reason);
      setSteps(goalData.steps);
      setTargetDate(goalData.targetDate);
      setCompletedDate(goalData.completedDate);
      setIsFavorite(goalData?.isFavorite);
      setMilestones(goalData.goalMilestones);
    }
  }, [goalData]);

  useEffect(() => {
    if (badgeData) {
      setTitle("Earn badge: " + badgeData?.name);
    }
  }, [badgeData]);

  useEffect(() => {
    if (interestData) {
      setTitle("Learn more about: " + interestData?.name);
    }
  }, [interestData]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const midScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const isEdit = !!goalId;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(false);

  const [steps, setSteps] = useState("");
  const [stepsError, setStepsError] = useState(false);

  const [milestones, setMilestones] = useState([]);
  const [milestoneDeleteIndex, setMilestoneDeleteIndex] = useState(null);

  const [targetDate, setTargetDate] = useState(dayjs().format("MM/DD/YYYY"));
  const [targetDateError, setTargetDateError] = useState(false);

  const [completedDate, setCompletedDate] = useState();
  const [isFavorite, setIsFavorite] = useState();

  const [showFirstGoalBadgeDialog, setShowFirstGoalBadgeDialog] =
    useState(false);

  const FormHelper = (props) => {
    const { helperText } = props;
    const { focused } = useFormControl() || {};
    return (
      <Collapse in={focused} out={!focused && "true"}>
        <FormHelperText sx={helperTextStyles}>{helperText}</FormHelperText>
      </Collapse>
    );
  };

  const GetPlaceholderWhat = () => {
    const placeholders = [
      "Graduate High School",
      "Join a club",
      "Learn to swim",
      "Hike to the top of Mt. Rainier",
    ];
    return placeholders[Math.floor(Math.random() * placeholders.length)];
  };

  const GetPlaceholderWhy = () => {
    const placeholders = [
      "To prepare myself for my future job",
      "To improve my physical stamina",
      "To meet new people",
      "To break out of my comfort zone",
    ];
    return placeholders[Math.floor(Math.random() * placeholders.length)];
  };

  const GetPlaceholderHow = () => {
    const placeholders = [
      "By practicing twice a week",
      "By joining a class to about this topic",
      "By asking my friends to help me",
      "By researching and reading about it",
    ];
    return placeholders[Math.floor(Math.random() * placeholders.length)];
  };

  const AddMilestone = () => {
    const newMilestone = {
      milestoneDescription: "",
      mileStoneDate: dayjs(),
      complete: false,
      createdDate: dayjs(),
      modifiedDate: dayjs(),
      uniqueKey: dayjs(),
    };
    setMilestones([...milestones, newMilestone]);
    setCompletedDate(null);
  };

  const DeleteMilestone = () => {
    setMilestones((old) => {
      return old.filter((_, index) => index !== milestoneDeleteIndex);
    });
    setMilestoneDeleteIndex(null);
  };

  const ValidateForm = () => {
    let isValid = true;
    if (!title) {
      setTitleError(true);
      isValid = false;
    }
    if (!reason) {
      setReasonError(true);
      isValid = false;
    }
    if (!steps) {
      setStepsError(true);
      isValid = false;
    }
    if (!targetDate) {
      setTargetDateError(true);
      isValid = false;
    }
    milestones.forEach((milestone, index) => {
      if (!milestone.milestoneDescription) {
        let copyMilestones = structuredClone(milestones);
        copyMilestones[index].milestoneDescriptionError = true;
        setMilestones(copyMilestones);
        isValid = false;
      }
      if (!milestone.mileStoneDate) {
        let copyMilestones = structuredClone(milestones);
        copyMilestones[index].milestoneDateError = true;
        setMilestones(copyMilestones);
        isValid = false;
      }
    });
    return isValid;
  };

  const SubmitForm = async () => {
    if (ValidateForm()) {
      UpsertGoal.mutate();
    }
  };

  const fieldsDisabled = () =>
    query.isLoading ||
    query.isFailure ||
    UpsertGoal.isLoading ||
    deleteGoal.isLoading;

  const GetSubmitBody = () => {
    if (isEdit) {
      return {
        studentGoalId: goalId,
        studentId: activeStudent?.studentId,
        title: title,
        type: "default", //TODO: badge, interest, edit?
        createdDate: goalData?.createdDate,
        modifiedDate: dayjs(),
        percentageComplete:
          milestones.filter((m) => m.complete).length / milestones.length !== 0
            ? milestones.length
            : 1,
        badgeId: goalData?.badgeId,
        studentInterestId: goalData?.badgeId,
        targetDate: formatDateOnly(targetDate),
        completedDate: completedDate
          ? formatDateOnly(completedDate)
          : completedDate,
        reason: reason,
        steps: steps,
        isFavorite: isFavorite,
        goalMilestones: milestones,
      };
    } else {
      //new
      return {
        // studentGoalId: goalId,
        studentId: activeStudent?.studentId,
        title: title,
        type: "default", //TODO: badge, interest, edit?
        createdDate: dayjs(),
        modifiedDate: dayjs(),
        percentageComplete:
          milestones.filter((m) => m.complete).length / milestones.length !== 0
            ? milestones.length
            : 1.0,
        badgeId: badgeId,
        interestId: interestId,
        targetDate: formatDateOnly(targetDate),
        completedDate: completedDate
          ? formatDateOnly(completedDate)
          : completedDate,
        reason: reason,
        steps: steps,
        isFavorite: isFavorite,
        goalMilestones: milestones,
      };
    }
  };

  const UpsertGoal = useUpsertStudentGoal(GetSubmitBody(), () => {
    // Check if this is the first goal
    if (firstGoalBadgeData?.earned?.length === 0) {
      // award the badge
      AwardFirstGoalBadge.mutate();
    } else {
      navigate("/goals");
    }
  });

  const firstGoalBadge = {
    studentId: activeStudent?.studentId,
    badgeId: firstGoalBadgeId, // badge earned for creating first goal
    schoolYear: currentSchoolYear,
    dateReceived: formatDateOnly(new Date().toLocaleDateString()),
  };

  const AwardFirstGoalBadge = usePostEarnedBadge(firstGoalBadge, () => {
    //open dialog to show badge
    setShowFirstGoalBadgeDialog(true);
  });

  //#region Styles
  const whatStyles = {
    "& .MuiOutlinedInput-input": {
      fontWeight: "bold",
      fontSize: smallScreen ? "1.25rem" : midScreen ? "1.5rem" : "2rem",
    },
  };
  const helperTextStyles = {
    color: theme.palette.secondary.main,
    fontSize: "0.9rem",
  };
  const milestoneStyles = {
    mx: theme.spacing(1),
    my: theme.spacing(2),
    transition: `all ${theme?.transitions?.easing?.easeInOut} ease-in-out`,
  };
  const milestoneCompleteStyles = {
    ...milestoneStyles,
    transition: "all 0.3s ease-in-out",
    border: `1px solid ${theme?.palette?.success?.main}`,
    backgroundColor: theme?.palette?.background?.neutral,
    "& .MuiIconButton-root": {
      color: theme?.palette?.text?.secondary,
    },
  };
  const addMilestoneButtonStyles = {
    ml: 1,
    mb: 4,
  };

  const badgeStyles = {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      maxWidth: 350,
    },
  };

  const iconFooterStyles = {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const earnedStyles = {
    ...iconFooterStyles,
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    color: theme?.palette?.secondary?.text,
    "& span": {
      display: "inline-block",
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
    },
  };
  //#endregion

  //#region Render
  return (
    <>
      <ScrollToTopOnMount />
      <Button
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faArrowAltLeft} />}
        onClick={() => {
          navigate("/goals");
        }}
        size="small"
        id="compass-back-button"
      >
        Goals
      </Button>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h1">
            {isEdit ? "Edit" : "New"} Goal
            {"  "}
            {!!completedDate && (
              <FontAwesomeIcon
                color={theme.palette.success.light}
                icon={faCircleCheck}
              />
            )}
            <IconButton
              disabled={fieldsDisabled()}
              onClick={(e) => {
                setIsFavorite(!isFavorite);
              }}
              color="secondary"
            >
              <FontAwesomeIcon icon={isFavorite ? faSolidStar : faHollowStar} />
            </IconButton>
          </Typography>
        </Grid>
        {/* {(!completedDate || today.diff(completedDate, "day") > 1) && isEdit && (
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              sx={{ color: theme?.palette?.error?.text }}
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              startIcon={<FontAwesomeIcon icon={faTrashCan} />}
            >
              Delete
            </Button>
          </Grid>
        )} */}
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this goal?</Typography>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteGoal.mutate();
            }}
            startIcon={<FontAwesomeIcon icon={faTrashCan} />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <br />
      <Grid container spacing={2}>
        {/* What */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              sx={whatStyles}
              disabled={
                badgeId != null || interestId != null || fieldsDisabled()
              }
              label={
                <>
                  <strong>What?</strong> Title this goal. Be brief and specific.
                </>
              }
              required={true}
              placeholder={GetPlaceholderWhat()}
              error={titleError}
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
                setTitleError(false);
              }}
              inputProps={{
                autoComplete: "off",
                maxLength: 120,
              }}
              helperText={
                <FormHelper
                  helperText={
                    <>
                      What to consider:
                      <ul>
                        <li>Keep it short</li>
                        <li>
                          Be specific enough that it is easy to know when you've
                          met the goal For example, "Get healthy" is too vague,
                          but "Run 1 mile three times a week" is clear and
                          specific.
                        </li>
                        <li>
                          If you created this goal from a badge details page,
                          the title is automatically filled out for you.
                        </li>
                      </ul>
                    </>
                  }
                />
              }
            />
          </FormControl>
        </Grid>

        {/* Why */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={fieldsDisabled()}
              label={
                <>
                  <strong>Why?</strong> Explain your reasons for this goal.
                </>
              }
              placeholder={GetPlaceholderWhy()}
              error={reasonError}
              value={reason}
              onChange={(event) => {
                setReason(event.target.value);
                setReasonError(false);
              }}
              multiline
              rows={4}
              inputProps={{
                autoComplete: "off",
                maxLength: 1024,
              }}
              helperText={
                <FormHelper
                  helperText={
                    <>
                      What to consider:
                      <ul>
                        <li>What led you to this goal?</li>
                        <li>How does this goal matter?</li>
                        <li>Is it realistic and challenging?</li>
                        <li>What supports will you need?</li>
                      </ul>
                    </>
                  }
                />
              }
            />
          </FormControl>
        </Grid>

        {/* How */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              disabled={fieldsDisabled()}
              label={
                <>
                  <strong>How?</strong> List steps to meet this goal.
                </>
              }
              placeholder={GetPlaceholderHow()}
              error={stepsError}
              value={steps}
              onChange={(event) => {
                setSteps(event.target.value);
                setStepsError(false);
              }}
              multiline
              rows={4}
              inputProps={{
                autoComplete: "off",
                maxLength: 5000,
              }}
              helperText={
                <FormHelper
                  helperText={
                    <>
                      What to consider:
                      <ul>
                        <li>
                          What are the steps you need to take to complete your
                          goal? Are they manageable?
                        </li>
                        <li>What resources do you need?</li>
                        <li>What is your plan?</li>
                        <li>
                          What evidence do you have that you have completed your
                          goal?
                        </li>
                      </ul>
                    </>
                  }
                />
              }
            />
          </FormControl>
        </Grid>

        {/* When */}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <MobileDatePicker
              disabled={fieldsDisabled()}
              label={
                <>
                  <strong>When?</strong> Set a target date for this goal.
                </>
              }
              inputFormat="MM/DD/YYYY"
              error={targetDateError}
              value={targetDate ? dayjs(targetDate) : null}
              onChange={(date) => {
                setTargetDate(date);
                setTargetDateError(false);
              }}
              fullWidth
              variant="outlined"
              orientation="portrait"
              required
              renderInput={(params) => (
                <TextField {...params} error={targetDateError} />
              )}
            />
          </FormControl>
        </Grid>

        {/* Milestones */}
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h5" component="h2" sx={{ mt: 1 }}>
                Milestones
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={8}>
              {milestones?.map((milestone, index) => {
                return (
                  <Card
                    sx={
                      milestone.complete
                        ? milestoneCompleteStyles
                        : milestoneStyles
                    }
                    key={
                      milestone.studentGoalMilestoneId ?? milestone.uniqueKey
                    }
                  >
                    <CardContent sx={{ position: "relative" }}>
                      {milestone.complete && (
                        <span
                          style={{
                            position: "absolute",
                            fontSize: "18rem",
                            top: "-9rem",
                            left: "-1.5rem",
                          }}
                        >
                          <FontAwesomeIcon
                            color={alpha(
                              theme.palette.success.main ?? "#777",
                              0.2
                            )}
                            icon={faCircleCheck}
                          />
                        </span>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} lg={9}>
                          <FormControl
                            error={milestone.milestoneDescriptionError}
                            fullWidth
                          >
                            <TextField
                              disabled={fieldsDisabled() || milestone.complete}
                              fullWidth
                              multiline
                              required
                              rows={1}
                              InputLabelProps={{
                                shrink:
                                  milestone.milestoneDescription?.length > 0,
                              }}
                              inputProps={{
                                autoComplete: "off",
                                maxLength: 100,
                              }}
                              onChange={(event) => {
                                let newMilestones = [...milestones];
                                newMilestones[index].milestoneDescription =
                                  event.target.value;
                                newMilestones[
                                  index
                                ].milestoneDescriptionError = false;
                                setMilestones(newMilestones);
                              }}
                              label="Description"
                              error={
                                milestone.milestoneDescriptionError === true
                              }
                              value={milestone?.milestoneDescription}
                            />
                            {milestone.milestoneDescriptionError && (
                              <FormHelperText>
                                Briefly describe this milestone
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3}>
                          <FormControl fullWidth error={true}>
                            <MobileDatePicker
                              disabled={fieldsDisabled() || milestone.complete}
                              label="Due Date"
                              inputFormat="MM/DD/YYYY"
                              fullWidth
                              variant="outlined"
                              orientation="portrait"
                              required
                              defaultValue={dayjs(milestone?.mileStoneDate)}
                              onChange={(date) => {
                                const newMilestones = [...milestones];
                                newMilestones[index].mileStoneDate = date;
                                newMilestones[index].mileStoneDateError = false;
                                setMilestones(newMilestones);
                              }}
                              slotProps={{
                                textField: {
                                  error: milestone.mileStoneDateError,
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <FormControlLabel
                            sx={{ fontSize: "0.75rem" }}
                            control={
                              <Switch
                                disabled={fieldsDisabled()}
                                color="success"
                                label="Completed"
                                checked={milestone.complete ?? false}
                                onChange={(event) => {
                                  const newMilestones = [...milestones];
                                  newMilestones[index].complete =
                                    event.target.checked;
                                  if (!event.target.checked) {
                                    setCompletedDate(null);
                                  }
                                  setMilestones(newMilestones);
                                }}
                              />
                            }
                            label="Milestone Completed"
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            disabled={fieldsDisabled()}
                            onClick={() => {
                              setMilestoneDeleteIndex(index);
                            }}
                            size="small"
                          >
                            <FontAwesomeIcon icon={faTrashCanRegular} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                );
              })}
              <Button
                disabled={fieldsDisabled()}
                onClick={() => {
                  AddMilestone();
                }}
                variant="outlined"
                color="secondary"
                startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
                sx={addMilestoneButtonStyles}
              >
                Add Milestone
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <GoalMilestonesTimeline
                goalTargetDate={targetDate}
                goalCompletedDate={completedDate}
                goalCreatedDateTime={goalData?.createdDateTime ?? dayjs()}
                goalMilestones={milestones}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <FormControlLabel
          control={
            <Switch
              disabled={milestones.some((milestone) => !milestone.complete)}
              checked={!!completedDate ?? false}
              onChange={(e) => {
                setCompletedDate(
                  e.target.checked ? dayjs().format("MM/DD/YYYY") : null
                );
              }}
              color="success"
            />
          }
          label={
            !!completedDate ? (
              <strong>GOAL COMPLETED!</strong>
            ) : (
              <strong>Goal Completed</strong>
            )
          }
        />
        {milestones.some((milestone) => !milestone.complete) && (
          <FormHelperText>
            Gotta complete all the milestones first...
          </FormHelperText>
        )}
      </Box>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            disabled={fieldsDisabled()}
            onClick={SubmitForm}
            size="large"
            startIcon={<FontAwesomeIcon icon={faSave} />}
          >
            Save
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              navigate("/goals");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      {/* Delete milestone confirmation  */}
      <Dialog open={milestoneDeleteIndex !== null}>
        <DialogTitle>
          <FontAwesomeIcon icon={faTrashCanRegular} /> Delete Milestone?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this milestone?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setMilestoneDeleteIndex(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => DeleteMilestone()}
            color="error"
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faTrashCanRegular} />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Earned First Goal Badge  */}
      <Dialog
        open={showFirstGoalBadgeDialog}
        onClose={() => navigate("/goals")}
      >
        <DialogTitle>You set your first goal...Congratulations!</DialogTitle>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} lg={6}>
              <Box sx={badgeStyles}>
                <img src={firstGoalBadgeData?.url} alt="" />
              </Box>
              {firstGoalBadgeData?.earned?.length && (
                <Box sx={earnedStyles}>
                  <Typography>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;<strong>EARNED</strong>
                  </Typography>
                  <Typography variant="caption" component="div">
                    {firstGoalBadgeData?.earned?.map((eb, i) => {
                      return (
                        <span key={i}>
                          {dayjs(eb.dateReceived).format("M/D/YYYY")}
                        </span>
                      );
                    })}
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => navigate("/goals")}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
  //#endregion
}
