import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faOctagon,
  faDiamond,
  faCircle,
  faSquare,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";

export default function MoodsAbout() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const dialogStyles = {
    "& .MuiPaper-root": {
      [theme.breakpoints.up("lg")]: {
        width: "1200px !important",
        maxWidth: "1200px !important",
      },
      [theme.breakpoints.only("md")]: {
        width: "900px !important",
        maxWidth: "900px !important",
      },
    },
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: alpha(theme.palette.neutral.main ?? "#777", 0.7),
    "&:hover": {
      color: theme.palette.neutral.light,
    },
  };
  const contentStyles = {
    minWidth: `calc(375px - ${theme.spacing(2)})`,
    padding: 0,
  };
  const howToStyles = {
    margin: theme.spacing(2),
    "& p": {
      marginBottom: "1rem",
    },
    "& h4": {
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3),
    },
  };
  const zonesStyles = {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(3),
    },

    background: theme.palette.background.neutralAlt,
    color: theme.palette.text.primaryAlt,
  };
  const zoneTitleStyles = {
    background: theme.palette?.moods?.blue,
    color: theme.palette.background.neutralAlt,
    padding: `${
      theme.typography.name === "overpass" ? ".5rem" : ".25rem"
    } .5rem ${theme.typography.name === "overpass" ? 0 : ".5rem"} .5rem`,
  };
  const blueZoneTitleStyles = {
    ...zoneTitleStyles,
    background: theme.palette?.moods?.blueZone.main,
    color: theme.palette?.moods?.blueZone.contrastText,
  };
  const greenZoneTitleStyles = {
    ...zoneTitleStyles,
    background: theme.palette?.moods?.greenZone.main,
    color: theme.palette?.moods?.greenZone.contrastText,
  };
  const yellowZoneTitleStyles = {
    ...zoneTitleStyles,
    background: theme.palette?.moods?.yellowZone.main,
    color: theme.palette?.moods?.yellowZone.contrastText,
  };
  const redZoneTitleStyles = {
    ...zoneTitleStyles,
    background: theme.palette?.moods?.redZone.main,
    color: theme.palette?.moods?.redZone.contrastText,
  };
  const zoneIcon = {
    fontSize: "3.5rem",
    float: "left",
    margin: theme.spacing(1),
    marginLeft: 0,
  };
  const blueZoneIcon = {
    ...zoneIcon,
    color: theme.palette?.moods?.blueZone.secondary,
  };
  const greenZoneIcon = {
    ...zoneIcon,
    color: theme.palette?.moods?.greenZone.secondary,
  };
  const yellowZoneIcon = {
    ...zoneIcon,
    color: theme.palette?.moods?.yellowZone.secondaryAlt,
  };
  const redZoneIcon = {
    ...zoneIcon,
    color: theme.palette?.moods?.redZone.secondary,
  };
  const actionsStyles = {
    borderTop: `1px solid ${alpha(theme.palette.neutral.main ?? "#777", 0.2)}`,
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Moods
      </Button>
      <Dialog
        open={aboutDialogIsOpen}
        onClose={handleAboutClose}
        sx={dialogStyles}
      >
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> About Moods
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={contentStyles}>
          <Grid container spacing={largeScreen ? 4 : 2} sx={zonesStyles}>
            <Grid item xs={12}>
              <Typography variant="lede">
                Here's what the Zones of Regulation are all about...
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={blueZoneTitleStyles}>
                Blue Zone
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faSquare} style={blueZoneIcon} />
                ...describes low states of alertness and down feelings such as
                when you feel sad, tired, sick, or bored. The Blue Zone can be
                compared to the rest area signs where a driver goes to rest
                or&nbsp;re&#8209;energize.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={greenZoneTitleStyles}>
                Green Zone
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faCircle} style={greenZoneIcon} />
                ...describes a calm state of alertness. A person may be
                described as happy, focused, content, or ready to learn when in
                the Green Zone. This is the zone where optimal learning occurs.
                When given a green light or in the Green Zone, you're “good
                to&nbsp;go.”
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={yellowZoneTitleStyles}>
                Yellow Zone
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faDiamond} style={yellowZoneIcon} />
                ...describes a heightened state of alertness and elevated
                emotions with a loss of some control. A person may be
                experiencing stress, frustration, anxiety, excitement,
                silliness, the wiggles, or nervousness when in the Yellow Zone.
                A yellow road sign means be aware or take caution, which applies
                to the Yellow&nbsp;Zone.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={redZoneTitleStyles}>
                Red Zone
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faOctagon} style={redZoneIcon} />
                ...describes extremely heightened states of alertness and
                intense emotions when you may feel out of control. A person may
                be experiencing anger, rage, devastation, or terror when in the
                Red Zone. A red light or stop sign means stop, and when someone
                is in the Red Zone this often is the&nbsp;case.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                sx={{ textAlign: "center" }}
                component="p"
              >
                Based on The Zones of Regulation® (2011), by Leah Kuypers
              </Typography>
            </Grid>
          </Grid>
          <Box sx={howToStyles}>
            <Typography variant="h4" color="primary">
              How to use Compass Moods
            </Typography>
            <Typography>
              <strong>Check in</strong> by choosing a zone and then a&nbsp;mood.
            </Typography>
            <Typography>
              <strong>Tips</strong> are suggestions you see based on your mood.
              While every tip may not become your go-to strategy, it does take
              time and practice to learn self-regulation. So keep an open-mind
              and give the tip a try more than&nbsp;once.
            </Typography>
            <Typography>
              <strong>My moods</strong> is where you'll see all your check-ins.
              You can click on any of them to pull up the tip you saw before.
              You can even rate whether you liked it or&nbsp;not.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
