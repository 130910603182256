import dayjs from "dayjs";
import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../services/utilities";
import { useGetCompassCompletedGoals } from "../../services/hooks/studentGoalsHook";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

export default function GoalsStats() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const itemStyles = {
    ...commonStyles.textOverBackground,
  };

  const completedGoalsData = useGetCompassCompletedGoals();

  const sortedGoalsByCompletedDate = useMemo(() => {
    return completedGoalsData?.data?.data.sort((a, b) =>
      a.completedDate > b.completedDate ? -1 : 1
    );
  }, [completedGoalsData]);

  function dateCompare(date1, date2) {
    if (dayjs(date1) < dayjs(date2))
      return `${
        "Completed " +
        dayjs(`${date2}`).diff(dayjs(`${date1}`), "day") +
        " days early"
      }`;
    else if (dayjs(date1) > dayjs(date2))
      return `${
        "Completed " +
        dayjs(`${date1}`).diff(dayjs(`${date2}`), "day") +
        " days late"
      }`;
    else if (formatDate(date1) === formatDate(date2))
      return `${"Completed on time"}`;
  }

  function isEarly(date1, date2) {
    if (dayjs(date1) < dayjs(date2) || formatDate(date1) === formatDate(date2))
      return true;
  }

  return (
    <>
      <Timeline position="right">
        {sortedGoalsByCompletedDate?.map((gi, index) => (
          <TimelineItem key={gi?.title} sx={itemStyles}>
            <TimelineOppositeContent>
              <Typography>{formatDate(gi?.completedDate)}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              {isEarly(dayjs(gi?.targetDate), dayjs(gi?.completedDate)) ? (
                <TimelineDot
                  sx={{
                    background: theme?.palette?.background?.default,
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faCircleCheck}
                    color={theme.palette.success.main}
                  />
                </TimelineDot>
              ) : (
                <TimelineDot
                  sx={{
                    background: theme?.palette?.background?.default,
                  }}
                >
                  <FontAwesomeIcon
                    size="2x"
                    icon={faCircleCheck}
                    color={theme.palette.neutral.main}
                  />
                </TimelineDot>
              )}
              {index !== sortedGoalsByCompletedDate?.length - 1 && (
                <TimelineConnector />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontWeight: "bold" }}>{gi?.title}</Typography>
              <Typography variant="body2">
                {dateCompare(dayjs(gi?.targetDate), dayjs(gi?.completedDate))}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}
