import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import PossibleExternalLinkButton from "../common/PossibleExternalLinkButton";
import useCommonStyles from "../../services/hooks/useCommonStyles";

export default function HeroBanner1(props) {
  const { title, subtitle, description, imageUrl, textColor, url, linkText } =
    props;
  const commonStyles = useCommonStyles();

  // Styles
  const bannerStyles = {
    ...commonStyles.paperPadding,
    background: `url(${imageUrl}) no-repeat`,
    backgroundSize: "cover",
    color: textColor,
    textShadow: "0 0 0.25rem #000, 1px 1px 1px #000",
    "& h1": {
      marginTop: "0", // removes margin in Forest theme since banner hides page background
    },
  };

  return (
    <Paper elevation={2} sx={bannerStyles}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component="h1"
            variant="h1"
            sx={{ maxWidth: "100% !important" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            sx={{ maxWidth: "100% !important" }}
          >
            {subtitle}
          </Typography>
          <Typography sx={{ maxWidth: "100% !important" }}>
            {description}{" "}
            <PossibleExternalLinkButton url={url} linkText={linkText} />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
