import { React, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useGetSurvey, usePostSurvey } from "../../services/hooks/surveyHook";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MobileStepper,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClipboardCheck,
  faClipboardList,
  faHome,
  faInfoCircle,
  faLinkSlash,
  faPaperPlane,
  faTimes,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import TrailSlideUpFadeIn from "../animations/TrailSlideUpFadeIn";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { showFeatures } from "../../services/utilities";
import { widowUnmaker } from "../../services/utilities";

export default function Survey() {
  const theme = useTheme();
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const surveyQuery = useGetSurvey(surveyId);
  const smallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  const [results, setResults] = useState([]);
  const [others, setOthers] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [completedDialogOpen, setCompletedDialogOpen] = useState(false);
  const [completedRequiredQuestions, setCompletedRequiredQuestions] = useState(
    []
  );
  const [activePage, setActivePage] = useState(0);

  //Styles
  const headerStyles = {
    padding: theme.spacing(2),
    mb: theme.spacing(1),
    backgroundColor: theme.palette.background.neutral,
  };
  const iconStyles = {
    opacity: 0.85,
  };
  const sectionTitleStyles = {
    color: theme.palette.secondary.text,
    mt: theme.spacing(4),
  };
  const questionStyles = {
    p: theme.spacing(2),
    mb: theme.spacing(2),
    "& .MuiFormGroup-root": {
      ml: theme.spacing(3),
    },
  };
  const surveyActionsStyles = {
    display: "flex",
    justifyContent: "flex-end",
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    position: "absolute",
    right: 4,
    top: 4,
    zIndex: 1000,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    mixBlendMode: "difference",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const requiredNoteStyles = {
    textAlign: "right",
    color: theme.palette.warning.text,
    px: 1,
    mb: "-1rem",
  };
  const progressStyles = {
    position: "fixed",
    right: theme.spacing(2),
    zIndex: 1000,
  };
  const completedDialogContentStyles = {
    margin: "0 auto",
    minHeight: "65vh",
    display: "flex",
    alignItems: "center",
  };
  const likertStyles = {
    flexWrap: "nowrap",
    textAlign: "center",
  };

  const REQ = (
    <sup
      style={{ fontWeight: 800, color: theme.palette.warning.text }}
      title="required"
    >
      *
    </sup>
  );

  const surveyTemp = surveyQuery?.data?.data;

  //Rebuilding survey object to nest pages > sections > questions
  const pagesWithSections =
    !!surveyTemp &&
    surveyTemp.questions.reduce((groupedQuestions, question) => {
      const page = question.pageNumber;
      const cat = question.category;
      const existingPage = groupedQuestions.find((p) => p.name === page);
      if (existingPage) {
        const existingCat = existingPage.sections.find((c) => c.name === cat);
        if (existingCat) {
          existingCat.questions.push(question);
        } else existingPage.sections.push({ name: cat, questions: [question] });
      } else {
        groupedQuestions.push({
          name: page,
          sections: [{ name: cat, questions: [question] }],
        });
      }
      return groupedQuestions;
    }, []);

  const survey = {
    ...surveyTemp,
    pages: pagesWithSections,
  };

  const requiredQuestions = useMemo(() => {
    const reqs = survey?.questions?.filter((q) => q.required);
    return reqs?.map((q) => q.questionInstanceId);
  }, [survey]);

  const surveyDescription = useMemo(() => {
    return !!survey.description ? JSON.parse(survey.description) : "";
  }, [survey]);

  const surveyCompletion = useMemo(() => {
    return !!survey.completion ? JSON.parse(survey.completion) : "";
  }, [survey]);

  const surveyResponse = usePostSurvey({
    Respondent: {
      SurveyId: survey.surveyId,
      InstanceID: survey.instanceId,
    },
    Results: results,
  });

  useEffect(() => {
    //update an array showing ids of questions that are both required and completed
    const crq = results.filter((r) => r.required);
    const crqSet = new Set(crq.map((r) => r.questionInstanceId)); //handles checkbox questions with multiple answers in the results array
    setCompletedRequiredQuestions(Array.from(crqSet));
  }, [results]);

  useEffect(() => {
    //update results array when an "Other" option's text field is changed
    others.map((o) => {
      results.map((r) => {
        if (r.questionInstanceId === o.questionInstanceId) {
          r.ResultText = o.ResultText;
        }
        return;
      });
      return;
    });
  }, [others]);

  return !!showFeatures.survey ? (
    <>
      <ScrollToTopOnMount />
      {showInfoDialog()}
      {showCompletedDialog()}

      {!surveyQuery.isLoading &&
        (!!surveyQuery.isError || survey.questions?.length === 0) &&
        showNoSurveyMessage()}
      {!!surveyQuery.isSuccess && survey.questions?.length > 0 && (
        <>
          {showProgress()}
          {showHeader()}

          {showQuestionLayout(surveyDescription.layout)}

          <Divider sx={{ mt: 6, mb: 4 }} />
          {showSubmitSection()}
        </>
      )}
    </>
  ) : (
    navigate("/")
  );

  function showNoSurveyMessage() {
    return (
      <>
        <Typography variant="h1" component="h2" gutterBottom>
          <FontAwesomeIcon icon={faClipboardList} /> Surveys
        </Typography>
        <Grid container spacing={4} alignItems="center">
          <Grid item>
            <FontAwesomeIcon
              icon={faLinkSlash}
              size="8x"
              style={{ ...iconStyles, color: theme.palette.error.main }}
            />
          </Grid>
          <Grid item>
            <Typography color="error">
              <span style={{ fontSize: "2rem" }}>
                <strong>Sorry</strong>
              </span>
            </Typography>
            <Typography>
              It looks like...
              <ul>
                <li>You've already taken this survey.&nbsp;Or...</li>
                <li>This survey may no longer be active.&nbsp;Or...</li>
                <li>There was an error retrieving&nbsp;it.</li>
              </ul>
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate("/surveys")}
              startIcon={<FontAwesomeIcon icon={faClipboardList} />}
            >
              Go to the Survey List
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }

  function showProgress() {
    if (completedRequiredQuestions?.length > 0) {
      return (
        <CircularProgressWithLabel
          value={Math.round(
            (completedRequiredQuestions?.length / requiredQuestions?.length) *
              100
          )}
          sx={progressStyles}
        />
      );
    }
  }

  function showHeader() {
    return (
      <>
        <Badge
          badgeContent="Anonymous"
          color="primary"
          invisible={!survey.isAnonymous}
          onClick={handleInfoDialogOpen}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <Typography variant="h2" gutterBottom>
            {survey.name}
          </Typography>
        </Badge>
        {!!surveyDescription && (
          <Paper sx={headerStyles}>
            {!!surveyDescription.heading && (
              <Typography variant="h4" component="h3">
                {widowUnmaker(surveyDescription.heading)}
              </Typography>
            )}
            {!!surveyDescription.lede && (
              <Typography variant="lede" component="p">
                <em>{widowUnmaker(surveyDescription.lede)}</em>
              </Typography>
            )}
            {!!surveyDescription.message && (
              <Typography variant="caption">
                <em>{widowUnmaker(surveyDescription.message)}</em>
              </Typography>
            )}
          </Paper>
        )}
        {requiredQuestions?.length > 0 && (
          <Typography variant="caption" component="div" sx={requiredNoteStyles}>
            {REQ}Required
          </Typography>
        )}
      </>
    );
  }

  function showQuestionLayout(layout) {
    /* 
    Each survey can have an optional layout defined in the JSON object stored in the database in
    survey.Survey.Description. 

    "solo" displays only one question at a time and ignores the PageNumber column

    "solo-auto" is the same as "solo" but automatically advances to next page when question is
    answered. All but last question must use radio buttons. 

    The default renders a paginated survey if QuestionInstances have defined page numbers, otherwise
    it shows all questions on one page and hides the MobileStepper.
    */
    const qs = survey.questions;
    const p = survey.pages[activePage];
    switch (layout) {
      case "solo-auto": // rendering is same as solo but handleChoiceChange auto-advances to next page
      case "solo":
        return (
          <>
            <Fade
              in
              timeout={theme.transitions.duration.enteringScreen}
              style={{
                transitionDelay: `600ms`,
              }}
            >
              <MobileStepper
                variant={qs.length > 7 ? "text" : "dots"}
                steps={qs.length}
                activeStep={activePage}
                position="static"
                sx={{ background: "none", mt: 2, mb: "-1.5rem" }}
                backButton={
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleBack();
                    }}
                    startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    sx={{
                      visibility: activePage !== 0 ? "visible" : "hidden",
                    }}
                  >
                    Back
                  </Button>
                }
                nextButton={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleNext();
                    }}
                    endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                    sx={{
                      visibility:
                        activePage !== qs.length - 1 ? "visible" : "hidden",
                    }}
                  >
                    Next
                  </Button>
                }
              />
            </Fade>
            <Box component="section">
              <Fade
                in
                timeout={theme.transitions.duration.enteringScreen}
                style={{
                  transitionDelay: `400ms`,
                }}
              >
                <Typography variant="h4" sx={sectionTitleStyles}>
                  {qs[activePage]?.category}
                </Typography>
              </Fade>
              <Slide
                direction="left"
                in
                timeout={theme.transitions.duration.enteringScreen}
              >
                {showQuestion(qs[activePage])}
              </Slide>
            </Box>
          </>
        );
      default:
        return (
          <>
            {p.sections.map((s, index) => {
              return (
                <Box component="section" key={index}>
                  <Fade
                    in
                    timeout={theme.transitions.duration.enteringScreen}
                    style={{
                      transitionDelay: `800ms`,
                    }}
                  >
                    <Typography variant="h4" sx={sectionTitleStyles}>
                      {s.name}
                    </Typography>
                  </Fade>
                  <TrailSlideUpFadeIn>
                    {s.questions.map((q) => {
                      return showQuestion(q);
                    })}
                  </TrailSlideUpFadeIn>
                </Box>
              );
            })}
            {survey.pages.length > 1 && (
              <Fade
                in
                timeout={theme.transitions.duration.enteringScreen}
                style={{
                  transitionDelay: `800ms`,
                }}
              >
                <MobileStepper
                  variant={survey.pages.length > 7 ? "text" : "dots"}
                  steps={survey.pages.length}
                  activeStep={activePage}
                  position="static"
                  sx={{ background: "none", mt: 2, mb: "-1.5rem" }}
                  backButton={
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleBack();
                      }}
                      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                      sx={{
                        visibility: activePage !== 0 ? "visible" : "hidden",
                      }}
                    >
                      Back
                    </Button>
                  }
                  nextButton={
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleNext();
                      }}
                      endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                      sx={{
                        visibility:
                          activePage !== survey.pages.length - 1
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      Next
                    </Button>
                  }
                />
              </Fade>
            )}
          </>
        );
    }
  }

  function showQuestionNumber(q) {
    return `${
      survey.questions.findIndex(
        (qs) => qs.questionInstanceId === q.questionInstanceId
      ) + 1
    }. `;
  }

  function showQuestion(q) {
    return (
      <Paper key={q.questionInstanceId} sx={questionStyles}>
        <FormControl
          id={`question-${q.questionInstanceId}`}
          sx={{ width: "100%" }}
        >
          <FormLabel>
            <Typography variant="h5" component="p">
              {showQuestionNumber(q)}
              {widowUnmaker(q.question)}
              {!!q.required && REQ}
            </Typography>
          </FormLabel>
          {!!q.description && <FormHelperText>{q.description}</FormHelperText>}
          {q.type.toLowerCase() === "radio" && showRadios(q)}
          {q.type.toLowerCase() === "checkbox" && showCheckboxes(q)}
          {q.type.toLowerCase() === "dropdown" && showDropdown(q)}
          {q.type.toLowerCase() === "likert" && showRadios(q, true)}
          {q.type.toLowerCase() === "single-line" && showSingleLine(q)}
          {q.type.toLowerCase() === "multi-line" && showMultiLine(q)}
        </FormControl>
      </Paper>
    );
  }

  function isChecked(q, c) {
    const r = results.find(
      (r) => r.questionInstanceId === q.questionInstanceId
    );
    if (!!(c.choiceInstanceId === r?.ChoiceInstanceID)) {
      return true;
    }
  }

  function getSelectedDropdownOption(q) {
    const r = results.find(
      (r) => r.questionInstanceId === q.questionInstanceId
    );
    return r?.label;
  }

  function showRadios(q, row) {
    const handleTextFieldClick = (event) => {
      event.stopPropagation();
      const radio = event.target
        .closest("label")
        .querySelector('input[type="radio"]');
      radio.click();
    };

    return (
      <RadioGroup
        aria-labelledby={`question-${q.questionInstanceId}`}
        name={`radio-group-${q.questionInstanceId}`}
        onChange={(event) => {
          handleChoiceChange(event, q);
        }}
        row={!!(row && !smallScreen) ? true : false}
        sx={!!row ? likertStyles : {}}
      >
        {q.choices.map((c) => {
          return (
            <FormControlLabel
              value={c.choiceInstanceId}
              label={
                !!c.includeOther ? (
                  <TextField
                    label={c.choice}
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    value={getPreviousInput(q, true)}
                    onClick={handleTextFieldClick}
                    onChange={(event) => {
                      handleOtherInputChange(event, q);
                    }}
                  />
                ) : (
                  c.choice
                )
              }
              labelPlacement={!!(row && !smallScreen) ? "bottom" : "end"}
              control={<Radio checked={isChecked(q, c)} />}
              key={c.choiceInstanceId}
            />
          );
        })}
      </RadioGroup>
    );
  }

  function showCheckboxes(q) {
    return (
      <FormGroup
        aria-labelledby={`question-${q.questionInstanceId}`}
        name={`form-group-${q.questionInstanceId}`}
        onChange={(event) => {
          handleChoiceChange(event, q);
        }}
      >
        {q.choices.map((c) => {
          return (
            <FormControlLabel
              value={c.choiceInstanceId}
              label={c.choice}
              control={<Checkbox checked={isChecked(q, c)} />}
              key={c.choiceInstanceId}
            />
          );
        })}
      </FormGroup>
    );
  }

  function showDropdown(q) {
    const options = q.choices.map((c) => {
      return { label: c.choice, choiceInstanceId: c.choiceInstanceId };
    });
    return (
      <Autocomplete
        disableClearable
        id={`dropdown-${q.questionInstanceId}`}
        options={options}
        renderInput={(params) => <TextField {...params} />}
        onChange={(event, selectedValue) =>
          handleChoiceChange(event, q, selectedValue)
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ mt: 1, maxWidth: 560 }}
        value={getSelectedDropdownOption(q)}
      />
    );
  }

  function showSingleLine(q) {
    const format = !!q.formatting ? JSON.parse(q.formatting) : "";
    return (
      <TextField
        hiddenLabel
        id={`single-line-${q.questionInstanceId}`}
        value={getPreviousInput(q)}
        onChange={(event) => {
          handleInputChange(event, q);
        }}
        variant="outlined"
        sx={{ mt: 1, maxWidth: 560 }}
        inputProps={{
          autoComplete: "off",
          maxLength: format?.max,
        }}
        helperText={!!format.max ? `Max: ${format.max} characters` : ""}
      />
    );
  }

  function showMultiLine(q) {
    const format = !!q.formatting ? JSON.parse(q.formatting) : "";
    return (
      <TextField
        hiddenLabel
        id={`multi-line-${q.questionInstanceId}`}
        value={getPreviousInput(q)}
        onChange={(event) => {
          handleInputChange(event, q);
        }}
        variant="outlined"
        multiline
        minRows={4}
        sx={{ mt: 1 }}
        inputProps={{
          autoComplete: "off",
          maxLength: format?.max,
        }}
        helperText={!!format.max ? `Max: ${format.max} characters` : ""}
      />
    );
  }

  function getPreviousInput(q, other) {
    const a = other
      ? others.filter((o) => o.questionInstanceId === q.questionInstanceId)
      : results.filter((r) => r.questionInstanceId === q.questionInstanceId);
    return !!a[0] ? a[0].ResultText : "";
  }

  function handleOtherInputChange(event, q) {
    //filter out any previous answer to this question
    const filteredOthers = others.filter(
      (o) => o.questionInstanceId !== q.questionInstanceId
    );
    const newOthers = [
      ...filteredOthers,
      {
        ResultText: event.target.value,
        questionInstanceId: q.questionInstanceId,
      },
    ];
    setOthers(newOthers);
  }

  function handleInputChange(event, q) {
    //filter out any previous answer to this question
    const filteredResults = results.filter(
      (r) => r.questionInstanceId !== q.questionInstanceId
    );
    const newResults = [
      ...filteredResults,
      {
        ChoiceInstanceID: q.choices[0].choiceInstanceId,
        ResultText: event.target.value,
        questionInstanceId: q.questionInstanceId,
        required: q.required,
      },
    ];
    setResults(newResults);
  }

  function handleChoiceChange(event, q, selectedValue) {
    //filter out any previous answer to this question
    const filteredResults = results.filter(
      (r) => r.questionInstanceId !== q.questionInstanceId
    );
    const value = parseInt(event.target.value);

    if (q.type.toLowerCase() === "radio" || q.type.toLowerCase() === "likert") {
      const newResults = [
        ...filteredResults,
        {
          ChoiceInstanceID: value,
          ResultText: null, //if there's text from an "Other" TextField, a useEffect updates this
          questionInstanceId: q.questionInstanceId,
          required: q.required,
        },
      ];
      setResults(newResults);

      //clear any "Other" TextField on this question when a different option is selected
      const choice = q.choices.filter((c) => c.choiceInstanceId === value);
      if (!choice.includeOther) {
        const newOthers = others.filter(
          (o) => o.questionInstanceId !== q.questionInstanceId
        );
        setOthers(newOthers);
      }
      if (
        surveyDescription.layout === "solo-auto" &&
        activePage < survey.questions.length - 1
      ) {
        handleNext(); //auto-advances to next page for the "solo-auto" layout
      }
    }
    if (q.type.toLowerCase() === "dropdown") {
      const newResults = [
        ...filteredResults,
        {
          ChoiceInstanceID: selectedValue.choiceInstanceId,
          ResultText: null,
          questionInstanceId: q.questionInstanceId,
          required: q.required,
          label: selectedValue.label,
        },
      ];
      setResults(newResults);
    }
    if (q.type.toLowerCase() === "checkbox") {
      //filter out any previous record of this checkbox choice
      const filteredQuestionResults = results.filter(
        (r) =>
          r.questionInstanceId === q.questionInstanceId &&
          r.ChoiceInstanceID !== value
      );

      const newResults = !!event.target.checked
        ? [
            ...filteredQuestionResults,
            ...filteredResults,
            {
              ChoiceInstanceID: value,
              ResultText: null,
              questionInstanceId: q.questionInstanceId,
              required: q.required,
            },
          ]
        : [...filteredQuestionResults, ...filteredResults];
      setResults(newResults);
    }
  }

  function handleInfoDialogOpen() {
    setInfoDialogOpen(true);
  }

  function handleInfoDialogClose() {
    setInfoDialogOpen(false);
  }

  function showInfoDialog() {
    return (
      <Dialog
        open={infoDialogOpen}
        onClose={handleInfoDialogClose}
        aria-labelledby="alert-info-dialog-title"
        aria-describedby="alert-info-dialog-description"
      >
        <DialogTitle id="alert-info-dialog-title">
          <Grid container justifyContent="space-between" spacing={4}>
            <Grid item>{"Is this really anonymous?"}</Grid>
            <Grid item>
              <IconButton
                onClick={handleInfoDialogClose}
                sx={closeButtonStyles}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} lg={5}>
              <Typography
                variant="h1"
                component="div"
                sx={{ textAlign: "center" }}
                gutterBottom
              >
                <FontAwesomeIcon icon={faUserSecret} size="3x" />
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} lg={7} id="alert-info-dialog-description">
              <Typography>
                <strong>
                  We store your survey choices{" "}
                  <Typography component="em" color="secondary">
                    <strong>without</strong>
                  </Typography>{" "}
                  personally identifiable information
                </strong>{" "}
                like your name, email address, ID number, etc. Even though you
                are logged into this app, we don't save any extra data along
                with your answers that can tie them to&nbsp;you.
                <br />
                <br />
                <strong>
                  Next, we store a{" "}
                  <Typography component="em" color="secondary">
                    <strong>separate</strong>
                  </Typography>{" "}
                  record showing you completed this survey.
                </strong>{" "}
                That means no one can take this particular survey more than
                once, and we know not to ask you about it&nbsp;again.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleCompletedDialogOpen() {
    setCompletedDialogOpen(true);
  }
  /* no handleCompletedDialogClose() function - user's only options are
     to go to the Survey List or Home (or use browser controls to navigate) */

  function showCompletedDialog() {
    return (
      <Dialog
        open={completedDialogOpen}
        fullWidth
        maxWidth={"xl"}
        aria-labelledby="alert-completed-dialog-title"
        aria-describedby="alert-completed-dialog-description"
      >
        <DialogTitle id="alert-completed-dialog-title">
          {survey.name} Completed
        </DialogTitle>
        <DialogContent sx={completedDialogContentStyles}>
          <Grid container alignItems="center">
            <Grid item xs={12} md={4} lg={5}>
              <Typography
                variant="h1"
                component="div"
                sx={{ textAlign: "center", mt: 1 }}
                gutterBottom
              >
                <FontAwesomeIcon icon={faClipboardCheck} size="3x" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              lg={7}
              id="alert-completed-dialog-description"
            >
              <Typography variant="h2">{surveyCompletion?.heading}</Typography>
              <Typography>{surveyCompletion?.message}</Typography>
              <br />
              <br />
              <Link href="/surveys">Go to the survey list &raquo;</Link>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            color="primary"
            href="/"
            startIcon={<FontAwesomeIcon icon={faHome} />}
          >
            Home
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/surveys"
            startIcon={<FontAwesomeIcon icon={faClipboardList} />}
          >
            Survey List
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleNext() {
    setActivePage((p) => p + 1);
  }

  function handleBack() {
    setActivePage((p) => p - 1);
  }

  function showSubmitSection() {
    return (
      <Fade
        in
        timeout={theme.transitions.duration.enteringScreen}
        style={{
          transitionDelay: `1000ms`,
        }}
      >
        <Box component="section" sx={surveyActionsStyles}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              <Typography variant="caption" sx={{ textAlign: "right", mr: 2 }}>
                {completedRequiredQuestions?.length !==
                  requiredQuestions?.length && (
                  <span>
                    {REQ}To submit, please answer all required questions.
                  </span>
                )}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                disabled={
                  submitted ||
                  completedRequiredQuestions?.length !==
                    requiredQuestions?.length
                }
                endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "right" }}>
              {survey.isAnonymous && (
                <Box>
                  <Typography variant="caption">
                    <Link
                      onClick={handleInfoDialogOpen}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{ marginRight: 4 }}
                      />
                      Learn how we make this survey&nbsp;
                      <strong>anonymous</strong>...
                    </Link>
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Fade>
    );
  }

  function handleSubmit() {
    surveyResponse.mutateAsync();
    setSubmitted(true);
    if (!surveyResponse.isError) {
      handleCompletedDialogOpen();
    }
    setTimeout(() => {
      navigate("/surveys");
    }, 120000); //redirect after 2 minutes
  }
}
