import React from "react";
import { Grid, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { widowUnmaker } from "../../services/utilities";

export default function NoDataMessage(props) {
  const { message, icon } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            mt: 3,
            color: theme?.palette?.text?.secondary,
          }}
          gutterBottom
        >
          <em>{widowUnmaker(message)}</em>
        </Typography>
      </Grid>
      {!!icon && (
        <Grid item xs={12}>
          <FontAwesomeIcon
            icon={icon}
            size="10x"
            color={alpha(theme?.palette?.text?.primary ?? "#777", 0.25)}
          />
        </Grid>
      )}
    </Grid>
  );
}
