import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import InterestsAbout from "./InterestsAbout";

export default function InterestsIntro() {
  const aboutWrapStyles = {
    textAlign: "right",
  };
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item xs={12} md={9}>
        <Typography variant="lede" component="p">
          Rate a wide selection of interests. You may discover new things you
          want to try or places you want to go.
        </Typography>
        <br />
        <Typography
          variant="body2"
          gutterBottom
          sx={{ maxWidth: "100% !important" }}
        >
          Find careers that fit your interests, what jobs are in demand, how
          much those jobs pay, how to get the education you need for them and
          more...
        </Typography>
        <Button
          href="https://www.careerbridge.wa.gov/"
          target="_blank"
          rel="noreferrer noopener"
          variant="contained"
          color="secondary"
          endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
        >
          WA Career Bridge
        </Button>
      </Grid>

      <Grid item xs={12} md={3} sx={aboutWrapStyles}>
        <InterestsAbout />
      </Grid>
    </Grid>
  );
}
