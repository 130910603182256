import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSunglasses, faBellSchool, faFileInvoiceDollar } from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import HeroStandard from "../../components/heroes/HeroStandard";
import HeroBasic from "../../components/heroes/HeroBasic";
import HeroBanner1 from "../../components/heroes/HeroBanner1";
import { useUser } from "../contexts/userContext";
import { getFirstName } from "../utilities";
// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "./../../components/images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function useHero() {
  const theme = useTheme();
  const { user } = useUser();
  const today = dayjs();
  let isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);

  const unpaidInvoices = user?.students?.flatMap((student) => {
    return student?.unpaidEloInvoices?.filter((invoice) => invoice?.invoiceUrl) || [];
  });

  const heroes = [
    /* List these in priority order - higher in the list means higher priority 
       The array of date objects in each hero should be set until 2030 when possible.
       Dates are inclusive (i.e. the hero will be visible on both the startDate and the endDate)
    */

    // Unpaid Invoices
    {
      dates: [
        {
          startDate: unpaidInvoices?.length > 0 ? dayjs().format("YYYY-MM-DD") : dayjs().add(-1, "day").format("YYYY-MM-DD"),
          endDate: unpaidInvoices?.length > 0 ? dayjs().add(1, "day").format("YYYY-MM-DD") : dayjs().add(-1, "day").format("YYYY-MM-DD"),
        },
      ],
      available: true,
      component: (
        <HeroStandard
          title="Beyond the Bell"
          subtitle={`${unpaidInvoices.length} unpaid invoice${unpaidInvoices.length > 1 ? "s" : ""}`}
          description={`Thank you for choosing to support Beyond the Bell activities. Please pay your invoice${unpaidInvoices.length > 1 ? "s" : ""} immediately. You may not be able to register for activities in the next session if you have an unpaid invoice.`}
          image={
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              style={{
                height: "6.2rem",
                marginRight: "2rem",
                color: theme?.palette.primary.main,
              }}
            />
          }
          url={unpaidInvoices[0]?.invoiceUrl}
          linkText="Pay with MySchoolBucks"
        />
      ),
    },

    // Beyond the Bell
    //TODO: Make specific hero announcing registration windows
    {
      dates: [
        {
          startDate: "2024-09-01",
          endDate: "2025-06-01",
        },
      ],
      available: true,
      component: (
        <HeroStandard
          title="Beyond the Bell Activities"
          subtitle=""
          description="Tacoma Public Schools and dozens of community partners have come together to offer clubs, activities, sports, field trips and more."
          image={
            <FontAwesomeIcon
              icon={fasBeyondTheBell}
              style={{
                height: "8rem",
                marginRight: "2rem",
                marginBottom: "0.5rem",
                color: theme?.palette.primary.main,
              }}
            />
          }
          url="/activities"
          linkText="Learn more and register"
        />
      ),
    },

    // Have a great summer
    {
      dates: [
        {
          startDate: "2022-06-22",
          endDate: "2022-08-15",
        },
        {
          startDate: "2023-06-22",
          endDate: "2023-08-15",
        },
      ],
      available: user?.role === "student",
      component: (
        <HeroStandard
          title="Have a great summer!"
          subtitle=""
          description={
            <span>
              Check out the special summer activities, free meals and other
              resources available for you this&nbsp;summer.
            </span>
          }
          image={
            <FontAwesomeIcon
              icon={faSunglasses}
              style={{ height: "10rem" }}
              color={theme?.name === "Midnight" ? "#555" : "#000"}
            />
          }
          url="https://www.tacomaschools.org/departments/summer-programs-meals-resources"
          linkText="Learn more"
        />
      ),
    },
    // Make it a great summer for your kids
    {
      dates: [
        {
          startDate: "2022-06-22",
          endDate: "2022-08-15",
        },
        {
          startDate: "2023-06-22",
          endDate: "2023-08-15",
        },
      ],
      available: user?.role === "guardian",
      component: (
        <HeroStandard
          title={<span>Make it a great summer for your&nbsp;kids!</span>}
          subtitle=""
          description="Tacoma Public Schools and several partner organizations offer activities, free meals and other resources for students and families this summer."
          image={
            <FontAwesomeIcon
              icon={faSunglasses}
              style={{ height: "10rem" }}
              color={theme?.name === "Midnight" ? "#555" : "#000"}
            />
          }
          url="https://www.tacomaschools.org/departments/summer-programs-meals-resources"
          linkText="Learn more"
        />
      ),
    },
    // First day of school
    {
      dates: [
        {
          startDate: "2022-06-15",
          endDate: "2023-09-09",
        },
      ],
      available: true,
      component: (
        <HeroStandard
          title="First day of school!"
          subtitle="September 8th"
          description={<small>(Except for SOTA, SAMI and IDEA)</small>}
          image={
            <FontAwesomeIcon icon={faBellSchool} style={{ height: "10rem" }} />
          }
          url="/events"
          linkText="See more events"
        />
      ),
    },
    // New Year's Day (student)
    {
      dates: [
        {
          startDate: "2022-12-31",
          endDate: "2023-01-01",
        },
        {
          startDate: "2023-12-31",
          endDate: "2024-01-01",
        },
        {
          startDate: "2024-12-31",
          endDate: "2025-01-01",
        },
        {
          startDate: "2025-12-31",
          endDate: "2026-01-01",
        },
        {
          startDate: "2026-12-31",
          endDate: "2027-01-01",
        },
        {
          startDate: "2027-12-31",
          endDate: "2028-01-01",
        },
        {
          startDate: "2028-12-31",
          endDate: "2029-01-01",
        },
        {
          startDate: "2029-12-31",
          endDate: "2030-01-01",
        },
      ],
      available: user?.role === "student",
      component: (
        <HeroStandard
          title="Happy New Year!"
          description={
            <span>
              Consider ditching "resolutions" which are often just wishful
              thinking and replacing them with SMART&nbsp;goals.
            </span>
          }
          imageUrl={`https://images.unsplash.com/photo-1654756451106-1b8e65da7f70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80`}
          url="/goals"
          linkText="Set some goals..."
          textColor="#fff"
        />
      ),
    },
    // New Year's Day (guardian)
    {
      dates: [
        {
          startDate: "2022-12-31",
          endDate: "2023-01-01",
        },
        {
          startDate: "2023-12-31",
          endDate: "2024-01-01",
        },
        {
          startDate: "2024-12-31",
          endDate: "2025-01-01",
        },
        {
          startDate: "2025-12-31",
          endDate: "2026-01-01",
        },
        {
          startDate: "2026-12-31",
          endDate: "2027-01-01",
        },
        {
          startDate: "2027-12-31",
          endDate: "2028-01-01",
        },
        {
          startDate: "2028-12-31",
          endDate: "2029-01-01",
        },
        {
          startDate: "2029-12-31",
          endDate: "2030-01-01",
        },
      ],
      available: user?.role === "guardian",
      component: <HeroBasic title="Happy New Year!" description="" />,
    },
    // Winter Break
    {
      dates: [
        {
          startDate: "2022-12-10", // 1 week before winter break
          endDate: "2023-01-03",
        },
      ],
      available: true,
      component: (
        <HeroBanner1
          title="Winter Break"
          subtitle={<em>Enjoy the holidays...</em>}
          description={
            <span>
              Last school day of 2022 is Friday, December&nbsp;16th.
              School&nbsp;resumes Tuesday, January&nbsp;3rd.{" "}
              <small>
                (Except&nbsp;for&nbsp;SOTA,&nbsp;SAMI&nbsp;&amp;&nbsp;IDEA)
              </small>
            </span>
          }
          imageUrl={`https://images.unsplash.com/photo-1654756451106-1b8e65da7f70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80`}
          textColor="#fff"
        />
      ),
    },
  ];

  /* Other heroes to add:
   * Spring Break

  * MAJOR HOLIDAYS
        x New Year's Day
        * MLK Day
        * Presidents Day
        * Memorial Day
        * Juneteenth
        * Independence Day
        * Labor Day
        * Veterans Day
        * Thanksgiving
        * Christmas
    *  
   */

  const defaultHero = {
    component: (
      <HeroBasic
        title={`Hi there${!!user?.name ? ", " : ""}${getFirstName(
          user?.name
        )}!`}
        description={<em>Where will you go today?...</em>}
      />
    ),
  };

  const currentHero = heroes.find((hero) => {
    /* .find() returns the first match. If multiple heroes match the conditions, 
    only the first will be returned, therefore priority is built into the order of the heroes array.  */
    const inRange = (dates) => {
      const startDate = dayjs(dates.startDate);
      const endDate = dayjs(dates.endDate);
      if (today.isBetween(startDate, endDate, "day", "[]")) {
        // [] means inclusive
        return true;
      } else return false;
    };
    if (hero.dates.some(inRange) && hero.available) {
      return hero;
    }
  });

  return currentHero?.component ?? defaultHero.component;
}
