//#region Imports
import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { usePostDsaParticipant } from "../../services/hooks/dsaHook";
import { formatDateOnly } from "../../services/utilities";
//#endregion

export default function DsaWithdraw(props) {
  const { registrant, open, onClose } = props;
  const { activeStudent } = useActiveStudent();
  const { user } = useUser();
  const [value, setValue] = useState("");

  const [otherText, setOtherText] = useState("");
  const [note, setNote] = useState();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setNote(value === "Other" ? `Other: ${otherText}` : value);
  }, [value, otherText]);

  const handleOtherChange = (event) => {
    setOtherText(event.target.value);
  };

  const postObject = {
    registrantId: registrant?.registrantId,
    partnerId: registrant?.partnerId,
    studentId: activeStudent?.studentId,
    entryDate: registrant?.entryDate,
    withdrawalDate: formatDateOnly(new Date()), // since this is a new registration, this should be null
    note: note?.length > 0 ? note : null,
    grade: registrant?.grade,
    createdBy: registrant?.createdBy,
    createdDate: registrant?.createdDate,
    modifiedBy: user?.email,
    modifiedDate: new Date(),
  };

  const postDsaParticipant = usePostDsaParticipant(postObject, onClose);

  const submitWithdraw = () => {
    postDsaParticipant.mutate();
    setValue("");
    setOtherText("");
    setNote("");
  };

  //#region Render
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5">
          Are you sure you want to withdraw {activeStudent?.firstName} from{" "}
          {registrant?.partner?.dsaTitle ?? registrant?.partner?.name}?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ pb: "2em" }}>
          You may not be able to register again if the registration window
          has&nbsp;ended.
        </Typography>
        <Typography>Please select a reason for withdrawal:</Typography>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value="Not interested in program"
            control={<Radio />}
            label="Not interested in program"
          />
          <FormControlLabel
            value="Privacy concerns"
            control={<Radio />}
            label="Privacy concerns"
          />
          <FormControlLabel value="Other" control={<Radio />} label="Other" />
        </RadioGroup>
        {value === "Other" && (
          <TextField
            label="Please specify"
            value={otherText}
            onChange={handleOtherChange}
            inputProps={{ maxLength: 50 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            setValue("");
            setOtherText("");
            setNote("");
          }}
          disabled={postDsaParticipant?.isLoading}
        >
          {" "}
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            postDsaParticipant?.isLoading ||
            value === "" ||
            value?.length === 0 ||
            (value === "Other" && otherText === "") ||
            otherText === null
          }
          onClick={submitWithdraw}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
  //#endregion
}
