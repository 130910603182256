import { useGetStudentInterestsRatingCounts } from "../../services/hooks/interestsHook";
import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import ReactECharts from "echarts-for-react";
import { interestsRatingDefinitions } from "../../services/constants";

export default function InterestsStatsRadar() {
  const theme = useTheme();
  const interestsInfo = useGetStudentInterestsRatingCounts();
  const interestsData = interestsInfo?.data?.data;

  const interestSeries = useMemo(
    () =>
      interestsData?.map((id) => {
        return {
          category: id.interestCategory,
          ratings: interestsRatingDefinitions?.map((rd) => {
            return {
              name: rd.name,
              value: rd.value,
              count:
                id?.ratingCounts?.find((rc) => rc.rating === rd.value)?.count ??
                0,
            };
          }),
        };
      }),
    [interestsData]
  );

  const maxReduced = useMemo(
    () =>
      interestsData?.reduce((max, currentItem) => {
        let currentItemMax = Math.max(
          ...currentItem?.ratingCounts?.map((c) => c.count)
        );
        return currentItemMax > max ? currentItemMax : max;
      }, 0),
    [interestsData]
  );

  const seriesRatings = interestSeries?.map((c) =>
    c.ratings?.map((e) => e.count)
  );

  const interestRadarChart = {
    radar: {
      radius: ["0%", "45%"],

      center: ["50%", "40%"],
      indicator: [
        {
          name: "Don't\nLike",
          max: maxReduced,
        },
        { name: "Don't\nKnow", max: maxReduced },
        {
          name: "Want to\nlearn more",
          max: maxReduced,
        },
        { name: "Like it", max: maxReduced },
        { name: "Love it", max: maxReduced },
      ],
    },

    series: [
      {
        top: 15,
        type: "radar",
        data: [
          {
            value: interestSeries?.length > 0 && [...seriesRatings[0]],
            name: interestSeries?.length > 0 && interestSeries[0]?.category,
            itemStyle: { color: theme?.palette?.charts?.first },
          },
          {
            value: interestSeries?.length > 1 && [...seriesRatings[1]],
            name: interestSeries?.length > 1 && interestSeries[1]?.category,
            itemStyle: { color: theme?.palette?.charts?.second },
          },
          {
            value: interestSeries?.length > 2 && [...seriesRatings[2]],
            name: interestSeries?.length > 2 && interestSeries[2]?.category,
            itemStyle: { color: theme?.palette?.charts?.third },
          },
          {
            value: interestSeries?.length > 3 && [...seriesRatings[3]],
            name: interestSeries?.length > 3 && interestSeries[3]?.category,
            itemStyle: { color: theme?.palette?.charts?.fourth },
          },
          {
            value: interestSeries?.length > 4 && [...seriesRatings[4]],
            name: interestSeries?.length > 4 && interestSeries[4]?.category,
            itemStyle: { color: theme?.palette?.charts?.fifth },
          },
        ],
      },
    ],

    legend: {
      bottom: "1%",
      orient: "horizontal",

      textStyle: {
        color: theme?.palette?.text?.primary,
      },
    },
  };

  return (
    <>
      <ReactECharts option={interestRadarChart} />
    </>
  );
}
