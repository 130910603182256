import React from "react";
import { Grid, Typography } from "@mui/material";
import GoalsAbout from "./GoalsAbout";

export default function GoalsIntro() {
  const aboutWrapStyles = {
    textAlign: "right",
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="lede" component="p">
            <strong>Do something awesome!</strong> What do you want to achieve?
            Set a goal, make a plan and do&nbsp;it!
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={aboutWrapStyles}>
          <GoalsAbout />
        </Grid>
      </Grid>
    </>
  );
}
