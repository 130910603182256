export const interestsRatingDefinitions = [
  {
    name: "Dont Like",
    value: 1,
  },
  {
    name: "Dont know",
    value: 2,
  },
  {
    name: "Want to learn more",
    value: 3,
  },
  {
    name: "Like it",
    value: 4,
  },
  {
    name: "Love it!",
    value: 5,
  },
];
