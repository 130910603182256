import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { isFullUrl } from "../../services/utilities";

/*  Use when the url is unknown.
    Full URLs are presumed to be external links and will open in a new tab.
    buttonProps takes any MUI Button props, ie: 
        {variant: "contained", color:"secondary"}  */
export default function PossibleExternalLinkButton(props) {
  const { url, linkText, buttonProps } = props;
  const navigate = useNavigate();

  if (!url) return;
  if (isFullUrl(url)) {
    return (
      <Button
        {...buttonProps}
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        endIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
      >
        {linkText ?? "more"}
      </Button>
    );
  } else {
    return (
      <Button {...buttonProps} onClick={() => navigate(url)}>
        {linkText ?? "more"}
      </Button>
    );
  }
}
