import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldAlt } from "@fortawesome/pro-solid-svg-icons";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import BadgesEarned from "../images/BadgesEarned";
import AnimatedNumbers from "react-animated-numbers";

export default function AboutCompassBadges() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const size = largeScreen ? 320 : 270;

  const slideStyles = {
    ...commonStyles.slideStyles,
  };
  const flexCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const badgeWrap = {
    position: "relative",
    overflow: "hidden",
  };
  const badgeScore = {
    ...flexCenter,
    position: "absolute",
    width: size,
    height: size,
    fontWeight: 800,
    color: theme?.palette?.badges?.number,
    fontSize: largeScreen ? "3.5rem !important" : "3rem !important",
    zIndex: 2,
  };
  const badgeImage = {
    ...flexCenter,
    position: "relative",
    width: size,
    height: size,
    "& svg": {
      position: "absolute",
      width: size,
      zIndex: 1,
    },
  };

  return (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1">
        <FontAwesomeIcon icon={faShieldAlt} />
        &nbsp;Badges
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 32px)",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box sx={flexCenter}>
          <Box sx={badgeWrap}>
            <Typography sx={badgeScore}>
              <AnimatedNumbers
                animateToNumber={12345}
                configs={[
                  { mass: 1, tension: 220, friction: 50, precision: 0.001 },
                  { mass: 1, tension: 180, friction: 80, precision: 0.001 },
                  { mass: 1, tension: 280, friction: 40, precision: 0.001 },
                  { mass: 1, tension: 210, friction: 50, precision: 0.001 },
                  { mass: 1, tension: 180, friction: 85, precision: 0.001 },
                  { mass: 1, tension: 180, friction: 30, precision: 0.001 },
                  { mass: 1, tension: 260, friction: 50, precision: 0.001 },
                ]}
              ></AnimatedNumbers>
            </Typography>
            <Box sx={badgeImage}>
              <BadgesEarned scale={2} />
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant="h4" component="h2">
            Show 'em what you&nbsp;got...
          </Typography>
          <Typography variant="body2">
            All kinds of achievements in school can earn you a badge.{" "}
            <em>
              Compete with friends - who can rack up the most&nbsp;badges?
            </em>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
