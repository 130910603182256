import React from "react";
import { useTrail, animated, config } from "react-spring";

export default function TrailGrow(props) {
  const { children } = props;
  const trail = useTrail(children.length, {
    to: { transform: "scale(1)" },
    from: { transform: "scale(0)" },
    delay: 100,
    config: config.gentle,
  });

  return (
    <>
      {trail.map((props, i) => (
        <animated.div style={props} key={i}>
          {children[i]}
        </animated.div>
      ))}
    </>
  );
}
