import { useCallback, useMemo, useRef } from "react";
import { Button, Box, Skeleton } from "@mui/material";
import TrailSlideUpFadeIn from "../animations/TrailSlideUpFadeIn";

import FeedItem from "./FeedItem";
import Error from "./Error";

export default function Feed(props) {
  const { upcomingQuery, historicalQuery } = props;

  const upcoming = upcomingQuery.data?.data?.compassFeedItems;

  const feed = useMemo(() => {
    return historicalQuery?.data?.pages?.reduce(
      (total, val) => total.concat(val.data?.compassFeedItems),
      []
    );
  }, [historicalQuery]);

  const observer = useRef();
  const lastFeedItemRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && historicalQuery.hasNextPage) {
          historicalQuery.fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [historicalQuery?.hasNextPage]
  );

  return (
    <>
      {/* Upcoming */}
      {upcomingQuery.isLoading &&
        Array.from({ length: 4 }).map((a, i) => (
          <Box key={i} sx={{ position: "relative" }}>
            <Skeleton
              height={32}
              width={32}
              variant="circular"
              sx={{ position: "absolute", ml: 4, mt: 2 }}
            />
            <Skeleton height={106} variant="rect" sx={{ mb: 1, ml: 6 }} />
          </Box>
        ))}
      {upcomingQuery.isError && (
        <Error
          message="There was a problem getting upcoming events."
          query={upcomingQuery}
        />
      )}
      {upcomingQuery.isSuccess && upcoming.length > 0 && (
        <TrailSlideUpFadeIn>
          {upcoming?.map((item, index) => (
            <FeedItem item={item} key={index} />
          ))}
        </TrailSlideUpFadeIn>
      )}
      {/* History */}
      {historicalQuery.isError && (
        <Error
          message="There was a problem getting past items."
          query={historicalQuery}
        />
      )}
      {historicalQuery.isSuccess && feed.length > 0 && (
        <TrailSlideUpFadeIn>
          {feed?.map((item, index) => (
            <span key={index} ref={lastFeedItemRef}>
              <FeedItem item={item} />
            </span>
          ))}
        </TrailSlideUpFadeIn>
      )}
      {historicalQuery.isLoading &&
        Array.from({ length: 4 }).map((a, i) => (
          <Box key={i} sx={{ position: "relative" }}>
            <Skeleton
              height={32}
              width={32}
              variant="circular"
              sx={{ position: "absolute", ml: 4, mt: 2 }}
            />
            <Skeleton height={106} variant="rect" sx={{ mb: 1, ml: 6 }} />
          </Box>
        ))}
      {historicalQuery.hasNextPage && (
        <Box sx={{ textAlign: "center", mt: 3 }}>
          <Button variant="outlined" onClick={historicalQuery.fetchNextPage}>
            Load more
          </Button>
        </Box>
      )}
    </>
  );
}
