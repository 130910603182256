import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme, darken, lighten, alpha } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import EventsAbout from "./../common/EventsAbout";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
  useGetDistrictEvents,
  useGetSchoolEvents,
} from "../../services/hooks/eventsHook";
import {
  useCompassEnrolledPrograms,
  useCompassEnrolledProgramsAllStudents,
} from "../../services/hooks/programsHook";
import { useUser } from "../../services/contexts/userContext";

export default function Events() {
  const theme = useTheme();
  const { eid } = useParams();
  let sources = [];
  const [calendars, setCalendars] = useState();
  const [eventDialogIsOpen, setEventDialogIsOpen] = useState(false);
  const [feedDialogIsOpen, setFeedDialogIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [feedEvent, setFeedEvent] = useState();

  const { user } = useUser();
  const [studentIds, setStudentIdsState] = useState([]);
  let districtCalendarLoaded = false;
  let schoolsCalendarLoaded = false;
  let programCalendarLoaded = false;
  let calendarsLoaded = false;

  const [districtCalendarSelected, setDistrictCalendarSelected] =
    useState(true);
  const [schoolsCalendarSelected, setSchoolsCalendarSelected] = useState({});
  const [programCalendarSelected, setProgramCalendarSelected] = useState(true);

  useEffect(() => {
    let ids = [];
    !!user &&
      user?.students?.forEach((s) => {
        ids.push(s.studentId);
      });

    setStudentIdsState(ids);
  }, [user]);

  // Calendar colors
  let j = 7;
  const moreColors = Array(8)
    .fill()
    .map(() => {
      return { background: darken("#fff", j++ / 15), color: "#fff" };
    });

  let i = 0;
  const colorArray = !!theme.palette.background.array
    ? [...theme.palette.background.array, ...moreColors]
    : [
        ...Array(7)
          .fill()
          .map(() => {
            return { background: darken("#fff", i++ / 20), color: "#000" };
          }),
        ...moreColors,
      ];

  // District events
  const districtEventsQuery = useGetDistrictEvents();
  const districtEventsData = districtEventsQuery?.data?.data;
  let districtEvents = [];
  districtEventsData?.forEach((d) => {
    districtEvents.push({
      title: d.summary,
      start: d.startDateTime,
      end: d.endDateTime,
      allDay: true, // for the sake of easily styling both types the same
      extendedProps: {
        eventId: d.eventId,
        calendar: d.calendar,
        description: d.description,
        location: d.location,
      },
    });
  });

  sources?.push({
    events: !!districtEvents ? districtEvents : [],
    id: !!districtEventsData ? districtEventsData[0]?.calendar : "",
    color: colorArray[0]?.background,
    textColor: colorArray[0]?.color,
    selected: districtCalendarSelected,
  });
  if (districtEvents.length !== 0) {
    districtCalendarLoaded = true;
  }

  // Program events
  const programEventsQueryStudent = useCompassEnrolledPrograms();
  const programEventsQueryGuardian =
    useCompassEnrolledProgramsAllStudents(studentIds);

  const programEventsData =
    user.role === "student"
      ? programEventsQueryStudent?.data?.data
      : programEventsQueryGuardian?.map((d) => {
          return d?.data?.data;
        });
  let programEvents = [];
  programEventsData?.forEach((student, index) => {
    if (!!student) {
      const studentFirstName = user?.students[index]?.firstName;
      student?.forEach((program, index) => {
        program.programDays.forEach((date) => {
          programEvents.push({
            title: `${studentFirstName}: ${program.activityName}`,
            start: date,
            end: date,
            allDay: true,
            extendedProps: {
              calendar: "Activities",
              description: program.description,
              location: program.locationName,
              startTime: program.defaultStartTime,
            },
          });
        });
      });
    }
  });
  if (programEvents.length !== 0) {
    sources.push({
      events: programEvents,
      id: "Activities",
      color: colorArray[1].background,
      textColor: colorArray[1].color,
      selected: programCalendarSelected,
    });
  }
  if (programEventsData?.length === 0 || programEvents?.length !== 0) {
    programCalendarLoaded = true;
  }

  // School events
  const schools =
    user?.role === "student"
      ? [user.studentDetails?.schoolId]
      : user?.students?.map((stu) => {
          return stu?.schoolId;
        });
  const schoolEventsQuery = useGetSchoolEvents(schools);
  const schoolEventsData = schoolEventsQuery?.map((d) => {
    return d?.data?.data;
  });
  schoolEventsData.map((s, index) => {
    if (!!s) {
      let scs = schoolsCalendarSelected;
      if (!!scs && scs[`${formatCalendarName(s[0]?.calendar)}`] === undefined) {
        scs[`${formatCalendarName(s[0]?.calendar)}`] = true;
        setSchoolsCalendarSelected(scs);
      }

      let schoolEvents = [];
      s.map((d) => {
        schoolEvents.push({
          title: d.summary,
          start: d.startDateTime,
          end: d.endDateTime,
          allDay: true, // for the sake of easily styling both types the same
          extendedProps: {
            eventId: d.eventId,
            calendar: d.calendar,
            description: d.description,
            location: d.location,
          },
        });
      });
      sources.push({
        events: schoolEvents,
        id: formatCalendarName(s[0]?.calendar),
        color: colorArray[index + 2].background,
        textColor: colorArray[index + 2].color,
        selected:
          schoolsCalendarSelected[`${formatCalendarName(s[0]?.calendar)}`],
      });
      if (schoolEvents.length !== 0) {
        schoolsCalendarLoaded = true;
      }
    }
  });
  calendarsLoaded =
    districtCalendarLoaded && programCalendarLoaded && schoolsCalendarLoaded;

  useEffect(() => {
    if (!!sources) {
      // getting rid of any empty arrays
      let sourcesCleaned = sources.filter((s) => {
        if (s.events.length !== 0) return s;
      });
      setCalendars(sourcesCleaned);
    }
  }, [calendarsLoaded]);

  const handleCheckboxChange = (event) => {
    switch (event.target.name) {
      case "District-wide Events":
        setDistrictCalendarSelected(!districtCalendarSelected);
        break;

      case "Activities":
        setProgramCalendarSelected(!programCalendarSelected);
        break;

      default:
        let scs = schoolsCalendarSelected;
        let cals = sources;
        const calendar = formatCalendarName(event.target.name);
        const isSelected = scs[`${calendar}`];
        scs[`${calendar}`] = !isSelected;
        setSchoolsCalendarSelected(scs);
        cals.map((cal) => {
          if (cal.id === calendar) {
            cal.selected = !isSelected;
          }
        });
        setCalendars(cals);
        break;
    }
  };
  const handleEventClick = (info) => {
    setSelectedEvent(info);
    setEventDialogIsOpen(true);
  };

  const handleEventDialogClose = () => {
    setEventDialogIsOpen(false);
    setSelectedEvent();
  };

  const openedEvent = useMemo(() => {
    if (eid != null)
      return (
        schoolEventsData?.flat().find((el) => el?.eventId === eid) ||
        districtEventsData?.find((el) => el?.eventId === eid)
      );
  }, [schoolEventsData, districtEventsData, eid]);

  useEffect(() => {
    if (openedEvent) {
      setFeedEvent(openedEvent);
      setFeedDialogIsOpen(true);
    }
  }, [openedEvent]);

  const handleFeedDialogClose = () => {
    setFeedDialogIsOpen(false);
    setFeedEvent();
  };

  const renderEventContent = (eventInfo) => {
    return <Box sx={eventStyles}>{eventInfo.event.title}</Box>;
  };
  function formatCalendarName(calendar) {
    if (calendar?.includes("Bryant") || calendar?.includes("Wainwright")) {
      return calendar;
    } else {
      return calendar
        ?.replace(" High", "")
        ?.replace(" Middle", "")
        ?.replace(" Elementary", "")
        ?.replace(" Montessori", "");
    }
  }

  // Styles
  const commonPaddingStyles = {
    padding: theme.spacing(2),
  };
  const aboutWrapStyles = {
    textAlign: "right",
  };
  const dataWrapStyles = {
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse",
    },
  };
  const calendarPaperStyles = {
    ...commonPaddingStyles,
    width: "100% !important",
    "& .fc .fc-button-primary": {
      backgroundColor: theme?.palette?.neutral?.main,
      borderColor: darken(theme?.palette?.neutral?.main ?? "#777", 0.125),
      color: theme?.palette?.neutral?.contrastText,
      "&:disabled": {
        backgroundColor: theme?.palette?.neutral?.main,
        color: theme?.palette?.neutral?.contrastText,
      },
    },
    "& .fc td,  .fc th,  .fc .fc-scrollgrid": {
      borderColor: alpha(theme?.palette?.neutral?.main ?? "#777", 0.25),
    },
    "& .fc .fc-scrollgrid > thead > tr > th": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  };
  const eventStyles = {
    padding: "0.125rem 0.25rem",
    overflowX: "hidden",
    cursor: "pointer",
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const dialogContentStyles = {
    minWidth: 250,
    "& p": {
      marginBottom: "1rem",
    },
  };

  return (
    <Box>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2" gutterBottom>
        Events
      </Typography>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="lede" component="p">
            Multiple calendars in one place to help you find what's
            coming&nbsp;up...
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={aboutWrapStyles}>
          <EventsAbout />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={dataWrapStyles}>
        <Grid item xs={12} lg={3}>
          <FormGroup>
            {sources?.map((sc, index) => {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={!!sc.selected}
                      sx={{
                        color: sc.color,
                        "&.MuiCheckbox-root:after": {
                          content: "''",
                          left: 11,
                          top: 11,
                          height: 20,
                          width: 20,
                          position: "absolute",
                          border: `1px solid ${darken(
                            sc.color ?? "#777",
                            0.25
                          )}`,
                          borderRadius: 1,
                          zIndex: 0,
                        },
                        "&.Mui-checked": {
                          color: sc.color,
                          position: "relative",
                          zIndex: 0,
                          "&.Mui-checked:after": {
                            content: "''",
                            left: 11,
                            top: 11,
                            height: 20,
                            width: 20,
                            position: "absolute",
                            borderRadius: 1,
                            backgroundColor: sc.textColor,
                            zIndex: -1,
                          },
                        },
                      }}
                    />
                  }
                  name={sc.id}
                  label={formatCalendarName(sc.id)}
                />
              );
            })}
          </FormGroup>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Paper sx={calendarPaperStyles}>
            <FullCalendar
              height="auto"
              showNonCurrentDates={false}
              plugins={[dayGridPlugin]}
              collapseExtendedProps="true"
              initialView="dayGridMonth"
              eventSources={sources?.filter((c) => c.selected)}
              eventClick={handleEventClick}
              eventContent={renderEventContent}
              eventBorderColor={lighten(
                theme?.palette?.background?.neutral ?? "#777",
                0.25
              )}
              selectedEvent
            />
          </Paper>
          <Dialog open={eventDialogIsOpen} onClose={handleEventDialogClose}>
            <DialogTitle>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item>Event details</Grid>
                <Grid item>
                  <IconButton
                    onClick={handleEventDialogClose}
                    sx={closeButtonStyles}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Box sx={dialogContentStyles}>
                <Typography variant="h5" color="primary">
                  {selectedEvent?.event?.title}
                </Typography>
                <Typography>
                  <strong>
                    {formatDate(selectedEvent?.event?.start, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </strong>
                  <br />
                  <Typography variant="caption" component="span">
                    Calendar: {selectedEvent?.event?.extendedProps?.calendar}
                  </Typography>
                  <br />
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {selectedEvent?.event?.extendedProps?.description}
                  </span>
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>

          <Dialog open={feedDialogIsOpen} onClose={handleFeedDialogClose}>
            <DialogTitle>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item>Event details</Grid>
                <Grid item>
                  <IconButton
                    onClick={handleFeedDialogClose}
                    sx={closeButtonStyles}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Box sx={dialogContentStyles}>
                <Typography
                  variant="h5"
                  sx={{ color: `${theme?.palette?.primary?.text}` }}
                >
                  {openedEvent?.summary}
                </Typography>
                <Typography sx={{ color: theme?.palette?.primary }}>
                  <strong>
                    {formatDate(openedEvent?.startDateTime, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </strong>
                  <br />
                  <Typography variant="caption" component="span">
                    Calendar: {openedEvent?.calendar}
                  </Typography>
                  <br />
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {openedEvent?.description}
                  </span>
                </Typography>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
}
