import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  Box,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarXmark,
  faHandPointRibbon,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCalendarDay,
  faFlag,
  faCalendarAlt,
  faShieldHalved,
  faNewspaper,
} from "@fortawesome/pro-solid-svg-icons";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../services/contexts/userContext";

import { relativeDay, truncateText } from "../../services/utilities";
import dompurify from "dompurify";

export default function FeedItem(props) {
  const { item } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useUser();
  const userStudents = user?.user?.students;
  const largeScreen = useMediaQuery(theme?.breakpoints.up("md"));
  const itemDetails = JSON.parse(item?.details);
  const relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);
  const sanitizer = dompurify.sanitize;
  const titleLength = largeScreen ? 56 : 32; // characters

  const foundNames = userStudents?.find(
    (un) => un?.studentId === itemDetails?.StudentID
  );
  const studentName = foundNames?.firstName;

  // Styles
  const itemContainerStyles = {
    my: theme?.spacing(1),
    mx: 0,
    wordWrap: "break-word",
  };
  const itemStyles = {
    pl: 0,
    ml: 4,
    position: "relative",
    overflow: "visible",
  };
  const feedIconContainers = {
    display: "flex",
    justifyContent: "center",
    borderRadius: "50%",
  };
  const feedIconWrap = {
    ...feedIconContainers,
    position: "absolute",
    width: 64,
    height: 64,
    top: theme?.spacing(2),
    left: "-34px",
  };

  const calendarStyles = {
    ...itemStyles,
    border: `3px solid ${alpha(theme?.palette?.primary?.main ?? "#777", 0.3)}`,
    // hardcoding default color to avoid error when passing a null value to the MUI colorManipulator
    ...theme?.palette?.feed?.calendar, // overrides from theme
  };
  const goalStyles = {
    ...itemStyles,
    border: `1px solid ${theme?.palette?.secondary?.main} !important`,
    ...theme?.palette?.feed?.goal, // overrides from theme
  };
  const badgeStyles = {
    ...itemStyles,
    background: `${theme?.palette?.neutral?.main} !important`,
    color: theme?.palette?.text?.primaryAlt,
    border: `4px solid ${theme?.palette?.tertiary?.contrastText} !important`,
    ...theme?.palette?.feed?.badge, // overrides from theme
  };
  const badgeDescriptionStyles = {
    maxHeight: "3rem",
    overflow: "hidden",
    maxWidth: "490px !important",
    width: "100%",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "1.5rem",
      background: `linear-gradient(rgba(0, 0, 0, 0) 0%, ${theme?.palette?.neutral?.main} 90%)`,
      bottom: 0,
    },
  };
  const newsStyles = {
    ...itemStyles,
    background: `${theme?.palette?.primary?.contrastText}`,
    color: theme?.palette?.primary?.text,
    ...theme?.palette?.feed?.news, // overrides from theme
  };
  const newsDescriptionStyles = {
    maxHeight: "160px",
    overflow: "hidden",
    width: "100%",
    position: "relative",
    "&.MuiTypography-body2": {
      maxWidth: 640,
    },
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "1.5rem",
      background: `linear-gradient(rgba(0, 0, 0, 0) 0%, ${theme?.palette?.primary?.contrastText} 90%)`,
      bottom: 0,
    },
    "& img:first-of-type": {
      maxHeight: 100,
      float: "right",
      marginLeft: theme?.spacing(1),
      marginBottom: theme?.spacing(1),
      "& + br": {
        display: "none",
      },
      "& + br + br": {
        display: "none",
      },
    },
  };
  const attendanceStyles = {
    ...itemStyles,
    backgroundColor: theme?.palette?.warning?.main,
    backgroundImage: `linear-gradient(147deg, ${theme?.palette?.warning?.main} 26%, ${theme?.palette?.warning?.text} 100%)`,
    border: `3px solid ${theme?.palette?.warning?.text}`,
    ...theme?.palette?.feed?.attendance, // overrides from theme
  };

  const itemProps = useMemo(() => {
    switch (item?.typeName) {
      case "Calendar":
        return {
          title: item?.title,
          description: item?.description,
          styles: calendarStyles,
          link: `/events/${itemDetails?.EventId}`,
          icon: faCalendarAlt,
          image: (
            <Box sx={{ textAlign: "center" }}>
              <FontAwesomeIcon
                icon={faCalendarDay}
                size="3x"
                color={theme?.palette?.text?.secondary}
              />
              <br />
              <Typography
                variant="caption"
                color={theme?.palette?.text?.secondary}
              >
                <strong>EVENT</strong>
              </Typography>
            </Box>
          ),
        };
      case "Goal":
        return {
          title: item?.title,
          description: item?.description,
          styles: goalStyles,
          link: item?.url,
          icon: faFlag,
          image: (
            <Box sx={{ textAlign: "center" }}>
              <FontAwesomeIcon
                icon={faHandPointRibbon}
                size="3x"
                color={theme?.palette?.secondary?.main}
              />
              <br />
              <Typography variant="caption" color="secondary">
                <strong>GOAL</strong>
              </Typography>
            </Box>
          ),
        };
      case "Badge":
        return {
          title: `Badge: ${item.title}`,
          description: item?.description,
          styles: badgeStyles,
          link: item?.url,
          icon: faShieldHalved,
          image: null,
        };
      case "News":
        return {
          title: item?.title,
          description: item?.description,
          styles: newsStyles,
          link: item?.url,
          icon: faNewspaper,
          image: null,
        };
      case "Attendance":
        return {
          title: `${studentName} ${item?.title} (${item?.description})`,
          description: null,
          styles: attendanceStyles,
          link: `/attendance/${itemDetails?.StudentID}`,
          icon: faCalendarXmark,
          image: null,
        };
      default:
        return {
          title: item?.title,
          description: item?.description,
          styles: itemStyles,
          link: item?.url,
          icon: null,
          image: null,
        };
    }
  }, [item]);

  const renderIcon = (itemTypeName, icon) => {
    switch (itemTypeName) {
      case "Calendar":
        return (
          <Box
            sx={{
              ...feedIconContainers,
              alignItems: "center",
              width: 32,
              height: 32,
              background: alpha(theme?.palette?.text?.primary ?? "#777", 0.5),
            }}
          >
            <Box
              sx={{
                ...feedIconContainers,
                alignItems: "center",
                width: 28,
                height: 28,
                background: theme?.palette?.text?.secondary,
                color: theme?.palette?.primary?.contrastText,
              }}
            >
              <FontAwesomeIcon icon={icon} style={{ marginTop: "-2px" }} />
            </Box>
          </Box>
        );
      case "Goal":
        return (
          <Box
            sx={{
              ...feedIconContainers,
              alignItems: "center",
              width: 64,
              height: 64,
              background: alpha(theme?.palette?.text?.primary ?? "#777", 0.33),
              backdropFilter: "blur(24px)",
            }}
          >
            <Box
              sx={{
                ...feedIconContainers,
                alignItems: "center",
                width: 54,
                height: 54,
                border: `4px solid ${alpha(
                  theme?.palette?.text?.primary ?? "#777",
                  0.33
                )}`,
                color: theme?.palette?.background?.default,
                background: theme?.palette?.secondary?.main,
              }}
            >
              <FontAwesomeIcon size="2x" icon={icon} />
            </Box>
          </Box>
        );
      case "Badge":
        return (
          <Box
            sx={{
              ...feedIconContainers,
              alignItems: "center",
              width: 64,
              height: 64,
              background: alpha(theme?.palette?.neutral?.main ?? "#777", 0.33),
              backdropFilter: "blur(24px)",
            }}
          >
            <Box
              sx={{
                ...feedIconContainers,
                alignItems: "center",
                width: 54,
                height: 54,
                border: `4px solid ${theme?.palette?.text?.primary}`,
                color: theme?.palette?.background?.default,
                background: theme?.palette?.tertiary?.main,
              }}
            >
              <FontAwesomeIcon size="2x" icon={icon} />
            </Box>
          </Box>
        );
      case "News":
        return (
          <Box
            sx={{
              ...feedIconContainers,
              alignItems: "center",
              width: 64,
              height: 64,
              background: alpha(theme?.palette?.primary?.text ?? "#777", 0.33),
              backdropFilter: "blur(24px)",
            }}
          >
            <Box
              sx={{
                ...feedIconContainers,
                alignItems: "center",
                width: 54,
                height: 54,
                border: `4px solid ${alpha(
                  theme?.palette?.primary?.main ?? "#777",
                  0.33
                )}`,
                color: theme?.palette?.primary?.contrastText,
                background: theme?.palette?.primary?.text,
              }}
            >
              <FontAwesomeIcon size="2x" icon={icon} />
            </Box>
          </Box>
        );
      case "Attendance":
        return (
          <Box
            sx={{
              ...feedIconContainers,
              alignItems: "center",
              width: 54,
              height: 54,
              background: alpha(theme?.palette?.text?.primary ?? "#777", 0.33),
              backdropFilter: "blur(24px)",
            }}
          >
            <Box
              sx={{
                ...feedIconContainers,
                alignItems: "center",
                width: 44,
                height: 44,
                border: `4px solid ${theme?.palette?.error?.main}`,
                color: theme?.palette?.text?.primary,
                backgroundImage: `linear-gradient(147deg, ${theme?.palette?.error?.main} 0%, ${theme?.palette?.warning?.main} 100%)`,
              }}
            >
              <FontAwesomeIcon
                style={{ height: 20, marginTop: "-2px" }}
                icon={icon}
              />
            </Box>
          </Box>
        );
    }
  };

  return (
    <Card sx={[itemContainerStyles, itemProps?.styles]}>
      <CardActionArea
        sx={{ p: 2, pl: 5 }}
        onClick={() => {
          if (itemProps?.link?.toLowerCase().indexOf("http") === 0) {
            window.location.assign(itemProps?.link);
          } else navigate(itemProps?.link ?? "#");
        }}
      >
        {itemProps.icon && (
          <Box sx={feedIconWrap}>
            {renderIcon(item?.typeName, itemProps.icon)}
          </Box>
        )}
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <Grid
            item
            container
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography variant="h5" component="h3">
              {dayjs(item?.date).format("M/D")}:{" "}
              {truncateText(itemProps?.title, titleLength)}
            </Typography>
            {!!itemProps.description &&
              (item?.typeName === "Badge" || item?.typeName === "News" ? (
                <Typography
                  variant="body2"
                  sx={
                    item?.typeName === "Badge"
                      ? badgeDescriptionStyles
                      : newsDescriptionStyles
                  }
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(itemProps?.description),
                  }} // sanitizer makes it no longer dangerous (should protect from even the small chance of XSS attacks)
                />
              ) : (
                <Typography variant="body2">
                  {truncateText(itemProps?.description, 115)}
                </Typography>
              ))}
            <Typography variant="caption">
              <em>{relativeDay(item?.date)}</em>
            </Typography>
          </Grid>
          <Grid item>{itemProps.image && itemProps.image}</Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
