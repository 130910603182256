import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
  faCirclePlus,
  faPencil,
  faBookReader,
} from "@fortawesome/pro-solid-svg-icons";
import YouTubeVideo from "./YouTubeVideo";

export default function GoalsAbout() {
  const theme = useTheme();
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const actionsStyles = {
    borderTop: `1px solid ${
      theme.palette.neutral?.main
        ? alpha(theme.palette.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme.palette.neutral?.main
      ? alpha(theme.palette.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme.palette.neutral?.light,
    },
  };
  const howToStyles = {
    "& p": {
      mb: "1em",
    },
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Goals
      </Button>
      <Dialog
        open={aboutDialogIsOpen}
        onClose={handleAboutClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> About Goals
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">
                What do you really want? Dream big!
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography>
                Research shows you are much more likely to acheive those dreams
                if you set goals. Learn how to set a SMART goal in
                this&nbsp;video:
              </Typography>

              <YouTubeVideo id="U4IU-y9-J8Q" />
            </Grid>
            <Grid item xs={12} md={5} sx={howToStyles}>
              <Typography variant="h5" color="primary">
                How to use Compass Goals
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faCirclePlus} />
                <strong> Add new goals</strong>
                <br />
                Click the NEW button to start creating a goal. Notice there's
                help content for each&nbsp;question.
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faPencil} />
                <strong> Edit goals</strong>
                <br />
                As you go after your goals, come back to update your progress or
                refine&nbsp;them.
              </Typography>
              <Typography>
                <FontAwesomeIcon icon={faBookReader} />
                <strong> Review completed goals</strong>
                <br />
                Once you meet your goal, you can still get to it under
                "Completed Goals." When staring down a new audacious goal,
                sometimes it's encouraging to go back look at what you
                accomplished&nbsp;before.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
