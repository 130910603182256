import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
// import { useWithdrawParticipant } from "../../services/hooks/participantsHook";
import { useUpsertWaitlist } from "../../services/hooks/waitlistHook";
import { useUser } from "../../services/contexts/userContext";
import dayjs from "dayjs";

export default function WaitlistRemoveDialog(props) {
  const { user } = useUser();
  const { details, open, onClose, inactiveStatusId } = props;
  const { activeStudent } = useActiveStudent();

  const waitlistObject = {
    waitlistId: details?.waitlist?.waitlistId,
    programId: details?.programId,
    studentId: activeStudent?.studentId,
    waitlistStatusId: inactiveStatusId, //??
    note: null,
    TransportationId: null,
    Cost: null,
    modifiedBy: user?.email,
    modifiedDate: dayjs(),
    orderDate: dayjs(),
  };
  const removeFromWaitlist = useUpsertWaitlist(waitlistObject, onClose);

  const handleRemoveFromWaitlist = async () => {
    removeFromWaitlist?.mutateAsync();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h5">
          Are you sure you want remove {activeStudent?.firstName} from the
          waitlist for {details?.title ?? details?.activityName}?
        </Typography>
      </DialogTitle>

      <DialogContent>
        If you change your mind and the waitlist is still open, you can re-add
        your student. However, {activeStudent?.firstName} would then be at the
        end of the list.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={removeFromWaitlist?.isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={removeFromWaitlist?.isLoading}
          onClick={handleRemoveFromWaitlist}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
