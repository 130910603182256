import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

  export function useGetCurrentDsaRegisteredPartners(studentId) {
    const snackbar = useSnackbar();
    const token = GetAccessToken();
    const url = `Dsa/GetCurrentDsaRegisteredPartners/${studentId}`;
  
    const query = useQuery(
      url,
      async () => {
        return await AppsGet(token, url);
      },
      {
        enabled: !!studentId,
        onError: (error) => {
          snackbar.enqueueSnackbar(`Error getting eligible programs. ${error}`, {
            variant: "error",
          });
        },
      }
    );
    return query;
  }

  export function useGetDsaHistory(studentId) {
    const snackbar = useSnackbar();
    const token = GetAccessToken();
    const url = `Dsa/GetDsaHistory/${studentId}`;
  
    const query = useQuery(
      url,
      async () => {
        return await AppsGet(token, url);
      },
      {
        enabled: !!studentId,
        onError: (error) => {
          snackbar.enqueueSnackbar(`Error getting eligible programs. ${error}`, {
            variant: "error",
          });
        },
      }
    );
    return query;
  }

  export function useGetEligibleDsaPartners(studentId) {
    const snackbar = useSnackbar();
    const token = GetAccessToken();
    const url = `Dsa/GetEligibleDsaPartners/${studentId}`;
  
    const query = useQuery(
      url,
      async () => {
        return await AppsGet(token, url);
      },
      {
        enabled: !!studentId,
        onError: (error) => {
          snackbar.enqueueSnackbar(`Error getting eligible partners. ${error}`, {
            variant: "error",
          });
        },
      }
    );
    return query;
  }

  export function usePostDsaParticipant(participantBody, onSuccessFunction) {
    const queryClient = useQueryClient();
  
    const snackbar = useSnackbar();
    const token = GetAccessToken();
    const mutation = useMutation(
      async () => {
        return await AppsPost(
          token,
          "Dsa/UpsertDsaRegistrant",
          participantBody
        );
      },
      {
        onSuccess: async (data) => {
          snackbar.enqueueSnackbar(`Successfully registered`, {
            variant: "success",
          });
          
          await queryClient.invalidateQueries((key) => key.startsWith("Dsa"));
  
          onSuccessFunction();
        },
        onError: (error) => {
          snackbar.enqueueSnackbar(
            `There was an error registering for this program. ${error}`,
            {
              variant: "error",
            }
          );
          return null;
        },
      }
    );
    return mutation;
  }