import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetCompassUser(enabled) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "compassSignIn/GetCompassUser";

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      retry: 100,
      enabled: enabled,
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting user details. ${error}`, {
          variant: "error",
        });
      },
    }
  );

  return query;
}
