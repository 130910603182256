import { useMediaQuery } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";

export default function useCommonStyles() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const contentBox = {
    mx: "auto",
    maxWidth: 1200,
    px: smallScreen ? theme.spacing(1) : theme.spacing(3),
    py: smallScreen ? theme.spacing(10) : theme.spacing(3),
    width: "100%",
    "& .MuiTypography-subtitle1": {
      fontSize: largeScreen ? "1.25rem" : "1.125rem",
      maxWidth: 630,
    },
    "& .MuiTypography-subtitle2": {
      fontSize: largeScreen ? "1rem" : "0.875rem",
      maxWidth: 630,
    },
    "& .MuiTypography-body1": {
      fontSize: largeScreen ? "1.25rem" : "1rem",
      maxWidth: 630,
    },
    "& .MuiTypography-body2": {
      fontSize: largeScreen ? "1rem" : "0.875rem",
      maxWidth: 540,
    },
  };

  const textOverBackground = {
    textShadow: ` 1px  1px 0 ${theme?.palette?.background?.default},
    -1px  1px 0 ${theme?.palette?.background?.default}, 
     1px -1px 0 ${theme?.palette?.background?.default}, 
    -1px -1px 0 ${theme?.palette?.background?.default},
     0 0 0.5rem ${theme?.palette?.background?.default}`,
  }; // adds a stroke and shadow to text to ensure contrast of main text colors no matter the background image

  const iconOverBackground = (strokeWidth) => {
    return {
      "& svg path": {
        stroke: alpha(theme?.palette?.background?.default ?? "#777", 0.75),
        strokeWidth: strokeWidth ? strokeWidth : "80px",
        paintOrder: "stroke",
      }, // adds a stroke to the icon to help ensure contrast
      // use this in an MUI component (like Box) wrapping a FontAwesome icon
      // add a shadow if needed to the FontAwesome icon itself (shapes and sizes may need to vary)
    };
  };

  const paperPadding = {
    p: theme.spacing(2),
  };

  const videoStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };
  const videoWrapperStyles = {
    position: "relative",
    pb: "56.25%" /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */,
    height: 0,
    overflow: "hidden",
  };

  const slideStyles = {
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      height: 530,
      width: 309, // 309 is 375 (the smallest supported width) minus 64 for dialog margins
    },
    height: 600,
    width: 538, // 538 is 600 (sm width) minus 64 for dialog margins
    "& h1": {
      fontSize: "3em",
      marginRight: theme.spacing(4),
      marginTop: "0", // removes margin in Forest theme
      [theme.breakpoints.down("sm")]: {
        fontSize: "2em",
      },
    },
    "& h2": {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.8em",
      },
    },
    "& p": {
      fontSize: "1.5em",
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2em",
      },
    },
  };

  return {
    contentBox,
    paperPadding,
    videoStyles,
    videoWrapperStyles,
    textOverBackground,
    iconOverBackground,
    slideStyles,
  };
}
