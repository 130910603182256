import { useQuery, useInfiniteQuery } from "react-query";

import { useSnackbar } from "notistack";

import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetUpcomingParentFeed(numItems) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `feed/GetUpcomingParentFeed?items=${numItems}`;
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting feed items. ${error}`, {
          variant: "error",
        });
      },
    }
  );

  return query;
}

export function useGetUpcomingStudentFeed(numItems) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `feed/GetUpcomingStudentFeed?items=${numItems}`;
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting feed items. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetParentFeed(numItems) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const fetchMore = async (pageParam) => {
    return await AppsPost(
      token,
      `feed/GetParentFeed?numItems=${numItems}`,
      pageParam
    );
  };

  const feedItemsQuery = useInfiniteQuery(
    "feed/GetParentFeed",
    ({ pageParam = {} }) => fetchMore(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.data?.compassFeedItems?.length >= numItems
          ? lastPage?.data?.feedCounts
          : undefined;
      },
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting feed items. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return feedItemsQuery;
}

export function useGetStudentFeed(numItems) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const fetchMore = async (pageParam) => {
    return await AppsPost(
      token,
      `feed/GetStudentFeed?numItems=${numItems}`,
      pageParam
    );
  };

  const feedItemsQuery = useInfiniteQuery(
    "feed/GetStudentFeed",
    ({ pageParam = {} }) => fetchMore(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage?.data?.compassFeedItems?.length >= numItems
          ? lastPage?.data?.feedCounts
          : undefined;
      },
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting feed items. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return feedItemsQuery;
}
