import React from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Fab,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate, Outlet } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import GoalsIntro from "../common/GoalsIntro";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Goals() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const ChangeTab = (event, newValue) => {
    navigate(`/goals/${newValue}`);
  };
  const GetSelectedTab = () => {
    return location.pathname.replace("/goals/", "").replace("/goals", "");
  };

  // Styles
  const newGoalButtonStyles = {
    position: "fixed",
    right: theme.spacing(3),
    top: smallScreen ? theme.spacing(11) : theme.spacing(3),
    zIndex: theme?.zIndex?.appBar,
    "& svg": {
      fontSize: "2rem",
      marginRight: theme.spacing(1),
    },
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Box>
        <Fab
          sx={newGoalButtonStyles}
          color="secondary"
          variant="extended"
          onClick={() => {
            navigate("/goals/new");
          }}
        >
          <FontAwesomeIcon icon={faCirclePlus} />
          NEW&nbsp;GOAL
        </Fab>
        <Typography variant="h1" component="h2">
          Goals
        </Typography>

        <br />

        <GoalsIntro />

        <br />

        <Tabs
          onChange={ChangeTab}
          value={GetSelectedTab()}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab value="" label="Active" aria-label="Active" />
          <Tab value="completed" label="Completed" aria-label="completed" />
          {/* Hidden for now */}
          {/* <Tab value="timeline" label="Timeline" /> */}
        </Tabs>
        <Divider />
        <br />
        <Outlet />
      </Box>
    </>
  );
}
