import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Link,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
  faThumbsUp,
  faHeart,
  faFlag,
} from "@fortawesome/pro-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import YouTubeVideo from "./YouTubeVideo";
import { blob } from "../../services/utilities";

export default function InterestsAbout() {
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const actionsStyles = {
    borderTop: `1px solid ${
      theme.palette.neutral?.main
        ? alpha(theme.palette.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
  };
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme.palette.neutral?.main
      ? alpha(theme.palette.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme.palette.neutral?.light,
    },
  };

  const dialogContentStyles = {
    p: theme.spacing(2),
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Interests
      </Button>
      <br />
      <Button
        sx={{ textAlign: "left", maxWidth: smallScreen ? "350px" : "210px" }}
        size="small"
        onClick={() => {
          handleAboutClick();
        }}
        startIcon={<FontAwesomeIcon icon={faYoutube} />}
      >
        Successful People with Unique&nbsp;Hobbies
      </Button>
      <Dialog
        open={aboutDialogIsOpen}
        onClose={handleAboutClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> Using Compass Interests
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={dialogContentStyles}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography variant="h4" color="primary">
                What do you like?
              </Typography>
              <Typography>
                There are a lot of interests here - you probably won't like most
                of them — that's normal. Rate interests to show what you love to
                do.
              </Typography>
              <br />
              <Typography variant="body2">
                To explore how your interests may connect to careers, check out{" "}
                <strong>
                  <Link
                    href="https://www.careerbridge.wa.gov/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    careerbridge.wa.gov
                  </Link>
                </strong>
              </Typography>
              <Divider sx={{ my: 3 }} />
              <Typography>Just for fun, check out this video:</Typography>
              <Box sx={commonStyles.videoWrapperStyles}>
                <YouTubeVideo id="t8hvCz3lHjA" />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" color="primary">
                How to use Compass Interests
              </Typography>
              <Typography>
                <strong>Love it or hate it?</strong>
                <br />
                Pick a category and you'll get to rate one item at a time. It's
                easy and kinda&nbsp;fun.
                <br />
                <img
                  src={`${blob}Badges/In-App_CompletedAllInterestRatings.svg`}
                  alt="Interest Ratings Badge"
                  style={{ height: 100, float: "right" }}
                />
                <br />
                Rate everything in a category and you'll get a badge!{" "}
              </Typography>
              <br />
              <Typography>
                <strong>My ratings</strong>
                <br />
                On the main Interests page, by default, you'll see the interests
                you like&#8239;&nbsp;
                <FontAwesomeIcon icon={faThumbsUp} /> and love&#8239;
                <FontAwesomeIcon icon={faHeart} />. You can see all your ratings
                by clearing out the filters or use the fiters to explore a
                specific&nbsp;group.
              </Typography>
              <br />
              <Typography>
                <FontAwesomeIcon icon={faFlag} />
                &nbsp;<strong>Make it a goal</strong>
                <br />
                Maybe you want to try skateboarding for the first time or
                research a dream trip to Italy or find a tutor to help with
                Algebra. Revisit anything you've rated and you'll see a button
                to make it a goal.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
