import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { StudentPicker } from "../../services/contexts/activeStudentContext";
import FormSexEdOptOut from "../common/FormSexEdOptOut";
import { useUser } from "../../services/contexts/userContext";
import FormLinks from "../common/FormsLinks";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Forms() {
  const { user } = useUser();

  const theme = useTheme();

  const dividerStyles = {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">Forms</Typography>
      {user?.role === "guardian" ? (
        <Typography variant="lede">
          Find links to external TPS forms, and use the one form currently built
          right into&nbsp;Compass.
        </Typography>
      ) : (
        <Typography variant="lede">
          You will need your parent/guardian signature to submit some of
          these&nbsp;forms.
        </Typography>
      )}
      <StudentPicker />

      <Grid container spacing={4}>
        {user?.role === "guardian" && (
          <Grid item xs={12}>
            <FormSexEdOptOut />
          </Grid>
        )}
        {/* <Grid item xs={12}>
          <Paper sx={wrapperStyles}>
            <Typography variant="h5" component="h2">
              COVID Questionnaire
            </Typography>
            <Typography>
              Please complete a questionnaire if{" "}
              {user?.role === "student" ? "you have " : "your child has "}
              developed symptoms or tested positive for&nbsp;COVID&#8209;19.
            </Typography>
            <br />
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/covid");
                }}
                startIcon={<FontAwesomeIcon icon={faVirus} />}
              >
                Start COVID Questionnaire
              </Button>
            </Box>
          </Paper>
        </Grid> */}
      </Grid>
      <Divider sx={dividerStyles} />

      <FormLinks />
    </>
  );
}
