import { React } from "react";
import { useNavigate } from "react-router";
import { useGetSurveys } from "../../services/hooks/surveyHook";
import {
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faCircleArrowRight,
  faLinkSlash,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import TrailSlideUpFadeIn from "../animations/TrailSlideUpFadeIn";
import { showFeatures } from "./../../services/utilities";
import { widowUnmaker } from "../../services/utilities";

export default function SurveyList() {
  const theme = useTheme();
  const navigate = useNavigate();
  const surveysQuery = useGetSurveys();

  //Styles
  const surveyStyles = {
    p: 2,
  };
  const iconStyles = {
    opacity: 0.85,
  };

  const surveys = surveysQuery?.data?.data;

  return !!showFeatures.survey ? (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2" gutterBottom>
        <FontAwesomeIcon icon={faClipboardList} /> Surveys
      </Typography>
      {!!surveysQuery.isSuccess && surveys?.length !== 0 && (
        <>
          <Typography variant="lede">
            Here's a list of your current surveys:
          </Typography>
          <List>
            <TrailSlideUpFadeIn>
              {surveys?.map((s) => {
                const d = JSON.parse(s.description);
                return (
                  <ListItem
                    key={s.instanceId}
                    sx={surveyStyles}
                    button
                    divider
                    component={Link}
                    onClick={() => {
                      navigate(`/surveys/${s.instanceId}`);
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="h5" component="h3" color="primary">
                          {s.name}&nbsp;
                          <FontAwesomeIcon icon={faCircleArrowRight} />
                        </Typography>
                      }
                      secondary={
                        <span>
                          {!!d.teaser && (
                            <em>
                              {widowUnmaker(d.teaser)}
                              <br />
                            </em>
                          )}
                          Closes {dayjs(s.endDate).format("M/D/YYYY")}
                        </span>
                      }
                    />
                  </ListItem>
                );
              })}
            </TrailSlideUpFadeIn>
          </List>
        </>
      )}
      {surveys?.length === 0 && (
        <Typography>
          <span style={{ fontSize: "2rem" }}>
            <strong>Sorry...</strong>
          </span>
          <br />
          It looks like you've got no current&nbsp;surveys.
        </Typography>
      )}
      {!!surveysQuery.isError && (
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <FontAwesomeIcon
              icon={faLinkSlash}
              size="8x"
              style={{ ...iconStyles, color: theme.palette.error.main }}
            />
          </Grid>
          <Grid item>
            <Typography color="error">
              <span style={{ fontSize: "2rem" }}>
                <strong>Sorry...</strong>
              </span>
            </Typography>
            <Typography>
              Seems like there was an error getting your surveys. Please try
              again&nbsp;later.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  ) : (
    navigate("/")
  );
}
