import { useState, React } from "react";
import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MoodsStats from "../common/MoodsStats";
import BadgeStats from "../common/BadgeStats";
import InterestsStats from "../common/InterestsStats";
import InterestsStatsRadar from "../common/InterestsStatsRadar";
import GoalsStats from "../common/GoalsStats";

export default function MyJourney(props) {
  const theme = useTheme();
  const [viewMoodJourney, setViewMoodJourney] = useState(false);
  const [viewBadgeJourney, setViewBadgeJourney] = useState(false);
  const [viewInterestJourney, setViewInterestJourney] = useState(false);
  const [viewGoalJourney, setViewGoalJourney] = useState(false);

  const handleViewJourneyClick = () => {
    setViewMoodJourney(true);
    if (viewMoodJourney === true) {
      setViewMoodJourney(false);
    }
  };
  const handleViewInterestClick = () => {
    setViewInterestJourney(true);
    if (viewInterestJourney === true) {
      setViewInterestJourney(false);
    }
  };

  const handleViewBadgeJourneyClick = () => {
    setViewBadgeJourney(true);
    if (viewBadgeJourney === true) {
      setViewBadgeJourney(false);
    }
  };

  const handleViewGoalJourneyClick = () => {
    setViewGoalJourney(true);
    if (viewGoalJourney === true) {
      setViewGoalJourney(false);
    }
  };

  //Styles
  const commonPaddingStyles = {
    padding: theme.spacing(2),
    my: theme.spacing(4),
  };

  return (
    <Box>
      <Typography variant="h1" component="h2" gutterBottom>
        My Journey
      </Typography>
      <Typography variant="body1">
        Welcome to Your Journey, a place to view and take note of your
        accomplishments throughout your adventures as a TPS student. Below, you
        will find information regarding
        {/*your Grade Point Average, your
        attendance record, */}{" "}
        the moods you have recorded, the interests you have rated and the badges
        you have earned! Feel free to click around and view the various
        categories!
      </Typography>

      {/* <Paper sx={commonPaddingStyles}>
        <Grid container spacing={12}>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Career GPA</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              This chart reflects your Grade Point Average during your student
              career
            </Typography>

            <CoursesGpaGraph />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">Career Attendance</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              This chart reflects your Attendance Average during your student
              career{" "}
            </Typography>
            <AttendanceStats />
          </Grid>
        </Grid>
      </Paper> */}
      <Paper sx={commonPaddingStyles}>
        <Grid container spacing={2}>
          <Grid item>
            {!viewMoodJourney ? (
              <Button
                variant="contained"
                onClick={() => handleViewJourneyClick()}
              >
                View Moods
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleViewJourneyClick()}
              >
                Hide Moods
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {!viewMoodJourney && (
              <Typography variant="body2">
                This is a snapshot of your historical moods throughout this
                current school year.
                <br></br>
              </Typography>
            )}
            <br></br>
            {viewMoodJourney && <MoodsStats />}
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={commonPaddingStyles}>
        <Grid container spacing={2}>
          <Grid item>
            {!viewBadgeJourney ? (
              <Button
                variant="contained"
                onClick={() => handleViewBadgeJourneyClick()}
              >
                View Badges
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleViewBadgeJourneyClick()}
              >
                Hide Badges
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {!viewBadgeJourney && (
              <Typography variant="body2">
                This is a snapshot of the badges you have earned during your
                student career.
                <br></br>
              </Typography>
            )}
            <br></br>

            {viewBadgeJourney && <BadgeStats />}
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={commonPaddingStyles}>
        <Grid container spacing={2}>
          <Grid item>
            {!viewInterestJourney ? (
              <Button
                variant="contained"
                onClick={() => handleViewInterestClick()}
              >
                View Interests
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleViewInterestClick()}
              >
                Hide Interests
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            {!viewInterestJourney && (
              <Typography variant="body2">
                Here you will find some of the areas of interest you have scored
                <br></br>
              </Typography>
            )}
            <br></br>
            {viewInterestJourney && <InterestsStats />}
            {viewInterestJourney && <InterestsStatsRadar />}
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={commonPaddingStyles}>
        <Grid container spacing={2}>
          <Grid item>
            {!viewGoalJourney ? (
              <Button
                variant="contained"
                onClick={() => handleViewGoalJourneyClick()}
              >
                View Goals
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleViewGoalJourneyClick()}
              >
                Hide Goals
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start">
              {!viewGoalJourney && (
                <Typography variant="body2">
                  This is an overview of all of the goals you have completed
                  throughout your student career.
                  <br></br>
                </Typography>
              )}

              <br></br>

              {viewGoalJourney && <GoalsStats />}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
