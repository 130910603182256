import { useTheme, darken } from "@mui/material/styles";

export default function BadgesEarned(props) {
  const theme = useTheme();
  return (
    <svg
      version="1.1"
      id="svg84"
      x="0px"
      y="0px"
      width="400px"
      height="400px"
      viewBox="0 0 400 400"
      enableBackground="new 0 0 400 400"
    >
      <g id="Ribbon_sides">
        <polygon
          id="polygon2"
          fill={theme?.palette?.badges?.shadow}
          opacity="0.1"
          enableBackground="new    "
          points="395.96,160 336,160 336,276 395.96,276 
		373.92,218 	"
        />
        <polygon
          id="polygon4"
          fill={theme?.palette?.badges?.shadow}
          opacity="0.1"
          enableBackground="new    "
          points="4.04,160 4.04,160.4 25.92,218 
		4.04,275.6 4.04,276 64,276 64,160 	"
        />
        <polygon
          id="polygon6"
          fill={theme?.palette?.badges?.ribbon}
          points="395.96,158 336,158 336,274 395.96,274 373.92,216 	"
        />
        <polygon
          id="polygon8"
          fill={theme?.palette?.badges?.ribbonEdge}
          points="392.92,166 335.84,166 335.84,170 391.4,170 	"
        />
        <polygon
          id="polygon10"
          fill={theme?.palette?.badges?.ribbonEdge}
          points="391.4,262 335.84,262 335.84,266 392.92,266 	"
        />
        <polygon
          id="polygon12"
          fill={
            theme?.palette?.badges?.ribbon
              ? darken(theme?.palette?.badges?.ribbon ?? "#777", 0.25)
              : "grey"
          }
          points="336,274 368.04,258 336,258 	"
        />
        <polygon
          id="polygon14"
          fill={theme?.palette?.badges?.ribbon}
          points="4.04,158 4.04,158.4 25.92,216 4.04,273.6 4.04,274 64,274 64,158 	"
        />
        <polygon
          id="polygon16"
          fill={theme?.palette?.badges?.ribbonEdge}
          points="8.48,262 6.96,266 63.84,266 63.84,262 	"
        />
        <polygon
          id="polygon18"
          fill={theme?.palette?.badges?.ribbonEdge}
          points="6.96,166 8.48,170 63.84,170 63.84,166 	"
        />
        <polygon
          id="polygon20"
          fill={
            theme?.palette?.badges?.ribbon
              ? darken(theme?.palette?.badges?.ribbon ?? "#777", 0.25)
              : "grey"
          }
          points="64,274 31.96,258 64,258 	"
        />
      </g>
      <g id="frill">
        <path
          id="path23"
          fill={theme?.palette?.badges?.shadow}
          fillOpacity="0.1"
          d="M346.64,260.96c8.36-9.399,11.92-22.601,9.28-34.92
		c-2.76-12.88-11.88-20.76-4.36-34.28c11.48-20.76,2.881-46.68-18.479-57c-17.12-17.2-5.4-45.48-34.84-55.36
		c-12-3.8-24.16,1-31.36-12c-6.84-12.36-19.92-20.72-34.08-21.32c-12.96-0.56-24.72,11.6-36.88,8.48
		c-11.28-2.84-19.08-9.48-31.52-8.2c-24.96,2.6-29.64,28.4-53.88,31.12C84.4,80.44,77.12,100.4,74,122.88
		c-7.2,23.52-37,26-30.08,57.4c4.08,18.52,8.16,21.239,1.44,40.44c-4,11.439-2.72,24,3.52,34.319c5.56,9.2,15.52,11.96,21.24,20.2
		c7.84,11.279,3.44,26,12.6,37.56c10.04,13.24,23.68,15,38.88,16.24c9.48,0.8,12.36,12.64,18.4,18.84c11,11.28,28,14.8,42.8,9.521
		c6.92-2.44,13.28-9.521,20.761-6.521c10.56,4.24,18.76,9.96,30.799,8.92c25-2.159,23.281-22.68,40.521-31.2
		c7.08-3.479,18.36,0.32,26.72-3.359c10.28-4.561,18.48-13.641,21.96-24.36c3.921-12.16,0-23.319,12.48-31.36
		C340,267.28,343.64,264.4,346.64,260.96C349.4,257.84,346.44,261.24,346.64,260.96z"
        />
        <path
          id="path25"
          fill={theme?.palette?.badges?.frill}
          d="M346.64,257.96c8.36-9.399,11.92-22.601,9.28-34.92c-2.76-12.88-11.88-20.76-4.36-34.28
		c11.48-20.76,2.881-46.68-18.479-57c-17.12-17.2-5.4-45.48-34.84-55.36c-12-3.8-24.16,1-31.36-12C260,52,246.96,43.64,232.76,43.04
		c-12.96-0.56-24.72,11.6-36.88,8.48c-11.28-2.84-19.08-9.48-31.52-8.2c-24.96,2.6-29.64,28.4-53.88,31.12
		c-26.12,2.96-33.4,22.92-36.52,45.4c-7.2,23.52-37,26-30.08,57.4c4.08,18.52,8.16,21.24,1.44,40.439c-4,11.44-2.72,24,3.52,34.32
		c5.56,9.2,15.52,11.96,21.24,20.2c7.84,11.28,3.44,26,12.6,37.56c10.04,13.24,23.68,15,38.88,16.24
		c9.48,0.8,12.36,12.641,18.4,18.84c11,11.28,28,14.801,42.8,9.52c6.92-2.439,13.28-9.52,20.76-6.52
		c10.561,4.24,18.76,9.96,30.801,8.92c25-2.16,23.279-22.68,40.52-31.199c7.08-3.48,18.36,0.319,26.721-3.36
		c10.279-4.56,18.479-13.64,21.959-24.36c3.921-12.16,0-23.32,12.48-31.359C340,264.28,343.64,261.359,346.64,257.96
		C349.4,254.84,346.44,258.2,346.64,257.96z"
        />
      </g>
      <g id="white_circle">
        <circle
          id="circle28"
          fill={theme?.palette?.badges?.decoration}
          cx="200"
          cy="200"
          r="135"
        />
      </g>
      <g id="blue_circle">
        <circle
          id="circle31"
          fill={theme?.palette?.badges?.circle}
          cx="200"
          cy="200"
          r="131"
        />
      </g>
      <g id="text_x2F_star">
        <polygon
          id="polygon56"
          fill={theme?.palette?.badges?.decoration}
          points="200,267.6 202.96,276.76 212.6,276.76 204.8,282.439 207.8,291.6 200,285.92 
		192.2,291.6 195.2,282.439 187.4,276.76 197.04,276.76 	"
        />
        <polygon
          id="polygon58"
          fill={theme?.palette?.badges?.decoration}
          points="230.279,264.96 233.561,268.48 237.92,266.439 235.6,270.68 238.88,274.2 
		234.12,273.28 231.8,277.48 231.2,272.721 226.48,271.8 230.84,269.76 	"
        />
        <polygon
          id="polygon60"
          fill={theme?.palette?.badges?.decoration}
          points="169.72,264.96 169.16,269.76 173.52,271.8 168.8,272.721 168.2,277.48 
		165.88,273.28 161.12,274.2 164.4,270.68 162.08,266.439 166.44,268.48 	"
        />
      </g>
      <g id="crown_x2F_laurel">
        <g id="g67">
          <rect
            id="rect63"
            x="177.76"
            y="124.88"
            fill={theme?.palette?.badges?.decoration}
            width="43.92"
            height="4.8"
          />
          <path
            id="path65"
            fill={theme?.palette?.badges?.decoration}
            d="M219.2,96.64c0.72,0.88,1.079,1.76,1.079,3.04c0,2.68-2.119,4.8-4.799,4.8
			c-2.681,0-4.801-2.12-4.801-4.8c0-1.96,1.24-3.56,2.84-4.44l-13.68-16l-13.68,16c1.6,0.72,2.84,2.48,2.84,4.44
			c0,2.68-2.12,4.8-4.8,4.8c-2.68,0-4.8-2.12-4.8-4.8c0-1.08,0.36-2.12,1.08-3.04l-11.36-5.68l8.88,29.16h44.08l8.88-29.16
			L219.2,96.64z"
          />
        </g>
        <path
          id="path69"
          fill={theme?.palette?.badges?.decoration}
          d="M288.92,135.8l-0.359-0.88c-1.201-2.8-2.84-5.28-4.521-7.4l-0.2-0.28l0.2-0.28
		c0.96-1.04,1.36-2.2,1.36-3.52c0.039-4.04-4.641-7.88-5.961-9.04c-1.08,2.76-1.279,5.36-0.719,7.48l0.079,0.48h-0.68l-0.08-0.08
		c-1.04-0.6-2.08-1.16-3.24-1.64l-0.28-0.08l0.08-0.28c0.2-0.48,0.28-0.96,0.32-1.44c0.04-3.72-5.199-6.08-6.72-6.76
		c-0.12,2.68,0.319,4.8,1.36,6.32l0.479,0.76l-0.88-0.12c-1.439-0.2-2.881-0.4-4.32-0.44h-0.279l-0.08-0.28
		c-0.641-4.12-6.681-3.8-8.4-3.72c0.48,1.36,2.04,5.28,6,5.52h0.96l-0.681,0.56c-1.719,1.52-3,3.6-3.479,4.56
		c7.08,1.2,9.32-2.8,9.92-4.6l0.08-0.28h0.28c0.279,0.08,0.561,0.12,0.84,0.2c1.641,0.4,3.24,0.88,4.68,1.56l1.041,0.48l-1.16,0.28
		c-2.48,0.56-4.801,1.96-5.761,2.64c5.88,4.44,9.841,1.92,11.28,0.6l0.279-0.2l0.281,0.2c1.039,0.96,2,1.92,2.84,3.08l0.56,0.76
		l-0.96-0.2c-2.4-0.52-4.96-0.16-6.04,0.04c3.2,6.08,7.399,6,9.64,5.36l0.28-0.08l0.08,0.28c0.28,0.68,0.64,1.44,0.92,2.2l0.84,2.12
		l0.28,0.96c0.64,2.12,1.12,4.32,1.48,6.72L292,147.2c-0.561-3.36-1.359-6.52-2.32-9.32"
        />
        <path
          id="path71"
          fill={theme?.palette?.badges?.decoration}
          d="M110.24,137.84c-0.92,2.8-1.76,5.96-2.32,9.32l1.44,0.16c0.36-2.4,0.84-4.6,1.48-6.72
		l0.28-0.96l0.84-2.12c0.28-0.76,0.64-1.52,0.92-2.2l0.08-0.28l0.28,0.08c2.2,0.64,6.44,0.72,9.64-5.36
		c-1.04-0.2-3.64-0.56-6.04-0.04l-0.96,0.2l0.56-0.76c0.84-1.16,1.8-2.12,2.84-3.08l0.28-0.2l0.28,0.2c1.44,1.32,5.4,3.88,11.28-0.6
		c-0.96-0.68-3.28-2.08-5.76-2.64l-1.16-0.28l1.04-0.48c1.44-0.68,3.04-1.16,4.68-1.56c0.28-0.08,0.56-0.12,0.84-0.2h0.28l0.08,0.28
		c0.6,1.8,2.84,5.8,9.92,4.6c-0.48-0.96-1.76-3.04-3.48-4.56l-0.68-0.56h0.96c3.92-0.24,5.52-4.16,6-5.52
		c-1.72-0.08-7.76-0.4-8.4,3.72l-0.08,0.28h-0.28c-1.44,0-2.88,0.2-4.32,0.44l-0.88,0.12l0.48-0.76c1.04-1.56,1.48-3.64,1.36-6.32
		c-1.52,0.68-6.76,3.04-6.72,6.76c0,0.48,0.12,0.96,0.32,1.44l0.08,0.28l-0.28,0.08c-1.16,0.48-2.2,1.08-3.24,1.64l-0.08,0.08h-0.68
		l0.08-0.48c0.56-2.12,0.36-4.68-0.72-7.48c-1.32,1.16-6,5.04-5.96,9.04c0,1.36,0.4,2.48,1.36,3.52l0.2,0.28l-0.2,0.28
		c-1.72,2.12-3.32,4.64-4.52,7.4l-0.36,0.88"
        />
      </g>
      <g id="Ribbon_front">
        <polygon
          id="polygon74"
          opacity="0.5"
          fill={theme?.palette?.badges?.shadow}
          enableBackground="new    "
          points="32,146 32,258.04 39.96,262 360.04,262 
		368,258.04 368,146 	"
        />
        <rect
          id="rect76"
          x="32"
          y="142"
          fill={theme?.palette?.badges?.ribbon}
          width="336"
          height="116"
        />
        <rect
          id="rect78"
          x="31.96"
          y="150"
          fill={theme?.palette?.badges?.ribbonEdge}
          width="336.12"
          height="4"
        />
        <rect
          id="rect80"
          x="31.96"
          y="246"
          fill={theme?.palette?.badges?.ribbonEdge}
          width="336.12"
          height="4"
        />
      </g>
      <g id="guides"></g>
      <path
        id="SVGID_x5F_1_x5F_"
        fill="none"
        d="M318.96,200c0,62.4-53.239,113-118.96,113c-65.72,0-118.96-50.6-118.96-113
	c0-62.4,53.28-112.96,118.96-112.96C265.68,87.04,318.96,137.6,318.96,200z"
      />
      <text
        transform="matrix(1 0 0 1 4.882813e-004 40)"
        fontFamily="'ArialMT'"
        fontSize="4.8"
      >
        {" "}
      </text>
      <g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M131.28,286.04l3.36,2.319c1.56,1.08,2.52,2.08,2.88,3s0.24,1.881-0.44,2.881
			c-0.44,0.64-1,1.08-1.64,1.279c-0.64,0.201-1.32,0.16-2.04-0.199l-0.04,0.08c0.68,0.68,1.08,1.399,1.12,2.08
			c0.08,0.68-0.16,1.439-0.68,2.16c-0.68,1-1.6,1.56-2.72,1.639c-1.12,0.08-2.28-0.319-3.56-1.159l-4.08-2.8L131.28,286.04z
			 M128.88,293.439L126.4,297l1.96,1.359c0.76,0.521,1.44,0.801,2.04,0.761s1.12-0.36,1.56-1c0.4-0.601,0.52-1.16,0.28-1.72
			c-0.2-0.561-0.72-1.12-1.52-1.68L128.88,293.439z M129.92,291.96l1.76,1.24c0.76,0.521,1.4,0.8,1.96,0.8c0.52,0,1-0.279,1.4-0.88
			c0.36-0.521,0.44-1.04,0.2-1.56c-0.2-0.521-0.76-1.041-1.56-1.601l-1.6-1.12L129.92,291.96z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M130.84,301.52c-1.04,0-2.2-0.399-3.36-1.24l-4.24-2.92l8-11.6l3.52,2.44c1.56,1.08,2.56,2.12,2.96,3.08
			c0.4,1,0.24,2-0.48,3.04c-0.48,0.68-1.08,1.16-1.76,1.359c-0.52,0.16-1.04,0.16-1.6,0c0.48,0.561,0.72,1.16,0.8,1.76
			c0.08,0.761-0.16,1.521-0.72,2.32c-0.72,1.08-1.68,1.641-2.84,1.721C131.04,301.48,130.92,301.52,130.84,301.52z M123.8,297.24
			l3.92,2.72c1.2,0.84,2.36,1.24,3.4,1.16s1.88-0.601,2.56-1.56c0.48-0.721,0.72-1.4,0.64-2.041c-0.08-0.64-0.44-1.319-1.08-1.959
			l-0.12-0.121l0.24-0.359l0.16,0.08c0.68,0.32,1.32,0.359,1.88,0.16c0.6-0.2,1.12-0.6,1.56-1.2c0.64-0.92,0.76-1.8,0.44-2.681
			c-0.36-0.879-1.32-1.879-2.84-2.92l-3.2-2.199L123.8,297.24z M130.36,299.359c-0.6,0-1.32-0.279-2.08-0.799l-2.12-1.48l2.68-3.92
			l2.04,1.4c0.84,0.6,1.4,1.199,1.6,1.799c0.24,0.641,0.12,1.281-0.32,1.92c-0.48,0.681-1.04,1.041-1.72,1.08
			C130.4,299.359,130.4,299.359,130.36,299.359z M126.68,296.96l1.8,1.24c0.72,0.521,1.36,0.76,1.92,0.72c0.52-0.04,1-0.32,1.4-0.92
			c0.36-0.52,0.44-1.04,0.28-1.52c-0.2-0.521-0.68-1.041-1.48-1.601l-1.72-1.159L126.68,296.96z M133.64,294.2L133.64,294.2
			c-0.56,0-1.24-0.28-2.04-0.841l-1.92-1.319l2.4-3.44l1.76,1.2c0.88,0.601,1.4,1.16,1.64,1.681c0.24,0.56,0.16,1.16-0.24,1.76
			C134.76,293.84,134.24,294.2,133.64,294.2z M130.2,291.92l1.6,1.12c0.72,0.521,1.36,0.76,1.84,0.76l0,0
			c0.44,0,0.84-0.239,1.24-0.8c0.32-0.48,0.4-0.92,0.2-1.359c-0.2-0.48-0.72-0.961-1.52-1.521l-1.44-1L130.2,291.92z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M142.48,308.96l0.6-4l-4.6-2.479l-3,2.72l-2.08-1.12l11.04-9.68l2.16,1.16l-2.04,14.56L142.48,308.96z
			 M143.48,303l0.64-3.88c0.04-0.28,0.12-0.681,0.2-1.24c0.12-0.56,0.2-0.96,0.28-1.2c-0.52,0.6-1.12,1.2-1.84,1.881l-2.8,2.56
			L143.48,303z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M144.76,310.439l-2.48-1.319l0.6-4l-4.36-2.36l-3,2.721l-2.44-1.32l11.36-9.96l2.4,1.28L144.76,310.439z
			 M142.72,308.88l1.72,0.92l1.96-14.12l-1.92-1.039l-10.72,9.399l1.72,0.92l3-2.72l4.84,2.64L142.72,308.88z M143.64,303.32
			l-4-2.16l3-2.76c0.72-0.641,1.32-1.28,1.84-1.84l0.56-0.641l-0.2,0.84c-0.08,0.24-0.16,0.641-0.24,1.2
			c-0.12,0.56-0.2,0.96-0.2,1.2L143.64,303.32z M140.32,301.04l3,1.64l0.6-3.6c0.04-0.28,0.12-0.68,0.24-1.24
			c0.04-0.16,0.08-0.32,0.08-0.48c-0.4,0.44-0.84,0.881-1.36,1.32L140.32,301.04z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M160.28,308.96c-0.84,2.12-2.08,3.479-3.68,4.12s-3.52,0.52-5.72-0.359l-3.56-1.441l5.08-12.719l3.92,1.56
			c2.04,0.8,3.4,2,4.08,3.521C161.08,305.16,161.04,307,160.28,308.96z M158.04,308.16c1.2-3.04,0.4-5.12-2.44-6.28l-1.8-0.72
			l-3.68,9.24l1.48,0.6C154.64,312.2,156.8,311.279,158.04,308.16z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M154.4,313.68c-1.08,0-2.28-0.24-3.6-0.76l-3.76-1.48l5.24-13.08L156.4,300c2.08,0.84,3.48,2.04,4.2,3.641
			c0.72,1.6,0.64,3.399-0.16,5.439l0,0c-0.84,2.16-2.12,3.561-3.8,4.24C155.96,313.561,155.2,313.68,154.4,313.68z M147.6,311.2
			l3.36,1.36c2.12,0.84,4,0.959,5.56,0.359c1.56-0.64,2.76-1.96,3.56-4l0,0c0.76-1.92,0.8-3.64,0.16-5.12
			c-0.68-1.479-2-2.64-3.96-3.44l-3.76-1.479L147.6,311.2z M153.96,311.721c-0.76,0-1.56-0.16-2.48-0.521l-1.68-0.681l3.84-9.6
			l2,0.801c2.96,1.199,3.8,3.399,2.56,6.52l0,0C157.32,310.561,155.88,311.721,153.96,311.721z M150.36,310.279l1.28,0.521
			c2.96,1.2,5,0.28,6.2-2.72l0,0c1.2-2.96,0.4-4.92-2.32-6l-1.6-0.641L150.36,310.279z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M168.96,311l4.88,1.32l-1.76,6.64c-0.88,0.04-1.68,0.04-2.44-0.08c-0.76-0.08-1.56-0.239-2.44-0.479
			c-2-0.521-3.4-1.561-4.16-3c-0.76-1.44-0.88-3.28-0.28-5.44c0.56-2.12,1.64-3.64,3.16-4.521c1.56-0.879,3.4-1.039,5.56-0.439
			c1.4,0.359,2.64,1,3.72,1.84l-1.24,1.6c-0.96-0.799-1.96-1.319-3.04-1.6c-1.4-0.359-2.64-0.24-3.72,0.439
			c-1.04,0.681-1.8,1.761-2.2,3.32c-0.44,1.601-0.4,2.961,0.12,4c0.52,1.041,1.48,1.76,2.84,2.12c0.72,0.2,1.48,0.28,2.32,0.32
			l0.92-3.399l-2.76-0.721L168.96,311z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M171.16,319.16c-0.56,0-1.08-0.04-1.6-0.08c-0.76-0.08-1.6-0.24-2.44-0.48c-2.04-0.56-3.48-1.6-4.28-3.119
			c-0.8-1.521-0.88-3.4-0.32-5.601c0.6-2.159,1.68-3.72,3.28-4.64c1.6-0.92,3.52-1.08,5.68-0.48c1.4,0.36,2.68,1,3.8,1.881
			l0.16,0.119l-1.48,1.92l-0.16-0.119c-0.92-0.761-1.92-1.281-2.96-1.561c-1.36-0.359-2.56-0.24-3.56,0.4
			c-1,0.64-1.72,1.72-2.12,3.199c-0.4,1.561-0.4,2.84,0.12,3.84c0.48,1,1.4,1.641,2.72,2c0.64,0.16,1.36,0.281,2.12,0.32l0.8-3.08
			l-2.76-0.72l0.6-2.239l5.24,1.399l-1.88,7h-0.16C171.76,319.16,171.48,319.16,171.16,319.16z M169.08,304.84
			c-1.12,0-2.16,0.24-3.04,0.76c-1.48,0.84-2.52,2.32-3.08,4.4c-0.56,2.12-0.48,3.88,0.28,5.32c0.72,1.4,2.08,2.4,4.04,2.92
			c0.84,0.24,1.64,0.4,2.4,0.48c0.72,0.079,1.48,0.119,2.28,0.079l1.68-6.319l-4.48-1.201l-0.4,1.48l2.76,0.721l-1,3.76h-0.16
			c-0.84-0.04-1.64-0.16-2.36-0.36c-1.44-0.399-2.44-1.12-3-2.239c-0.52-1.08-0.6-2.48-0.12-4.121c0.44-1.6,1.2-2.72,2.28-3.439
			c1.12-0.68,2.4-0.84,3.88-0.48c1.04,0.28,2.04,0.801,2.96,1.521l1-1.28c-1.04-0.76-2.2-1.359-3.48-1.68
			C170.6,304.92,169.8,304.84,169.08,304.84z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M183.88,321.48l-7.64-1.201l2.12-13.52l7.64,1.2l-0.28,1.88l-5.44-0.84l-0.6,3.68l5.12,0.801l-0.28,1.84
			l-5.12-0.801l-0.68,4.24l5.44,0.84L183.88,321.48z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M184.08,321.721l-8.04-1.281l2.2-13.92l8.04,1.28l-0.36,2.28l-5.44-0.84l-0.52,3.279l5.12,0.801
			l-0.36,2.24l-5.12-0.801l-0.6,3.84l5.44,0.84L184.08,321.721z M176.48,320.12l7.28,1.159L184,319.8l-5.44-0.84l0.72-4.64l5.12,0.8
			l0.24-1.44l-5.12-0.8l0.64-4.08l5.44,0.841l0.24-1.48l-7.28-1.16L176.48,320.12z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M196,318.721c-0.08,1.199-0.6,2.159-1.52,2.76c-0.92,0.64-2.16,0.879-3.72,0.799
			c-1.56-0.119-2.8-0.439-3.76-0.959l0.16-2.12c0.6,0.319,1.24,0.6,1.96,0.84c0.68,0.2,1.32,0.36,1.96,0.399
			c0.88,0.041,1.56-0.08,2-0.359c0.44-0.32,0.68-0.76,0.72-1.32c0.04-0.52-0.12-0.96-0.48-1.32c-0.36-0.399-1.12-0.84-2.32-1.439
			s-2.04-1.24-2.52-1.92s-0.68-1.48-0.6-2.4c0.08-1.16,0.56-2,1.4-2.6c0.84-0.6,1.96-0.84,3.32-0.76c1.32,0.08,2.6,0.479,3.84,1.119
			l-0.84,1.761c-1.2-0.601-2.24-0.921-3.2-1c-0.72-0.04-1.28,0.079-1.68,0.36c-0.4,0.279-0.6,0.68-0.64,1.199
			c-0.04,0.36,0.04,0.68,0.16,0.92c0.12,0.28,0.36,0.521,0.68,0.76c0.32,0.24,0.92,0.601,1.8,1c0.96,0.48,1.68,0.92,2.12,1.32
			s0.76,0.84,0.96,1.28C195.96,317.561,196.04,318.08,196,318.721z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M191.4,322.48c-0.2,0-0.4,0-0.64-0.041c-1.56-0.119-2.88-0.439-3.84-1l-0.12-0.08l0.16-2.56l0.28,0.16
			c0.6,0.319,1.24,0.601,1.92,0.8c0.68,0.2,1.32,0.32,1.88,0.36c0.84,0.04,1.48-0.04,1.88-0.32s0.6-0.64,0.64-1.159
			c0.04-0.44-0.12-0.841-0.44-1.201c-0.36-0.359-1.12-0.84-2.24-1.359c-1.24-0.6-2.12-1.28-2.6-1.96
			c-0.48-0.72-0.72-1.56-0.64-2.521c0.08-1.199,0.56-2.119,1.48-2.76c0.88-0.64,2.04-0.88,3.44-0.8c1.32,0.08,2.64,0.479,3.92,1.16
			l0.16,0.079l-1,2.121l-0.2-0.08c-1.16-0.561-2.2-0.92-3.12-0.961c-0.68-0.039-1.2,0.08-1.56,0.32c-0.36,0.24-0.52,0.6-0.56,1.08
			c-0.04,0.32,0.04,0.6,0.16,0.84c0.12,0.24,0.32,0.48,0.64,0.68c0.32,0.24,0.92,0.561,1.76,1c1,0.48,1.72,0.961,2.16,1.361
			c0.48,0.399,0.8,0.879,1,1.359c0.2,0.48,0.28,1.08,0.24,1.721c-0.08,1.279-0.64,2.239-1.6,2.92
			C193.76,322.2,192.68,322.48,191.4,322.48z M187.24,321.2c0.92,0.479,2.12,0.8,3.56,0.88c1.48,0.12,2.68-0.16,3.6-0.76
			c0.88-0.6,1.36-1.48,1.44-2.641l0,0c0.04-0.6-0.04-1.119-0.2-1.56c-0.16-0.44-0.48-0.841-0.88-1.2
			c-0.44-0.359-1.12-0.8-2.08-1.279c-0.88-0.44-1.48-0.761-1.84-1.041c-0.36-0.279-0.6-0.56-0.76-0.84
			c-0.16-0.279-0.2-0.64-0.2-1.039c0.04-0.561,0.28-1.041,0.72-1.361c0.44-0.319,1.04-0.439,1.8-0.399
			c0.92,0.08,1.96,0.36,3.08,0.92l0.68-1.399c-1.16-0.601-2.4-0.92-3.6-1c-1.32-0.08-2.4,0.16-3.2,0.72
			c-0.8,0.56-1.24,1.36-1.32,2.479c-0.04,0.881,0.12,1.641,0.56,2.28s1.28,1.28,2.44,1.84c1.2,0.601,2,1.08,2.36,1.479
			c0.4,0.441,0.6,0.921,0.56,1.48c-0.04,0.641-0.32,1.12-0.8,1.48c-0.48,0.32-1.2,0.48-2.12,0.4c-0.6-0.041-1.28-0.16-2-0.4
			c-0.6-0.2-1.16-0.4-1.72-0.68L187.24,321.2z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M212.8,321.84l-7.72,0.52l-0.88-13.68l7.72-0.52l0.12,1.88l-5.521,0.36l0.24,3.72l5.16-0.32l0.12,1.88
			l-5.16,0.32l0.28,4.279l5.52-0.359L212.8,321.84z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M204.84,322.561l-0.92-14.08l8.12-0.521l0.16,2.28l-5.521,0.359l0.2,3.32l5.16-0.32l0.16,2.28l-5.16,0.32
			l0.239,3.88l5.521-0.359l0.16,2.279L204.84,322.561z M204.359,308.88l0.881,13.28l7.32-0.48l-0.08-1.52l-5.521,0.359l-0.319-4.68
			l5.159-0.32l-0.079-1.479l-5.16,0.319l-0.281-4.119l5.521-0.36l-0.08-1.479L204.359,308.88z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M225.2,320.04l-1.96-3.521l-5.16,0.84l-0.721,3.961l-2.319,0.4l2.8-14.4l2.4-0.4l7.32,12.721L225.2,320.04
			z M222.32,314.68l-1.881-3.479c-0.119-0.24-0.319-0.601-0.56-1.12c-0.239-0.52-0.44-0.88-0.521-1.12
			c-0.039,0.8-0.159,1.64-0.319,2.601l-0.64,3.719L222.32,314.68z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M214.76,321.96l2.881-14.84l2.68-0.44l7.52,13.12l-2.76,0.44l-1.96-3.52l-4.88,0.799l-0.721,3.961
			L214.76,321.96z M218,307.48l-2.721,14l1.921-0.32l0.72-3.96l5.439-0.921l1.961,3.521l1.92-0.319l-7.08-12.36L218,307.48z
			 M218.12,315.561l0.68-4c0.16-0.961,0.28-1.801,0.32-2.601l0.04-0.84l0.32,0.8c0.119,0.24,0.279,0.6,0.52,1.08
			c0.24,0.52,0.439,0.88,0.561,1.08l2,3.72L218.12,315.561z M219.48,309.76c-0.041,0.6-0.16,1.2-0.28,1.881l-0.601,3.439l3.4-0.561
			l-1.76-3.24c-0.12-0.239-0.32-0.6-0.6-1.119C219.6,310.04,219.52,309.92,219.48,309.76z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M231.08,313.04l1.48,5.28l-2.16,0.6l-3.68-13.199l3.719-1.041c1.721-0.479,3.041-0.52,4.041-0.08
			c1,0.44,1.68,1.28,2.039,2.561c0.44,1.64-0.08,3.04-1.6,4.24l5.359,4.8l-2.439,0.68l-4.64-4.399L231.08,313.04z M230.561,311.24
			l1.479-0.4c1-0.279,1.681-0.68,2.04-1.16c0.359-0.479,0.439-1.119,0.199-1.84c-0.199-0.76-0.6-1.199-1.159-1.4
			c-0.56-0.199-1.36-0.16-2.32,0.121l-1.44,0.399L230.561,311.24z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M230.24,319.16l-3.76-13.6l3.92-1.08c1.72-0.48,3.16-0.521,4.199-0.08c1.041,0.439,1.76,1.32,2.16,2.68
			c0.44,1.641-0.039,3.08-1.48,4.279l5.44,4.881l-2.92,0.8l-4.64-4.399l-1.84,0.52l1.479,5.279L230.24,319.16z M226.96,305.84
			l3.56,12.84l1.761-0.479l-1.48-5.28l2.44-0.68l4.64,4.4l2-0.561l-5.239-4.721l0.199-0.159c1.439-1.12,1.96-2.44,1.52-4
			c-0.319-1.24-1-2.04-1.92-2.4c-0.959-0.399-2.279-0.36-3.92,0.08L226.96,305.84z M230.439,311.48l-1.319-4.721l1.601-0.439
			c1-0.28,1.84-0.32,2.439-0.12c0.64,0.2,1.08,0.72,1.32,1.56c0.199,0.801,0.119,1.48-0.24,2c-0.36,0.521-1.08,0.961-2.16,1.24
			L230.439,311.48z M229.641,307.04l1.08,3.92l1.319-0.36c0.96-0.279,1.601-0.64,1.92-1.08c0.319-0.439,0.36-1,0.2-1.68
			c-0.2-0.68-0.52-1.08-1.04-1.279c-0.521-0.16-1.28-0.121-2.24,0.119L229.641,307.04z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M253.84,310.8l-2.561,1.04l-10.359-7.561l-0.08,0.041l0.28,0.56c0.521,1.04,0.96,2,1.319,2.92l2.681,6.601
			l-1.88,0.76l-5.16-12.68l2.52-1.041l10.32,7.521l0.04-0.04c-0.08-0.12-0.28-0.6-0.681-1.439c-0.359-0.84-0.639-1.48-0.84-1.92
			l-2.72-6.641l1.921-0.76L253.84,310.8z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M243.16,315.359l-5.32-13.08l2.801-1.159l9.879,7.2c-0.08-0.2-0.239-0.48-0.359-0.801
			c-0.36-0.84-0.68-1.479-0.84-1.959l-2.801-6.84l2.28-0.921l5.32,13.08l-2.84,1.16l-9.921-7.24c0.521,1.04,0.961,1.96,1.281,2.841
			l2.76,6.799L243.16,315.359z M238.359,302.52l5.041,12.32l1.52-0.6l-2.6-6.4c-0.36-0.88-0.801-1.84-1.32-2.88l-0.359-0.72
			l0.359-0.16l10.359,7.561l2.24-0.92l-5.039-12.32l-1.521,0.64l2.64,6.479c0.2,0.48,0.48,1.121,0.84,1.92
			c0.4,0.84,0.601,1.281,0.641,1.4l0.08,0.2l-0.36,0.16l-10.319-7.521L238.359,302.52z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M264.92,304.96l-6.8,3.72l-6.56-12.039l6.799-3.721l0.92,1.68l-4.84,2.641l1.801,3.279l4.52-2.479
			l0.881,1.64l-4.521,2.48l2.04,3.8l4.84-2.64L264.92,304.96z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M258.04,308.92l-6.72-12.4l7.16-3.879l1.08,2l-4.84,2.639l1.6,2.921l4.52-2.48l1.08,2l-4.52,2.48
			l1.88,3.44l4.84-2.641l1.08,2.04L258.04,308.92z M251.84,296.68l6.36,11.68l6.479-3.52l-0.72-1.32l-4.84,2.641l-2.24-4.12
			l4.521-2.479l-0.721-1.281l-4.52,2.48l-1.96-3.64l4.84-2.64l-0.72-1.32L251.84,296.68z"
          />
        </g>
        <g>
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M273.641,290.76c1.279,1.881,1.76,3.641,1.399,5.36c-0.36,1.68-1.521,3.2-3.44,4.56l-3.16,2.16
			l-7.799-11.24l3.479-2.399c1.8-1.24,3.521-1.72,5.2-1.44C271,288,272.439,289.04,273.641,290.76z M271.76,292.2
			c-1.88-2.72-4.039-3.16-6.6-1.44l-1.6,1.12l5.64,8.2l1.319-0.92C273.24,297.24,273.641,294.96,271.76,292.2z"
          />
          <path
            fill={theme?.palette?.badges?.decoration}
            d="M268.4,303.12l-8-11.601l3.64-2.52c1.84-1.279,3.64-1.76,5.319-1.48c1.721,0.28,3.201,1.32,4.44,3.121l0,0
			c1.32,1.879,1.8,3.76,1.44,5.479c-0.36,1.76-1.561,3.319-3.521,4.68L268.4,303.12z M260.92,291.641l7.561,10.959l3-2.08
			c1.879-1.319,3.039-2.799,3.359-4.439c0.36-1.641-0.119-3.359-1.359-5.2l0,0c-1.16-1.68-2.601-2.68-4.201-2.96
			c-1.6-0.279-3.319,0.2-5.039,1.4L260.92,291.641z M269.16,300.32l-5.881-8.521l1.761-1.2c1.319-0.92,2.56-1.24,3.681-1
			c1.159,0.24,2.199,1.08,3.159,2.48l0,0c0.96,1.4,1.36,2.76,1.16,3.96s-1.04,2.319-2.44,3.28L269.16,300.32z M263.84,291.92
			l5.439,7.84l1.16-0.8c1.32-0.92,2.08-1.92,2.24-3c0.2-1.08-0.16-2.319-1.08-3.64l0,0c-0.88-1.32-1.88-2.08-2.92-2.32
			c-1.039-0.24-2.16,0.08-3.4,0.92L263.84,291.92z"
          />
        </g>
      </g>
    </svg>
  );
}
