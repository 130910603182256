import { useState, useEffect } from "react";
import { useUser } from "../contexts/userContext";

// Hook
export default function useLocalStorage(key, initialValue) {
  const { user } = useUser();
  const oid = user?.oid;

  const GetStoredValue = () => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(`${oid} ${key}`);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error("useLocalStorage: " + error);
      return initialValue;
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(GetStoredValue());

  // When user changes, get new value from local storage
  useEffect(() => {
    if (user) {
      var foundValue = GetStoredValue();
      setStoredValue(foundValue);
    }
  }, [oid]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(
        `${oid} ${key}`,
        JSON.stringify(valueToStore)
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error("useLocalStorage: " + error);
    }
  };
  return [storedValue, setValue];
}
