//#region Imports
//import React from 'react'
import { Typography } from "@mui/material";
//#endregion

//#region Render
export default function DsaProgramHeader() {
  return (
    <>
      <Typography variant="lede" component="h2" sx={{ mt: 2 }}>
        Programs with Data Sharing Agreements
      </Typography>
      <Typography component="p" variant="caption" sx={{ mb: 2 }}>
        In addition to partners offering after-school activities, Tacoma Schools
        has other partners who are also dedicated to enhancing our students'
        social, emotional, and academic well-being. With parent/guardian
        permission, they use our student's data to guide and refine the content
        and delivery of their programs. Participation in these programs requires
        sharing your student's data, which allows them to tailor the experience
        to your student.
      </Typography>
    </>
  );
}
//#endregion
