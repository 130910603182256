import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import TrailFanDownFadeIn from "../animations/TrailFanDownFadeIn";
import { blob } from "../../services/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// custom icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fasBeyondTheBell } from "../images/icons/fasBeyondTheBell";
library.add(fasBeyondTheBell);

export default function AboutCompassActivities() {
  const { user } = useUser();
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  // Styles
  const slideStyles = {
    ...commonStyles.slideStyles,
    background: "#fff",
  };
  const iconStyles = {
    margin: 1,
    "& img": {
      width: 55,
      height: 55,
    },
  };

  return (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1" gutterBottom color="#0277bd">
        <FontAwesomeIcon
          icon={fasBeyondTheBell}
          style={{ marginRight: "1rem" }}
        />
        Activities
      </Typography>
      <Grid
        container
        spacing={1}
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="space-between"
        sx={{ height: 450 }}
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={`${blob}beyond-the-bell-logo.svg`}
                alt="Beyond the Bell"
                style={{
                  float: "right",
                  marginLeft: !!largeScreen ? "1rem" : "0.5rem",
                  marginBottom: !!largeScreen ? "1rem" : "0.5rem",
                  maxWidth: !!largeScreen ? 200 : 120,
                }}
              />
              <Typography
                sx={{ color: "#212121", marginTop: !!largeScreen ? "1rem" : 0 }}
              >
                {user?.role === "student" ? (
                  <span>
                    Check out the clubs, activities, field trips and other
                    programs offered at your&nbsp;school.
                  </span>
                ) : (
                  <span>
                    Register your kids for various clubs, activities, field
                    trips and&nbsp;more.
                  </span>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
          >
            <TrailFanDownFadeIn>
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-atom.svg`} alt="atom" />
              </Box>
              <Box sx={iconStyles}>
                <img
                  src={`${blob}doodle-ballet-shoes.svg`}
                  alt="ballet shoes"
                />
              </Box>
              {largeScreen && (
                <Box sx={iconStyles}>
                  <img src={`${blob}doodle-basketball.svg`} alt="basketball" />
                </Box>
              )}
              {largeScreen && (
                <Box sx={iconStyles}>
                  <img src={`${blob}doodle-dialog.svg`} alt="dialog" />
                </Box>
              )}
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-football.svg`} alt="football" />
              </Box>
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-music-note.svg`} alt="music note" />
              </Box>
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-paintbrush.svg`} alt="paintbrush" />
              </Box>
              {largeScreen && (
                <Box sx={iconStyles}>
                  <img
                    src={`${blob}doodle-paper-plane.svg`}
                    alt="paper plane"
                  />
                </Box>
              )}
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-robot.svg`} alt="robot" />
              </Box>
              <Box sx={iconStyles}>
                <img src={`${blob}doodle-soccer.svg`} alt="soccer" />
              </Box>
              <Box sx={iconStyles}>
                <img
                  src={`${blob}doodle-theater-masks.svg`}
                  alt="theater masks"
                />
              </Box>
              <Box sx={iconStyles}>
                <img
                  src={`${blob}doodle-video-camera.svg`}
                  alt="video camera"
                />
              </Box>
            </TrailFanDownFadeIn>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
