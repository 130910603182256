import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetParentAnnualRegistrationByStudentAndSchoolYear(
  studentId,
  schoolYear
) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `AnnualRegistration/GetParentAnnualRegistrationByStudentAndSchoolYear/${studentId}/${schoolYear}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId && !!schoolYear,
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting annual registration. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function usePostAnnualRegistration(annualRegistration) {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPost(
        token,
        "AnnualRegistration/PostAnnualRegistration",
        annualRegistration
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          `AnnualRegistration/GetParentAnnualRegistrationByStudentAndSchoolYear/${annualRegistration?.studentId}`
        );
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error posting annual registration", ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return mutation;
}
