import React, { useState, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { themes } from "../../themes";
const ThemeContext = React.createContext();
function useSelectedTheme() {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error(
      `useSelectedTheme must be used within a ThemeContextProvider`
    );
  }
  return context;
}
function ThemeContextProvider(props) {
  let defaultTheme = themes[0]?.name;

  const [theme, setTheme] = useLocalStorage("theme", defaultTheme);
  const [selectedTheme, setSelectedTheme] = useState();
  useEffect(() => {
    if (theme) {
      var foundTheme = themes.find((t) => t.name === theme);
      setSelectedTheme(foundTheme ?? themes[0]);
    }
  }, [theme]);
  const value = { selectedTheme, setTheme };
  return <ThemeContext.Provider value={value} {...props} />;
}
export { ThemeContextProvider, useSelectedTheme };
