import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import BouncingO from "./../images/BouncingO";
import { ScrollToTopOnMount } from "../../services/utilities";

//Styles
const centerContentStyles = {
  alignItems: "center",
  display: "flex",
  minHeight: "63vh",
  justifyContent: "center",
};
const centerOStyles = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

export default function LoadingUser(props) {
  const { userQuery } = props;

  const GetRandomLoadingMessage = () => {
    const messages = [
      "Opening doors to success...",
      "Getting ready to rock...",
      "Building the future...",
      "Enabling postive feedback loops...",
      "Calibrating warp stabilizers...",
      "Downloading more RAM",
      "Dialing the modem",
      "Checking in on old friends",
      "Doing the heavy lifting...",
      "Waiting on the minions",
      "How about this weather, eh?",
      "Ordering 1s and 0s...",
      "Initializing the initializer...",
      "Dividing by zero...",
      "Making sure all the i's have dots...",
      "Adjusting the flux capacitor...",
      "Let's take a mindfulness moment...",
      "Combobulating discombobulator",
      <span>
        Turning <em>impossible</em> into <em>I'm possible</em>
      </span>,
      <span>
        How <em>you</em> doing?
      </span>,
      <span>
        Boing&thinsp;.&nbsp;.&nbsp;.
        <span style={{ padding: "0 2rem" }}>Boing&thinsp;.&nbsp;.&nbsp;.</span>
        Boing&thinsp;.&nbsp;.&nbsp;.
      </span>,
      <span>
        <span style={{ fontSize: "2rem" }}>👨🏾‍🚀</span> Preparing for liftoff...
      </span>,
      <span>
        Searching for our <span style={{ fontSize: "2rem" }}>🦄</span>
      </span>,
      "📞 Please hold...",
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  };

  return (
    <>
      <ScrollToTopOnMount />
      <Box sx={centerContentStyles}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Box sx={centerOStyles}>
              <BouncingO />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="caption"
              component="div"
              sx={{ textAlign: "center" }}
            >
              {GetRandomLoadingMessage()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {userQuery?.failureCount > 0 && (
              <>
                <Typography
                  component="h2"
                  variant="h5"
                  sx={{ textAlign: "center", mt: 5 }}
                >
                  Looks like we're having trouble getting some data. 😓
                  <br />
                  <span style={{ fontSize: "1rem" }}>We'll keep trying...</span>
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
