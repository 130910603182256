import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetBadgesForStudent() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "CompassBadges/GetBadgesForStudent";

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },

    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting earned badges. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetBadgeDetailsForStudent(badgeId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `CompassBadges/GetBadgeDetailsForStudent/${badgeId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: badgeId != null,
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting earned badge details. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useGetCompassBadge(badgeId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `CompassBadges/GetCompassBadge/${badgeId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: badgeId != null,
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting earned badge details. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}
export function useGetEarnedBadgeCountByMonth() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "EarnedBadges/GetEarnedBadgeCountByMonth";
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error loading Moods Journey. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
export function usePostEarnedBadge(earnedBadge, onSuccessCallback) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPost(
        token,
        "EarnedBadges/PostEarnedBadge",
        earnedBadge
      );
    },
    {
      onSuccess: async (response) => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("Interests")
        );
        snackbar.enqueueSnackbar("Earned badge saved", {
          variant: "success",
        });
        onSuccessCallback();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error saving earned badge. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return mutation;
}
