import { useQuery, useQueries } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetDistrictEvents() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "calendar/districtCalendar";

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting district events. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetSchoolEvents(schoolIds) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const error = {
    onError: (error) => {
      snackbar.enqueueSnackbar(`Error getting events. ${error}`, {
        variant: "error",
      });
    },
  };
  const queryArray = schoolIds?.map((schoolId) => {
    return {
      queryKey: `calendar/GetCalendarBySchoolID/${schoolId}`,
      queryFn: async () => {
        return await AppsGet(
          token,
          `calendar/GetCalendarBySchoolID/${schoolId}`
        );
      },
      error,
    };
  });

  const queries = useQueries(queryArray);
  return queries;
}
