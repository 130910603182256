import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { AppsPost } from "../apiService";

export function useCheckGuardianAndSendInvite(email) {
  const snackbar = useSnackbar();
  const token = Promise.resolve({});
  const mutation = useMutation(
    async () => {
      return await AppsPost(
        token,
        `Guardian/CheckGuardianAndSendInvite/${email}`,
        email
      );
    },
    {
      onSuccess: async (data) => {
        if (data?.data === false) {
          snackbar.enqueueSnackbar(
            `We could not find that email address, please contact your school for assistance.`,
            {
              variant: "error",
            }
          );
        } else {
          snackbar.enqueueSnackbar(
            `Success! Please check your email for an invitation from Microsoft`,
            {
              variant: "success",
            }
          );
        }
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `An error occured while registering.  ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return mutation;
}
