import { Box, Fade, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { blob } from "../../services/utilities";
import { useUser } from "../../services/contexts/userContext";

export default function Eligiblity(props) {
  const theme = useTheme();
  const { user } = useUser();

  // Styles
  const contentStyles = {
    alignItems: "center",
    display: "flex",
    minHeight: "63vh",
    justifyContent: "center",
    padding: theme?.spacing(2),
  };

  return (
    <Box sx={contentStyles}>
      <Fade in={true} style={{ transitionDelay: "5000ms" }}>
        <Box>
          <Typography variant="h3" component="h1" sx={{ fontWeight: 300 }}>
            <img
              src={`${blob}this-tall-to-ride.svg`}
              alt="must be this tall to ride"
              style={{
                float: "right",
                margin: "0 0 1rem 1rem",
                width: "150px",
              }}
            />
            Sorry...
          </Typography>
          <Typography gutterBottom>
            Compass is an app currently only for students in middle and high
            school and guardians.
          </Typography>
          <Typography variant="caption">
            You are currently logged in as <strong>{user?.email}</strong>.
          </Typography>
        </Box>
      </Fade>
    </Box>
  );
}
