import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import * as currency from "currency.js";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCurrentSchoolYear } from "../../services/hooks/currentSchoolYearHook";
import Error from "./Error";
import { formatDate } from "../../services/utilities";
import { useGetStudentMedicalInformation } from "../../services/hooks/studentMedicalHook";
import { useGetStudentEmergencyContacts } from "../../services/hooks/studentEmergencyContactHook";
import { useGetTransportations } from "../../services/hooks/transportationsHook";

import dayjs from "dayjs";
import { useUser } from "../../services/contexts/userContext";
import {
  useGetParentAnnualRegistrationByStudentAndSchoolYear,
  usePostAnnualRegistration,
} from "../../services/hooks/annualRegistrationHook";
import { useUpsertWaitlist } from "../../services/hooks/waitlistHook";

export default function ActivityWaitlistDialog(props) {
  const { details, open, onClose, activeStatusId } = props;
  const { user } = useUser();
  const theme = useTheme();
  const { activeStudent } = useActiveStudent();
  const currentSchoolYear = useCurrentSchoolYear()?.data?.data;
  const schoolYear = currentSchoolYear?.schoolYear;

  //Reset form when open changes
  useEffect(() => {
    setSelectedTransportation(null);
    setTransportationError(false);
    if (details?.isFixedCost) {
      setSelectedPayment(details?.cost);
    } else {
      setSelectedPayment(null);
    }
    setSelectedPaymentError(false);

    setSignature("");
    setSignatureError(false);
    setSignatureDate(dayjs());

    setTermsConsented(null);
    setTermsConsentedError(false);
  }, [open]);

  //annual registration
  const annualRegistrationQuery =
    useGetParentAnnualRegistrationByStudentAndSchoolYear(
      activeStudent?.studentId,
      schoolYear
    );
  const annualRegistration = annualRegistrationQuery?.data?.data;

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  //health info
  const medicalInfoQuery = useGetStudentMedicalInformation(
    activeStudent?.studentId
  );
  const medicalInfo = medicalInfoQuery?.data?.data;

  //emergency contacts
  const emergencyContactQuery = useGetStudentEmergencyContacts(
    activeStudent?.studentId
  );
  const emergencyContacts = emergencyContactQuery?.data?.data;

  //transportation
  const transportationsQuery = useGetTransportations();
  const transportations = transportationsQuery?.data?.data;
  const [selectedTransportation, setSelectedTransportation] = useState();
  const [transportationError, setTransportationError] = useState();

  //t-shirt size
  const [selectedTShirtSize, setSelectedTShirtSize] = useState();
  const [tshirtSizeError, setTshirtSizeError] = useState();

  //terms
  const [termsConsented, setTermsConsented] = useState(false);
  const [termsConsentedError, setTermsConsentedError] = useState(false);

  //payment
  const feeAmounts = [
    currency(0).value,
    currency(details?.cost).divide(2).value,
    currency(details?.cost).value,
  ].sort((a, b) => a - b);

  const [selectedPayment, setSelectedPayment] = useState();
  const [selectedPaymentError, setSelectedPaymentError] = useState();

  //signature
  const [signature, setSignature] = useState();
  const [signatureDate, setSignatureDate] = useState(
    dayjs().format("MM/DD/YYYY")
  ); // originally the value of a date picker in the form, but we're using today's date instead
  const [signatureError, setSignatureError] = useState(false);

  const tshirtSizes = [
    { size: "youth small", value: "YS" },
    { size: "youth medium", value: "YM" },
    { size: "youth large", value: "YL" },
    { size: "small", value: "S" },
    { size: "medium", value: "M" },
    { size: "large", value: "L" },
    { size: "extra large", value: "XL" },
    { size: "double extra large", value: "2XL" },
    { size: "triple extra large", value: "3XL" },
  ];

  //validate form
  const validateForm = () => {
    //check if transportation selected when available
    if (details?.enableTransportation && selectedTransportation == null) {
      setTransportationError(true);
      return false;
    }
    //check if required t-shirt size has been selected
    if (details?.isTeeShirtRequired && selectedTShirtSize == null) {
      setTshirtSizeError(true);
      return false;
    }
    //terms
    if (!termsConsented) {
      setTermsConsentedError(true);
      return false;
    }
    //payment
    if (
      !selectedPayment &&
      details?.previouslyEnrolledCost == null &&
      details?.cost > 0
    ) {
      setSelectedPaymentError(true);
      return false;
    }
    //signature
    if (signature == null || signature.length === 0) {
      setSignatureError(true);
      return false;
    }

    return true;
  };

  const waitlistObject = {
    waitlistId: details?.waitlist?.waitlistId
      ? details?.waitlist?.waitlistId
      : 0,
    programId: details?.programId,
    studentId: activeStudent?.studentId,
    waitlistStatusId: activeStatusId, //??
    note: null,
    TransportationId: selectedTransportation
      ? selectedTransportation
      : details?.waitlist?.transportationId
      ? details?.waitlist?.transportationId
      : null,
    Cost: selectedPayment
      ? selectedPayment
      : details?.waitlist?.cost
      ? details?.waitlist?.cost
      : null,
    createdDate: dayjs(),
    createdBy: user?.email,
    modifiedDate: dayjs(),
    modifiedBy: user?.email,
    orderDate: dayjs(),
  };
  // const postParticipant = usePostParticipant(postObject, onClose);

  const postWaitlistParticipant = useUpsertWaitlist(waitlistObject, onClose);

  const postAnnualRegistration = usePostAnnualRegistration({
    SchoolYear: schoolYear,
    StudentId: activeStudent?.studentId,
    CreatedBy: user?.oid,
    DataOptOut: false,
    MediaOptOut: false,
    SpecialConditions: "",
    TeeShirtSize: selectedTShirtSize,
    ModifiedDate: dayjs(),
    CreatedDate: dayjs(),
  });

  const handleJoinWaitlist = async () => {
    if (!validateForm()) return;

    if (!annualRegistration) await postAnnualRegistration.mutateAsync();

    postWaitlistParticipant.mutate();
  };

  useEffect(() => {
    if (postWaitlistParticipant?.isSuccess === true) {
      setConfirmDialogOpen(true);
    }
  }, [postWaitlistParticipant?.isSuccess]);

  // Styles
  const radioStyles = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  };
  const termsContainerStyles = {
    borderRadius: theme.spacing(0.5),
    border: "1px solid rgba(0, 0, 0, 0)",
    background: "rgba(0,0,0,0.2)",
    height: 160,
    overflowY: "scroll",
    padding: theme.spacing(1),
  };
  const signatureStyles = {
    "& .MuiInputBase-input": {
      fontFamily:
        "Bradley Hand ITC, Brush Script MT, Noteworthy, Dancing Script, cursive",
    },
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="h3" component="h2">
            Join the waitlist for {details?.title ?? details?.activityName}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            <em>
              Please complete the following to add your student to the waitlist
              for this program. By e-signing below you confirm the terms either
              initialed below or initialed on a previous registration this
              school year.
            </em>
          </Typography>
          {/* Fixed Cost */}
          {details?.cost > 0 && details?.isFixedCost && (
            <>
              <br />
              <Typography variant="h4">Cost: ${details?.cost}</Typography>
              <Typography gutterBottom>
                If a spot becomes available, Parents/Guardians will be billed $
                {details?.cost} for this activity. Login to{" "}
                <Link
                  href="https://www.myschoolbucks.com/ver2/keysso?kc_idp_hint=MMW_11144"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  MySchoolBucks
                </Link>{" "}
                to pay the fee.
              </Typography>
            </>
          )}
          {/* Transportation */}
          {details?.enableTransportation && (
            <>
              <br />
              <Typography variant="h4">Transportation</Typography>
              <Typography gutterBottom>
                Parents and guardians are responsible for making arrangements
                for their student to get home safely. Select the end-of-the-day
                arrangements you have made.
              </Typography>

              <FormControl component="fieldset" error={transportationError}>
                {transportationError && (
                  <FormHelperText>
                    Please select a transportation option
                  </FormHelperText>
                )}
                <FormLabel name="transportationOption" component="legend">
                  <strong sx={{ color: theme?.palette?.text?.secondary }}>
                    Transportation Options
                  </strong>{" "}
                  (*required)
                </FormLabel>
                <RadioGroup
                  sx={{ marginLeft: theme.spacing(2) }}
                  aria-label="Transportation"
                  name="transportation1"
                  onChange={(event, value) => {
                    setSelectedTransportation(value);
                    setTransportationError(false);
                  }}
                >
                  {transportations?.map((option) =>
                    option.id === 1 ? (
                      <FormControlLabel
                        key={option.id}
                        // ref={transportationRef}
                        control={
                          <Radio
                            value={option.transportationId}
                            checked={
                              parseInt(selectedTransportation) ===
                              parseInt(option.transportationId)
                            }
                          />
                        }
                        label={option.method}
                      />
                    ) : (
                      <FormControlLabel
                        key={option.id}
                        control={
                          <Radio
                            value={option.transportationId}
                            checked={
                              parseInt(selectedTransportation) ===
                              parseInt(option.transportationId)
                            }
                          />
                        }
                        label={option.method}
                      />
                    )
                  )}
                </RadioGroup>
              </FormControl>
              <Typography>
                <strong style={{ color: theme?.palette?.text?.secondary }}>
                  People authorized to pick up my student:
                </strong>
              </Typography>
              {emergencyContactQuery.isLoading && <Skeleton height={40} />}
              {emergencyContactQuery.isError && (
                <Error
                  message={
                    "There was an error loading emergency contacts for this student."
                  }
                  query={emergencyContactQuery}
                />
              )}
              <Box>
                {emergencyContactQuery.isSuccess &&
                  emergencyContacts?.map((ec, i) => (
                    <Chip
                      key={i}
                      sx={{ m: theme.spacing(0.5) }}
                      label={`${ec.firstName} ${ec.lastName}`}
                    />
                  ))}
              </Box>
              <Typography variant="subtitle2">
                (This information is directly from our student information
                system. Contact the school directly to add to or edit the list
                of people authorized to pickup your student.)
              </Typography>
            </>
          )}

          <br />
          {/* Health */}
          <Typography variant="h4">Health</Typography>
          {medicalInfoQuery.isLoading && <Skeleton height={30} />}
          {medicalInfoQuery.isSuccess && medicalInfo.length === 0 && (
            <Typography>
              No medical info for {activeStudent.firstName} was found on file.
            </Typography>
          )}
          {medicalInfoQuery.isSuccess && medicalInfo.length > 0 && (
            <>
              <Typography>
                The medical information presented below is from our Student
                Information System and will be shared with third party program
                organizers. If any of the information is incorrect, contact your
                school directly to make any changes.*
              </Typography>
              <ul>
                {medicalInfo?.map((m, i) => (
                  <li key={i}>
                    <Typography
                      component="span"
                      color={theme?.palette?.text?.secondary}
                    >
                      <strong>{m.description}</strong>:
                    </Typography>{" "}
                    {m.comment}
                  </li>
                ))}
              </ul>
              <Typography variant="subtitle2">
                *This is an after-school program and Tacoma Public Schools will
                not have nursing staff present. We will not be able to
                administer medications.
              </Typography>
            </>
          )}

          {/* T-Shirts */}
          {details?.isTeeShirtRequired && (
            <>
              <br />
              <Typography variant="h4">T-Shirt Required</Typography>
              <Typography>
                This activity requires participants to wear a provided t-shirt.
              </Typography>
              <FormControl component="fieldset" error={tshirtSizeError}>
                {tshirtSizeError && (
                  <FormHelperText>Please select a t-shirt size</FormHelperText>
                )}
                <FormLabel name="transportationOption" component="legend">
                  <strong sx={{ color: theme?.palette?.text?.secondary }}>
                    Please select the appropriate size
                  </strong>{" "}
                  (*required)
                </FormLabel>
                <RadioGroup
                  sx={{ marginLeft: theme.spacing(2) }}
                  aria-label="T-Shirt size"
                  name="tshirtSize"
                  onChange={(event, value) => {
                    setSelectedTShirtSize(value);
                    setTshirtSizeError(false);
                  }}
                >
                  {tshirtSizes.map((size, id) => {
                    return (
                      <FormControlLabel
                        key={id}
                        control={
                          <Radio
                            value={size.value}
                            checked={selectedTShirtSize === size.value}
                          />
                        }
                        label={size.size}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <br />
            </>
          )}

          <br />
          {/* Terms */}
          <Typography variant="h4">Terms</Typography>
          <Box sx={termsContainerStyles}>
            <Typography variant="body2">
              <strong>What is ELO?</strong> Tacoma Public Schools (TPS) works
              with community partners to provide students with Expanded Learning
              Opportunities (ELO). The lead community partners are the Greater
              Tacoma Community Foundation and Metro Parks Tacoma (ELO Partners).
              ELO Partners may also work with other non-profit partners.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>What are examples of ELO?</strong> ELO may include summer
              school programs, after-school tutoring, an art class or a sports
              team. ELO may be available before and after school, during school
              breaks or on weekends.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>What is the Whole Child Access Pass?</strong> The Whole
              Child Access Pass allows TPS students to access community programs
              like admission to museums, library services, arts performances and
              Metro Parks classes. Tacoma residents who qualify for free/reduced
              lunch will automatically qualify for Whole Child Access Pass
              Financial Assistance program. To receive financial assistance, a
              parent or guardian must express consent to share free and reduced
              lunch information. The National School Lunch Act (42 USC section
              1758 (b)(6)(c)) protects the confidentiality of student's free and
              reduced lunch eligibility and provides for penalties for
              unauthorized disclosure. I consent to sharing free and reduced
              lunch information with ELO partners including 1) student name, 2)
              school name, 3) grade, 4) parent or guardian name, 5) email, 6)
              student home address and 7) free and reduced lunch information.
              ELO partners shall not share this information with any third party
              and will only use this information to register students, to send
              families promotional information and to offer special rates to
              eligible students.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Privacy and Confidentiality:</strong> The Family
              Educational Rights and Privacy Act of 1974 (FERPA) is a federal
              law that protects the privacy education records. FERPA limits
              release of student records without explicit written consent. I
              consent that:
              <ul>
                <li>
                  ELO staff may work together and with TPS to discuss how best
                  to support my student and his or her success.
                </li>
                <li>
                  TPS may share personally identifiable information regarding my
                  student with ELO Staff. Information may include my student's
                  demographic information (e.g. name, race, contact information,
                  emergency contacts), academic information (e.g. school
                  programs, test scores, qualification for programs such as
                  special education or ELL services, discipline information) and
                  my student's social/health information (e.g. emergency health
                  information, custody information).
                </li>
                <li>
                  ELO staff may talk with TPS teachers and staff about his/her
                  performance in school, including grades, behavior, test
                  results and any other information that will allow TPS and ELO
                  staff to more effectively serve my student. Further, I grant
                  ELO permission to share my student's ELO program attendance
                  with TPS.
                </li>
                <li>
                  ELO staff will keep information received about my student
                  confidential.
                </li>
              </ul>
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Photo/Video:</strong> I consent to photographs or videos
              being taken of my student while engaged in ELO and for use in
              editorial, advertising and promotional purposes in any print or
              electronic media. I agree that ELO partners own the copyright to
              this media.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Technology:</strong> Students may use a variety of
              technological resources while participating in an ELO including
              but not limited to computers, tablets, internet access, software
              and applications. I consent for my student to use technology
              resources.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Emergency Medical Treatment:</strong> I understand that if
              my student is participating in an ELO Program, there will not be
              TPS staff or nursing services available who can administer
              medications. I understand that ELO staff will not administer
              medications to my student. In the event of an emergency involving
              my student, I understand that every effort will be made to contact
              me. If I cannot be reached in a timely manner, I hereby give ELO
              staff/partners permission to act on my behalf to seek emergency
              medical treatment for my student. I release the ELO Program, its
              staff, partners, and TPS from any liability in acting on my
              behalf.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Participation:</strong> I understand that my student's
              participation in an ELO and the Whole Child Access Pass is
              voluntary. The ELO is responsible for supervision of my student.
              The ELO reserves the right to terminate my student's participation
              for any reason including that the needs of my student cannot be
              met by the services provided, when the program guidelines are not
              adhered to or that the safety of others or the program are
              compromised.
            </Typography>
            <br />
            <Typography variant="body2">
              <strong>Consent:</strong> By registering, I certify that I have
              read the above, understand its content and give my permission for
              TPS to share student information, including free and reduced lunch
              eligibility, with ELO partners. Additionally, at any time
              authorized parents or guardians can opt-out by contacting the ELO
              Coordinator at your school Tacoma Public School Summer School
              Office and requesting to remove their student from ELO programs.
            </Typography>
          </Box>
          <FormGroup>
            {termsConsentedError && (
              <FormHelperText error={termsConsentedError}>
                You must agree to the terms
              </FormHelperText>
            )}
            <FormControlLabel
              error={termsConsentedError}
              control={
                <Checkbox
                  checked={termsConsented}
                  onChange={(e) => {
                    setTermsConsented(e.target.checked);
                    setTermsConsentedError(false);
                  }}
                />
              }
              label="I hereby consent to all disclosures listed above"
            />
          </FormGroup>

          <br />
          {/* Payment */}
          {details?.cost > 0 && !details?.isFixedCost && (
            <>
              <Typography variant="h4">
                Flexible Pricing: After-school clubs, activities, and sports
              </Typography>
              <Typography gutterBottom>
                Beyond the Bell partners want to ensure that every student has
                access to affordable after-school experiences.
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                Program Costs
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                ${Math.max(...feeAmounts)} per activity
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Flexible pricing (${feeAmounts.join(", $")}) is available for
                families who need financial assistance. No application is
                required - please select the best option based on your family’s
                current need.
              </Typography>
              <FormControl
                component="fieldset"
                error={selectedPaymentError}
                sx={radioStyles}
              >
                <Grid container alignItems="center">
                  <Grid item xs={12} lg={4}>
                    <FormLabel component="legend">
                      <Typography>
                        <strong>Pay What You Choose:</strong>
                      </Typography>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography>
                      <strong>
                        (Payment only required once your student is off the
                        waitlist and enrolled in the program.)
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    {selectedPaymentError && (
                      <FormHelperText error={selectedPaymentError}>
                        Please select a payment option
                      </FormHelperText>
                    )}
                    {details?.previouslyEnrolledCost != null && (
                      <Typography>
                        The cost was already chosen as $
                        {details?.previouslyEnrolledCost}.
                      </Typography>
                    )}
                    {details?.previouslyEnrolledCost == null && (
                      <RadioGroup
                        aria-label="fee"
                        row
                        disabled={details?.previouslyEnrolledCost}
                        name="Fee"
                        value={selectedPayment}
                        onChange={(e) => {
                          setSelectedPayment(e.target.value);
                          setSelectedPaymentError(false);
                        }}
                      >
                        <Grid container justify="space-around">
                          {feeAmounts.map((fa, i) => {
                            return (
                              <Grid item key={i} mx={3}>
                                <FormControlLabel
                                  key={i}
                                  value={fa}
                                  control={<Radio />}
                                  label={`${currency(fa).format()}`}
                                  labelPlacement="bottom"
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    )}
                  </Grid>
                </Grid>
              </FormControl>
              <br />
              <Typography variant="body2" gutterBottom>
                <strong>
                  Every dollar paid goes directly to the Beyond the Bell
                  program, helping make activities more affordable and
                  accessible for every child.
                </strong>{" "}
                Cost should never be a barrier for any family, so further fee
                waiver assistance/scholarships are available to help. Call Metro
                Parks Tacoma at{" "}
                <Link href="tel:253-305-1030">253-305-1030</Link> for additional
                information.
              </Typography>
              <Typography variant="body2" gutterBottom>
                The average cost per activity is $320. Discounted rates are
                offered thanks to Tacoma Creates, private philanthropy, The City
                of Tacoma, Pierce County, Greentrike, Metro Parks Tacoma, Tacoma
                Public Schools and individual donors -- leveraging resources to
                help reduce fees for ALL Tacoma families.
              </Typography>
            </>
          )}
          <Divider sx={{ my: theme?.spacing(3) }} />

          {/* Signature */}
          <Typography variant="body2">
            I understand that my student's participation in an ELOC program is
            voluntary and that the ELOC reserves the right to terminate my
            student's participation in the program on the basis that the needs
            of my student cannot be met by the services provided, when the
            program guidelines are not adhered to, or the safety of others or
            the program are compromised. My e-signature indicates my agreement
            to my student's enrollment in the program and confirms any informed
            consents initialed above.
          </Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <FormControl error={signatureError} fullWidth>
                <TextField
                  sx={signatureStyles}
                  value={signature}
                  error={signatureError}
                  onChange={(e) => {
                    setSignature(e.target.value);
                    setSignatureError(false);
                  }}
                  fullWidth
                  required
                  label="Parent or Guardian Signature"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              Date: {formatDate(signatureDate)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {transportationError && (
            <FormControl error={transportationError}>
              <FormHelperText>
                Please select a transportation option
              </FormHelperText>
            </FormControl>
          )}

          {termsConsentedError && (
            <FormControl error={termsConsentedError}>
              <FormHelperText>You must agree with the terms</FormHelperText>
            </FormControl>
          )}

          {selectedPaymentError && (
            <FormControl error={selectedPaymentError}>
              <FormHelperText>You must choose what you pay</FormHelperText>
            </FormControl>
          )}

          {signatureError && (
            <FormControl error={signatureError}>
              <FormHelperText>Please complete your signature</FormHelperText>
            </FormControl>
          )}

          <Button
            onClick={onClose}
            disabled={postWaitlistParticipant.isLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleJoinWaitlist}
            disabled={
              annualRegistrationQuery.isLoading ||
              postWaitlistParticipant.isLoading
            }
            variant="contained"
          >
            Join waitlist
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialogOpen} onClose={onClose}>
        <DialogTitle>Thanks for joining the waitlist!</DialogTitle>
        <DialogContent>
          The Program Managers are monitoring the program for availability. If a
          spot opens up for your student, a program manager will reach out to
          you to provide enrollment details!
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              setConfirmDialogOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
