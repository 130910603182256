import React from "react";
import { Box, Card, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/pro-solid-svg-icons";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import { blob } from "../../services/utilities";

export default function AboutCompassPersonalization() {
  const commonStyles = useCommonStyles();
  const largeScreen = useMediaQuery(useTheme().breakpoints.up("sm"));

  const slideStyles = {
    ...commonStyles.slideStyles,
    overflow: "hidden",
    background: "#424242",
    color: "#fff",
  };
  const cardStyles = {
    width: "237px",
    height: "300px",
    position: "absolute",
    backgroundSize: "237px 300px",
    boxShadow: "-20px 20px 30px rgba(0, 0, 0, 0.6)",
    transform: "rotateX(45deg) rotateZ(15deg)",
  };
  const cardStyles1 = {
    ...cardStyles,
    background: `url(${blob}theme-card-default.png) no-repeat`,
    top: "0px",
    left: "200px",
  };
  const cardStyles2 = {
    ...cardStyles,
    background: `url(${blob}theme-card-forest.png) no-repeat`,
    top: "-20px",
    left: "160px",
  };
  const cardStyles3 = {
    ...cardStyles,
    background: `url(${blob}theme-card-midnight.png) no-repeat`,
    top: "-40px",
    left: "120px",
  };
  const cardStyles4 = {
    ...cardStyles,
    background: `url(${blob}theme-card-classic.png) no-repeat`,
    top: "-60px",
    left: "80px",
  };

  return (
    <Box sx={slideStyles}>
      <Typography
        component="h1"
        variant="h1"
        sx={{ fontSize: largeScreen ? "auto" : "1.7rem !important" }}
      >
        <FontAwesomeIcon icon={faUserEdit} />
        &nbsp;Personalization
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 32px)",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            perspective: "400px",
            position: "relative",
            height: 300,
          }}
        >
          <Card elevation={1} sx={cardStyles1} />
          <Card elevation={2} sx={cardStyles2} />
          <Card elevation={3} sx={cardStyles3} />
          <Card elevation={4} sx={cardStyles4} />
        </Box>
        <Box>
          <Typography
            variant="h4"
            component="h2"
            sx={{ marginBottom: largeScreen ? "auto" : "0px !important" }}
          >
            Pick a theme
          </Typography>
          <Typography variant="body2">Make Compass yours...</Typography>
        </Box>
      </Box>
    </Box>
  );
}
