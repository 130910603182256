import React from "react";
import { Button, Grid, Paper, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RouterLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCheckCircle,
  faFlag,
} from "@fortawesome/pro-solid-svg-icons";
import { useGetBadgeDetailsForStudent } from "../../services/hooks/badgesHook";
import dompurify from "dompurify";
import dayjs from "dayjs";

export default function BadgesDetail() {
  const theme = useTheme();

  const sanitizer = dompurify.sanitize;

  const { badgeId } = useParams();
  const badgeResponse = useGetBadgeDetailsForStudent(badgeId);
  const badge = badgeResponse?.data?.data;

  // Styles
  const paperStyles = {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  };
  const iconFooterStyles = {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const earnedStyles = {
    ...iconFooterStyles,
    "& .MuiTypography-body1": {
      fontSize: "1.5rem",
    },
    color: theme?.palette?.secondary?.text,
    "& span": {
      display: "inline-block",
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
    },
  };
  const badgeStyles = {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100%",
      maxWidth: 350,
    },
  };
  const descriptionStyles = {
    width: "100%",
    "& img": {
      maxWidth: `calc(100vw - ${theme.spacing(6)})`,
      [theme.breakpoints.up("lg")]: {
        maxWidth: `calc( (100vw - 280px) / 2 - ${theme.spacing(8)})`,
      },
    },
    "& a": {
      color: theme.palette.secondary.text,
    },
  };

  return (
    <>
      <Typography variant="h1" component="h2" gutterBottom>
        Badge
      </Typography>
      <Button
        component={RouterLink}
        to="/badges"
        startIcon={<FontAwesomeIcon icon={faCircleArrowLeft} />}
      >
        &nbsp;All Badges
      </Button>
      {!!badge && (
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={6}>
            <Box sx={badgeStyles}>
              <img src={badge?.url} alt="" />
            </Box>
            {!!badge.earned.length ? (
              <Box sx={earnedStyles}>
                <Typography>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  &nbsp;<strong>EARNED</strong>
                </Typography>
                <Typography variant="caption" component="div">
                  {badge.earned.map((eb) => {
                    return (
                      <span>{dayjs(eb.dateReceived).format("M/D/YYYY")}</span>
                    );
                  })}
                </Typography>
              </Box>
            ) : (
              <Box sx={iconFooterStyles}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<FontAwesomeIcon icon={faFlag} />}
                  component={RouterLink}
                  to={`/goals/badge/${badgeId}`}
                >
                  Make it a Goal
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Paper sx={paperStyles}>
              <Typography variant="h5" component="h3">
                {badge?.name}
              </Typography>
              <Typography
                variant="body1"
                component="div"
                sx={descriptionStyles}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(badge.description),
                }} // sanitizer makes it no longer dangerous (should protect from even the small chance of XSS attacks)
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
