import React from "react";
import { Box, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile } from "@fortawesome/pro-solid-svg-icons";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import { blob } from "../../services/utilities";

export default function AboutCompassMoods() {
  const commonStyles = useCommonStyles();

  const slideStyles = {
    ...commonStyles.slideStyles,
    background: "#212121",
    color: "#fff",
  };

  return (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1">
        <FontAwesomeIcon icon={faSmile} />
        &nbsp;Moods
      </Typography>
      <Box
        sx={{
          display: "flex",
          height: "calc(100% - 32px)",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <Box>
          <img src={`${blob}intro-moods.svg`} alt="" />
        </Box>
        <Box>
          <Typography variant="h4" component="h2">
            How are you feeling?
          </Typography>
          <Typography variant="body2">
            Learning to regulate your emotions starts with observing and
            identifying them. Put the Zones to use and get tips based on your
            mood.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
