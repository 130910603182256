import { useCheckGuardianAndSendInvite } from "../../services/hooks/compassRegistrationHook";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Link,
  useMediaQuery,
  Fab,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useSelectedTheme } from "../../services/contexts/themeContext";
import { useUser } from "../../services/contexts/userContext";
import TranslationInfo from "../common/TranslationInfo";
import { blob } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faCircleHalfStroke } from "@fortawesome/pro-solid-svg-icons";
import CompassLogo from "../images/CompassLogo";

export default function Register() {
  const theme = useTheme();
  const user = useUser();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const { selectedTheme, setTheme } = useSelectedTheme();

  const [email, setEmail] = useState("");
  let emailValid = /\S+@\S+\.\S+./.test(email) && !/tps10\.or/.test(email);

  const emailToRegister = useCheckGuardianAndSendInvite(email);
  const isEnabled =
    email.length > 0 && emailValid && emailToRegister.isLoading === false;

  const handleRegisterClick = async () => {
    if (emailValid)
      emailToRegister.mutateAsync().then(() => {
        setEmail("");
      });
  };

  // Styles
  const section = {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  };
  const emailField = {
    maxWidth: 315,
    marginBottom: theme.spacing(2),
  };
  const emailTips = {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingBottom: 0,
    color: theme?.palette?.primary?.charts?.first,
  };
  const step = {
    marginBottom: theme.spacing(2),
  };
  const stepTitle = {
    color: theme?.palette?.tertiary?.main,
    fontWeight: 800,
    fontSize: "1.5em",
  };
  const notReceivedTitle = {
    color: theme?.palette?.secondary?.main,
    fontWeight: 800,
    fontSize: "1.2em",
  };
  const stepImgTip = {
    alignItems: "center",
    "& $emailTips": {
      margin: theme.spacing(1),
      width: 375,
    },
  };
  const subSteps = {
    listStyleType: "lower-alpha",
    "@media (maxWidth: 600px)": {
      /* 600 is the small breakpoint */
      steps: {
        "& ol": {
          paddingLeft: 0,
        },
        "& li": {
          paddingTop: theme.spacing(1),
        },
      },
      step: {
        marginBottom: theme.spacing(2),
        "& img": {
          marginLeft: 0,
          marginRight: 0,
          maxWidth: "calc(100vw - 50px)",
        },
      },
      emailTips: {
        margin: 0,
        maxWidth: "calc(100vw - 50px)",
      },
    },
  };
  const commonPaddingStyles = {
    padding: theme.spacing(2),
    my: theme.spacing(4),
  };
  const smallPaddingStyle = {
    padding: theme.spacing(1),
    my: theme.spacing(2),
  };
  const imageWrapperStyles = {
    "& img": {
      width: "100%",
    },
    width: largeScreen ? 375 : "calc(100vw - 84px)",
    maxWidth: 375,
    my: theme.spacing(1),
  };
  const printButtonStyles = {
    margin: "0.5rem 1.5rem",
    "@media (max-width: 900px)": {
      display: "none",
    },
  };
  const contrastButtonStyles = {
    m: "0.5rem 0",
    "@media (max-width: 900px)": {
      mr: " 1.5rem",
    },
  };

  return (
    <Grid container id="page-content" sx={{ ...commonPaddingStyles, mt: 0 }}>
      <Grid item xs={12} component="header">
        <Grid container flexDirection="row-reverse" alignItems="center">
          <Grid item xs={6} align="right">
            <Grid container flexDirection="row-reverse" alignItems="center">
              <Grid item className="noPrint" align="right">
                <TranslationInfo browser={user.browser} />
              </Grid>
              <Grid item className="noPrint" align="right">
                <Fab
                  aria-label="Toggle high-contrast theme"
                  title="Toggle high-contrast theme"
                  sx={contrastButtonStyles}
                  onClick={() => {
                    setTheme(
                      selectedTheme?.name === "Midnight"
                        ? "Default"
                        : "Midnight"
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faCircleHalfStroke} size="2x" />
                </Fab>
                <Fab
                  color="primary"
                  aria-label="print"
                  id="fab"
                  sx={printButtonStyles}
                  onClick={() => {
                    window.print();
                  }}
                >
                  <FontAwesomeIcon icon={faPrint} size="2x" />
                </Fab>
              </Grid>
            </Grid>
            <Grid
              item
              className="printOnly"
              sx={{ background: "#3746af", padding: "1rem" }}
            >
              <img src={`${blob}compass-logo-print.svg`} alt="" />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Link href="/" sx={{ display: "block", ml: "-1rem" }}>
              <CompassLogo theme={theme} scale={0.75} />
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} component="main">
        <Grid container>
          <Grid item sm={12} sx={section}>
            <Typography
              gutterBottom
              component="h2"
              variant="h2"
              id="page-title"
            >
              Register your account
            </Typography>
            <Typography component="h3" variant="h5">
              <em>
                Parent and guardians,{" "}
                <span className="printOnly">
                  go to{" "}
                  <span style={{ color: "#222" }}>
                    https://compass.tpsapps.org/register
                  </span>
                  <br />
                  and{" "}
                </span>
                begin by entering the email address your school has on file
                for&nbsp;you.
              </em>
            </Typography>
            <Typography component="p">
              You'll then use or create a Microsoft account that will allow you
              to login and see info for your&nbsp;student(s).
            </Typography>
          </Grid>
          <Grid item sm={12} sx={section} className="noPrint">
            <form sx={emailField} noValidate>
              <TextField
                required
                // autoFocus //removing autofocus so users tabbing through the page start with the first items
                value={email}
                inputProps={{
                  maxLength: 50,
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                variant="outlined"
                label="Email address"
                placeholder="email@example.com"
                fullWidth
                sx={emailField}
                onChange={(event) => setEmail(event.target.value)}
                helperText="Secondary students, no need to register &mdash; just login with your
                TPS account."
              />
              <br />
              &nbsp;
              <Button
                variant="contained"
                disabled={!isEnabled}
                color="primary"
                size="large"
                onClick={handleRegisterClick}
              >
                Send Invitation
              </Button>
            </form>
          </Grid>
          <Paper sx={commonPaddingStyles} elevation={5}>
            <Grid container sx={emailTips} spacing={2}>
              <Grid item sm={6} sx={section}>
                <Typography component="h4" variant="h5" color="secondary">
                  Not sure which email?
                </Typography>
                <Typography component="p">
                  You can try a likely email address and see if you receive an
                  invitation and/or you can ask the staff at your school's main
                  office to verify or update your email address in our Student
                  Information&nbsp;System.
                </Typography>
                <br></br>
                <Typography component="h4" variant="h5" color="secondary">
                  Don't have an email account?
                </Typography>

                <Typography component="p">
                  <small>
                    We recommend a free account at{" "}
                    <Link href="https://outlook.com/">outlook.com</Link>. Set it
                    up and then ask your school's office staff to add it to our
                    system so you can&nbsp;register.
                  </small>
                </Typography>
              </Grid>

              <Grid item sm={6}>
                <Typography component="h4" variant="h5" color="secondary">
                  Microsoft account?
                </Typography>
                <Typography component="p">
                  <em>
                    <strong>
                      When you login to the TPS Compass App, you are really
                      logging into your Microsoft&nbsp;account.
                    </strong>
                  </em>{" "}
                  Then Microsoft tells the TPS Compass App if the login worked
                  and what your email address&nbsp;is.
                  <br />
                  <br />
                  If you need one, you can create a Microsoft account with any
                  email address following the process below. And you can always
                  manage your own Microsoft account by signing in at{" "}
                  <Link href="https://account.microsoft.com/">
                    account.microsoft.com
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Grid item sm={12}>
            <Typography component="h4" variant="h4">
              The step-by-step process
            </Typography>
            <Typography component="div" sx={{ maginBottom: "3rem" }}>
              <ol id="registrationSteps">
                <li style={step}>
                  <span style={stepTitle}>
                    Enter your email address above and click the{" "}
                    <em>Send Invitation</em> button
                  </span>
                  <br />
                  The email address must match what we have on file in our
                  Student Information System. Otherwise, you will not receive an
                  invitation&nbsp;email.
                  <br />
                  <small>
                    <em>One email, one account.</em> If we have the same email
                    address on file for more than one parent or guardian, only
                    one account will be able to be created with it. Multiple
                    accounts require a different email addresses for each parent
                    or guardian. See your school office to update
                    email&nbsp;addresses.
                  </small>
                </li>
                <li style={step}>
                  <span style={stepTitle}>
                    Check your email for the invitation and click the{" "}
                    <em>Accept&nbsp;Invitation</em>&nbsp;link
                  </span>
                  <br />
                  Look in your spam folder if you don't see it immediately. The
                  sender will be{" "}
                  <em>Microsoft Invitations &lt;invites@microsoft.com&gt;</em>
                  <Grid container sx={stepImgTip}>
                    <Grid item xs={12} md={6}>
                      <Box sx={imageWrapperStyles}>
                        <img
                          src={`${blob}reg-step-2.png`}
                          alt="Step 2 screenshot"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Paper elevation={5} sx={commonPaddingStyles}>
                        <Typography component="p" sx={smallPaddingStyle}>
                          <strong style={notReceivedTitle}>
                            Didn't get the email?
                          </strong>
                          <br />
                          Check with the school's main office to verify or
                          update your email address in our Student
                          Information&nbsp;System.
                          <br />
                          <br />
                          Also, check your email's junk&nbsp;folder.
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </li>
                <li style={step}>
                  <span style={stepTitle}>Create your Microsoft account</span>
                  <br />
                  If your email address is already associated with a Microsoft
                  account, you'll enter your password and skip to the
                  next&nbsp;step.
                  <ol>
                    <li style={subSteps}>Create a password</li>
                    <li style={subSteps}>
                      Enter the verification code Microsoft sent. (You'll need
                      to check your email a second time. Do that in a new tab so
                      you don't lose this page where you can enter
                      the&nbsp;code.)
                      <br />
                    </li>
                    <li style={subSteps}>
                      As a safety measure, you may also need to enter some
                      characters from an image to show it's not a computer
                      program creating the&nbsp;account.
                    </li>
                  </ol>
                  <Box sx={imageWrapperStyles}>
                    <img
                      src={`${blob}reg-step-3b.png`}
                      alt="Step 3b screenshot"
                    />
                  </Box>
                </li>
                <li style={step}>
                  <span style={stepTitle}>Accept the permissions</span> so that
                  TPS can use your Microsoft account to log you in
                  to&nbsp;Compass.
                  <br />
                  <Box sx={imageWrapperStyles}>
                    <img
                      src={`${blob}reg-step-4.png`}
                      alt="Step 4 screenshot"
                    />
                  </Box>
                </li>
                <li style={step}>
                  <span style={stepTitle}>
                    Click the <em>Sign In</em> button
                  </span>
                  <br />
                  on the <Link href="/">Compass&nbsp;homepage</Link>
                  <span className="printOnly">
                    {" "}
                    at{" "}
                    <span style={{ color: "#222" }}>
                      https://compass.tpsapps.org
                    </span>
                  </span>
                  .
                </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
