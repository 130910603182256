//#region Imports
import React from "react";
import { Skeleton, Grid, Divider } from "@mui/material";
import { faRectangleHistoryCircleUser } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCompassProgramHistory } from "../../services/hooks/programsHook";
import { useGetDsaHistory } from "../../services/hooks/dsaHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";
import DsaListItem from "./DsaListItem";
import DsaProgramHeader from "./DsaProgramHeader";
import NoDataMessage from "./NoDataMessage";
//#endregion

export default function ActivitiesHistory() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();

  const query = useCompassProgramHistory(activeStudent?.studentId);
  const programs = query?.data?.data;

  const dsaHistoryQuery = useGetDsaHistory(activeStudent?.studentId);
  const dsaHistory = dsaHistoryQuery?.data?.data;

  //#region Render
  return (
    <>
      {query.isError && (
        <Error message="There was an issue retrieving program history." />
      )}
      {query.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!query.isLoading &&
        programs?.length === 0 &&
        dsaHistory?.length === 0 && (
          <NoDataMessage
            message={`If ${
              user?.role === "guardian"
                ? `${activeStudent?.firstName} was`
                : "you were"
            } previously enrolled in any activities that have now ended,
                  you'd see a list of them here...`}
            icon={faRectangleHistoryCircleUser}
          />
        )}
      {!query.isLoading &&
        programs?.length > 0 &&
        programs?.map((program, index) => {
          return (
            <ActivityListItem
              program={program}
              pid={program?.programId}
              key={index}
            />
          );
        })}
      {!query.isLoading && programs?.length > 0 && dsaHistory?.length > 0 && (
        <Divider variant="middle" sx={{ my: 6 }} />
      )}
      {!query?.isLoading && dsaHistory?.length > 0 && (
        <>
          <DsaProgramHeader />
          <Grid container spacing={2}>
            {dsaHistory?.map((dsa, index) => {
              return (
                <DsaListItem
                  key={index}
                  partner={dsa.partner}
                  activeStudent={activeStudent}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
  //#endregion
}
