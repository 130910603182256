import React from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import BadgesAbout from "./BadgesAbout";
import BadgesEarned from "../images/BadgesEarned";

export default function BadgesIntro(props) {
  const { earned } = props;
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const aboutWrapStyles = {
    textAlign: "right",
  };
  const flexCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  const badgeWrap = {
    position: "relative",
    overflow: "hidden",
  };
  const badgeScore = {
    ...flexCenter,
    position: "absolute",
    width: 220,
    height: 220,
    fontWeight: 800,
    color: theme?.palette?.badges?.number,
    fontSize: largeScreen ? "2.5rem !important" : "2rem !important",
    zIndex: 2,
  };
  const badgeImage = {
    ...flexCenter,
    position: "relative",
    width: 220,
    height: 220,
    "& svg": {
      position: "absolute",
      width: 220,
      zIndex: 1,
    },
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="lede" component="p">
          All kinds of achievements can earn you a badge. The harder the
          achievement, the more points the badge is worth.
        </Typography>
        <br />
      </Grid>
      <Grid item xs={7} md={4} sx={flexCenter}>
        <Box sx={badgeWrap}>
          <Typography sx={badgeScore}>{!!earned && earned}</Typography>
          <Box sx={badgeImage}>
            <BadgesEarned />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={5} md={2} sx={aboutWrapStyles}>
        <BadgesAbout />
      </Grid>
    </Grid>
  );
}
