//#region IMPORTS
import React, { useState } from "react";
import {
  Divider,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { faFaceConfused } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useUser } from "../../services/contexts/userContext";
import { useCompassEligiblePrograms } from "../../services/hooks/programsHook";
import { useGetEligibleDsaPartners } from "../../services/hooks/dsaHook";
import { useCompassEnrolledPrograms } from "../../services/hooks/programsHook";
import { useGetWaitlistByStudentId } from "../../services/hooks/waitlistHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";
import DsaListItem from "./DsaListItem";
import DsaProgramHeader from "./DsaProgramHeader";
import NoDataMessage from "./NoDataMessage";
//#endregion

export default function ActivitiesEligible() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();
  const [viewAllLocations, setViewAllLocations] = useState(false);

  const eloQuery = useCompassEligiblePrograms(activeStudent?.studentId);
  const eloPrograms = eloQuery?.data?.data;

  const enrolledQuery = useCompassEnrolledPrograms(activeStudent?.studentId);
  const enrolled = enrolledQuery?.data?.data;

  const dsaQuery = useGetEligibleDsaPartners(activeStudent?.studentId);
  const dsaPrograms = dsaQuery?.data?.data;

  const waitlistQuery = useGetWaitlistByStudentId(activeStudent?.studentId);
  const waitlist = waitlistQuery?.data?.data;

  const filteredPrograms = viewAllLocations
    ? eloPrograms
    : eloPrograms?.filter(
        (program) => program.locationId === activeStudent?.locationId
      );

  const handleToggleChange = (event) => {
    setViewAllLocations(event.target.checked);
  };

  //MARK: RENDER
  return (
    <>
      {/* MARK: both
       */}
      {/* if ELOs and DSAs are both loading, show 2 Skeletons */}
      {eloQuery.isLoading && dsaQuery.isLoading ? (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      ) : (
        /* else if both ELOs and DSAs are loaded but empty, show a message */
        eloPrograms?.length === 0 &&
        dsaPrograms?.length === 0 && (
          <NoDataMessage
            message={`Sorry, looks like there are no activities open for ${
              user?.role === "guardian" ? activeStudent?.firstName : "you"
            } right now.`}
            icon={faFaceConfused}
          />
        )
      )}

      {/* MARK: elo
       */}
      {eloQuery.isLoading ? (
        <Skeleton
          height={175}
          variant="rect"
          sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
        />
      ) : eloQuery.isError ? (
        <Error message="There was an issue retrieving eligible activities for Beyond the Bell or Club B." />
      ) : (
        eloPrograms?.length > 0 && (
          <>
            <Typography variant="lede" component="h2" sx={{ mt: 2 }}>
              {activeStudent?.isSecondary ? "Club B" : "Beyond the Bell"}{" "}
              Activities{" "}
              {!viewAllLocations
                ? !!activeStudent?.school
                  ? `at ${activeStudent?.school}`
                  : "at this student's school"
                : ""}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={viewAllLocations}
                  onChange={handleToggleChange}
                  name="viewAllLocations"
                />
              }
              label={
                <Typography variant="body2">
                  View activities at all locations
                </Typography>
              }
            />
            {filteredPrograms?.length === 0 ? (
              <NoDataMessage
                message={`Sorry, there are no eligible activities currently available at ${
                  !!activeStudent?.school
                    ? activeStudent?.school
                    : "this student's school"
                }.`}
                icon={faFaceConfused}
              />
            ) : (
              filteredPrograms?.map((p, index) => {
                const enrolledProgram = enrolled?.find(
                  (e) => e?.programId === p?.programId
                );
                const isEnrolled = !!enrolledProgram;
                const matchingWaitlist = waitlist?.find(
                  (w) => w?.programId === p?.programId
                );
                return (
                  <ActivityListItem
                    key={index}
                    program={p}
                    isEnrolled={isEnrolled}
                    matchingProgram={enrolledProgram}
                    matchingWaitlist={matchingWaitlist}
                    viewAllLocations={viewAllLocations}
                    activeStudentLocationId={activeStudent?.locationId}
                  />
                );
              })
            )}
          </>
        )
      )}

      {/* divider */}
      {!eloQuery?.isLoading &&
        eloPrograms?.length > 0 &&
        dsaPrograms?.length > 0 && <Divider variant="middle" sx={{ my: 6 }} />}

      {/* MARK: dsa
       */}
      {dsaQuery.isLoading ? (
        <Skeleton
          height={175}
          variant="rect"
          sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
        />
      ) : dsaQuery.isError ? (
        <Error message="There was an issue retrieving eligible data-sharing programs." />
      ) : (
        dsaPrograms?.length > 0 && (
          <>
            <DsaProgramHeader />
            <Grid container spacing={2}>
              {dsaPrograms?.map((partner, index) => {
                return (
                  <DsaListItem
                    key={index}
                    partner={partner}
                    activeStudent={activeStudent}
                  />
                );
              })}
            </Grid>
          </>
        )
      )}
    </>
  );
}
