//#region Imports
import React, { useMemo } from "react";
import {
  Badge,
  Card,
  CardActionArea,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarRange } from "@fortawesome/pro-solid-svg-icons";
import { formatDate } from "../../services/utilities";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { truncateText } from "../../services/utilities";
//#endregion

export default function DsaListItem(props) {
  const { partner, key, activeStudent } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  var registrant = useMemo(() => {
    return partner?.registrants?.find(
      (r) => r.studentId === activeStudent?.studentId
    );
  }, [partner, activeStudent?.studentId]);

  const dsaEndDate = dayjs(partner?.dsaEndDate);

  const renderTitleWithBadge = (title) => {
    const today = dayjs(dayjs().format("YYYY-MM-DD"));
    const titleArray =
      partner?.dsaTitle.trim().length > 0
        ? partner?.dsaTitle.split(" ")
        : partner?.name.split(" ");

    return (
      <Typography variant="h4" component="h2" color="primary" sx={titleStyles}>
        {titleArray?.map((word, index) => {
          if (index !== titleArray.length - 1) return `${word + " "}`;
          return null;
        })}
        {registrant !== undefined &&
        today <= dsaEndDate &&
        registrant.withdrawalDate === null ? (
          <span
            style={{
              display: "inline-block",
              paddingRight: "6rem",
              minWidth: "200px",
            }}
          >
            <Badge
              badgeContent={
                <span style={{ whiteSpace: "nowrap" }}>
                  <strong>Enrolled</strong>
                </span>
              }
              color={"secondary"}
            >
              <span>{titleArray[titleArray?.length - 1]}</span>
            </Badge>
          </span>
        ) : (
          <span>{titleArray[titleArray?.length - 1]}</span>
        )}
      </Typography>
    );
  };

  //#region Styles
  const cardStyles = {
    my: theme.spacing(2),
  };
  const titleStyles = {
    mt: "0.75rem",
    "& span > span": {
      display: "flex",
      alignItems: "flex-end",
    },
  };
  const categoryStyles = {
    position: "absolute",
    backgroundColor: alpha(theme?.palette?.neutral?.darkest ?? "#777", 0.5),
    color: theme?.palette?.neutral?.light,
    py: "0.25rem",
    pl: "0.5rem",
    pr: "0.375rem",
    borderBottomLeftRadius: "0.5rem",
    right: 0,
    top: 0,
  };
  const detailIconStyles = {
    width: "1.5rem",
  };
  //#endregion

  //#region Render
  return (
    <Grid item xs={12} sm={6} key={key}>
      <Card sx={cardStyles}>
        <CardActionArea
          sx={commonStyles.paperPadding}
          onClick={() => {
            navigate(`/activities/dsa/${partner?.partnerId}`);
          }}
        >
          <Typography variant="caption" component="div" sx={categoryStyles}>
            Data Sharing
          </Typography>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item xs={12}>
              {renderTitleWithBadge(partner?.dsaTitle ?? partner?.name)}
              <Typography
                variant="subtitle1"
                component="p"
                style={{ maxWidth: "100%" }}
              >
                {truncateText(partner?.dsaDescription, 200)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <FontAwesomeIcon
                  icon={faCalendarRange}
                  style={detailIconStyles}
                />{" "}
                <strong>Start &amp; End&nbsp;Dates</strong>:{" "}
                {formatDate(partner.dsaStartDate)}&nbsp;-&nbsp;
                {formatDate(partner.dsaEndDate)}
              </Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Grid>
  );
  //#endregion
}
