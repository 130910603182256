import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGetImage } from "../apiService";

export function useStudentPhoto(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `StudentPhoto/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGetImage(token, url);
    },
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      cacheTime: 1000 * 60 * 60, // 1 hour
      retry: 5,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting student photo. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
