import { useTheme } from "@mui/material/styles";

export default function CompassLogo(props) {
  let { tagline, scale, alt } = props;
  if (tagline === null || tagline === undefined || tagline === "") {
    tagline = true;
  }
  if (scale === null || scale === undefined || scale === "") {
    scale = 1;
  }
  if (alt === null || alt === undefined || alt === "") {
    alt = false;
  }
  const width = 276 * scale;
  const height = 100 * scale;

  const theme = useTheme();

  const textColor = alt
    ? theme?.palette.nav?.main
    : theme?.palette.nav?.logoText;

  return (
    <svg
      // Illustrator svg attributes unsupported in React
      // version="1.1"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      //  xml:space="preserve"
      id="Layer_1"
      x="0px"
      y="0px"
      width={`${width}px`}
      height={`${height}px`}
      viewBox="0 0 276 100"
      enableBackground="new 0 0 276 100"
      fill="none"
      style={{
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      }}
    >
      <title>Compass</title>
      <g id="Layer_1_1_">
        <g id="hashes">
          <polygon
            fill={theme?.palette.nav?.hashes}
            points="138.041,14.328 131.995,3.748 138.222,2.238 144.087,3.93     "
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M173.831,20.132l4.05-6.953l-3.144-1.813l-3.989,6.952C171.775,18.863,172.804,19.467,173.831,20.132z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M157.628,12.817l2.057-7.739l-3.506-0.967l-2.056,7.678C155.271,12.152,156.48,12.453,157.628,12.817z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M187.614,31.376l5.804-5.803l-2.539-2.539l-5.743,5.743C185.922,29.623,186.769,30.53,187.614,31.376z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M91.369,28.838l-5.743-5.744l-2.539,2.539l5.805,5.804C89.677,30.53,90.523,29.623,91.369,28.838z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M105.698,18.258l-3.99-6.952l-3.144,1.813l4.05,6.953C103.642,19.467,104.67,18.863,105.698,18.258z"
          />
          <path
            fill={theme?.palette.nav?.hashes}
            d="M122.323,11.849l-2.055-7.678l-3.508,0.968l2.057,7.738C119.966,12.453,121.175,12.152,122.323,11.849z"
          />
        </g>
        <g id="circle_1_">
          <path
            fill={theme?.palette.nav?.arc}
            d="M138.222,5.441c25.634,0,48.244,13.481,61,33.674c1.391-1.148,2.9-2.177,4.533-3.022
      C189.972,14.448,165.79,0,138.222,0c-27.567,0-51.75,14.448-65.532,36.092c1.632,0.846,3.144,1.874,4.534,3.022
      C89.979,18.922,112.591,5.441,138.222,5.441z"
          />
        </g>
        <g id="text">
          <g>
            <path
              fill={textColor}
              d="M169.175,48.606c-0.726-1.813-1.691-3.386-3.021-4.775c-1.27-1.391-2.781-2.6-4.534-3.507
        c-1.753-0.847-3.688-1.391-5.803-1.391h-18.802v42.922h12.091V69.16h7.013c2.115,0,3.99-0.544,5.742-1.391
        c1.754-0.847,3.266-1.996,4.475-3.386c1.209-1.391,2.176-3.022,2.902-4.837c0.665-1.813,1.027-3.627,1.027-5.562
        C170.263,52.173,169.901,50.359,169.175,48.606z M157.326,57.735c-0.604,0.665-1.33,1.147-1.994,1.147h-6.228V49.21h5.925
        c0.725,0,1.451,0.423,2.176,1.089c0.726,0.664,1.088,1.994,1.088,3.747C158.293,55.74,157.992,57.07,157.326,57.735z"
            />
            <polygon
              fill={textColor}
              points="119.481,81.855 131.572,81.855 131.572,38.933 118.636,38.933 109.325,59.125 100.075,38.933 
        87.44,38.933 87.44,81.855 98.927,81.855 98.927,59.427 106.181,75.205 112.47,75.205 119.481,59.427       "
            />
            <path
              fill={textColor}
              d="M0.506,60.213c0-2.6,0.484-5.199,1.451-7.738c0.967-2.539,2.418-4.837,4.292-6.831
        c1.874-1.995,4.171-3.628,6.892-4.897c2.721-1.27,5.804-1.874,9.25-1.874c4.171,0,7.738,0.847,10.761,2.6
        c3.023,1.692,5.32,3.99,6.832,6.831l-8.947,6.349c-0.423-1.027-0.907-1.935-1.633-2.6c-0.664-0.665-1.39-1.208-2.176-1.632
        c-0.786-0.423-1.632-0.665-2.539-0.846c-0.846-0.182-1.693-0.242-2.54-0.242c-1.692,0-3.143,0.363-4.413,1.028
        c-1.209,0.664-2.237,1.511-3.023,2.599c-0.786,1.088-1.391,2.297-1.753,3.628c-0.363,1.33-0.604,2.721-0.604,4.05
        c0,1.511,0.242,2.902,0.665,4.292c0.423,1.393,1.088,2.602,1.935,3.629c0.847,1.026,1.874,1.873,3.144,2.479
        c1.209,0.605,2.6,0.907,4.111,0.907c0.786,0,1.632-0.121,2.479-0.242c0.846-0.183,1.692-0.483,2.418-0.906
        c0.786-0.424,1.511-0.968,2.116-1.633s1.088-1.45,1.512-2.418l9.612,5.743c-0.665,1.572-1.631,2.962-2.901,4.231
        c-1.33,1.27-2.781,2.298-4.474,3.145s-3.446,1.511-5.38,1.935c-1.935,0.422-3.809,0.664-5.623,0.664
        c-3.204,0-6.106-0.664-8.766-1.934c-2.66-1.271-4.897-2.963-6.832-5.019c-1.935-2.116-3.385-4.475-4.474-7.135
        C1.05,65.654,0.506,62.934,0.506,60.213z"
            />
            <path
              fill={textColor}
              d="M61.808,82.4c-3.266,0-6.228-0.604-8.888-1.873c-2.659-1.271-4.957-2.902-6.832-4.897
        c-1.874-2.056-3.386-4.354-4.413-7.013c-1.028-2.6-1.572-5.32-1.572-8.102c0-2.841,0.544-5.562,1.632-8.162
        c1.088-2.6,2.6-4.896,4.534-6.892c1.935-1.995,4.292-3.567,6.953-4.776s5.623-1.814,8.767-1.814c3.265,0,6.228,0.604,8.887,1.874
        c2.66,1.27,4.958,2.902,6.832,4.958c1.874,2.056,3.325,4.414,4.413,7.013c1.028,2.6,1.512,5.319,1.512,8.041
        c0,2.842-0.544,5.563-1.633,8.161c-1.087,2.601-2.599,4.897-4.533,6.892c-1.935,1.996-4.232,3.627-6.893,4.838
        C67.913,81.796,65.013,82.4,61.808,82.4z M52.074,60.636c0,1.451,0.183,2.841,0.604,4.231c0.422,1.331,1.027,2.54,1.813,3.627
        c0.786,1.027,1.813,1.874,3.022,2.539s2.721,0.968,4.414,0.968c1.691,0,3.144-0.303,4.412-1.028
        c1.271-0.664,2.299-1.512,3.084-2.6s1.391-2.297,1.752-3.688c0.363-1.391,0.605-2.781,0.605-4.172
        c0-1.451-0.182-2.841-0.605-4.171c-0.423-1.33-1.026-2.539-1.813-3.567c-0.848-1.027-1.875-1.873-3.083-2.478
        c-1.208-0.604-2.66-0.968-4.292-0.968c-1.693,0-3.145,0.302-4.414,0.968c-1.209,0.664-2.236,1.511-3.023,2.599
        c-0.785,1.089-1.39,2.298-1.813,3.627C52.257,57.855,52.074,59.246,52.074,60.636z"
            />
            <path
              fill={textColor}
              d="M179.634,39.115h10.762l14.873,42.922h-11.972l-2.6-8.402h-11.426l-2.539,8.402h-12.03L179.634,39.115z
         M188.823,65.775l-3.809-13.301l-3.99,13.301H188.823z"
            />
            <path
              fill={textColor}
              d="M234.044,53.14c-1.21-0.726-2.479-1.391-3.749-1.934c-1.088-0.485-2.298-0.907-3.688-1.391
        c-1.33-0.423-2.66-0.604-3.931-0.604c-1.026,0-1.873,0.182-2.479,0.484c-0.604,0.302-0.968,0.846-0.968,1.631
        c0,0.544,0.182,1.027,0.545,1.391c0.362,0.362,0.905,0.665,1.571,0.967c0.664,0.304,1.512,0.604,2.539,0.907
        c0.967,0.302,2.115,0.604,3.325,1.027c1.994,0.604,3.748,1.27,5.382,1.995c1.569,0.726,2.961,1.572,4.108,2.6
        c1.148,0.968,1.995,2.177,2.601,3.627c0.604,1.391,0.906,3.145,0.906,5.139c0,2.599-0.482,4.716-1.449,6.529
        c-0.97,1.813-2.178,3.143-3.75,4.23c-1.57,1.027-3.324,1.813-5.259,2.297c-1.936,0.484-3.93,0.666-5.924,0.666
        c-1.573,0-3.206-0.121-4.838-0.362c-1.633-0.242-3.324-0.604-4.957-1.028c-1.633-0.424-3.204-0.967-4.715-1.571
        c-1.513-0.604-2.902-1.27-4.232-2.056l5.078-10.338c1.39,0.907,2.901,1.694,4.412,2.359c1.271,0.604,2.722,1.148,4.414,1.632
        c1.632,0.483,3.325,0.726,5.019,0.726c1.27,0,2.177-0.183,2.721-0.484c0.484-0.361,0.785-0.784,0.785-1.389
        c0-0.605-0.241-1.09-0.785-1.572c-0.483-0.424-1.209-0.786-2.116-1.088c-0.906-0.303-1.935-0.665-3.022-0.969
        c-1.147-0.302-2.357-0.726-3.627-1.147c-1.875-0.665-3.507-1.33-4.896-2.116c-1.391-0.726-2.479-1.572-3.385-2.539
        c-0.907-0.968-1.513-1.996-1.995-3.204c-0.483-1.209-0.604-2.6-0.604-4.171c0-2.357,0.424-4.475,1.27-6.288
        c0.848-1.813,2.057-3.325,3.566-4.535c1.512-1.209,3.204-2.115,5.078-2.72c1.936-0.604,3.93-0.967,6.106-0.967
        c1.569,0,3.083,0.182,4.594,0.484c1.512,0.302,2.901,0.666,4.354,1.149c1.391,0.483,2.659,0.967,3.868,1.511
        c1.21,0.544,2.298,1.089,3.265,1.512L234.044,53.14z"
            />
            <path
              fill={textColor}
              d="M269.833,53.14c-1.209-0.726-2.479-1.391-3.748-1.934c-1.088-0.485-2.298-0.907-3.688-1.391
        c-1.33-0.423-2.659-0.604-3.93-0.604c-1.027,0-1.875,0.182-2.479,0.484c-0.604,0.302-0.967,0.846-0.967,1.631
        c0,0.544,0.182,1.027,0.544,1.391c0.361,0.362,0.906,0.665,1.572,0.967c0.664,0.304,1.511,0.604,2.539,0.907
        c0.967,0.302,2.115,0.604,3.324,1.027c1.995,0.604,3.748,1.27,5.381,1.995c1.57,0.726,2.961,1.572,4.111,2.6
        c1.146,0.968,1.994,2.177,2.6,3.627c0.604,1.391,0.906,3.145,0.906,5.139c0,2.599-0.484,4.716-1.451,6.529
        c-0.968,1.813-2.177,3.143-3.748,4.23c-1.571,1.027-3.326,1.813-5.26,2.297c-1.935,0.484-3.93,0.666-5.925,0.666
        c-1.572,0-3.204-0.121-4.837-0.362c-1.632-0.242-3.325-0.604-4.957-1.028c-1.632-0.424-3.205-0.967-4.716-1.571
        s-2.901-1.27-4.231-2.056l5.078-10.338c1.391,0.907,2.902,1.694,4.413,2.359c1.271,0.604,2.722,1.148,4.413,1.632
        c1.633,0.483,3.324,0.726,5.018,0.726c1.271,0,2.178-0.183,2.723-0.484c0.482-0.361,0.785-0.784,0.785-1.389
        c0-0.605-0.241-1.09-0.785-1.572c-0.484-0.424-1.209-0.786-2.116-1.088c-0.907-0.303-1.937-0.665-3.022-0.969
        c-1.147-0.302-2.357-0.726-3.627-1.147c-1.875-0.665-3.508-1.33-4.896-2.116c-1.391-0.726-2.479-1.572-3.387-2.539
        c-0.906-0.968-1.512-1.996-1.994-3.204c-0.484-1.209-0.604-2.6-0.604-4.171c0-2.357,0.423-4.475,1.271-6.288
        c0.847-1.813,2.056-3.325,3.565-4.535c1.513-1.209,3.205-2.115,5.079-2.72c1.934-0.604,3.93-0.967,6.105-0.967
        c1.571,0,3.082,0.182,4.594,0.484c1.513,0.302,2.902,0.666,4.354,1.149c1.392,0.483,2.659,0.967,3.869,1.511
        c1.209,0.544,2.299,1.089,3.266,1.512L269.833,53.14z"
            />
          </g>
        </g>
        <g id="arrow">
          <g>
            <polygon
              fill={theme?.palette.nav?.arrowLight}
              points="149.104,81.855 149.224,75.992 128.188,8.585 127.22,79.197 128.066,81.855      "
            />
            <polygon
              fill={theme?.palette.nav?.arrowDark}
              points="138.827,81.855 128.188,8.585 127.22,79.197 128.066,81.855       "
            />
          </g>
        </g>
      </g>
      {tagline && (
        <g enableBackground="new    ">
          <path
            fill={textColor}
            d="M6.24,99.75V89.72H2.296v-1.87h10.098v1.87H8.45v10.03H6.24z"
          />
          <polygon
            fill={textColor}
            points="27.524,87.85 27.524,92.763 21.37,92.763 21.37,87.85 19.16,87.85 19.16,99.75 21.37,99.75 
    21.37,94.65 27.524,94.65 27.524,99.75 29.734,99.75 29.734,87.85   "
          />
          <polygon
            fill={textColor}
            points="40.24,97.897 40.24,94.616 45.986,94.616 45.986,92.797 40.24,92.797 40.24,89.703 46.717,89.703 
    46.717,87.85 38.03,87.85 38.03,99.75 46.955,99.75 46.955,97.897   "
          />
          <path
            fill={textColor}
            d="M66.726,99.75V89.72h-3.944v-1.87h10.098v1.87h-3.944v10.03H66.726z"
          />
          <path
            fill={textColor}
            d="M88.996,89.822c-0.408-0.635-0.989-1.122-1.743-1.462c-0.754-0.341-1.658-0.511-2.711-0.511h-4.896v11.9
    h2.21v-3.434h2.686c1.054,0,1.958-0.17,2.711-0.511c0.753-0.34,1.334-0.827,1.743-1.462c0.408-0.635,0.612-1.388,0.612-2.261
    S89.403,90.457,88.996,89.822z M86.641,93.825c-0.493,0.414-1.227,0.621-2.201,0.621h-2.584V89.72h2.584
    c0.975,0,1.708,0.204,2.201,0.612c0.493,0.408,0.74,0.992,0.74,1.751C87.38,92.831,87.134,93.412,86.641,93.825z"
          />
          <path
            fill={textColor}
            d="M100.776,99.92c-0.93,0-1.819-0.133-2.669-0.399c-0.85-0.267-1.524-0.609-2.023-1.028l0.765-1.717
    c0.476,0.374,1.065,0.686,1.768,0.935s1.422,0.374,2.159,0.374c0.623,0,1.127-0.068,1.513-0.204s0.669-0.32,0.85-0.553
    c0.181-0.231,0.272-0.495,0.272-0.79c0-0.362-0.13-0.654-0.391-0.876c-0.261-0.221-0.598-0.396-1.011-0.526
    c-0.414-0.131-0.873-0.252-1.377-0.366c-0.504-0.112-1.009-0.246-1.513-0.399c-0.504-0.152-0.963-0.351-1.377-0.595
    c-0.414-0.243-0.751-0.569-1.011-0.978c-0.261-0.408-0.391-0.929-0.391-1.563c0-0.646,0.172-1.238,0.519-1.777
    c0.345-0.538,0.873-0.969,1.581-1.292c0.708-0.322,1.606-0.484,2.694-0.484c0.714,0,1.422,0.091,2.125,0.272
    c0.703,0.182,1.314,0.441,1.836,0.782l-0.697,1.717c-0.533-0.317-1.083-0.553-1.649-0.706c-0.566-0.152-1.111-0.229-1.632-0.229
    c-0.612,0-1.108,0.074-1.487,0.222c-0.38,0.147-0.658,0.34-0.833,0.578c-0.176,0.237-0.264,0.51-0.264,0.815
    c0,0.363,0.127,0.654,0.382,0.876c0.255,0.221,0.589,0.394,1.003,0.519c0.414,0.124,0.875,0.246,1.385,0.365
    c0.51,0.119,1.017,0.252,1.521,0.399s0.963,0.34,1.377,0.578s0.748,0.561,1.003,0.969c0.255,0.408,0.383,0.924,0.383,1.547
    c0,0.635-0.173,1.222-0.519,1.76s-0.875,0.969-1.589,1.292C102.765,99.759,101.864,99.92,100.776,99.92z"
          />
          <polygon
            fill={textColor}
            points="131.359,89.703 131.359,87.85 122.672,87.85 122.672,99.75 124.882,99.75 124.882,95.194 
    130.628,95.194 130.628,93.324 124.882,93.324 124.882,89.703   "
          />
          <path
            fill={textColor}
            d="M146.999,99.75h2.312l-5.371-11.9h-2.176l-5.355,11.9h2.277l1.179-2.754h5.952L146.999,99.75z
     M140.606,95.262l2.229-5.207l2.236,5.207H140.606z"
          />
          <polygon
            fill={textColor}
            points="168.945,99.75 168.929,87.85 167.109,87.85 162.465,95.734 157.742,87.85 155.924,87.85 
    155.924,99.75 158.031,99.75 158.031,91.968 161.941,98.39 162.928,98.39 166.84,91.865 166.854,99.75  "
          />
          <path fill={textColor} d="M177.259,99.75v-11.9h2.21v11.9H177.259z" />
          <path
            fill={textColor}
            d="M187.781,99.75v-11.9h2.211v10.03h6.221v1.87H187.781z"
          />
          <polygon
            fill={textColor}
            points="211.615,87.85 209.439,87.85 205.924,93.639 202.436,87.85 200.072,87.85 204.748,95.587 
    204.748,99.75 206.957,99.75 206.957,95.558  "
          />
          <path
            fill={textColor}
            d="M236.859,99.75h2.313l-5.372-11.9h-2.176l-5.354,11.9h2.277l1.179-2.754h5.951L236.859,99.75z
     M230.467,95.262l2.229-5.207l2.236,5.207H230.467z"
          />
          <path
            fill={textColor}
            d="M255.135,89.822c-0.408-0.635-0.988-1.122-1.742-1.462c-0.754-0.341-1.658-0.511-2.711-0.511h-4.896v11.9
    h2.21v-3.434h2.687c1.053,0,1.957-0.17,2.711-0.511c0.754-0.34,1.334-0.827,1.742-1.462s0.612-1.388,0.612-2.261
    S255.543,90.457,255.135,89.822z M252.78,93.825c-0.493,0.414-1.227,0.621-2.201,0.621h-2.584V89.72h2.584
    c0.975,0,1.708,0.204,2.201,0.612s0.739,0.992,0.739,1.751C253.52,92.831,253.273,93.412,252.78,93.825z"
          />
          <path
            fill={textColor}
            d="M272.407,89.822c-0.408-0.635-0.989-1.122-1.743-1.462c-0.754-0.341-1.657-0.511-2.711-0.511h-4.896v11.9
    h2.211v-3.434h2.686c1.054,0,1.957-0.17,2.711-0.511c0.754-0.34,1.335-0.827,1.743-1.462c0.407-0.635,0.612-1.388,0.612-2.261
    S272.814,90.457,272.407,89.822z M270.053,93.825c-0.493,0.414-1.227,0.621-2.201,0.621h-2.584V89.72h2.584
    c0.975,0,1.708,0.204,2.201,0.612c0.492,0.408,0.739,0.992,0.739,1.751C270.792,92.831,270.545,93.412,270.053,93.825z"
          />
        </g>
      )}
    </svg>
  );
}
