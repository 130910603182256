import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Grid, Button, Paper, Typography } from "@mui/material";
import { useGetInterestsAndCategories } from "../../services/hooks/interestsHook";

export default function InterestsCategories() {
  const theme = useTheme();
  const navigate = useNavigate();
  const interestsResponse = useGetInterestsAndCategories();
  const categories = useMemo(() => {
    return interestsResponse?.data?.data?.interestsCategories;
  }, [interestsResponse]);

  const commonPaddingStyle = {
    padding: theme?.spacing(2),
    paddingBottom: theme?.spacing(1.5),
  };
  const mainHeadingStyle = {
    background: `${theme?.palette?.background?.neutral} !important`,
    ...commonPaddingStyle,
  };

  return (
    <Paper>
      <Paper square sx={mainHeadingStyle} elevation={0}>
        <Typography component="h3" variant="h4">
          Select a category
        </Typography>
      </Paper>
      {categories?.length > 0 && (
        <Grid container spacing={3} sx={commonPaddingStyle}>
          {categories?.map((category, id) => {
            return (
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  fullWidth
                  name={category}
                  id={id}
                  onClick={() =>
                    navigate(`/interests/${encodeURIComponent(category)}`)
                  }
                >
                  {category}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Paper>
  );
}
