import React from "react";
import { Grid, Divider, useTheme, useMediaQuery } from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import Feed from "../common/Feed";
import {
  useGetUpcomingParentFeed,
  useGetUpcomingStudentFeed,
  useGetParentFeed,
  useGetStudentFeed,
} from "../../services/hooks/feedHook";
import HomeSidebar from "../common/HomeSidebar";
import useHero from "../../services/hooks/heroHook";
import { ScrollToTopOnMount } from "../../services/utilities";
import HomeBirthday from "../common/HomeBirthday";

export default function Home() {
  const { user } = useUser();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const hero = useHero();

  const parentUpcomingQuery = useGetUpcomingParentFeed(4);
  const parentHistoricalQuery = useGetParentFeed(20);

  const studentUpcomingQuery = useGetUpcomingStudentFeed(4);
  const studentHistoricalQuery = useGetStudentFeed(20);

  // Styles
  const homeWrapperStyles = {
    flexDirection: largeScreen ? "row-reverse" : "colomn",
  };

  return (
    <>
      <ScrollToTopOnMount />
      <HomeBirthday />
      <Grid container spacing={3} sx={homeWrapperStyles}>
        <Grid item xs={12}>
          {hero}
          <Divider sx={{ mt: 3 }} />
        </Grid>
        <Grid item xs={12} md={4}>
          <HomeSidebar />
        </Grid>
        <Grid item xs={12} md={8}>
          {user?.role === "guardian" && (
            <Feed
              upcomingQuery={parentUpcomingQuery}
              historicalQuery={parentHistoricalQuery}
            />
          )}
          {user?.role === "student" && (
            <Feed
              upcomingQuery={studentUpcomingQuery}
              historicalQuery={studentHistoricalQuery}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
