import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import {
  GetAccessToken,
  AppsDelete,
  AppsGet,
  AppsPatch,
  AppsPost,
} from "../apiService";

export function useGetCompassActiveGoals() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `studentGoals/GetCompassActiveGoals`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting active goals. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetCompassCompletedGoals() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `studentGoals/GetCompassCompletedGoals`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting completed goals. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetStudentGoalByGoalId(goalId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `studentGoals/GetStudentGoalByGoalId/${goalId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: goalId !== undefined,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting goal. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useUpsertStudentGoal(studentGoal, onSuccess) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsPost(
        token,
        "studentGoals/UpsertStudentGoal",
        studentGoal
      );
    },
    {
      onSuccess: async (response) => {
        await queryClient.invalidateQueries((key) =>
          key.startsWith("studentGoals/")
        );
        snackbar.enqueueSnackbar("Goal saved", {
          variant: "success",
        });
        onSuccess(response.data);
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving your goal. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function usePatchGoalFavorite(studentGoalId) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const mutation = useMutation(
    async (isFavorite) => {
      return await AppsPatch(
        token,
        `studentGoals/PatchGoalFavorite/${studentGoalId}/${isFavorite}`,
        null
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          `studentGoals/GetCompassActiveGoals`,
          (queryData) => {
            let foundIndex = queryData?.data?.findIndex(
              (goal) => goal.studentGoalId === data?.data?.studentGoalId
            );
            if (foundIndex >= 0) {
              queryData.data[foundIndex].isFavorite = data?.data?.isFavorite;
            }
            return queryData;
          }
        );
        queryClient.setQueryData(
          `studentGoals/GetCompassCompletedGoals`,
          (queryData) => {
            let foundIndex = queryData?.data?.findIndex(
              (goal) => goal.studentGoalId === data?.data?.studentGoalId
            );
            if (foundIndex >= 0) {
              queryData.data[foundIndex].isFavorite = data?.data?.isFavorite;
            }
            return queryData;
          }
        );
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error updating the status of this goal. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return mutation;
}

export function useDeleteStudentGoal(studentGoalId, onSuccess) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      return await AppsDelete(
        token,
        `studentGoals/DeleteStudentGoal/${studentGoalId}`,
        null
      );
    },
    {
      onSuccess: onSuccess,
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error deleting this goal. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return mutation;
}
