import React, { useCallback, useRef } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme, darken } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import dayjs from "dayjs";
import { getFirstName } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles, faPartyHorn } from "@fortawesome/pro-solid-svg-icons";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 1500,
};

export default function HomeBirthday() {
  const { user } = useUser();
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const iconSize = largeScreen ? "4x" : "3x";
  const month =
    !!user?.studentDetails?.birthDate &&
    dayjs(user?.studentDetails?.birthDate).month();
  const day =
    !!user?.studentDetails?.birthDate &&
    dayjs(user?.studentDetails?.birthDate).date();
  const birthday = Boolean(
    user?.role === "student" &&
      dayjs().month() === month &&
      dayjs().date() === day
  );

  /* Confetti ******************************************************************/
  // Based on this example: https://codesandbox.io/s/2o3pe?file=/src/App.js:16-36
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
        colors: [
          theme?.palette?.primary?.main,
          theme?.palette?.secondary?.main,
          theme?.palette?.tertiary?.main,
          theme?.palette?.success?.main,
        ],
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    makeShot(0.2, {
      spread: 60,
    });
    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);
  /****************************************************************************/

  // Styles
  const birthdayStyles = {
    background: `linear-gradient(${theme?.palette?.success?.main}, ${darken(
      theme?.palette?.success?.main,
      0.2
    )})`,
    // color: theme?.palette?.tertiary?.contrastText,
    borderBottomRightRadius: theme?.spacing(1),
    borderBottomLeftRadius: theme?.spacing(1),
    border: `3px solid ${darken(theme?.palette?.success?.main, 0.25)}`,
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    boxShadow: theme?.shadows[16],
    textShadow: "1px 1px 1px rgba(0,0,0,0.5)",
    padding: theme?.spacing(2),
    marginTop: "-32px",
    marginBottom: "32px",
    marginLeft: "-8px",
  };

  return (
    birthday && (
      <Grid container sx={birthdayStyles} spacing={2}>
        <Grid item xs={2} sm={1}>
          <FontAwesomeIcon icon={faCakeCandles} size={iconSize} />
        </Grid>
        <Grid item xs={8} sm={10}>
          <Typography variant="h3" component="div" gutterBottom>
            Happy birthday{user?.name && `, ${getFirstName(user.name)}`}!
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={fire}
            size="small"
            startIcon={<FontAwesomeIcon icon={faPartyHorn} />}
          >
            Fire Confetti!
          </Button>
          <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
        </Grid>
        <Grid item xs={2} sm={1}>
          <FontAwesomeIcon icon={faCakeCandles} size={iconSize} />
        </Grid>
      </Grid>
    )
  );
}
