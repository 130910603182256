//#region Imports
import { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { formatDateOnly } from "../../services/utilities";
import { alpha } from "@mui/material/styles";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCurrentSchoolYear } from "../../services/hooks/currentSchoolYearHook";
import { formatDate } from "../../services/utilities";
import { useGetStudentMedicalInformation } from "../../services/hooks/studentMedicalHook";
import { useGetStudentEmergencyContacts } from "../../services/hooks/studentEmergencyContactHook";
import dayjs from "dayjs";
import { useUser } from "../../services/contexts/userContext";
import { usePostDsaParticipant } from "../../services/hooks/dsaHook";
//#endregion

export default function DsaRegistration(props) {
  const { details, open, onClose } = props;
  const { user } = useUser();
  const theme = useTheme();
  const { activeStudent } = useActiveStudent();

  const currentYear = dayjs().year();
  const startYear = dayjs().month() >= 8 ? currentYear : currentYear - 1;
  const endYear = startYear + 4;
  const yearSpan = `${startYear} - ${endYear}`;

  const [withdrawalDate, setWithdrawalDate] = useState(null);
  const [note, setNote] = useState(null);

  //terms
  const [termsConsented, setTermsConsented] = useState(false);
  const [termsConsentedError, setTermsConsentedError] = useState(false);

  //signature
  const [signature, setSignature] = useState();
  const [signatureDate, setSignatureDate] = useState(
    dayjs().format("MM/DD/YYYY")
  ); // originally the value of a date picker in the form, but we're using today's date instead
  const [signatureError, setSignatureError] = useState(false);

  // set registrant defaults
  const registrant = useMemo(() => {
    return details?.registrants?.find(
      (r) => r.studentId === activeStudent?.studentId
    );
  }, [details, activeStudent?.studentId]);

  //Reset form when open changes
  useEffect(() => {
    setSignature("");
    setSignatureError(false);
    setSignatureDate(dayjs());

    setTermsConsented(null);
    setTermsConsentedError(false);
  }, [open, details]);

  //health info
  const medicalInfoQuery = useGetStudentMedicalInformation(
    activeStudent?.studentId
  );
  const medicalInfo = medicalInfoQuery?.data?.data;

  //#region Validation
  const validateForm = () => {
    //terms
    if (!termsConsented) {
      setTermsConsentedError(true);
      return false;
    }

    //signature
    if (signature == null || signature.length === 0) {
      setSignatureError(true);
      return false;
    }

    return true;
  };
  //#endregion

  const postObject = {
    registrantId: registrant !== undefined ? registrant.registrantId : 0, // 0 creates a new entry
    partnerId: details?.partnerId,
    studentId: activeStudent?.studentId,
    entryDate: formatDateOnly(new Date()),
    withdrawalDate: withdrawalDate ? formatDateOnly(withdrawalDate) : null, // since this is a new registration, this should be null
    note: registrant !== undefined ? registrant.note : note,
    grade: registrant !== undefined ? registrant.grade : activeStudent?.grade,
    createdBy: registrant !== undefined ? registrant.createdBy : user?.email,
    createdDate: registrant !== undefined ? registrant.createdDate : new Date(),
    modifiedBy: user?.email,
    modifiedDate: new Date(),
  };

  const postDsaParticipant = usePostDsaParticipant(postObject, onClose);

  const handleRegisterClick = async () => {
    if (!validateForm()) return;

    postDsaParticipant.mutate();
  };

  //#region Styles
  const termsContainerStyles = {
    borderRadius: theme.spacing(0.5),
    border: "1px solid rgba(0, 0, 0, 0)",
    background: "rgba(0,0,0,0.2)",
    height: 160,
    overflowY: "scroll",
    padding: theme.spacing(1),
  };
  const signatureStyles = {
    "& .MuiInputBase-input": {
      fontFamily:
        "Bradley Hand ITC, Brush Script MT, Noteworthy, Dancing Script, cursive",
    },
  };
  //#endregion

  //#region Render
  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md">
        <DialogTitle>
          <Typography variant="h3" component="h2">
            Register for {details?.dsaTitle ?? details?.name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            <em>
              Please complete the following to register your student for this
              program. By e-signing below you confirm the terms either initialed
              below or initialed on a previous registration this school year.
            </em>
          </Typography>

          <br />
          {/* Health */}
          <Typography variant="h4">Health</Typography>
          {medicalInfoQuery.isLoading && <Skeleton height={30} />}
          {medicalInfoQuery.isSuccess && medicalInfo.length === 0 && (
            <Typography>
              No medical info for {activeStudent.firstName} was found on file.
            </Typography>
          )}
          {medicalInfoQuery.isSuccess && medicalInfo.length > 0 && (
            <>
              <Typography>
                The medical information presented below is from our Student
                Information System and will be shared with third party program
                organizers. If any of the information is incorrect, contact your
                school directly to make any changes.*
              </Typography>
              <ul>
                {medicalInfo?.map((m) => (
                  <li>
                    <Typography
                      component="span"
                      color={theme?.palette?.text?.secondary}
                    >
                      <strong>{m.description}</strong>:
                    </Typography>{" "}
                    {m.comment}
                  </li>
                ))}
              </ul>
              <Typography variant="subtitle2">
                *This is an after-school program and Tacoma Public Schools will
                not have nursing staff present. We will not be able to
                administer medications.
              </Typography>
            </>
          )}

          <br />
          {/* Terms */}
          <Typography variant="h4">Terms</Typography>
          <Box sx={termsContainerStyles}>
            <Typography variant="body2">
              I consent for my child,{" "}
              <strong>
                {activeStudent?.firstName} {activeStudent?.lastName}
              </strong>
              , to receive <strong>{details?.name}</strong> services. To enable
              education planning and coordination services, I also consent for
              the Tacoma School District to release some of my child’s
              educational records in order to assist{" "}
              <strong>{details?.name}</strong> to provide services to my child.
            </Typography>
            <br />
            <Typography variant="body2">
              The education records to be released only include:
              <ul style={{ marginBottom: 0 }}>
                <li>
                  Demographics
                  <ul>
                    <li>
                      ID, Name, School, Grade, Gender, Race, Age, Class,
                      Language, Programs (English Language Learner, Special
                      Education, 504, McKinney-Vento, Highly Capable)
                    </li>
                  </ul>
                </li>
                <li>
                  Attendance
                  <ul>
                    <li>School Year, % Unexcused, % Excused, % Tardy</li>
                  </ul>
                </li>
                <li>
                  Enrollment
                  <ul>
                    <li>School Year, Start Date, End Date</li>
                  </ul>
                </li>
                <li>
                  Assessments
                  <ul>
                    <li>School Year, Subject, Season, Test, Score, Level</li>
                  </ul>
                </li>
                <li>
                  Marks (Grades)
                  <ul>
                    <li>
                      School Year, Marking Period, Period, Teacher, Subject,
                      Mark (Grade)
                    </li>
                  </ul>
                </li>
                <li>
                  Discipline
                  <ul>
                    <li>School Year, # of Incidents</li>
                  </ul>
                </li>
              </ul>
            </Typography>
            <br />
            <Typography variant="body2">
              This release allows <strong>{details?.name}</strong> staff to
              access education records online and/or directly from the School or
              School Districts. I understand <strong>{details?.name}</strong>{" "}
              will use these education records to monitor student’s academic
              progress and to evaluate and improve the educational programs that{" "}
              <strong>{details?.name}</strong> offers. I further understand that{" "}
              <strong>{details?.name}</strong> coordinates assessment, planning
              and intervention efforts with schools and other social service
              agencies, but that <strong>{details?.name}</strong> will not
              disclose my child’s educational records to anyone outside{" "}
              <strong>{details?.name}</strong> without written permission from
              the parent/guardian.
            </Typography>
            <br />
            <Typography variant="body2">
              This release may be revoked or withdrawn at any time in writing,
              but that will not affect any information already shared. This
              consent and release of education records to{" "}
              <strong>{details?.name}</strong> will be valid for the {yearSpan}{" "}
              school years, or as long as the student is served by{" "}
              <strong>{details?.name}</strong>, whichever is the lesser time
              length.
            </Typography>
          </Box>
          <FormGroup>
            {termsConsentedError && (
              <FormHelperText error={termsConsentedError}>
                You must agree to the terms
              </FormHelperText>
            )}
            <FormControlLabel
              error={termsConsentedError}
              control={
                <Checkbox
                  checked={termsConsented}
                  onChange={(e) => {
                    setTermsConsented(e.target.checked);
                    setTermsConsentedError(false);
                  }}
                />
              }
              label="I hereby consent to all disclosures listed above"
            />
          </FormGroup>

          <Divider sx={{ my: theme?.spacing(3) }} />

          {/* Signature */}
          <Typography variant="body2">
            I understand that my student's participation in the {details?.name}{" "}
            program is voluntary and that {details?.name} reserves the right to
            terminate my student's participation in the program on the basis
            that the needs of my student cannot be met by the services provided,
            when the program guidelines are not adhered to, or the safety of
            others or the program are compromised. My e-signature indicates my
            agreement to my student's enrollment in the program and confirms any
            informed consents initialed above.
          </Typography>
          <br />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8}>
              <FormControl error={signatureError} fullWidth>
                <TextField
                  sx={signatureStyles}
                  value={signature}
                  error={signatureError}
                  onChange={(e) => {
                    setSignature(e.target.value);
                    setSignatureError(false);
                  }}
                  fullWidth
                  required
                  label="Parent or Guardian Signature"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              Date: {formatDate(signatureDate)}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {termsConsentedError && (
            <FormControl error={termsConsentedError}>
              <FormHelperText>You must agree with the terms</FormHelperText>
            </FormControl>
          )}

          {signatureError && (
            <FormControl error={signatureError}>
              <FormHelperText>Please complete your signature</FormHelperText>
            </FormControl>
          )}

          <Button onClick={onClose} disabled={postDsaParticipant.isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleRegisterClick}
            disabled={postDsaParticipant.isLoading}
            variant="contained"
          >
            Register
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
  //#endregion
}
