import React, { useState, useEffect } from "react";
import { useUser } from "./userContext";
import { Box, Tabs, Tab, useTheme } from "@mui/material";
import StudentPhoto from "../../components/common/StudentPhoto";

const ActiveStudentContext = React.createContext();

export function useActiveStudent() {
  const context = React.useContext(ActiveStudentContext);
  if (!context) {
    throw new Error(
      `useActiveStudent must be used within a SelectedStudentContextProvider`
    );
  }
  return context;
}

export function ActiveStudentContextProvider(props) {
  const { user } = useUser();

  const [activeStudent, setActiveStudent] = useState();

  //Initialize selected student when user is loaded
  useEffect(() => {
    if (activeStudent == null) {
      if (user.role === "guardian" && user?.students?.length > 0)
        setActiveStudent(user.students[0]);
      else if (user.role === "student") setActiveStudent(user?.studentDetails);
    }
  }, [user]);

  const setActiveStudentById = (studentId) => {
    let foundStudent = user?.students?.find(
      (un) => un?.studentId === studentId
    );
    if (foundStudent) setActiveStudent(foundStudent);
  };
  const value = {
    activeStudent,
    setActiveStudent,
    setActiveStudentById,
  };

  return <ActiveStudentContext.Provider value={value} {...props} />;
}

export function StudentPicker() {
  const { activeStudent, setActiveStudent } = useActiveStudent();
  const { user } = useUser();
  const theme = useTheme();
  const students = user?.students;

  // Styles
  const studentLabelStyles = (studentId) => {
    return {
      color:
        activeStudent?.studentId === studentId
          ? theme.palette.text.primary
          : theme.palette.text.secondary,
      fontSize: "1.2rem",
      textTransform: "capitalize",
    };
  };
  const blur = {
    content: "''",
    display: "block",
    width: 100,
    height: 100,
    background: "rgba(255,255,255,0.075)",
    backdropFilter: "blur(3px)",
    position: "absolute",
    zIndex: 1000,
  };
  const studentPhotoStyles = (studentId) => {
    return {
      width: "4em",
      height: "4em",
      mb: theme.spacing(1),
      "&:hover": {
        transform:
          studentId === activeStudent?.studentId ? "scale(1.1)" : "scale(1.05)",
      },
      transform:
        studentId === activeStudent?.studentId ? "scale(1.1)" : "scale(1)",
      "&.MuiAvatar-root": {
        transitionDuration: `100ms`,
        boxShadow:
          studentId === activeStudent?.studentId &&
          `0 0 ${theme.spacing(1)} ${theme.spacing(0.2)} ${
            theme.palette.secondary.light
          }`,
        border:
          studentId === activeStudent?.studentId
            ? `2px solid ${theme.palette.grey[50]}`
            : `2px solid ${theme.palette.grey[500]}`,
        "&::before": process.env.REACT_APP_ENVIRONMENT === "uat" ? blur : {},
        "& img": {
          mt: "20%",
          height: "140%",
          position: "relative",
          filter:
            studentId === activeStudent?.studentId
              ? "none"
              : "grayscale(100%) opacity(0.75)",
        },
      },
    };
  };
  const tabContainerStyles = {
    my: theme.spacing(4),
  };
  const noPickerStyles = {
    my: theme.spacing(2),
  };

  return (
    <>
      {/* Only show student picker when user is guardian */}
      {user?.role === "guardian" && !!activeStudent ? (
        <Tabs
          sx={tabContainerStyles}
          value={activeStudent}
          onChange={(e, val) => {
            setActiveStudent(val);
          }}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {students?.length > 0 &&
            students?.map((stu, index) => (
              <Tab
                key={index}
                value={stu}
                sx={studentLabelStyles(stu.studentId)}
                label={
                  <>
                    <StudentPhoto
                      studentId={stu.studentId}
                      variant="circular"
                      styles={studentPhotoStyles(stu.studentId)}
                      name={stu.firstName}
                    />
                    {stu.firstName}
                  </>
                }
              />
            ))}
        </Tabs>
      ) : (
        <Box sx={noPickerStyles} />
      )}
    </>
  );
}
