import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";

export default function BadgesAbout() {
  const theme = useTheme();
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const howToStyles = {
    "& p": {
      marginBottom: "1rem",
    },
    "& h4": {
      marginBottom: "1rem",
    },
  };
  const actionsStyles = {
    borderTop: `1px solid ${
      theme?.palette?.neutral?.main
        ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Badges
      </Button>
      <Dialog open={aboutDialogIsOpen} onClose={handleAboutClose}>
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> About Badges
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={howToStyles}>
            <Typography variant="h4" color="primary">
              How to use Compass Badges
            </Typography>
            <Typography>
              <strong>While you were sleeping...</strong>
              <br />
              Most badges are awarded nightly based on grades, attendance, etc.,
              but some badges are awarded instantly for stuff you do in Compass,
              like rating a whole category of&nbsp;interests.
            </Typography>
            <Typography>
              <strong>Mo badges, mo betta...</strong>
              <br />
              Use the filterable list to check out all the badges. Click one to
              learn more about it. You can even make it a goal in Compass to
              earn one of&nbsp;'em.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
