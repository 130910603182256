import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  useMediaQuery,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { useGetBadgesForStudent } from "../../services/hooks/badgesHook";
import BadgesIntro from "../common/BadgesIntro";
import TrailGrow from "../animations/TrailGrow";

export default function BadgesList() {
  const theme = useTheme();
  const mediumPlusScreen = useMediaQuery(theme.breakpoints.up("md"));
  const badgesResponse = useGetBadgesForStudent();
  const allBadges = badgesResponse?.data?.data;
  const [badges, setBadges] = useState();
  const [badgesAcademics, setBadgesAcademic] = useState();
  const [badgesAttendance, setBadgesAttendance] = useState();
  const [badgesAssessment, setBadgesAssessment] = useState();
  const [badgesInApp, setBadgesInApp] = useState();
  const [badgesEarned, setBadgesEarned] = useState();
  const [badgesEarnedAcademics, setBadgesEarnedAcademics] = useState();
  const [badgesEarnedAttendance, setBadgesEarnedAttendance] = useState();
  const [badgesEarnedAssessment, setBadgesEarnedAssessment] = useState();
  const [badgesEarnedInApp, setBadgesEarnedInApp] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [earnedOnly, setEarnedOnly] = useState(false);

  useEffect(() => {
    setBadges(allBadges);
    let academics = [];
    let attendance = [];
    let assessment = [];
    let inApp = [];
    let earnedCount = 0;
    let academicsEarnedCount = 0;
    let attendanceEarnedCount = 0;
    let assessmentEarnedCount = 0;
    let inAppEarnedCount = 0;
    allBadges?.forEach((b) => {
      switch (b.category) {
        case "Academics":
          academics.push(b);
          if (b.earnedCount > 0) {
            academicsEarnedCount++;
            earnedCount++;
          }
          break;
        case "Attendance":
          attendance.push(b);
          if (b.earnedCount > 0) {
            attendanceEarnedCount++;
            earnedCount++;
          }
          break;
        case "Assessment":
          assessment.push(b);
          if (b.earnedCount > 0) {
            assessmentEarnedCount++;
            earnedCount++;
          }
          break;
        case "In-App":
          inApp.push(b);
          if (b.earnedCount > 0) {
            inAppEarnedCount++;
            earnedCount++;
          }
          break;
        default:
          break;
      }
    });
    setBadgesAcademic(academics);
    setBadgesAttendance(attendance);
    setBadgesAssessment(assessment);
    setBadgesInApp(inApp);
    setBadgesEarned(earnedCount);
    setBadgesEarnedAcademics(academicsEarnedCount);
    setBadgesEarnedAttendance(attendanceEarnedCount);
    setBadgesEarnedAssessment(assessmentEarnedCount);
    setBadgesEarnedInApp(inAppEarnedCount);
  }, [allBadges]);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };
  const handleEarnedOnlyChange = (event, newSwitchValue) => {
    setEarnedOnly(newSwitchValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }
  function BadgeList(props) {
    const { badges, earnedCount } = props;
    return (
      <Paper sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Grid
          container
          sx={tabHeaderStyles}
          spacing={2}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>
              {!!earnedOnly ? earnedCount : badges.length} badges
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={earnedOnly}
                  onChange={handleEarnedOnlyChange}
                />
              }
              label="Earned only"
            />
          </Grid>
        </Grid>
        {!!earnedOnly && earnedCount === 0 && (
          <Typography variant="subtitle1" sx={{ p: 3 }}>
            Sorry, you haven't earned any of these...{" "}
            <strong>
              <em>yet!</em>
            </strong>
          </Typography>
        )}
        <List sx={listStyles}>
          {/* <TrailGrow> */}
          {badges.map((b) => {
            return (
              <ListItem
                key={b.badgeId}
                divider={true}
                sx={
                  !!earnedOnly && !(b.earnedCount > 0)
                    ? hiddenItemStyles
                    : b.earnedCount > 0
                    ? earnedStyles
                    : listItemStyles
                }
                component={RouterLink}
                to={`/badges/${b.badgeId}`}
              >
                <ListItemIcon>
                  <TrailGrow>
                    <img
                      src={b.url}
                      loading="lazy"
                      width={mediumPlusScreen ? 120 : 56}
                      height={mediumPlusScreen ? 150 : 63}
                      alt=""
                    />
                    <Badge
                      badgeContent={b.earnedCount > 1 ? b.earnedCount : 0}
                      color="secondary"
                      overlap="circular"
                    />
                  </TrailGrow>
                </ListItemIcon>
                <ListItemText
                  primary={
                    b.earnedCount > 0 ? <strong>{b.name}</strong> : b.name
                  }
                  secondary={
                    <>
                      {b.earnedCount > 0 ? (
                        <>
                          {b.category} | EARNED&nbsp;
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </>
                      ) : (
                        b.category
                      )}
                    </>
                  }
                />
              </ListItem>
            );
          })}
          {/* </TrailGrow> */}
        </List>
      </Paper>
    );
  }

  //Styles
  const tabHeaderStyles = {
    padding: `${theme.spacing(1)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
  };
  const listStyles = {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      padding: theme.spacing(1),
    },
  };

  const listItemStyles = {
    color: theme?.palette?.text?.primary,
    borderLeft: "10px solid rgba(0, 0, 0, 0)",
    "& .MuiListItemIcon-root": {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: theme.spacing(2),
      width: `calc(33% - ${theme.spacing(4)})`,
      borderLeft: "none",
      "& .MuiListItemIcon-root": {
        margin: "0px !important",
      },
      "& .MuiTypography-root": {
        textAlign: "center",
      },
    },
  };
  const earnedStyles = {
    ...listItemStyles,
    borderLeft: `10px solid ${theme?.palette?.primary?.main}`,
    background: alpha(theme?.palette?.primary?.main ?? "#777", 0.25),
  };
  const hiddenItemStyles = {
    display: "none",
  };

  return (
    <>
      <BadgesIntro earned={badgesEarned} />
      <br />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="Badge tabs"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
            >
              <Tab label="All" aria-label="All" {...a11yProps(0)} />
              <Tab label="Academics" aria-label="Academics" {...a11yProps(1)} />
              <Tab
                label="Attendance"
                aria-label="Attendance"
                {...a11yProps(2)}
              />
              <Tab
                label="Assessment"
                aria-label="Assessment"
                {...a11yProps(3)}
              />
              <Tab label="In-App" aria-label="In-app" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {!!badges && (
              <BadgeList badges={badges} earnedCount={badgesEarned} />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {!!badgesAcademics && (
              <BadgeList
                badges={badgesAcademics}
                earnedCount={badgesEarnedAcademics}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            {!!badgesAttendance && (
              <BadgeList
                badges={badgesAttendance}
                earnedCount={badgesEarnedAttendance}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            {!!badgesAssessment && (
              <BadgeList
                badges={badgesAssessment}
                earnedCount={badgesEarnedAssessment}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            {!!badgesInApp && (
              <BadgeList badges={badgesInApp} earnedCount={badgesEarnedInApp} />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
}
