export default function RegisterButton() {
  return (
    <svg
      width="108"
      height="41"
      viewBox="0 0 108 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#FFFFFF" d="M108,0H0v41h108V0z" />
      <path
        fill="#8C8C8C"
        d="M107.498,1v39H0.502V1H107.498z M108,0H0v41h108V0z"
      />
      <path
        fill="#5E5E5E"
        d="M35.217,25.5H33.18l-1.677-2.813c-0.151-0.259-0.299-0.479-0.443-0.663c-0.144-0.183-0.291-0.333-0.439-0.45
	s-0.311-0.203-0.487-0.256c-0.176-0.055-0.374-0.081-0.593-0.081h-0.703V25.5h-1.736V14.997h3.457c0.493,0,0.947,0.059,1.362,0.176
	s0.776,0.294,1.084,0.531s0.548,0.532,0.721,0.886c0.173,0.354,0.26,0.768,0.26,1.242c0,0.371-0.055,0.712-0.165,1.022
	c-0.11,0.31-0.267,0.586-0.469,0.827c-0.203,0.242-0.447,0.448-0.732,0.619c-0.286,0.171-0.607,0.303-0.963,0.396v0.029
	c0.19,0.107,0.356,0.226,0.498,0.355c0.142,0.129,0.276,0.262,0.403,0.398s0.253,0.293,0.377,0.466
	c0.125,0.173,0.263,0.374,0.414,0.604L35.217,25.5z M28.837,16.411v3.413h1.45c0.269,0,0.517-0.042,0.743-0.125
	c0.228-0.083,0.424-0.203,0.59-0.359s0.295-0.347,0.388-0.571s0.139-0.476,0.139-0.754c0-0.503-0.159-0.896-0.476-1.179
	s-0.774-0.425-1.37-0.425H28.837z"
      />
      <path
        fill="#5E5E5E"
        d="M42.204,22.211h-5.112c0.02,0.694,0.233,1.229,0.641,1.604c0.408,0.376,0.968,0.563,1.682,0.563
	c0.8,0,1.535-0.238,2.204-0.717v1.369c-0.684,0.43-1.587,0.645-2.71,0.645c-1.104,0-1.969-0.341-2.597-1.021
	c-0.627-0.682-0.94-1.64-0.94-2.875c0-1.167,0.345-2.118,1.036-2.853c0.69-0.735,1.549-1.103,2.574-1.103s1.818,0.33,2.38,0.989
	s0.843,1.575,0.843,2.747V22.211z M40.563,21.01c-0.005-0.61-0.149-1.084-0.433-1.424s-0.674-0.509-1.172-0.509
	c-0.488,0-0.901,0.178-1.241,0.535c-0.339,0.356-0.548,0.823-0.626,1.398H40.563z"
      />
      <path
        fill="#5E5E5E"
        d="M50.648,24.899c0,2.754-1.384,4.131-4.152,4.131c-0.977,0-1.829-0.164-2.557-0.491v-1.553
	c0.82,0.469,1.6,0.703,2.337,0.703c1.782,0,2.673-0.876,2.673-2.629v-0.82H48.92c-0.562,0.957-1.406,1.436-2.533,1.436
	c-0.914,0-1.649-0.333-2.209-1c-0.559-0.666-0.838-1.561-0.838-2.684c0-1.275,0.3-2.288,0.9-3.04s1.426-1.128,2.476-1.128
	c0.991,0,1.726,0.405,2.204,1.216h0.029V18h1.699V24.899z M48.965,22.064v-0.974c0-0.527-0.175-0.978-0.524-1.352
	c-0.349-0.373-0.785-0.56-1.308-0.56c-0.645,0-1.148,0.238-1.512,0.714c-0.364,0.476-0.546,1.142-0.546,1.995
	c0,0.738,0.175,1.327,0.523,1.77c0.35,0.441,0.812,0.662,1.388,0.662c0.586,0,1.063-0.211,1.429-0.633
	C48.781,23.265,48.965,22.725,48.965,22.064z"
      />
      <path
        fill="#5E5E5E"
        d="M53.717,16.425c-0.278,0-0.517-0.09-0.714-0.271c-0.198-0.181-0.297-0.41-0.297-0.688
	s0.099-0.51,0.297-0.696c0.197-0.186,0.436-0.278,0.714-0.278c0.288,0,0.532,0.093,0.732,0.278s0.301,0.417,0.301,0.696
	c0,0.264-0.101,0.49-0.301,0.678S54.005,16.425,53.717,16.425z M54.559,25.5h-1.699V18h1.699V25.5z"
      />
      <path
        fill="#5E5E5E"
        d="M56.31,25.266v-1.574c0.635,0.482,1.336,0.725,2.103,0.725c1.025,0,1.538-0.303,1.538-0.908
	c0-0.171-0.044-0.316-0.132-0.436c-0.088-0.12-0.206-0.226-0.355-0.318c-0.148-0.093-0.324-0.176-0.527-0.25
	c-0.202-0.072-0.429-0.156-0.677-0.248c-0.309-0.123-0.585-0.252-0.832-0.389c-0.246-0.137-0.451-0.291-0.615-0.465
	c-0.163-0.174-0.287-0.37-0.369-0.59c-0.084-0.22-0.125-0.476-0.125-0.769c0-0.361,0.085-0.68,0.256-0.956s0.4-0.508,0.689-0.696
	c0.287-0.188,0.615-0.329,0.98-0.425c0.367-0.095,0.745-0.143,1.136-0.143c0.693,0,1.313,0.105,1.86,0.315v1.487
	c-0.527-0.361-1.133-0.542-1.816-0.542c-0.215,0-0.409,0.022-0.582,0.066c-0.174,0.044-0.321,0.105-0.443,0.183
	s-0.218,0.172-0.285,0.282c-0.069,0.109-0.104,0.23-0.104,0.362c0,0.161,0.034,0.298,0.104,0.41
	c0.067,0.112,0.169,0.211,0.304,0.297c0.134,0.085,0.295,0.164,0.483,0.234c0.188,0.071,0.403,0.147,0.648,0.23
	c0.322,0.132,0.611,0.267,0.867,0.403c0.257,0.137,0.475,0.291,0.656,0.465c0.18,0.174,0.319,0.373,0.417,0.601
	c0.098,0.227,0.146,0.497,0.146,0.81c0,0.381-0.088,0.713-0.264,0.996s-0.41,0.518-0.703,0.703s-0.631,0.323-1.015,0.413
	c-0.383,0.091-0.787,0.136-1.212,0.136C57.621,25.676,56.911,25.539,56.31,25.266z"
      />
      <path
        fill="#5E5E5E"
        d="M67.201,25.42c-0.332,0.165-0.769,0.248-1.311,0.248c-1.455,0-2.183-0.697-2.183-2.094v-4.241h-1.253V18
	h1.253v-1.736l1.699-0.483V18h1.794v1.333h-1.794v3.75c0,0.444,0.081,0.762,0.241,0.952c0.162,0.19,0.43,0.285,0.807,0.285
	c0.287,0,0.536-0.082,0.746-0.248V25.42z"
      />
      <path
        fill="#5E5E5E"
        d="M74.929,22.211h-5.112c0.02,0.694,0.233,1.229,0.641,1.604c0.408,0.376,0.968,0.563,1.682,0.563
	c0.8,0,1.535-0.238,2.204-0.717v1.369c-0.684,0.43-1.587,0.645-2.71,0.645c-1.104,0-1.969-0.341-2.597-1.021
	c-0.627-0.682-0.94-1.64-0.94-2.875c0-1.167,0.345-2.118,1.036-2.853c0.69-0.735,1.549-1.103,2.574-1.103s1.818,0.33,2.38,0.989
	s0.843,1.575,0.843,2.747V22.211z M73.288,21.01c-0.005-0.61-0.149-1.084-0.433-1.424s-0.674-0.509-1.172-0.509
	c-0.488,0-0.901,0.178-1.241,0.535c-0.339,0.356-0.548,0.823-0.626,1.398H73.288z"
      />
      <path
        fill="#5E5E5E"
        d="M80.898,19.619c-0.205-0.161-0.501-0.242-0.887-0.242c-0.503,0-0.923,0.227-1.26,0.681
	s-0.506,1.072-0.506,1.853V25.5h-1.699V18h1.699v1.545h0.029c0.166-0.527,0.422-0.938,0.766-1.234
	c0.345-0.295,0.729-0.443,1.154-0.443c0.307,0,0.541,0.046,0.703,0.139V19.619z"
      />
    </svg>
  );
}
