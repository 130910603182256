import { React } from "react";
import { useParams, useNavigate } from "react-router";
import { Box } from "@mui/material";
import { ScrollToTopOnMount } from "../../services/utilities";
import Survey from "../common/Survey";
import SurveyList from "../common/SurveyList";
import { showFeatures } from "./../../services/utilities";

export default function Surveys() {
  const { surveyId } = useParams();
  const navigate = useNavigate();

  return !!showFeatures.survey ? (
    <Box>
      <ScrollToTopOnMount />
      {!!surveyId ? <Survey /> : <SurveyList />}
    </Box>
  ) : (
    navigate("/")
  );
}
