import React from "react";
import { Box } from "@mui/material";

export default function YouTubeVideo(props) {
  const { id, my } = props;
  // unique video id from youtube.com URL
  // and optional margin value for y-axis (top and bottom)

  // Styles
  const videoWrapperStyles = {
    position: "relative",
    pb: "56.25%", // for 16:9 aspect ratio (9/16 = 0.5625 or 56.25%)
    height: 0,
    overflow: "hidden",
    my: my === 0 ? 0 : !!my ? my : 2,
  };
  const videoIframeStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };

  return (
    <Box sx={videoWrapperStyles}>
      <iframe
        style={videoIframeStyles}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube video"
        src={`https://www.youtube-nocookie.com/embed/${id}?rel=0`}
        // "youtube-nocookie" is used for privacy-enhanced mode
        // and rel=0 limits related videos to ones from the same channel
      />
    </Box>
  );
}
