//#region Imports
import React from "react";
import { Skeleton, Grid, Divider } from "@mui/material";
import { faList } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../services/contexts/userContext";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import { useCompassEnrolledPrograms } from "../../services/hooks/programsHook";
import { useGetCurrentDsaRegisteredPartners } from "../../services/hooks/dsaHook";
import Error from "../common/Error";
import ActivityListItem from "./ActivityListItem";
import DsaListItem from "./DsaListItem";
import DsaProgramHeader from "./DsaProgramHeader";
import NoDataMessage from "./NoDataMessage";
//#endregion

export default function ActivitiesEnrolled() {
  const theme = useTheme();
  const { user } = useUser();
  const { activeStudent } = useActiveStudent();

  const query = useCompassEnrolledPrograms(activeStudent?.studentId);
  const programs = query?.data?.data;

  const dsaRegisteredQuery = useGetCurrentDsaRegisteredPartners(
    activeStudent?.studentId
  );
  const dsaRegistered = dsaRegisteredQuery?.data?.data;

  //#region Render
  return (
    <>
      {query.isError && (
        <Error message="There was an issue retrieving current activities." />
      )}
      {query.isLoading && (
        <>
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
          <Skeleton
            height={175}
            variant="rect"
            sx={{ margin: `${theme.spacing(2)} 0`, borderRadius: "4px" }}
          />
        </>
      )}
      {!query.isLoading &&
        programs?.length === 0 &&
        dsaRegistered?.length === 0 && (
          <NoDataMessage
            message={`When ${
              user?.role === "guardian"
                ? `${activeStudent?.firstName} is`
                : "you are"
            } enrolled in any currently-running activities, you'll see a list of them here...`}
            icon={faList}
          />
        )}
      {!query.isLoading &&
        programs?.length > 0 &&
        programs?.map((p, index) => {
          return (
            <ActivityListItem program={p} pid={p?.programId} key={index} />
          );
        })}
      {!query.isLoading &&
        programs?.length > 0 &&
        dsaRegistered?.length > 0 && (
          <Divider variant="middle" sx={{ my: 6 }} />
        )}
      {!query?.isLoading && dsaRegistered?.length > 0 && (
        <>
          <DsaProgramHeader />
          <Grid container spacing={2}>
            {dsaRegistered?.map((dsa, index) => {
              return (
                <DsaListItem
                  key={index}
                  partner={dsa.partner}
                  activeStudent={activeStudent}
                />
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
  //#endregion
}
