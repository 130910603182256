import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-solid-svg-icons";
import { useActiveStudent } from "../../services/contexts/activeStudentContext";
import useCommonStyles from "../../services/hooks/useCommonStyles";
import {
  useGetStudentISRFileNames,
  useGetStudentISRFile,
} from "../../services/hooks/studentsHook";
import Error from "./Error";

export default function CoursesISR() {
  const commonStyles = useCommonStyles();
  const { activeStudent } = useActiveStudent();
  const studentId = activeStudent?.studentId;

  const isrFilesQuery = useGetStudentISRFileNames(studentId);
  const isrFiles = isrFilesQuery.data?.data;

  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const getFile = useGetStudentISRFile(studentId, selectedFile);
  useEffect(() => {
    if (studentId && selectedFile) {
      getFile.refetch();
      setSelectedFile(null);
      closeDialog();
    }
  }, [selectedFile]);

  return (
    <>
      <Paper sx={commonStyles.paperPadding}>
        <Typography variant="h5" component="h2">
          Individual Student Report
        </Typography>
        <Typography>
          View a list of reports created by the Washington Office of
          Superintendent of Public Instruction.
        </Typography>
        <br />
        <Button
          onClick={() => {
            setDialogOpen(true);
          }}
          variant="contained"
        >
          View Files
        </Button>
      </Paper>

      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Individual Student Report Files</DialogTitle>
        <DialogContent>
          <Typography>
            An Individual Student Report (ISR) shows how{" "}
            {activeStudent?.firstName} did on a state test for Math and English
            Language Arts. Check out the pdf report(s) below:
          </Typography>
          <br />
          {isrFilesQuery.isLoading && <CircularProgress />}
          {isrFilesQuery.isError && (
            <Error
              message="There was a problem retrieving ISR files."
              query={isrFilesQuery}
            />
          )}
          {isrFilesQuery.isSuccess && isrFiles?.length === 0 && (
            <Typography>
              <strong>No files were found</strong>
            </Typography>
          )}
          {isrFilesQuery.isSuccess &&
            isrFiles?.length > 0 &&
            isrFiles?.map((f, i) => (
              <Button
                variant="outlined"
                key={i}
                sx={{ mb: 2 }}
                onClick={() => {
                  setSelectedFile(f);
                }}
                startIcon={<FontAwesomeIcon icon={faDownload} />}
              >
                {f}
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
