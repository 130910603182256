import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { SnackbarProvider } from "notistack";
import { ThemeContextProvider } from "./services/contexts/themeContext";
import { UserContextProvider } from "./services/contexts/userContext";
import { ActiveStudentContextProvider } from "./services/contexts/activeStudentContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//MSAL
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();
const container = document.getElementById("root");
const root = createRoot(container);

const ReactQueryClientProvider = (props) => {
  const msal = useMsal();
  const user = msal?.accounts[0]?.username;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryKeyHashFn: (queryKey) => user + queryKey,
        retry: false,
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60, //Cache for one minute
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
};

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ReactQueryClientProvider>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={5000}
          >
            <UserContextProvider>
              <ActiveStudentContextProvider>
                <ThemeContextProvider>
                  <App />
                </ThemeContextProvider>
              </ActiveStudentContextProvider>
            </UserContextProvider>
          </SnackbarProvider>
        </ReactQueryClientProvider>
      </LocalizationProvider>
    </MsalProvider>
  </React.StrictMode>
);
