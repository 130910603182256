import React from "react";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import BadgesDetail from "../common/BadgesDetail";
import BadgesList from "../common/BadgesList";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Badges() {
  const { badgeId } = useParams();

  return (
    <>
      <ScrollToTopOnMount />
      {badgeId ? (
        <BadgesDetail />
      ) : (
        <Box>
          <Typography variant="h1" component="h2" gutterBottom>
            Badges
          </Typography>
          <BadgesList />
        </Box>
      )}
    </>
  );
}
