import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import ReactECharts from "echarts-for-react";
import { useGetEarnedBadgeCountByMonth } from "../../services/hooks/badgesHook";

export default function BadgeStats() {
  const theme = useTheme();

  const earnedBadgesDetails = useGetEarnedBadgeCountByMonth();
  const badgeCount = earnedBadgesDetails?.data?.data;

  const months = [
    {
      Name: "Sep",
      Value: 9,
    },
    {
      Name: "Oct",
      Value: 10,
    },
    {
      Name: "Nov",
      Value: 11,
    },
    {
      Name: "Dec",
      Value: 12,
    },
    {
      Name: "Jan",
      Value: 1,
    },
    {
      Name: "Feb",
      Value: 2,
    },
    {
      Name: "Mar",
      Value: 3,
    },
    {
      Name: "Apr",
      Value: 4,
    },
    {
      Name: "May",
      Value: 5,
    },
    {
      Name: "Jun",
      Value: 6,
    },
    {
      Name: "Jul",
      Value: 7,
    },
    {
      Name: " Aug",
      Value: 8,
    },
  ];

  const badgeSeries = useMemo(() => {
    // Map through badgeCount
    // Create an inner array for each category
    return badgeCount?.map((c) => {
      return {
        category: c.category.toTitleCase(),
        counts: months.map(
          (m, i) =>
            badgeCount
              ?.find((e) => e.category === c.category)
              ?.monthCounts?.find((c) => c.month === m.Value)?.count ?? 0
        ),
      };
    });
  }, [badgeCount]);

  const BadgeChart = {
    title: {
      text: "Badge Types earned",
      textStyle: {
        color: theme?.palette?.text?.primary,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    dataset: {
      source: [
        ["Months", ...months.map((m) => m.Name)],
        badgeSeries?.length > 0 && [
          badgeSeries[0].category,
          ...badgeSeries[0].counts,
        ],
        badgeSeries?.length > 1 && [
          badgeSeries[1].category,
          ...badgeSeries[1].counts,
        ],
        badgeSeries?.length > 2 && [
          badgeSeries[2].category,
          ...badgeSeries[2].counts,
        ],
        badgeSeries?.length > 3 && [
          badgeSeries[3].category,
          ...badgeSeries[3].counts,
        ],
        badgeSeries?.length > 4 && [
          badgeSeries[4].category,
          ...badgeSeries[4].counts,
        ],
      ],
    },
    textStyle: {
      color: theme?.palette?.text?.primary,
    },

    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
    },
    yAxis: {
      gridIndex: 0,
    },
    series: [
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.first },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.second },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.third },
      },
      {
        type: "line",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.fourth },
      },
    ],
  };

  return (
    <>
      <ReactECharts option={BadgeChart} />
    </>
  );
}
