import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetWaitlistByStudentId(studentId) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `Waitlist/GetWaitlistByStudentId/${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!studentId,
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting waitlist information. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useGetGetWaitlistStatuses() {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `WaitlistStatus/GetWaitlistStatuses`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting waitlist information. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useCompassProgramDetails(studentId, programId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Programs/CompassProgramDetails/${studentId}/${programId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      //   enabled: !!studentId && !!programId,
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting program details. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useUpsertWaitlist(waitlist, onCloseFunction) {
  const token = GetAccessToken();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const url = `Waitlist/UpsertWaitlist`;
  const mutation = useMutation(
    async () => {
      return await AppsPost(token, url, waitlist);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(
          `Programs/CompassProgramDetails/${waitlist?.studentId}/${waitlist?.programId}`
        );
        queryClient.invalidateQueries(
          `Waitlist/GetWaitlistByStudentId/${waitlist?.studentId}`
        );
        snackbar.enqueueSnackbar("Waitlist request successfully sent", {
          variant: "success",
        });
        onCloseFunction();
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error sending the waitlist request. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}
