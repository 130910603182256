import React, { useMemo, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Paper,
  Typography,
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Chip,
  Box,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ScrollToTopOnMount } from "../../services/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faThumbsDown,
  faHeart,
} from "@fortawesome/pro-solid-svg-icons";
import { useGetStudentInterests } from "../../services/hooks/interestsHook";
import { blob } from "../../services/utilities";

export default function InterestsMyRatings() {
  const theme = useTheme();
  const [filters, setFilters] = useState([
    {
      name: "Academics",
      active: false,
      condition: function (item) {
        item = item.interest ? item.interest : item;
        if (item.category.search("Academics") >= 0) {
          return true;
        } else return false;
      },
    },
    {
      name: "Activities & Athletics",
      active: false,
      condition: function (item) {
        item = item.interest ? item.interest : item;
        if (item.category.search("Activities & Athletics") >= 0) {
          return true;
        } else return false;
      },
    },
    {
      name: "Culture & Travel",
      active: false,
      condition: function (item) {
        item = item.interest ? item.interest : item;
        if (item.category.search("Culture & Travel") >= 0) {
          return true;
        } else return false;
      },
    },
    {
      name: "Entertainment & Hobbies",
      active: false,
      condition: function (item) {
        item = item.interest ? item.interest : item;
        if (item.category.search("Entertainment & Hobbies") >= 0) {
          return true;
        } else return false;
      },
    },
    {
      name: (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="1x"
          />
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="1x"
          />
        </span>
      ),
      active: false,
      rating: true,
      condition: function (item) {
        if (item.studentInterestId && item.rating === 1) {
          return true;
        } else return false;
      },
    },
    {
      name: (
        <img
          src={`${blob}Interests/dont-know.svg`}
          style={theme?.palette?.interests?.svgFilterIcon}
          alt="Don't know"
        />
      ),
      active: false,
      rating: true,
      condition: function (item) {
        if (item.studentInterestId && item.rating === 2) {
          return true;
        } else return false;
      },
    },
    {
      name: (
        <img
          src={`${blob}Interests/learn-more.svg`}
          style={theme?.palette?.interests?.svgFilterIcon}
          alt="Learn more"
        />
      ),
      active: false,
      rating: true,
      condition: function (item) {
        if (item.studentInterestId && item.rating === 3) {
          return true;
        } else return false;
      },
    },

    {
      name: (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="1x"
          />
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="1x"
          />
        </span>
      ),
      active: true,
      rating: true,
      condition: function (item) {
        if (item.studentInterestId && item.rating === 4) {
          return true;
        } else return false;
      },
    },
    {
      name: (
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon
            icon={faHeart}
            style={{
              color: theme?.palette?.interests?.heart,
            }}
            size="1x"
          />
          <FontAwesomeIcon
            icon={faHeart}
            style={{
              color: theme?.palette?.interests?.heart,
            }}
            size="1x"
          />
        </span>
      ),
      active: true,
      rating: true,
      condition: function (item) {
        if (item.studentInterestId && item.rating === 5) {
          return true;
        } else return false;
      },
    },
  ]);
  const [filteredStudentInterests, setFilteredStudentInterests] = useState([]);

  const studentInterestsResponse = useGetStudentInterests();

  const studentInterests = useMemo(() => {
    const sir = studentInterestsResponse?.data?.data;
    return sir;
  }, [studentInterestsResponse]);

  useEffect(() => {
    if (studentInterests?.length > 0) {
      const copyStudentInterests = [...studentInterests];
      const filteredList = copyStudentInterests.filter(
        (item) => item.rating === 4 || item.rating === 5
      );
      setFilteredStudentInterests(
        studentInterests?.length === 0
          ? []
          : filteredList.sort(
              (a, b) =>
                new Date(b.createdDateTime) - new Date(a.createdDateTime)
            )
      );
    }
  }, [studentInterests]);

  useEffect(() => {
    if (!!filters && studentInterests?.length > 0) {
      const categoryFilter = filters.filter(
        (item) => item.active && !item.rating === true
      ); // filtering the filters to make an array with only the active ones
      const ratingFilter = filters.filter(
        (item) => item.active & (item.rating === true)
      );
      var items = [];

      if (categoryFilter.length > 0 || ratingFilter.length > 0) {
        // 1. Get all Items in filter category
        if (ratingFilter.length > 0) {
          studentInterests.forEach((item) => {
            ratingFilter.forEach((filter) => {
              if (item.rating) {
                const isValid = filter.condition(item);
                if (isValid) {
                  items = items.concat(item);
                }
              }
            });
          });
        }

        // 2. filter by category
        if (categoryFilter.length > 0) {
          let filterItems;
          if (items.length > 0) {
            filterItems = items;
          } else {
            filterItems = studentInterests;
          }
          filterItems.forEach(
            (item, index) => {
              categoryFilter.forEach((filter) => {
                const isValid = filter.condition(item);
                if (isValid) {
                  items = items.concat(item);
                } else {
                  // setting position to undefined
                  // deleting here to improve runtime
                  delete items[index];
                }
              });
            }
            // .every only returns true if the test is true for all filter elements and efficiently bails on testing when it hits the first false
            // If a list item passes all the tests in the .every, it makes it through the filter
          );
        }

        // remove all undefined items
        items = items.filter((i) => i !== undefined);

        // unique values
        items = Array.from(new Set(items.map(JSON.stringify)))
          .map(JSON.parse)
          .sort(
            (a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime)
          );
        setFilteredStudentInterests(items.length > 0 ? items : []);
      } else {
        setFilteredStudentInterests([]);
      }
    }
  }, [filters, studentInterests]);

  const getRatingItem = (rating) => {
    let iconToReturn;
    switch (rating) {
      case 1:
        iconToReturn = (
          <FontAwesomeIcon
            icon={faThumbsDown}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="2x"
          />
        );
        break;
      case 2:
        iconToReturn = (
          <img
            src={`${blob}Interests/dont-know.svg`}
            style={theme?.palette?.interests?.svgIcon}
            alt="Don't know"
          />
        );
        break;
      case 3:
        iconToReturn = (
          <img
            src={`${blob}Interests/learn-more.svg`}
            style={theme?.palette?.interests?.svgIcon}
            alt="Learn more"
          />
        );
        break;
      case 4:
        iconToReturn = (
          <FontAwesomeIcon
            icon={faThumbsUp}
            style={{
              color: theme?.palette?.interests?.thumbs,
            }}
            size="2x"
          />
        );
        break;
      case 5:
        iconToReturn = (
          <FontAwesomeIcon
            icon={faHeart}
            style={{
              color: theme?.palette?.interests?.heart,
            }}
            size="2x"
          />
        );
        break;
      default:
        iconToReturn = null;
    }
    return iconToReturn;
  };

  const handleFilterClick = (name, active) => {
    const updatedFilters = filters.map((filter) =>
      filter.name === name ? { ...filter, active: !active } : filter
    );
    setFilters(updatedFilters);
  };

  const commonPaddingStyle = {
    padding: theme?.spacing(2),
    paddingBottom: theme?.spacing(1.5),
  };
  const mainHeadingStyle = {
    background: `${theme?.palette?.background?.neutral} !important`,
    ...commonPaddingStyle,
  };
  const list = {
    padding: 0,
    borderTop: `2px solid ${theme?.palette?.grey[300]}`,
  };
  const interestIcon = {
    justifyContent: "center",
    marginLeft: "-16px",
  };
  const interestText = {
    "& .MuiListItemText-secondary": {
      display: "inline-block",
    },
  };
  const interestItem = {
    margin: 0,
  };
  const chip = {
    margin: theme?.spacing(1),
  };

  return (
    <Paper>
      <ScrollToTopOnMount />
      <Paper square sx={mainHeadingStyle} elevation={0}>
        <Typography component="h3" variant="h4">
          My ratings
        </Typography>
      </Paper>
      <Grid container>
        {filters.map((filter) => (
          <Grid item key={filter.name}>
            <Chip
              clickable
              color="primary"
              variant={filter.active ? "default" : "outlined"}
              key={filter.name}
              label={filter.name}
              aria-label={filter.name}
              onClick={() => handleFilterClick(filter.name, filter.active)}
              sx={chip}
            />
          </Grid>
        ))}
      </Grid>
      <List sx={list} aria-label="Interest list" component={Box}>
        {filteredStudentInterests?.length > 0 ? (
          filteredStudentInterests.map((interest, id) => {
            let isRated;
            if (interest.studentInterestId) {
              isRated = true;
            }
            return studentInterestsResponse.isLoading ? (
              <ListItem
                key={id}
                button
                component={RouterLink}
                to=""
                divider={true}
              >
                <ListItemIcon sx={interestIcon}>
                  <Skeleton
                    variant="circle"
                    height={34}
                    width={34}
                    style={{ marginBottom: 6 }}
                    animation="wave"
                  />
                </ListItemIcon>
                <ListItemText sx={interestText}>
                  <Skeleton
                    height={26}
                    width={250}
                    style={{ marginBottom: 6 }}
                    animation="wave"
                  />
                </ListItemText>
              </ListItem>
            ) : isRated ? (
              <ListItem
                key={id}
                button
                component={RouterLink}
                to={`/interests/${interest.interest.category}/${interest.interest.interestId}`}
                divider={true}
                sx={interestItem}
              >
                <ListItemIcon sx={interestIcon}>
                  {interest.rating
                    ? // Item rating img here
                      getRatingItem(interest.rating)
                    : // Placeholder here
                      ""}
                </ListItemIcon>
                <ListItemText
                  sx={interestText}
                  primary={`${interest.interest.name} `}
                  secondary={` ${interest.interest.category}`}
                />
              </ListItem>
            ) : (
              <ListItem
                key={id}
                button
                component={RouterLink}
                to={`/interests/${interest.category}/${interest.interestId}`}
                divider={true}
                sx={interestItem}
              >
                <ListItemIcon sx={interestIcon}>
                  {interest.rating
                    ? // Item rating img here
                      ""
                    : // Placeholder here
                      ""}
                </ListItemIcon>
                <ListItemText
                  sx={interestText}
                  primary={`${interest.name} `}
                  secondary={` ${interest.category}`}
                />
              </ListItem>
            );
          })
        ) : (
          // No interests are returned
          <Fade in={true}>
            <ListItem key="none" sx={interestText}>
              {/* No interests because student has not yet rated any */}
              {studentInterests?.length === 0 ? (
                <ListItemText
                  primary="You gotta rate some... "
                  secondary=" Click one of those colorful category buttons at the top to get started."
                  sx={interestText}
                />
              ) : (
                // No interests because of selected filters
                <ListItemText
                  primary="Sorry, nothing to see here. "
                  secondary=" Try removing a filter..."
                  sx={interestText}
                />
              )}
            </ListItem>
          </Fade>
        )}
      </List>
    </Paper>
  );
}
