import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost, AppsPut } from "../apiService";

export function useGetInterestsAndCategories() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Interests/GetInterestsWithCategories`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },

    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting interests categories. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useGetInterest(interestId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `Interests/GetInterest/${interestId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },

    {
      enabled: interestId != null,
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting interests categories. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useGetStudentInterests() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `StudentInterests/GetStudentInterests`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting interests categories. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useGetStudentInterestsRatingCounts() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = "StudentInterests/GetStudentInterestsRatingCounts";

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting interests categories. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}

export function useInsertStudentInterest(studentInterest, onSuccessCallback) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      let postResponse = await AppsPost(
        token,
        "StudentInterests/PostStudentInterest",
        studentInterest
      );
      let data = await postResponse?.data;
      return data;
    },
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          `Interests/GetInterestsWithCategories`,
          (queryData) => {
            let foundIndex = queryData?.data?.interests.findIndex(
              (interest) => interest.interestId === data.interestId
            );
            if (foundIndex >= 0) {
              queryData.data.interests[foundIndex].studentInterests = [
                ...queryData.data.interests[foundIndex].studentInterests,
                data,
              ];
            }
            return queryData;
          }
        );
        onSuccessCallback(data);
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error saving interest. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return mutation;
}

export function useUpdateStudentInterest(studentInterest, onSuccessCallback) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      await AppsPut(
        token,
        `StudentInterests/PutStudentInterest/${studentInterest.studentInterestId}`,
        studentInterest
      );
    },
    {
      onSuccess: async (data) => {
        queryClient.setQueryData(
          `StudentInterests/GetStudentInterests`,
          (queryData) => {
            let foundIndex = queryData?.data?.findIndex(
              (interest) =>
                parseInt(interest.studentInterestId) ===
                parseInt(data?.data?.studentInterestId)
            );
            if (foundIndex >= 0) {
              queryData.data[foundIndex].rating = data?.data?.rating;
              queryData.data[foundIndex].modifiedDateTime =
                data?.data?.modifiedDateTime;
            }
            snackbar.enqueueSnackbar("Updated interest saved", {
              variant: "success",
            });
            onSuccessCallback();
            return queryData;
          }
        );
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error updating interest. ${error}`, {
          variant: "error",
        });
        return null;
      },
    }
  );
  return mutation;
}
