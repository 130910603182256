import React from "react";
import { useTrail, animated, config } from "react-spring";

export default function TrailSlideUpFadeIn(props) {
  const { children } = props;
  const trail = useTrail(children.length, {
    to: { opacity: 1, y: 0, x: 0 },
    from: { opacity: 0, y: 500, x: 8 },
    delay: 200,
    config: config.gentle,
  });

  return (
    <>
      {trail.map((props, i) => (
        <animated.div style={props} key={i}>
          {children[i]}
        </animated.div>
      ))}
    </>
  );
}
