import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

export default function InvalidUser(props) {
  const theme = useTheme();

  //Styles
  const contentStyles = {
    alignItems: "center",
    display: "flex",
    minHeight: "63vh",
    justifyContent: "center",
    padding: theme?.spacing(2),
  };

  return (
    <Box sx={contentStyles}>
      <Typography variant="h3" component="h1" sx={{ fontWeight: 300 }}>
        Sorry...
      </Typography>
      <Typography gutterBottom>
        Looks like your Microsft account is not associated to a student,
        guardian, or employee of Tacoma Public Schools.
      </Typography>
    </Box>
  );
}
