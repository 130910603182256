import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useCurrentSchoolYear() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `CurrentSchoolYears`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting current school year. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
