import { useTheme } from "@mui/material/styles";

export default function SynergyLogo() {
  const theme = useTheme();
  const backgroundColor =
    theme?.name === "Default" || theme?.name === "Forest"
      ? "#FFFFFF"
      : theme?.palette?.background?.paper;

  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="25px"
      height="25px"
      viewBox="187.041 185.417 125 125"
      enableBackground="new 187.041 185.417 125 125"
    >
      <title>Synergy logo icon</title>
      <g>
        <path
          fill={backgroundColor}
          d="M310.271,253.619c-0.561-3.595-1.756-7.068-3.547-10.285l-0.045-0.08l-0.051-0.075 c-0.246-0.364-0.49-0.748-0.73-1.148c0.98-2.812,1.623-5.71,1.908-8.631c1.361-13.197-4.732-25.859-15.904-33.033 c-5.604-3.495-12.018-5.608-18.572-6.125c-0.92-0.064-1.836-0.097-2.74-0.097c-1.703,0-3.422,0.113-5.111,0.336l-0.041,0.006 l-0.041,0.007c-0.686,0.118-1.369,0.265-2.049,0.439c-2.834-2.289-5.836-4.14-8.756-5.394c-4.513-2.008-9.33-3.031-14.292-3.031 c-8.056,0-15.938,2.793-22.193,7.864c-4.574,3.877-8.184,8.545-10.765,13.905c-1.695,3.278-2.774,6.799-3.208,10.465l-0.011,0.094 l-0.002,0.095c-0.009,0.305-0.025,0.621-0.05,0.952c-2.481,1.694-4.781,3.647-6.847,5.816l-0.021,0.021l-0.02,0.022 c-8.904,9.887-11.437,23.729-6.608,36.127c3.371,8.26,9.119,15.116,16.636,19.858c0.818,0.504,1.93,0.941,3.107,1.405 c0.104,0.041,0.208,0.082,0.312,0.123c0.87,2.954,2.098,5.784,3.659,8.427c6.255,10.875,17.954,17.644,30.519,17.644 c0.61,0,1.228-0.016,1.838-0.048l0.032-0.001l0.032-0.003c5.962-0.493,11.651-2.188,16.911-5.036 c3.256-1.642,6.17-3.827,8.658-6.495l0.074-0.079l0.066-0.086c0.064-0.087,0.133-0.175,0.203-0.264 c0.756,0.044,1.514,0.066,2.264,0.066c2.738,0,5.463-0.297,8.098-0.884c13.012-2.72,23.242-12.409,26.67-25.282 C311.102,265.396,311.309,259.487,310.271,253.619z"
        />
        <g>
          <path
            fill="#602364"
            d="M274.305,218.036c0.248-11.265-11.141-22.529-20.541-26.567c-11.388-5.065-24.654-3.313-34.334,4.535 c-4.292,3.638-7.771,8.14-10.208,13.211c-1.581,3.051-2.598,6.361-3.002,9.774c-0.047,1.702-0.27,3.396-0.663,5.052 c3.417-5.342,7.641-10.995,13.853-13.211c5.756-1.778,12.018-0.634,16.773,3.064c2.258,1.816,4.143,4.053,5.55,6.585l3.396,5.633 c0.187,0.356,0.395,0.702,0.621,1.035l0,0c4.824,7.147,14.529,9.032,21.677,4.209c4.326-2.92,6.908-7.81,6.879-13.03V218.036z"
          />
          <path
            fill="#5C9242"
            d="M241.027,230.481c0.007-6.936-4.526-13.059-11.162-15.075l0,0c-0.414-0.124-0.808-0.228-1.222-0.311 h-0.249h-0.331c-0.5-0.087-1.005-0.143-1.512-0.166l0,0h-0.476c-0.642,0-1.284,0-1.926,0c-1.054,0.012-2.105,0.095-3.148,0.249 l0,0c-8.504,1.52-16.302,5.713-22.26,11.969c-8.32,9.237-10.723,22.376-6.212,33.96c3.173,7.775,8.697,14.363,15.799,18.844 c1.615,0.994,5.736,2.071,6.833,3.334c-4.141-5.114-8.283-10.871-8.511-17.456c-0.228-8.428,6.709-16.048,14.93-18.119 c1.18-0.29,2.34-0.518,3.458-0.745l0,0l2.402-0.58c1.842-0.439,3.612-1.137,5.259-2.071c0.972-0.52,1.887-1.137,2.733-1.843 l0.684-0.579l0.725-0.787l0,0C239.512,238.207,241.004,234.42,241.027,230.481z"
          />
          <path
            fill="#DD2879"
            d="M255.398,295.047c-8.118-2.381-13.253-11.326-12.673-19.754c0-0.746,0.104-1.492,0.145-2.217l0,0l0,0 c0-0.311,0-0.6,0-0.91v-0.188c0-0.186,0-0.372,0-0.559c0.11-2.204,0.034-4.414-0.228-6.605l0,0 c-0.996-8.554-8.737-14.682-17.291-13.686c-6.782,0.789-12.261,5.898-13.522,12.609l0,0l0,0c0,0.518-0.166,1.056-0.207,1.573 c-0.283,2.315-0.421,4.646-0.414,6.978l0,0c-0.078,6.441,1.612,12.78,4.887,18.326c6.219,10.813,17.984,17.215,30.439,16.566 c5.646-0.467,11.133-2.104,16.111-4.805c3.037-1.527,5.777-3.582,8.096-6.066c1.064-1.407,2.287-2.686,3.645-3.811 C268.342,294.612,261.881,296.952,255.398,295.047z"
          />
          <path
            fill="#2972B8"
            d="M308.203,253.984c-0.527-3.38-1.65-6.64-3.314-9.629c-0.963-1.426-1.795-2.938-2.484-4.515 c0.311,6.337,0.291,13.315-3.438,18.761c-4.783,6.979-14.908,9.07-22.777,5.923l-0.414-0.165c-1.719-0.767-3.459-1.512-5.156-2.32 h-0.166c-0.402-0.225-0.816-0.425-1.242-0.6l0,0c-1.957-0.869-4.072-1.32-6.213-1.326c-8.703,0.09-15.686,7.217-15.596,15.92 c0.053,5.17,2.639,9.984,6.92,12.885l0,0l0.248,0.145l0.373,0.248c0.725,0.456,1.449,0.912,2.195,1.326 c0.744,0.414,2.07,1.076,3.168,1.574l0.725,0.289l0,0l1.49,0.601c6.4,2.362,13.346,2.829,20.004,1.347 c12.186-2.547,21.895-11.744,25.098-23.772C308.984,265.213,309.182,259.527,308.203,253.984z"
          />
          <path
            fill="#E76926"
            d="M290.768,202.133c-5.318-3.316-11.355-5.306-17.602-5.798c-2.473-0.173-4.957-0.097-7.414,0.228 c-0.973,0.168-1.934,0.396-2.877,0.684l2.236,2.07c4.447,1.322,8.424,3.896,11.451,7.414c4.762,6.212,4.141,15.344-0.207,21.825 l-0.477,0.58l-1.699,2.07c-1.139,1.388-2.256,2.774-3.291,4.142c-0.291,0.414-0.602,0.828-0.871,1.263 c-0.225,0.291-0.434,0.595-0.621,0.911l0,0c-4.541,7.425-2.201,17.125,5.223,21.666c2.432,1.486,5.221,2.287,8.072,2.314h0.123 h1.223c4.334-0.388,8.313-2.564,10.975-6.006c1.361-1.421,2.621-2.936,3.77-4.535c3.902-5.171,6.303-11.319,6.936-17.767 C306.99,220.85,301.209,208.839,290.768,202.133z"
          />
        </g>
      </g>
    </svg>
  );
}
