import React from "react";
import { useTrail, animated } from "react-spring";

export default function TrailFadeIn(props) {
  const { children, delay } = props;
  const trail = useTrail(children?.length, {
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: delay ?? 0,
    config: {
      tension: 800,
      friction: 40,
    },
  });

  return (
    <>
      {trail?.map((props, i) => (
        <animated.div style={props} key={i}>
          {children[i]}
        </animated.div>
      ))}
    </>
  );
}
