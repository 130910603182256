import React from "react";
import { Box, CircularProgress, Fade, Typography } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";

export default function CircularProgressWithLabel(props) {
  const theme = useTheme();
  const { value, sx } = props;

  const wrapStyles = {
    p: 1,
    borderRadius: "50%",
    backgroundColor: alpha(theme.palette.background.default, 0.1),
    backdropFilter: "blur(2px)",
  };
  const valueStyles = {
    fontSize: "1rem",
    fontWeight: 800,
    "& span": {
      fontWeight: 300,
    },
  };
  const progress100 = {
    fontWeight: 800,
    fontSize: "1.8rem",
    textAlign: "center",
    textShadow: `2px 2px 1px ${theme.palette.text.secondaryAlt}`,
    "& span": {
      fontWeight: 800,
    },
  };

  return (
    <Fade
      in
      timeout={theme.transitions.duration.enteringScreen}
      style={{
        transitionDelay: `400ms`,
      }}
    >
      <Box position="relative" display="inline-flex" sx={[wrapStyles, sx]}>
        <CircularProgress
          variant="determinate"
          value={value}
          color="primary"
          thickness={5}
          size={60}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            sx={[valueStyles, value === 100 && progress100]}
          >
            {value}
            <span>%</span>
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
