import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PossibleExternalLinkButton from "../common/PossibleExternalLinkButton";
import { alpha } from "@mui/material/styles";

export default function HeroStandard(props) {
  const { title, subtitle, description, image, url, linkText, sx } = props;
  const theme = useTheme();
  const extraSmallScreen = useMediaQuery(theme?.breakpoints.only("xs"));

  // Styles
  const imageWrapStyles = {
    float: extraSmallScreen ? "none" : "left",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "1rem",
    marginBottom: "1rem",
  };

  return (
    <Grid
      container
      sx={
        theme?.name === "Forest"
          ? {
              p: 1,
              mt: 16,
              "& h1": {
                mt: 0,
              },
              background: "rgba(255,255,255,0.075)",
              backdropFilter: "blur(24px)",
              border: "1px solid rgba( 255, 255, 255, 0.2 )",
              borderRadius: "0.5rem",
              "@supports not (backdrop-filter: blur(24px))": {
                background: alpha("#31785f", 0.8),
                border: "none",
              },
              "& button:hover": {
                background: "rgba(255,255,255,0.9)",
              },
              ...sx,
            }
          : { sx }
      }
    >
      <Grid item xs={12}>
        <Typography
          component="h1"
          variant="h1"
          sx={{ maxWidth: "100% !important" }}
        >
          <span style={imageWrapStyles}>{image}</span>
          {title}
        </Typography>
        <Typography
          variant="h4"
          component="h2"
          sx={{ maxWidth: "100% !important" }}
        >
          {subtitle}
        </Typography>
        <Typography sx={{ maxWidth: "100% !important" }}>
          {description}{" "}
          <PossibleExternalLinkButton url={url} linkText={linkText} />
        </Typography>
      </Grid>
    </Grid>
  );
}
