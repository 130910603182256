import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";

export function useGetStudentMedicalInformation(studentId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `studentMedical/GetStudentMedicalInformation?studentId=${studentId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error getting medical information. ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return query;
}
