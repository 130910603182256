import { useQuery, useQueryClient } from "react-query";
//import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet } from "../apiService";
import { useUser } from "../../services/contexts/userContext";

export function useParticipantUnpaidInvoices(execute = false, studentId, onSuccessFunction) {
    const queryClient = useQueryClient();
    //const snackbar = useSnackbar();
    const token = GetAccessToken();
    const { user, updateUser } = useUser();
    const url = `Invoices/GetUnpaidParticipantInvoices/${studentId}`;
  
    const query = useQuery(
      url,
      async () => {
        return await AppsGet(token, url);
      },
      {
        enabled: execute,
        onSuccess: async (data) => {
            const students = user?.students?.map((student) => {
                return student.studentId === studentId ? {...student, unpaidEloInvoices: data?.data} : student;
            });

            updateUser({students: students});

            onSuccessFunction(data);
        },
        // onError: (error) => {
        //   snackbar.enqueueSnackbar(`Error getting partner details. ${error}`, {
        //     variant: "error",
        //   });
        // },
      }
    );
    return query;
  }