import { Grid, Typography } from "@mui/material";
import { useUser } from "../../services/contexts/userContext";
import { StudentPicker } from "../../services/contexts/activeStudentContext";
import CoursesISR from "../common/CoursesISR";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Courses() {
  const { user } = useUser();

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1">Reports</Typography>
      <Grid container>
        <Typography variant="lede" component="p">
          View Reports about{" "}
          {user?.role === "guardian" ? "your students" : "yourself"}.
        </Typography>
      </Grid>

      <StudentPicker />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5}>
          <CoursesISR />
        </Grid>
      </Grid>
    </>
  );
}
