import {
  useQuery,
  useMutation,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";

import { useSnackbar } from "notistack";

import { GetAccessToken, AppsGet, AppsPost, AppsPatch } from "../apiService";

export function useGetMoods() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const query = useQuery(
    "CompassMoods",
    async () => {
      return await AppsGet(token, "CompassMoods");
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting moods. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetMoodsCount() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const query = useQuery(
    "StudentMoods/GetStudentMoodCount",
    async () => {
      return await AppsGet(token, "StudentMoods/GetStudentMoodCount");
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting moods. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
export function usePostStudentMood(studentMood, setStudentMoodId) {
  const queryClient = useQueryClient();

  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const mutation = useMutation(
    async () => {
      let postResponse = await AppsPost(
        token,
        "StudentMoods/PostStudentMood",
        studentMood
      );
      let data = await postResponse?.data;
      setStudentMoodId(data?.studentMoodId);
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          "StudentMoods/GetInfiniteScrollStudentMoods",
          (oldData) => {
            let firstPage = oldData?.pages[0].data ?? [];
            firstPage.unshift(data);
            oldData.pages[0].data = firstPage;
            return oldData;
          }
        );
        queryClient.invalidateQueries("StudentMoods/GetStudentMoodCount");
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `There was an error saving your mood. Please try again. ${error}`,
          {
            variant: "error",
          }
        );
        return null;
      },
    }
  );
  return mutation;
}

export function useGetInfiniteMoods() {
  const fetchMoods = async (page = 1) => {
    return await AppsGet(
      token,
      `StudentMoods/GetInfiniteScrollStudentMoods?pageNum=${page}&numItems=30`
    );
  };
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const infiniteMoods = useInfiniteQuery(
    "StudentMoods/GetInfiniteScrollStudentMoods",
    ({ pageParam = 1 }) => fetchMoods(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.data.length < 30 ? undefined : allPages.length + 1;
      },
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting your moods. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return infiniteMoods;
}

export function usePatchStudentMoodRating(studentMoodId) {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const token = GetAccessToken();
  const url = `StudentMoods/PatchStudentMoodRating`;
  const mutation = useMutation(
    url,
    async (resetTipRatingFunction) => {
      return await AppsPatch(token, url, studentMoodId).then(
        resetTipRatingFunction
      );
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          "StudentMoods/GetInfiniteScrollStudentMoods",
          (oldData) => {
            oldData.pages[0].data[0].rating = studentMoodId?.rating;
            return oldData;
          }
        );
      },
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error saving tip. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return mutation;
}

export function useGetStudentMoods() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `StudentMoods/GetStudentMoods/`;
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting your moods. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetTip(moodId) {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `ReflectionPrompts/GetRandomMoodPrompt/${moodId}`;
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    { enabled: !!moodId },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting tips. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetStudentMoodCountByMonth() {
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `StudentMoods/GetStudentMoodCountByMonth`;
  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error loading Moods Journey. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}
