import { Box } from "@mui/material";

export default function ThemePickerExample(props) {
  const { theme } = props;

  return (
    <Box>
      <svg
        width="150"
        height="225"
        viewBox="0 0 346 519"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="346"
          height="519"
          fill={theme?.palette?.background?.default}
        />
        <rect
          width="346"
          height="53"
          fill={theme?.palette?.nav?.appBar}
          fillOpacity="0.72"
        />
        <rect width="76" height="519" fill={theme?.palette?.nav?.main} />
        <circle
          cx="15.5"
          cy="75.5"
          r="6.5"
          fill={theme?.palette?.primary?.main}
        />
        <circle
          cx="15.5"
          cy="112.5"
          r="6.5"
          fill={theme?.palette?.nav?.contrastText}
        />
        <circle
          cx="15.5"
          cy="143.5"
          r="6.5"
          fill={theme?.palette?.nav?.contrastText}
        />
        <circle
          cx="15.5"
          cy="178.5"
          r="6.5"
          fill={theme?.palette?.nav?.contrastText}
        />
        <circle
          cx="17.5"
          cy="213.5"
          r="6.5"
          fill={theme?.palette?.nav?.contrastText}
        />
        <path
          d="M9.93934 24.9393C9.35355 25.5251 9.35355 26.4749 9.93934 27.0607L19.4853 36.6066C20.0711 37.1924 21.0208 37.1924 21.6066 36.6066C22.1924 36.0208 22.1924 35.0711 21.6066 34.4853L13.1213 26L21.6066 17.5147C22.1924 16.9289 22.1924 15.9792 21.6066 15.3934C21.0208 14.8076 20.0711 14.8076 19.4853 15.3934L9.93934 24.9393ZM34 24.5L11 24.5V27.5L34 27.5V24.5Z"
          fill={theme?.palette?.nav?.contrastText}
        />
        <rect
          x="97"
          y="180"
          width="130"
          height="44"
          fill={theme?.palette?.neutral?.main}
        />
        <rect
          x="97"
          y="241"
          width="130"
          height="44"
          fill={theme?.palette?.secondary?.contrastText}
        />
        <rect
          x="97"
          y="302"
          width="130"
          height="44"
          fill={theme?.palette?.neutral?.main}
        />
        <rect
          x="95"
          y="361"
          width="134"
          height="48"
          fill={theme?.palette?.primary?.text}
        />
        <rect
          x="97"
          y="363"
          width="130"
          height="44"
          fill={theme?.palette?.background?.default}
        />
        <rect
          x="97"
          y="424"
          width="130"
          height="44"
          fill={theme?.palette?.neutral?.main}
        />
        <line
          x1="253"
          y1="188.5"
          x2="332"
          y2="188.5"
          stroke={theme?.palette?.neutral?.main}
        />
        <line
          x1="253"
          y1="323.5"
          x2="332"
          y2="323.5"
          stroke={theme?.palette?.neutral?.main}
        />
        <line
          x1="253"
          y1="227.5"
          x2="332"
          y2="227.5"
          stroke={theme?.palette?.neutral?.main}
        />
        <line
          x1="76"
          y1="129.5"
          x2="346"
          y2="129.5"
          stroke={theme?.palette?.neutral?.main}
        />
        <rect
          x="34"
          y="71"
          width="35"
          height="9"
          fill={theme?.palette?.primary?.main}
        />
        <rect
          x="34"
          y="107"
          width="35"
          height="9"
          fill={theme?.palette?.nav?.contrastText}
        />
        <rect
          x="34"
          y="139"
          width="35"
          height="9"
          fill={theme?.palette?.nav?.contrastText}
        />
        <rect
          x="34"
          y="174"
          width="35"
          height="9"
          fill={theme?.palette?.nav?.contrastText}
        />
        <rect
          x="34"
          y="209"
          width="35"
          height="9"
          fill={theme?.palette?.nav?.contrastText}
        />
        <rect
          x="97"
          y="68"
          width="202"
          height="38"
          fill={theme?.palette?.primary?.text}
        />
        <circle cx="312" cy="161" r="13" fill={theme?.palette?.primary?.text} />

        <circle cx="99" cy="201" r="13" fill={theme?.palette?.tertiary?.main} />
        <circle
          cx="97"
          cy="261"
          r="13"
          fill={theme?.palette?.secondary?.main}
        />
        <circle cx="97" cy="324" r="13" fill={theme?.palette?.tertiary?.main} />
        <circle cx="97" cy="385" r="13" fill={theme?.palette?.primary?.text} />
        <circle cx="97" cy="446" r="13" fill={theme?.palette?.tertiary?.main} />
        <rect
          x="253"
          y="201"
          width="79"
          height="16"
          fill={theme?.palette?.primary?.main}
        />
        <rect
          x="253"
          y="153"
          width="37"
          height="16"
          fill={theme?.palette?.primary?.main}
        />
        <rect
          x="253"
          y="345"
          width="79"
          height="16"
          fill={theme?.palette?.primary?.main}
        />
        <rect
          x="253"
          y="248"
          width="79"
          height="16"
          fill={theme?.palette?.primary?.main}
        />
        <rect
          x="253"
          y="285"
          width="79"
          height="16"
          fill={theme?.palette?.primary?.main}
        />
      </svg>
    </Box>
  );
}
