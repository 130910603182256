import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPhone,
  faComment,
  faLifeRing,
} from "@fortawesome/free-solid-svg-icons";
import TrailSlideUpFadeIn from "../animations/TrailSlideUpFadeIn";

export default function Help() {
  const theme = useTheme();

  //Styles
  const accordionStyles = {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    "&.Mui-expanded:last-of-type": {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  };
  const accordionDetailsStyles = {
    paddingTop: 0,
  };
  const accordionIntroStyles = {
    "&.MuiTypography-body1": {
      maxWidth: 1000,
    },
  };
  const accordionButtonStyles = {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  };

  return (
    <Box>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2" gutterBottom>
        <FontAwesomeIcon icon={faLifeRing} /> Get Help
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <Typography variant="body1">
            You are not alone. There are compassionate people ready to listen
            and help you out of a crisis.
            <br />
            <strong>
              Need help now?{" "}
              <Link href="tel:988" color={theme.palette.secondary.text}>
                Call 988
              </Link>
            </strong>{" "}
            or{" "}
            <strong>
              <Link href="sms:741741" color={theme.palette.secondary.text}>
                Text <em>HOME</em> &nbsp;to 741741
              </Link>
            </strong>
            <br />
            <br />
            Find the services that seem best for you below.
          </Typography>
        </Grid>
        <Grid item xs={12} md={5} sx={{ textAlign: "right" }}>
          <Typography variant="h4" component="p" color="secondary">
            Emergency?
          </Typography>
          <Typography variant="h2" component="p" color="secondary">
            Call 911
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <TrailSlideUpFadeIn>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                aria-controls="accordion1-content"
                id="accordion1-header"
              >
                <Typography variant="h5" component="h3">
                  Suicide Lifeline
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://suicidepreventionlifeline.org/talk-to-someone-now/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                      color={theme.palette.primary.main}
                    >
                      National Suicide Prevention Lifeline
                    </Link>
                    <Typography variant="body2">
                      The National Suicide Prevention Lifeline fields calls{" "}
                      <strong>24/7</strong> for anyone with suicidal thoughts or
                      who are in crisis. They offer help for Spanish-speakers
                      and anyone who is deaf or hard of hearing. Their website
                      also offers many resources to get help for yourself or
                      someone you know.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+988"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      988
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                aria-controls="accordion2-content"
                id="accordion2-header"
              >
                <Typography variant="h5" component="h3">
                  Crisis Hotlines
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography sx={accordionIntroStyles}>
                      These two services specialize in helping individuals (and
                      their friends, family and loved ones) who are in crisis,
                      talking through family, personal, mental health and many
                      other issues.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.crisistextline.org"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      Crisis Text Line
                    </Link>
                    <Typography variant="body2">
                      Crisis Text Line fields messages <strong>24/7</strong>{" "}
                      about suicidal thoughts, abuse, sexual assault,
                      depression, anxiety, bullying and more. What makes it
                      unique is that it's entirely text-based, which makes it
                      easy for anyone who doesn't feel comfortable or safe
                      talking on the phone to use it. You can text 741741 in the
                      US or{" "}
                      <Link
                        href="https://www.facebook.com/help/1088995834578594"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>message Crisis Text Line on Facebook</strong>
                      </Link>{" "}
                      for help. You'll be matched with a volunteer counselor,
                      who is supervised by a licensed, trained mental health
                      professional.
                    </Typography>
                    <Button
                      variant="contained"
                      href="sms:741741"
                      startIcon={<FontAwesomeIcon icon={faComment} />}
                      sx={accordionButtonStyles}
                    >
                      Text{" "}
                      <strong>
                        <em>HOME</em>
                      </strong>{" "}
                      &nbsp;to 741741
                    </Button>
                    <Button
                      variant="contained"
                      href="https://www.facebook.com/help/1088995834578594"
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<FontAwesomeIcon icon={faComment} />}
                      sx={accordionButtonStyles}
                    >
                      Message on Facebook
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://oregonyouthline.org/about/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      YouthLine
                    </Link>
                    <Typography variant="body2">
                      Teens helping teens. Based in Portland, YouthLine provides
                      a safe space for people ages 11 to 21, to talk through any
                      issues they may be facing, including eating disorders,
                      relationship or family concerns, bullying, sexual
                      identity, depression, self-harm, anxiety and thoughts of
                      suicide.
                    </Typography>
                    <Button
                      variant="contained"
                      href="sms:839863"
                      startIcon={<FontAwesomeIcon icon={faComment} />}
                      sx={accordionButtonStyles}
                    >
                      Text{" "}
                      <strong>
                        <em>teen2teen</em>
                      </strong>{" "}
                      &nbsp;to 839863
                    </Button>
                    <Button
                      variant="contained"
                      href="tel:+01-877-968-8491"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-877-968-8491
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                aria-controls="accordion3-content"
                id="accordion3-header"
              >
                <Typography variant="h5" component="h3">
                  Abuse/Assault Hotlines
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography sx={accordionIntroStyles}>
                      For anyone who is a victim of child abuse, domestic
                      violence or sexual assault (or know someone who is), these
                      hotlines offer counseling and advice.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.childhelp.org/hotline"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      Childhelp National Child Abuse Hotline
                    </Link>
                    <Typography variant="body2">
                      For issues related to child abuse, Childhelp connects you
                      with a professional counselors to help in a crisis, and
                      provide information on how to get help. They offer phone
                      support in 170 languages.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-800-422-4453"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-800-422-4453
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.thehotline.org/support-others/help-for-abusive-partners/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      National Domestic Violence Hotline
                    </Link>
                    <Typography variant="body2">
                      Anyone who is experiencing domestic violence and/or abuse,
                      plus anyone concerned about a friend, family member or
                      loved one can call the National Domestic Violence Hotline
                      (NDVH) <strong>24/7</strong>. They offer support in more
                      than 200 languages, and offer a confidential, secure
                      online chat.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-800-799-7233"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-800-799-7233
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.adwas.org/hotline/national/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      National Deaf Domestic Violence Hotline
                    </Link>
                    <Typography variant="body2">
                      The National Deaf Domestic Violence Hotline is a spinoff
                      from the NDVH specifically for Deaf and Hard of Hearing
                      individuals. You can get help over email, or talk via
                      video call to a trained counselor.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-855-812-1001"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-855-812-1001
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.rainn.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      RAINN
                    </Link>
                    <Typography variant="body2">
                      The Rape, Abuse &amp; Incest National Network hotline is
                      for anyone who's experienced sexual abuse or assault. When
                      you call their main hotline, you'll be connected with
                      someone at a local organization in your area who can
                      provide live support and direct you to additional
                      resources.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-800-656-4673"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-800-656-4673
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                aria-controls="accordion4-content"
                id="accordion4-header"
              >
                <Typography variant="h5" component="h3">
                  Hotlines for the LGBTQIA+ Community
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography sx={accordionIntroStyles}>
                      Whether you're struggling with your gender identity or
                      sexuality, or are experiencing a crisis and want to talk
                      to someone who is part of the LGBTQIA+ community, you can
                      lean on these hotlines.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.thetrevorproject.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      The Trevor Project
                    </Link>
                    <Typography variant="body2">
                      LGBTQIA+ kids and teens can reach out to The Trevor
                      Project for support during a crisis, if they are feeling
                      suicidal or need a safe space to talk about any issue.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-866-488-7386"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-866-488-7386
                    </Button>
                    <Button
                      variant="contained"
                      href="sms:678678"
                      startIcon={<FontAwesomeIcon icon={faComment} />}
                      sx={accordionButtonStyles}
                    >
                      Text{" "}
                      <strong>
                        <em>START</em>
                      </strong>{" "}
                      &nbsp;to 678678
                    </Button>
                    <Button
                      variant="contained"
                      href="https://www.thetrevorproject.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<FontAwesomeIcon icon={faComment} />}
                      sx={accordionButtonStyles}
                    >
                      Chat online
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.translifeline.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      Trans Lifeline
                    </Link>
                    <Typography variant="body2">
                      The Trans Lifeline provides support specifically for
                      transgender and questioning callers, run by trans people.
                      They provide support during a crisis and can also offer
                      guidance to anyone who is questioning their gender and
                      needs support. The hotline is available between 7am and
                      1am PST (9am to 3am CST or 10am to 4am EST). But,
                      operators are often available during off-hours, so no
                      matter when you need to call, you should.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-877-565-8860"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-877-565-8860
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.glbthotline.org/youth-talkline.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      LGBT National Youth Talkline
                    </Link>
                    <Typography variant="body2">
                      This line offers support, but not necessarily crisis
                      intervention. You can also try the general number{" "}
                      <Link href="tel:+01-888-843-4564">
                        <strong>1-888-843-4564</strong>
                      </Link>
                      .
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-800-246-7743"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-800-246-7743
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={accordionStyles}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
                aria-controls="accordion5-content"
                id="accordion5-header"
              >
                <Typography variant="h5" component="h3">
                  Drug or Alcohol Abuse Hotline
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={accordionDetailsStyles}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    If you are experiencing any kind of emergency situation
                    related to drug or alcohol use, you should call 911.
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Link
                      href="https://www.samhsa.gov/find-help/national-helpline"
                      target="_blank"
                      rel="noopener noreferrer"
                      variant="h6"
                    >
                      SAMHSA National Helpline
                    </Link>
                    <Typography variant="body2">
                      If you are struggling with addiction or are concerned
                      about a loved one's alcohol or drug abuse, you can contact
                      the hotline for the Substance Abuse and Mental Health
                      Services Administration. This US government agency offers
                      support and information about treatment and recovery.
                    </Typography>
                    <Button
                      variant="contained"
                      href="tel:+01-800-662-4357"
                      startIcon={<FontAwesomeIcon icon={faPhone} />}
                      sx={accordionButtonStyles}
                    >
                      1-800-662-4357
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Typography variant="caption">
              <br />
              <br />
              The information contained on this page is for educational and
              informational purposes only and is not intended as health or
              medical advice. Always consult a physician or other qualified
              health provider regarding any questions you may have about a
              medical condition or health objectives.
            </Typography>
          </TrailSlideUpFadeIn>
        </Grid>
      </Grid>
    </Box>
  );
}
