import { Avatar } from "@mui/material";
import { useStudentPhoto } from "../../services/hooks/studentPhotoHook";

export default function StudentPhoto(props) {
  const { studentId, name, variant, styles } = props;
  const photoQuery = useStudentPhoto(studentId);
  const photoData = photoQuery?.data;

  const avatarStyles = {
    height: "3em",
    width: "3em",
  };

  return (
    <>
      <Avatar
        alt={`${name}'s photo`}
        src={photoData}
        variant={variant ?? "circular"}
        sx={[avatarStyles, styles]}
      />
    </>
  );
}
