import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useGetSurveys() {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `survey/getSurveys`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      staleTime: 1000 * 60 * 10, // 10 mins
      cacheTime: 1000 * 60 * 10, // 10 mins
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting list of surveys. ${error}`, {
          variant: "error",
        });
      },
    }
  );
  return query;
}

export function useGetSurvey(surveyId) {
  const token = GetAccessToken();
  const snackbar = useSnackbar();
  const url = `survey/getSurvey/${surveyId}`;

  const query = useQuery(
    url,
    async () => {
      return await AppsGet(token, url);
    },
    {
      enabled: !!surveyId,
      staleTime: 1000 * 60 * 10, // 10 mins
      cacheTime: 1000 * 60 * 10, // 10 mins
      onError: (error) => {
        snackbar.enqueueSnackbar(`Error getting survey. ${error}`, {
          variant: "error",
        });
      },
    }
  );

  return query;
}

export function usePostSurvey(surveyResponse) {
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();
  const token = GetAccessToken();
  const url = `survey/PostSurveyResponse`;
  const mutation = useMutation(
    async () => {
      return await AppsPost(token, url, surveyResponse);
    },
    {
      onSuccess: async (response) => {
        await queryClient.invalidateQueries(`survey/GetSurveys`);
        snackbar.enqueueSnackbar(`Survey submitted`, {
          variant: "success",
        });
      },
      onError: (error) => {
        snackbar.enqueueSnackbar(
          `Error submitting survey.  Please try again ${error}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
  return mutation;
}
