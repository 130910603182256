import { useGetStudentInterestsRatingCounts } from "../../services/hooks/interestsHook";
import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import ReactECharts from "echarts-for-react";
import { interestsRatingDefinitions } from "../../services/constants";

export default function InterestsStats() {
  const theme = useTheme();
  const interestsInfo = useGetStudentInterestsRatingCounts();
  const interestsData = interestsInfo?.data?.data;

  const interestSeries = useMemo(
    () =>
      interestsData?.map((id) => {
        return {
          category: id.interestCategory,
          ratings: interestsRatingDefinitions?.map((rd) => {
            return {
              name: rd.name,
              value: rd.value,
              count:
                id?.ratingCounts?.find((rc) => rc.rating === rd.value)?.count ??
                0,
            };
          }),
        };
      }),
    [interestsData]
  );

  const seriesRatings = interestSeries?.map((c) =>
    c.ratings?.map((e) => e.count)
  );

  const interestsChart = {
    legend: {
      textStyle: {
        color: theme?.palette?.text?.primary,
      },
      grid: {
        top: "25%", //From the top margin
        left: "25%", //distance from the left margin
        right: "25%", //distance from the right margin
        bottom: "25%", //distance from bottom margin
      },
      padding: 5,
    },

    tooltip: {
      trigger: "axis",
    },
    dataset: {
      source: [
        ["Ratings", ...interestsRatingDefinitions.map((m) => m.name)],
        interestSeries?.length > 0 && [
          interestSeries[0]?.category,
          ...seriesRatings[0],
        ],
        interestSeries?.length > 1 && [
          interestSeries[1]?.category,
          ...seriesRatings[1],
        ],
        interestSeries?.length > 2 && [
          interestSeries[2]?.category,
          ...seriesRatings[2],
        ],
        interestSeries?.length > 3 && [
          interestSeries[3]?.category,
          ...seriesRatings[3],
        ],
        interestSeries?.length > 4 && [
          interestSeries[4]?.category,
          ...seriesRatings[4],
        ],
      ],
    },
    textStyle: {
      color: theme?.palette?.text?.primary,
    },
    grid: {
      top: "35%", //From the top margin
      left: "3%", //distance from the left margin
      right: "4", //distance from the right margin
      bottom: "5%", //distance from bottom margin
      containLabel: true,
    },

    xAxis: {
      type: "category",
      axisLabel: {
        show: true,
        rotate: 45,
      },
    },

    yAxis: {
      gridIndex: 0,
    },
    series: [
      {
        type: "bar",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.first },
      },
      {
        type: "bar",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.second },
      },
      {
        type: "bar",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.third },
      },
      {
        type: "bar",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.fourth },
      },
      {
        type: "bar",
        smooth: true,
        seriesLayoutBy: "row",
        emphasis: {
          focus: "series",
        },
        itemStyle: { color: theme?.palette?.charts?.fifth },
      },
    ],
  };

  return (
    <>
      <ReactECharts option={interestsChart} />
    </>
  );
}
