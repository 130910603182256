import { useQuery, useQueryClient, useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { GetAccessToken, AppsGet, AppsPost } from "../apiService";

export function useDsaPartnerDetails(partnerId) {
    const snackbar = useSnackbar();
    const token = GetAccessToken();
    const url = `Partners/GetPartnerForFamilies/${partnerId}`;
  
    const query = useQuery(
      url,
      async () => {
        return await AppsGet(token, url);
      },
      {
        //   enabled: !!studentId && !!programId,
        onError: (error) => {
          snackbar.enqueueSnackbar(`Error getting partner details. ${error}`, {
            variant: "error",
          });
        },
      }
    );
    return query;
  }