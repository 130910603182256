import React, { useState } from "react";
import {
  IconButton,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Link,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router";

export default function EventsAbout() {
  const theme = useTheme();
  const [aboutDialogIsOpen, setAboutDialogIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleAboutClick = () => {
    setAboutDialogIsOpen(true);
  };
  const handleAboutClose = () => {
    setAboutDialogIsOpen(false);
  };

  // Styles
  const closeButtonStyles = {
    width: 32,
    height: 32,
    color: theme?.palette?.neutral?.main
      ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.7)
      : "grey",
    "&:hover": {
      color: theme?.palette?.neutral?.light,
    },
  };
  const howToStyles = {
    "& p": {
      marginBottom: "1rem",
    },
    "& h4": {
      marginBottom: "1rem",
    },
  };
  const actionsStyles = {
    borderTop: `1px solid ${
      theme?.palette?.neutral?.main
        ? alpha(theme?.palette?.neutral?.main ?? "#777", 0.2)
        : "grey"
    }`,
  };

  return (
    <>
      <Button
        variant="bigIcon"
        startIcon={<FontAwesomeIcon icon={faInfoCircle} />}
        onClick={() => {
          handleAboutClick();
        }}
      >
        Learn
        <br />
        About
        <br />
        Events
      </Button>
      <Dialog open={aboutDialogIsOpen} onClose={handleAboutClose}>
        <DialogTitle>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <FontAwesomeIcon icon={faInfoCircle} /> About Events
            </Grid>
            <Grid item>
              <IconButton onClick={handleAboutClose} sx={closeButtonStyles}>
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={howToStyles}>
            <Typography variant="h4" color="primary">
              How to use Compass Events
            </Typography>
            <Typography>
              <strong>Bringing it all together...</strong>
              <br />
              The Compass Events calendar automatically pulls in info from the{" "}
              <Link
                href="https://www.tacomaschools.org/about/calendar"
                target="_blank"
                rel="noopener noreferrer"
                color={theme.palette.primary.main}
              >
                district website&nbsp;
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
              ,{" "}
              <Link
                href="https://www.tacomaschools.org/schools-and-learning-sites"
                target="_blank"
                rel="noopener noreferrer"
                color={theme.palette.primary.main}
              >
                school websites&nbsp;
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
              ,{" "}
              <Link
                onClick={() => {
                  navigate("/activities");
                }}
              >
                <strong>Beyond the Bell Activities</strong>
              </Link>
              , etc. and displays them all in one place.
              <br />
              <br />
              Click an event to see any available&nbsp;details.
              <br />
              Use the checkboxes to toggle calendars on and off.
            </Typography>
            <Typography variant="body2">
              You can also look back at excused and unexcused absences on the{" "}
              <Link
                onClick={() => {
                  navigate("/attendance");
                }}
              >
                <strong>Attendance&nbsp;page</strong>
              </Link>
              .
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={actionsStyles}>
          <Button
            variant="contained"
            color="neutral"
            onClick={handleAboutClose}
            startIcon={<FontAwesomeIcon icon={faCheck} />}
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
