import React from "react";
import { Box, Typography } from "@mui/material";
import InterestsIntro from "../common/InterestsIntro";
import InterestsCategories from "../common/InterestsCategories";
import InterestsMyRatings from "../common/InterestsMyRatings";
import { ScrollToTopOnMount } from "../../services/utilities";

export default function Interests() {
  //Styles

  return (
    <Box>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2" gutterBottom>
        Interests
      </Typography>
      <InterestsIntro />

      <br />

      <InterestsCategories />

      <br />

      <InterestsMyRatings />
    </Box>
  );
}
