import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/pro-solid-svg-icons";
import useCommonStyles from "./../../services/hooks/useCommonStyles";
import { blob } from "../../services/utilities";
import { useSpring, animated, config } from "react-spring";

export default function AboutCompassGoals() {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const commonStyles = useCommonStyles();

  const slideStyles = {
    ...commonStyles.slideStyles,
    background: `url(${blob}intro-goals.svg) no-repeat top left #bbdefb`,
    backgroundPosition: !!largeScreen ? "0 0" : "-19px -32px",
  };

  // Animations
  const headingSpringProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -500 },
    delay: 200,
    config: config.gentle,
  });
  const paragraphSpringProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 600,
    config: config.gentle,
  });
  const AnimatedHeading = animated(Typography);
  const AnimatedParagraph = animated(Typography);

  return (
    <Box sx={slideStyles}>
      <Typography component="h1" variant="h1" color="#212121">
        <FontAwesomeIcon icon={faFlag} color="#c62828" />
        &nbsp;Goals
      </Typography>
      <AnimatedHeading
        style={headingSpringProps}
        component="h2"
        variant="h4"
        color="#fff"
        sx={{ mt: !!largeScreen ? 30 : 20 }}
      >
        What do you <em>really</em> want?
      </AnimatedHeading>
      <AnimatedParagraph
        style={paragraphSpringProps}
        variant="body2"
        color="#fff"
        sx={{
          fontSize: !!largeScreen ? "1.25rem !important" : "1rem !important",
        }}
      >
        Are you willing to work for it? Set a goal in Compass and we'll help you
        with tips and reminders so you can go after it - no matter how big,
        hairy and audacious your goal is!
        <br />
        <em>
          Will you ...climb a mountain ...get a 4.0 ...become a doctor ...ace
          your Math test? <strong>It's up to you.</strong>
        </em>
      </AnimatedParagraph>
    </Box>
  );
}
