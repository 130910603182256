import React from "react";
import { Box, Typography } from "@mui/material";

export default function HeroBasic(props) {
  const { title, description } = props;

  return (
    <Box>
      <Typography
        component="h1"
        variant="h1"
        sx={{ maxWidth: "100% !important" }}
      >
        {title}
      </Typography>
      <Typography sx={{ maxWidth: "100% !important" }}>
        {description}
      </Typography>
    </Box>
  );
}
