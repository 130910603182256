import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  Grid,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ScrollToTopOnMount } from "../../services/utilities";
import { useGetMoods, useGetTip } from "../../services/hooks/moodsHook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faThumbsUp,
  faThumbsDown,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { useSpring, animated } from "react-spring";
import MoodsMy from "../common/MoodsMy";
import MoodsAbout from "../common/MoodsAbout";
import {
  usePostStudentMood,
  usePatchStudentMoodRating,
} from "../../services/hooks/moodsHook";
import { useUser } from "../../services/contexts/userContext";
import { blob } from "../../services/utilities";

export default function Moods() {
  const theme = useTheme();
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [selectedMood, setSelectedMood] = useState();
  const [tipDialogIsOpen, setTipDialogIsOpen] = useState(false);
  const [tipRating, setTipRating] = useState(0);
  const [confirmedMood, setConfirmedMood] = useState();
  const { user } = useUser();
  const [studentMoodId, setStudentMoodId] = useState();

  const studentId = user?.studentDetails?.studentId;

  const lgLowEnd = useMediaQuery("(min-width:1200px) and (max-width:1335px)");

  const moodsResponse = useGetMoods();
  const moods = moodsResponse?.data?.data;

  const tipResponse = useGetTip(selectedMood?.moodId);

  const tip = tipResponse?.data?.data;

  const closeButtonStyles = {
    width: 32,
    height: 32,
    position: "absolute",
    right: 4,
    top: 4,
    zIndex: 1000,
    color:
      theme?.palette?.moods[`${selectedMood?.mood?.color}Zone`]?.contrastText,
  };

  const handleTipUpClick = () => {
    setTipRating(2);
    setTipDialogIsOpen(false);
  };

  const handleTipDownClick = () => {
    setTipRating(1);
    setTipDialogIsOpen(false);
  };

  const handleMoodClick = (m) => {
    setConfirmDialogIsOpen(true);
    setSelectedMood(m);
  };
  const handleConfirmClose = () => {
    setConfirmDialogIsOpen(false);
  };
  const handleConfirm = (m) => {
    setConfirmedMood(selectedMood);
    handleConfirmClose();
    setTipDialogIsOpen(true);
  };
  const handleTipClose = () => {
    setTipDialogIsOpen(false);
    setSelectedMood();
    setConfirmedMood();
  };

  const createMood = usePostStudentMood(
    {
      MoodID: selectedMood?.moodId,
      StudentID: studentId,
      ReflectionPromptMoodId: tip?.reflectionPromptMoodId,
      CreatedDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      rating: tipRating,
    },
    setStudentMoodId
  );

  const patchRating = usePatchStudentMoodRating({
    studentMoodId: studentMoodId,
    rating: tipRating,
  });

  useEffect(() => {
    if (tipRating !== 0) {
      patchRating.mutateAsync(() => {
        setTipRating(0);
      });
    }
  }, [tipRating]);

  const displayMood = (m, index) => {
    return (
      <Grid item sm={6} key={index}>
        <Link
          color={theme.palette.text.primary}
          underline="none"
          sx={moodWrapStyles}
          onClick={() => {
            handleMoodClick(m);
          }}
        >
          <img src={m.url} alt="" style={emojiStyles} />
          <Typography>{m.name}</Typography>
        </Link>
      </Grid>
    );
  };

  //Styles
  const citeStyles = {
    "&.MuiTypography-body1": {
      fontStyle: "normal",
      fontSize: "1rem",
    },
  };
  const aboutWrapStyles = {
    textAlign: "right",
  };
  const moodPaperStyles = {
    padding: theme.spacing(2),
    background: `${theme.palette.background.neutralAlt} !important`,
    color: theme.palette.text.primaryAlt,
    marginBottom: theme.spacing(6),
  };
  const bluePanelStyles = {
    background: `${theme.palette.moods.blueZone.main} !important`,
    color: theme.palette.moods.blueZone.contrastText,
    "& .MuiAccordionDetails-root": {
      backgroundColor: theme.palette.moods.blueZone.secondary,
      "& .MuiLink-root": {
        color: theme.palette.moods.blueZone.contrastText,
      },
    },
  };
  const greenPanelStyles = {
    background: `${theme.palette.moods.greenZone.main} !important`,
    color: theme.palette.moods.greenZone.contrastText,
    "& .MuiAccordionDetails-root": {
      backgroundColor: theme.palette.moods.greenZone.secondary,
      "& .MuiLink-root": {
        color: theme.palette.moods.greenZone.contrastText,
      },
    },
  };
  const yellowPanelStyles = {
    background: `${theme.palette.moods.yellowZone.main} !important`,
    color: theme.palette.moods.yellowZone.contrastText,
    "& .MuiAccordionDetails-root": {
      backgroundColor: theme.palette.moods.yellowZone.secondary,
      "& .MuiLink-root": {
        color: theme.palette.moods.yellowZone.contrastText,
      },
    },
  };
  const redPanelStyles = {
    background: `${theme.palette.moods.redZone.main} !important`,
    color: theme.palette.moods.redZone.contrastText,
    "& .MuiAccordionDetails-root": {
      backgroundColor: theme.palette.moods.redZone.secondary,
      "& .MuiLink-root": {
        color: theme.palette.moods.redZone.contrastText,
      },
    },
  };
  const subtitleStyles = {
    fontStyle: "italic",
    fontSize: "1rem !important",
    fontWeight: 300,
    marginLeft: theme.spacing(1),
  };
  const moodWrapStyles = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: ".4s ease all",
    minWidth: 200,
    cursor: "pointer",
    borderRadius: "0.5rem",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "& .MuiTypography-body1": {
      fontSize: lgLowEnd && "14px",
    },
  };
  const emojiStyles = {
    background: `url(${blob}Moods/mood-bg.svg) no-repeat top center`,
    width: "5rem",
    margin: theme.spacing(1),
  };
  const emojiLargeStyles = {
    ...emojiStyles,
    width: "18rem",
  };

  const dialogTitleColorStyles = [
    {
      background: theme?.palette?.moods?.[`${selectedMood?.color}Zone`]?.main,
      color: theme?.palette?.moods[`${selectedMood?.color}Zone`]?.contrastText,
    },
  ];

  const dialogContentColorStyles = [
    {
      background: theme?.palette?.moods[`${selectedMood?.color}Zone`]?.main,
      color: theme?.palette?.moods[`${selectedMood?.color}Zone`]?.contrastText,
    },
  ];
  const dialogTitleStyles = [
    {
      textTransform: "capitalize",
      paddingBottom: theme.spacing(1.5),
    },
  ];
  const confirmDialogTitleStyles = [
    ...dialogTitleStyles,
    ...dialogTitleColorStyles,
  ];
  const dialogHeadingStyles = {
    textAlign: "center",
    color: theme.palette.text.primaryAlt,
    textShadow: `4px 4px 20px ${theme.palette.text.primary}, -4px -4px 20px ${theme.palette.text.primary}, 0px 0px 40px ${theme.palette.text.primary}`,
  };
  const dialogActionsStyles = {
    display: "flex",
    justifyContent: "space-between",
  };
  const confirmDialogContentStyles = {
    marginTop: theme.spacing(3),
    "& .MuiGrid-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
  const tipDialogTitleStyles = [
    ...dialogTitleStyles,
    ...dialogTitleColorStyles,
    selectedMood?.color === "yellow" && {
      color: theme?.palette?.moods?.yellowZone?.contrastText,
    },
  ];
  const tipDialogContentStyles = [
    {
      "& .MuiGrid-item": {
        display: "flex",
        alignItems: "center",
      },
    },
    ...dialogContentColorStyles,
  ];
  const tipDialogActionStyles = [
    dialogActionsStyles,
    ...dialogContentColorStyles,
  ];

  // Animations
  const blueSpringProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: -500 },
    delay: 200,
  });
  const greenSpringProps = useSpring({
    to: { opacity: 1, x: 0 },
    from: { opacity: 0, x: 500 },
    delay: 200,
  });
  const yellowSpringProps = useSpring({
    to: { opacity: 1, x: 0 },
    from: { opacity: 0, x: -500 },
    delay: 200,
  });
  const redSpringProps = useSpring({
    to: { opacity: 1, y: 0 },
    from: { opacity: 0, y: 500 },
    delay: 200,
  });
  const AnimatedAccordion = animated(Accordion);

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h1" component="h2">
        Moods
      </Typography>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Typography variant="lede" component="p">
            "Life is 10% what happens to us and 90% how we react to&nbsp;it."{" "}
            <Typography component="cite" sx={citeStyles}>
              ~&nbsp;Charles&nbsp;Swindoll
            </Typography>
          </Typography>
          <Typography>
            It's natural to experience all the zones, and the key is learning
            how to recognize and manage our zone based on where we are and who
            we are&nbsp;with.
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12} md={3} sx={aboutWrapStyles}>
          <MoodsAbout
            selectedMood={selectedMood}
            confirmedMood={confirmedMood}
          />
        </Grid>
      </Grid>
      <Paper sx={moodPaperStyles}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              component="h3"
              sx={{ textAlign: "center" }}
            >
              How are you feeling?
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <AnimatedAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={bluePanelStyles}
              style={blueSpringProps}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                <Typography variant="h5" component="h4">
                  Blue Zone
                  <Typography
                    variant="subtitle"
                    component="span"
                    sx={subtitleStyles}
                  >
                    Moving slowly...
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {moods?.map((m, index) => {
                    if (m?.color === "blue") {
                      return displayMood(m, index);
                    }
                    return null;
                  })}
                </Grid>
              </AccordionDetails>
            </AnimatedAccordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <AnimatedAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={greenPanelStyles}
              style={greenSpringProps}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                <Typography variant="h5" component="h4">
                  Green Zone
                  <Typography
                    variant="subtitle"
                    component="span"
                    sx={subtitleStyles}
                  >
                    Good to go...
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {moods?.map((m, index) => {
                    if (m?.color === "green") {
                      return displayMood(m, index);
                    }
                    return null;
                  })}
                </Grid>
              </AccordionDetails>
            </AnimatedAccordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <AnimatedAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={yellowPanelStyles}
              style={yellowSpringProps}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                <Typography variant="h5" component="h4">
                  Yellow Zone
                  <Typography
                    variant="subtitle"
                    component="span"
                    sx={subtitleStyles}
                  >
                    Use caution...
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container sx={{ justifyContent: "space-between" }}>
                  {moods?.map((m, index) => {
                    if (m?.color === "yellow") {
                      return displayMood(m, index);
                    }
                    return null;
                  })}
                </Grid>
              </AccordionDetails>
            </AnimatedAccordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <AnimatedAccordion
              TransitionProps={{ unmountOnExit: true }}
              sx={redPanelStyles}
              style={redSpringProps}
            >
              <AccordionSummary
                expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
              >
                <Typography variant="h5" component="h4">
                  Red Zone
                  <Typography
                    variant="subtitle"
                    component="span"
                    sx={subtitleStyles}
                  >
                    Stop...
                  </Typography>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {moods?.map((m, index) => {
                    if (m?.color === "red") {
                      return displayMood(m, index);
                    }
                    return null;
                  })}
                </Grid>
              </AccordionDetails>
            </AnimatedAccordion>
          </Grid>
        </Grid>
      </Paper>
      <MoodsMy
        setTipDialogIsOpen={setTipDialogIsOpen}
        selectedMood={selectedMood}
        setSelectedMood={setSelectedMood}
        setTipRating={setTipRating}
        createdMood={createMood}
      />

      <Dialog open={confirmDialogIsOpen} onClose={handleConfirmClose}>
        <DialogTitle sx={confirmDialogTitleStyles}>
          {selectedMood?.color} Zone
        </DialogTitle>
        <DialogContent sx={confirmDialogContentStyles}>
          <IconButton onClick={handleConfirmClose} sx={closeButtonStyles}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
          <Grid container>
            <Grid item xs={12}>
              <img src={selectedMood?.url} alt="" style={emojiLargeStyles} />
              <Typography component="h1" variant="h1" sx={dialogHeadingStyles}>
                {selectedMood?.name}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={dialogActionsStyles}>
          <Button size="small" color="neutral" onClick={handleConfirmClose}>
            Cancel
          </Button>
          <Button
            disabled={tip == null}
            variant="contained"
            color="neutral"
            onClick={() => {
              createMood.mutateAsync();
              handleConfirm(selectedMood);
            }}
          >
            {tip == null && <CircularProgress />}
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={tipDialogIsOpen} onClose={handleTipClose}>
        <DialogTitle sx={tipDialogTitleStyles}>
          {confirmedMood?.color} Zone
        </DialogTitle>
        <DialogContent sx={tipDialogContentStyles}>
          <Grid container>
            <Grid item xs={12}>
              <img src={confirmedMood?.url} alt="" style={emojiStyles} />
              <Typography component="h1" variant="h4">
                {confirmedMood?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h6"
                sx={tipDialogContentStyles}
              >
                A tip based on your mood:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={tipDialogActionStyles}>
                {tip?.reflectionPrompt?.prompt}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={tipDialogActionStyles}>
          <Button
            variant="contained"
            color="neutral"
            startIcon={<FontAwesomeIcon icon={faThumbsDown} />}
            onClick={() => {
              handleTipDownClick();
            }}
          >
            Dislike
          </Button>
          <Button
            variant="contained"
            color="neutral"
            endIcon={<FontAwesomeIcon icon={faThumbsUp} />}
            onClick={() => {
              handleTipUpClick();
            }}
          >
            Like
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
